/* eslint-disable react-hooks/exhaustive-deps */
import { useDraggable } from '@dnd-kit/core';
import classNames from 'classnames';
import { CSSProperties, useEffect, useRef } from 'react';
import useMusemindStore from '~/stores/musemind-store';

type DraggableDivProps = {
  children: React.ReactNode;
  className?: string;
};

const DraggableDiv = (props: DraggableDivProps) => {
  const { children, className } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const musemind = useMusemindStore(['pipPosition', 'setPipPosition']);
  const { transform, attributes, listeners, setNodeRef } = useDraggable({ id: 'draggable-pip' });

  useEffect(() => {
    if (containerRef.current) {
      setNodeRef(containerRef.current);
    }
  }, []);

  const handleAnimationEnd = () => {
    if (containerRef.current) {
      const boudingRect = containerRef.current.getBoundingClientRect();

      musemind.setPipPosition({
        ...boudingRect,
        width: boudingRect.width,
        height: boudingRect.height,
        top: boudingRect.top,
        left: boudingRect.left,
      } as DOMRectReadOnly);
    }
  };

  const customStyle: CSSProperties = {
    top: musemind.pipPosition?.top,
    left: musemind.pipPosition?.left,
    width: musemind.pipPosition?.width,
    height: musemind.pipPosition?.height,
  };

  const style = transform
    ? {
        top: musemind.pipPosition!.top + (transform?.y || 0),
        left: musemind.pipPosition!.left + (transform?.x || 0),
      }
    : {};

  return (
    <div
      id="draggable-div"
      onAnimationEnd={handleAnimationEnd}
      {...listeners}
      {...attributes}
      ref={containerRef}
      style={{ ...customStyle, ...style }}
      className={classNames('absolute data-[is-dragging]=true:cursor-grab', className)}
    >
      {children}
    </div>
  );
};

export default DraggableDiv;
