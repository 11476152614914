import Input from '~/components/ui/forms/input/input';
import { Controller, useFormContext } from 'react-hook-form';
import { UploadTrack } from '~/types/features/track-upload/track-upload.types';
import { CatalogInput } from './catalog-input';
import { TitleInput } from './title-input';
import { SpotifyTrack } from '~/types/schemas/track-upload/spotify-search.schema';
import { useTranslation } from 'react-i18next';
import { VersionSelect } from './version-select';
import { StepFormData } from '../form';
import { useCallback, useState } from 'react';
import { useTracksUploadItemContext } from '../../../../tracks-upload-item.context';
import errorHandler from '~/utils/error-handler';
import { CoverInput } from './cover-input';
import { MusicTypeSelect } from './music-type-select';

type TrackStepsFormContentProps = {
  file: UploadTrack;
  data: UploadTrack['matchedMetadata'] | UploadTrack['metadata'];
};

export function StepFormContent(props: TrackStepsFormContentProps) {
  const { file, data } = props;
  const { t } = useTranslation('tracks-upload-form');
  const { control, setValue } = useFormContext<StepFormData>();
  const { presignedData } = useTracksUploadItemContext();
  const [type, setType] = useState<string>(data?.type || 'manual');

  const handleSpotifySelect = useCallback(
    (track: SpotifyTrack) => {
      if (!track) {
        errorHandler('Spotify track is undefined on select', {
          extra: { file },
        });
      }
      const { title, artists, album, cover } = file.matchedMetadata || {};
      setValue('title', track.title || title, { shouldValidate: false });
      setValue('artists', track.artists || artists, { shouldValidate: false });
      setValue('album', track.album || album, { shouldValidate: false });
      setValue('cover', track.cover || cover, { shouldValidate: false });
      setValue('type', 'spotify', { shouldValidate: false });
      setValue('spotifyId', track.id, { shouldValidate: false });
      setValue('useId3Cover', false, { shouldValidate: false });
      setType('spotify');
    },
    [setValue, file],
  );
  const onChangeManual = useCallback(() => {
    setValue('type', 'manual');
    setValue('spotifyId', undefined);
    setValue('useId3Cover', false);
    setType('manual');
  }, [setValue]);

  return (
    <div className="custom-scrollbar row-span-4 flex h-full w-full flex-col items-center justify-between gap-3 overflow-x-hidden overflow-y-auto p-5">
      <div className="flex w-full shrink-0 items-end gap-4">
        <MusicTypeSelect
          musicType={file.matchedMetadata?.musicType}
          onMusicTypeChange={(values) => setValue('musicType', values.value)}
        />
        <VersionSelect
          version={file.matchedMetadata?.version}
          onVersionChange={(values) => setValue('version', values.value)}
        />
      </div>
      <Controller
        name="title"
        control={control}
        defaultValue={data?.title || file?.name}
        rules={{ required: t('card.form.title.empty') }}
        render={({ field: { value, onChange }, fieldState }) => {
          function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
            onChange(e);
            onChangeManual();
          }
          return (
            <TitleInput
              label={t('card.form.title.label')}
              error={fieldState.error?.message}
              value={value}
              onItemSelect={handleSpotifySelect}
              onChange={handleChange}
              required
            />
          );
        }}
      />
      <Controller
        name="artists"
        control={control}
        defaultValue={data?.artists}
        rules={{ required: t('card.form.artists.empty') }}
        render={({ field: { value, onChange }, fieldState }) => {
          function handleChange(v: string) {
            if (v === '' || v == null) {
              onChange([]);
              onChangeManual();
              return;
            }

            const artists = v.split(',');
            onChange(artists);
          }
          return (
            <Input
              label={t('card.form.artists.label')}
              labelInfo='Hit "Enter" to add artist'
              type="tags"
              value={value ? value.join(',') : ''}
              onChange={handleChange}
              error={fieldState.error?.message}
              required
            />
          );
        }}
      />
      <div className="flex w-full items-start gap-3">
        <Controller
          name="album"
          control={control}
          defaultValue={data?.album}
          rules={{ required: t('card.form.album.empty') }}
          render={({ field: { value, onChange }, fieldState }) => {
            function handleChange(v: string) {
              onChange(v);
              onChangeManual();
            }
            return (
              <Input
                type="text"
                label={t('card.form.album.label')}
                className="flex-1 shrink-0"
                error={fieldState.error?.message}
                value={value}
                onChange={handleChange}
                required
              />
            );
          }}
        />
        <Controller
          name="catalog"
          control={control}
          defaultValue={data?.catalog}
          rules={{ required: t('card.form.catalog.empty') }}
          render={({ field: { value, onChange }, fieldState }) => {
            return (
              <CatalogInput
                label={t('card.form.catalog.label')}
                className="flex-1 shrink-0"
                onChange={onChange}
                value={value}
                error={fieldState.error?.message}
                required
              />
            );
          }}
        />
      </div>
      <div className="flex w-full items-start gap-3">
        <Controller
          name="releaseDate"
          control={control}
          defaultValue={
            data?.releaseDate && new Date(data?.releaseDate).toLocaleDateString('sv-SE')
          }
          rules={{
            required: type === 'manual' && t('card.form.release.empty'),
          }}
          render={({ field: { value, onChange, ref }, fieldState }) => {
            function handleChange(v: string) {
              onChange(v);
              onChangeManual();
            }
            return (
              <Input
                ref={ref}
                className="flex-1 shrink-0"
                label={t('card.form.release.label')}
                type="date"
                error={type === 'manual' ? fieldState.error?.message : undefined}
                value={value}
                onChange={handleChange}
                required={type === 'manual'}
                disabled={type !== 'manual'}
              />
            );
          }}
        />
        <Controller
          name="cover"
          control={control}
          defaultValue={data?.cover}
          render={({ field: { onChange, ref }, fieldState }) => {
            return (
              <CoverInput
                ref={ref}
                label={t('card.form.cover.label')}
                className="flex-1 shrink-0"
                placeholder={t('card.form.cover.placeholder')}
                error={fieldState.error?.message}
                uploadId={presignedData?.id}
                value={data?.cover}
                onFilesChange={(files) => {
                  if (files[0]) onChange(URL.createObjectURL(files[0]));
                }}
              />
            );
          }}
        />
      </div>
    </div>
  );
}
