import { Artwork } from '~/components/ui/cover/artwork';
import { Title } from './title';
import { SubTitle } from './subtitle';
import { NavLink, useNavigate } from 'react-router-dom';
import { useDroppable } from '@dnd-kit/core';
import { useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { Tracklist as TracklistType } from '~/types/schemas/music/tracklist.schema';
import { globalStore } from '~/stores/global-store-v2';
import { StatusChip } from './status-chip';

type TracklistProps = {
  id: string;
  data: TracklistType;
};

export function Tracklist(props: TracklistProps) {
  const { id, data } = props;
  const { type, value } = data;
  const { user } = globalStore;

  const isTenant = useMemo(
    () => type === 'playlist' && value.created_by?.id === user?.value?.id,
    [value.created_by?.id, type, user?.value?.id],
  );
  const link = useMemo(() => `/${type}s/${id}`, [id, type]);

  const navigate = useNavigate();

  const { setNodeRef, isOver } = useDroppable({
    id: id,
    data: {
      type,
      accepts: ['track'],
    },
  });

  function getIcon() {
    if (type === 'playlist' && !isTenant) {
      return 'ri:group-line';
    }

    if (type === 'playlist') {
      return 'ri:headphone-line';
    }

    if (type === 'brief') {
      return 'ri:megaphone-line';
    }
  }

  useEffect(() => {
    if (isOver) {
      const timeout = setTimeout(() => {
        navigate(link);
      }, 1000);
      return () => clearTimeout(timeout);
    }
  }, [isOver, link, navigate]);

  return (
    <NavLink
      ref={setNodeRef}
      to={link}
      className={({ isActive }) =>
        classNames(
          'hover:bg-neutral-emphasis outline-focus flex h-14 items-center gap-3 rounded-md border border-transparent bg-transparent px-2 outline-hidden transition-all focus:outline-1',
          {
            'bg-btn-secondary': isActive,
            'bg-btn-secondary border-accent-emphasis!': isOver,
          },
        )
      }
    >
      {({ isActive }) => (
        <>
          <div className="relative aspect-square w-10">
            {type === 'brief' ? (
              <div className="absolute -top-0.5 -left-0.5">
                <StatusChip deadline={value.deadline} sended={value.public} />
              </div>
            ) : null}
            <Artwork
              artworks={value.artworks?.map((a) => a.small)}
              className="text-base"
              icon={getIcon()}
            />
          </div>
          <div className="flex flex-1 flex-col gap-1 overflow-hidden">
            <Title title={value.title} isActive={isActive} />
            <SubTitle type={type} company={value.created_by?.company_name || undefined} />
          </div>
        </>
      )}
    </NavLink>
  );
}
