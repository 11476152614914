import { useCardClick } from '~/components/features/presearch/hook/use-card-click';
import useAddFilterStore from '~/stores/add-filter-store';
import { useTranslation } from 'react-i18next';
import { useBriefSearch } from '~/hooks/api/brief';
import ItemSearch from '../../../search/item-search';
import Tab from '../tab';

function Brief() {
  const addFilter = useAddFilterStore(['setIsOpen', 'query', 'setQuery']);
  const {
    data: catalogs,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useBriefSearch(addFilter.query.brief || '');
  const { handleClick: addToFilter } = useCardClick('internal_refs');
  const { t } = useTranslation('filter');

  const flattenBriefs = catalogs?.pages.flatMap((page) => page.hits) || [];

  function handleClick(name: string, id: string, operator: 'is' | 'is_not') {
    addToFilter(name, id, operator);
    addFilter.setIsOpen(false);
  }

  return (
    <Tab label={t('panel.briefs.tab.title')} value="brief">
      <ItemSearch
        itemType="brief"
        inputPlaceholder={t('panel.briefs.tab.input.placeholder')}
        value={addFilter.query.brief}
        onValueChange={(q) => addFilter.setQuery('brief', q)}
        items={flattenBriefs?.map((brief) => ({
          type: 'brief',
          artworks: brief.artworks || [],
          title: brief.title,
          onIncludeClick: () => handleClick(brief.title, brief.id!, 'is'),
          onExcludeClick: () => handleClick(brief.title, brief.id!, 'is_not'),
        }))}
        hasNextPage={hasNextPage}
        isFetchingNextPage={isFetchingNextPage}
        totalCount={flattenBriefs.length}
        fetchNextPage={fetchNextPage}
        isEndReached={!hasNextPage}
      />
    </Tab>
  );
}

export default Brief;
