import Select, { OptionType } from '~/components/ui/forms/select/select';
import { useTranslation } from 'react-i18next';
import { mewoDataStore } from '~/stores/mewo-data-store-v2';

type VersionSelectProps = {
  version?: string;
  onVersionChange?: (value: OptionType) => void;
};

export function VersionSelect(props: VersionSelectProps) {
  const { version, onVersionChange } = props;
  const { t } = useTranslation(['tracks-upload-form', 'common']);
  const versionsIds = [
    '5989daf4d3f20f148cff8d5f',
    '5989dad4d3f20f148cff8d59',
    '5989da94d3f20f148cff8d4d',
    '5989da6dd3f20f148cff8d41',
    '5989daa0d3f20f148cff8d50',
    '5da190127850230060ddeabd',
  ];

  const versions = mewoDataStore.versions.array.value
    .map((v) => ({
      value: v.id,
      label: v.name || v.id,
    }))
    .filter((v) => versionsIds.includes(v.value));
  const defaultVersion = versions.find((v) => v.value === version);

  return (
    <div className="flex flex-1 flex-col gap-1">
      {version ? (
        <Select
          label={t('card.informations.version')}
          value={defaultVersion}
          options={versions}
          onSingleValueChange={onVersionChange}
        />
      ) : null}
    </div>
  );
}
