import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useCreatePlaylist, useGetPlaylistsList } from '~/hooks/api/playlist';

const MAX_ITEMS_PER_PAGE = 50;

export function usePlaylists() {
  const { data, isLoading, isError, fetchNextPage } = useGetPlaylistsList();
  const { mutate: createPlaylist } = useCreatePlaylist();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const total = data?.pages?.[0]?.total?.value || 0;
  const isAllDataDisplayed = total <= (data?.pages?.length || 0) * MAX_ITEMS_PER_PAGE;

  function handleCardClick(id: string) {
    navigate(`/playlists/${id}`);
  }

  function handleCreatePlaylist() {
    createPlaylist(
      {
        title: t('input.title.default', { ns: 'playlist-form' }),
      },
      {
        onSuccess: (newPlaylist) => {
          navigate(`/playlists/${newPlaylist.id}`);
        },
      },
    );
  }

  return {
    playlists: data?.pages?.flatMap((page) => page.hits) || [],
    nbHits: total,
    isEachDataDisplayed: isAllDataDisplayed,
    isLoading,
    isError,
    fetchNextPage,
    handleCardClick,
    handleCreatePlaylist,
  };
}
