/* eslint-disable no-useless-escape */
/* eslint-disable prefer-regex-literals */
export const youtube =
  /(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))([\w-]{11})(?:\S+)?/;
export const url =
  /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;

// Track version

export function createRegexVersion(version: string): RegExp {
  return new RegExp(`(.*)\\s+(\\(\\s*${version}\\s*\\)|-\\s*${version})`, 'i');
}

export const instrumental =
  '\\s*(?:version?)*\\s*(?:ins(?:t(?:r(?:u(?:mental(?:e?)?)?)?)?)?)\\s*(?:v(?:ersion?)?)*\\.*';
