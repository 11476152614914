import { useComputed } from '@preact/signals-react';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { activeChannelStore, briefChatsStore } from '~/stores/chats-store';
import { notificationStore } from '~/stores/notifications-store';
import { ChatNotificationMessage } from '~/types/types/chat-notification';
import { Message } from '~/utils/chat/message';

export function useNotificationCenter() {
  const navigate = useNavigate();
  const { isPanelOpen } = briefChatsStore;
  const channel = useComputed(() => notificationStore.channel.value);
  const showLoadMoreButton = useComputed(() => {
    if (channel.value?.totalMessageCount.value && channel.value?.messages.value.length) {
      return channel.value?.totalMessageCount.value > channel.value?.messages.value.length;
    }

    return false;
  });

  const notifications = useComputed(() => channel.value?.messages.value.slice().reverse() ?? []);

  const handleCardClick = useCallback(
    (notification: Message<ChatNotificationMessage>) => {
      isPanelOpen.value = true;
      activeChannelStore.trackId.value = notification.content.data.track.id;
      navigate(`/briefs/${notification.content.data.brief.id}`);

      notification.markAsRead();
    },
    [navigate],
  );

  return {
    channel: channel.value,
    notifications: notifications.value,
    showLoadMoreButton: showLoadMoreButton.value,
    handleCardClick,
  };
}
