import { forwardRef, Ref } from 'react';
import Icon from '~/components/ui/icon/icon';
import { Icon as Iconify } from '@iconify/react';
import classNames from 'classnames';

type ButtonColor = 'primary' | 'default' | 'danger' | 'success';

type ButtonProps = {
  color: ButtonColor;
  className?: string;
  children?: React.ReactNode;
  iconLeft?: string;
  iconRight?: string;
  external?: boolean;
  disabled?: boolean;
  dataTestId?: string;
  isLoading?: boolean;
  dataNoDnd?: boolean;
  href?: string;
  type?: 'button' | 'submit' | 'reset';
  style?: React.CSSProperties;
  form?: React.ButtonHTMLAttributes<HTMLButtonElement>['form'];
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const Button = forwardRef((props: ButtonProps, forwardRef: Ref<HTMLButtonElement>) => {
  const {
    children,
    iconLeft,
    iconRight,
    dataNoDnd = false,
    external = false,
    href,
    isLoading = false,
    disabled = false,
    className = '',
    dataTestId = '',
    type = 'button',
    style,
    form,
    onClick,
    color,
    ...rest
  } = props;

  const bgColorClasses: Record<ButtonColor, string> = {
    primary: 'bg-button-primary-rest hover:bg-button-primary-hover',
    default: 'bg-button-default-rest hover:bg-button-default-hover',
    success: 'bg-button-success-rest hover:bg-button-success-hover',
    danger: 'bg-button-danger-rest hover:bg-button-danger-hover',
  };

  const textColorClasses: Record<ButtonColor, string> = {
    primary: 'text-button-primary-rest hover:text-button-primary-hover',
    default: 'text-button-default-rest hover:text-button-default-hover',
    success: 'text-button-success-rest hover:text-button-success-hover',
    danger: 'text-button-danger-rest hover:text-button-danger-hover',
  };

  const borderColorClasses: Record<ButtonColor, string> = {
    primary: 'border-button-primary-rest hover:border-button-primary-hover',
    default: 'border-button-default-rest hover:border-button-default-hover',
    success: 'border-button-success-rest hover:border-button-success-hover',
    danger: 'border-button-danger-rest hover:border-button-danger-hover',
  };

  const buttonContent = (
    <>
      {iconLeft && <Icon name={iconLeft} />}
      {children && children}
      {!isLoading && iconRight && <Icon name={iconRight} />}
      {isLoading && (
        <Iconify icon="svg-spinners:90-ring-with-bg" className="text-default text-xs" />
      )}
    </>
  );

  const disabledClassName = disabled ? 'opacity-50 hover:bg-primary-500 pointer-events-none' : '';

  const btnClasses = classNames(
    'text-white gap-2 text-xs whitespace-nowrap font-bold block min-h-8 h-8 select-none inline-flex px-3 py-2 items-center rounded-lg transition-colors duration-300 border outline-hidden outline-focus focus:outline-1',
    bgColorClasses[color],
    textColorClasses[color],
    className,
    borderColorClasses[color],
    { [disabledClassName]: disabled },
  );

  if (external && href) {
    return (
      <a href={href} target="_blank" rel="noreferrer" className={btnClasses}>
        {buttonContent}
      </a>
    );
  }

  return (
    <button
      {...rest}
      ref={forwardRef}
      type={type}
      data-testid={dataTestId}
      data-no-dnd={dataNoDnd}
      onClick={onClick}
      className={btnClasses}
      style={style}
      form={form}
    >
      {buttonContent}
    </button>
  );
});

export default Button;
