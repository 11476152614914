import IconAdd from 'virtual:icons/ri/add-fill';
import IconSubtract from 'virtual:icons/ri/subtract-fill';
import { MouseEvent } from 'react';
import classNames from 'classnames';
import GeneralIcons, { GeneralIcon } from '~/components/ui/icon/general-icons';

export type TagItemProps = {
  title: string;
  subtitle: string;
  subtitlePrefix?: string;
  color?: string;
  icon?: GeneralIcon;
  isUnrelevant?: boolean;
  onIncludeClick?: () => void;
  onExcludeClick?: () => void;
};

function TagItem(props: TagItemProps) {
  const {
    title,
    subtitle,
    color,
    isUnrelevant,
    subtitlePrefix,
    icon,
    onIncludeClick,
    onExcludeClick,
  } = props;

  const handleIncludeClick = (e: MouseEvent) => {
    e.stopPropagation();
    onIncludeClick?.();
  };

  const handleExcludeClick = (e: MouseEvent) => {
    e.stopPropagation();
    onExcludeClick?.();
  };

  const buttonClassName =
    'hidden group-hover:flex rounded-sm items-center justify-center transition-colors bg-neutral-emphasis hover:bg-muted w-4 h-4 text-default text-xs';

  return (
    <div
      className={classNames(
        'group tag-filter-item hover:bg-neutral-muted flex items-center gap-2 rounded-lg bg-transparent p-2',
        isUnrelevant && 'opacity-50',
      )}
    >
      <div className="flex h-8 w-8 shrink-0 items-center justify-center" style={{ color }}>
        {icon ? (
          <GeneralIcons icon={icon} className="text-md" />
        ) : (
          <div className="h-4 w-4 rounded-sm" style={{ backgroundColor: color }} />
        )}
      </div>
      <div className="tag-filter-item__information flex flex-col">
        <h6 className="tag-filter-item__title text-default text-xs">{title}</h6>
        {(subtitle || subtitlePrefix) && (
          <div className="text-muted flex h-4 gap-1 text-xs">
            {subtitlePrefix && (
              <>
                <span className="flex shrink-0">{subtitlePrefix}</span>
                {subtitle && <span>{'>'}</span>}
              </>
            )}
            {subtitle}
          </div>
        )}
      </div>
      <div className="tag-filter-item__actions ml-auto flex gap-2">
        <button
          type="button"
          className={classNames('tag-filter-item__actions--subtract', buttonClassName)}
          onClick={handleExcludeClick}
        >
          <IconSubtract />
        </button>
        <button
          type="button"
          className={classNames('tag-filter-item__actions--add', buttonClassName)}
          onClick={handleIncludeClick}
        >
          <IconAdd />
        </button>
      </div>
    </div>
  );
}

export default TagItem;
