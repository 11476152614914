import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

type DropdownMenuItemProps = {
  children: React.ReactNode;
};

export function ContextSubMenuContainer(props: DropdownMenuItemProps) {
  const { children } = props;

  return (
    <DropdownMenu.Portal>
      <DropdownMenu.SubContent
        sideOffset={5}
        className="bg-control-rest border-control-rest w-fit min-w-60 rounded-md border p-1 shadow-xl"
      >
        {children}
      </DropdownMenu.SubContent>
    </DropdownMenu.Portal>
  );
}
