import { useState } from 'react';
import Button from '~/components/ui/control/button/button';
import { useSearchStore } from '~/stores/search-store';
import useAddFilterStore from '~/stores/add-filter-store';
import Range from '../../../range/range';
import Tab from '../tab';
import { useTranslation } from 'react-i18next';

function Popularity() {
  const [value, setValue] = useState<[number, number]>([0, 100]);
  const search = useSearchStore(['setPopularity']);
  const addFilter = useAddFilterStore(['setIsOpen']);
  const { t } = useTranslation('filter');

  function handleApply() {
    search.setPopularity(value[0], value[1]);
    addFilter.setIsOpen(false);
  }

  return (
    <Tab label={t('panel.popularity.tab.title')} value="popularity">
      <div className="flex h-full flex-col justify-between pb-3">
        <Range value={value} onValueChange={setValue} min={0} max={100} />
        <Button
          color="primary"
          className="filter-panel__apply-button col-span-full mt-auto ml-auto"
          onClick={() => handleApply()}
        >
          {t('panel.slider.apply')}
        </Button>
      </div>
    </Tab>
  );
}

export default Popularity;
