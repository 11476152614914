import { MouseEvent } from 'react';
import GeneralIcons from '~/components/ui/icon/general-icons';
import CheckLineIcon from 'virtual:icons/ri/check-line';
import AddIcon from 'virtual:icons/ri/add-fill';
import RemoveIcon from 'virtual:icons/ri/delete-bin-7-fill';
import IconArrowRightLine from 'virtual:icons/ri/arrow-right-line';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

type TracklistProps = {
  label: string;
  onClick?: (e: MouseEvent) => void;
  icon?: string;
  id?: string;
  type?: 'brief' | 'playlist';
  ownedByUser?: boolean;
  hasTrack?: boolean;
  className?: string;
};

function Tracklist(props: TracklistProps) {
  const { label, icon, className = '', type, ownedByUser, id, hasTrack, onClick } = props;

  const itemClassName =
    'group/tracklist relative transition-colors w-full p-1 text-muted transition outline-hidden ease-in duration-100 border-none shadow-none text-left flex items-center justify-between rounded-xs focus:outline-hidden';

  return (
    <div
      data-tracklist-id={id}
      data-tracklist-type={type}
      data-tracklist-owned-by-user={ownedByUser}
      data-no-dnd="true"
      className={classNames(className, itemClassName)}
    >
      <button
        type="button"
        className="tracklist hover:text-default flex flex-1 items-center text-left"
        onClick={onClick}
      >
        <div className="flex shrink-0 items-center">
          <div className="mr-1 flex w-5 items-center justify-center">
            {icon ? <GeneralIcons icon={icon} /> : null}
          </div>
          <div className="mr-1 flex w-5 items-center justify-center">
            <div className="block group-hover/tracklist:hidden">
              {hasTrack ? <CheckLineIcon /> : null}
            </div>
            <div className="hidden group-hover/tracklist:block">
              {hasTrack ? <RemoveIcon className="text-danger" /> : <AddIcon />}
            </div>
          </div>
        </div>
        <h6 className="text-middle flex-1 overflow-hidden text-sm">{label}</h6>
      </button>
      {type && id ? (
        <NavLink
          to={`/${type}s/${id}`}
          className="tracklist__item__link bg-neutral-emphasis hover:text-default absolute right-0 hidden w-5 items-center justify-center group-hover/tracklist:flex"
        >
          <IconArrowRightLine />
        </NavLink>
      ) : null}
    </div>
  );
}

export default Tracklist;
