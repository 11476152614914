import { Combobox, Portal } from '@ark-ui/react';
import { Icon } from '@iconify/react';
import classNames from 'classnames';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface OptionsProps<T = any> {
  items: T[];
  isLoading?: boolean;
  render: (item: T) => ReactNode;
}

export function SelectInputPortal(props: OptionsProps) {
  const { items, isLoading, render } = props;
  const { t } = useTranslation('common');

  return (
    <Portal>
      <Combobox.Positioner>
        <Combobox.Content
          style={{ width: 'calc(100% + 1.5rem)' }}
          className={classNames(
            'bg-neutral-emphasis border-neutral-emphasis custom-scrollbar animate-slide-in-bottom z-50 mt-1 -ml-3 h-fit max-h-64 overflow-y-auto rounded-sm border p-1 opacity-0 shadow-lg',
            {
              'opacity-100': isLoading || !!items.length,
            },
          )}
        >
          {!items.length && !isLoading && (
            <div className="my-3 flex w-full items-center justify-center">
              <span className="text-muted">{t('no-results')}</span>
            </div>
          )}
          {isLoading && (
            <div className="my-3 flex w-full items-center justify-center">
              <Icon icon="svg-spinners:6-dots-scale-middle" className="text-muted" />
            </div>
          )}
          {!isLoading &&
            items.map((item, index) => (
              <Combobox.Item
                key={`${item.value}-${index}`}
                item={item}
                className="hover:bg-neutral-muted group/list-item data-highlighted:bg-neutral-muted rounded-sm"
              >
                {render?.({ ...item.content })}
              </Combobox.Item>
            ))}
        </Combobox.Content>
      </Combobox.Positioner>
    </Portal>
  );
}
