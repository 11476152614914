import * as SwitchPrimitive from '@radix-ui/react-switch';
import classNames from 'classnames';
import { useState } from 'react';

type SwitchProps = {
  checked?: boolean;
  onChange?: (checked: boolean) => void;
};

const Toggle = (props: SwitchProps) => {
  const { checked, onChange } = props;
  const [isChecked, setIsChecked] = useState<boolean>(checked || false);

  const handleChange = () => {
    onChange?.(!isChecked);
    setIsChecked(!isChecked);
  };

  return (
    <SwitchPrimitive.Root
      checked={checked}
      onCheckedChange={handleChange}
      className={classNames(
        'flex h-6 w-10 items-center rounded-full border p-1 transition-all duration-300',
        {
          'bg-accent-emphasis border-accent-emphasis': isChecked,
          'bg-neutral-emphasis border-neutral-emphasis': !isChecked,
        },
      )}
    >
      <SwitchPrimitive.Thumb
        className={`h-4 w-4 rounded-full bg-white transition-all duration-300 ${
          isChecked && 'translate-x-full'
        }`}
      />
    </SwitchPrimitive.Root>
  );
};

export default Toggle;
