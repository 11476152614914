import Button from '~/components/ui/control/button/button';
import { useCardClick } from '~/components/features/presearch/hook/use-card-click';
import { useTranslation } from 'react-i18next';
import { useGetProviders } from '~/hooks/api/dashboard';
import usePreSearchStore from '~/stores/presearch-store';
import { useCatalogSearch } from '~/hooks/api/catalog';
import CardShelf from '../../../card-shelf/card-shelf';
import Tab from '../tab';

function CatalogsTab() {
  const { query } = usePreSearchStore(['query']);
  const { data: catalogs, fetchNextPage } = useCatalogSearch(query);
  const { data: providers } = useGetProviders();
  const total = catalogs?.pages[0]?.total.value || 0;
  const flatedCatalogs = catalogs?.pages.flatMap((page) => page.hits);
  const { handleFocusClick, handleClick } = useCardClick('catalog');
  const { t } = useTranslation('pre-search');

  return (
    <Tab value="catalogs" isEmpty={!flatedCatalogs?.length || !flatedCatalogs}>
      {flatedCatalogs ? (
        <>
          <CardShelf
            label={t('tab.all.catalogs.title')}
            containerClassName="flex-1 col-span-full"
            cardsProps={flatedCatalogs?.map((catalog) => {
              const providerType = providers
                ?.find((provider) => provider.id === catalog.tenant.id)
                ?.organization_settings.meta.provider_types?.map((type) => {
                  if (type.includes('core')) return t('music-type.core');
                  if (type.includes('production')) return t('music-type.prod');
                  return undefined;
                });

              return {
                type: 'album',
                artworks: catalog.artwork ? [catalog.artwork] : undefined,
                title: catalog.name,
                chips: providerType
                  ?.filter((item, index) => item && providerType.indexOf(item) === index)
                  .sort() as string[],
                onCardClick: () => handleFocusClick(catalog.name, catalog.id),
                onIncludeClick: () => handleClick(catalog.name, catalog.id, 'is'),
                onExcludeClick: () => handleClick(catalog.name, catalog.id, 'is_not'),
              };
            })}
          />
          {flatedCatalogs.length < total ? (
            <Button
              color="primary"
              onClick={() => fetchNextPage()}
              className="col-span-full mx-auto"
            >
              {t('button.load-more.label', 'common')}
            </Button>
          ) : null}
        </>
      ) : null}
    </Tab>
  );
}

export default CatalogsTab;
