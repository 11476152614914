import classNames from 'classnames';
import { ChangeEvent, MouseEvent, useEffect, useState } from 'react';
import GeneralIcons from '~/components/ui/icon/general-icons';
import { useTranslation } from 'react-i18next';

type FilterProps = {
  className?: string;
  setSelectedFilter?: (collections?: ('briefs' | 'playlists')[]) => void;
  onChange: (query: string) => void;
};

const Filter = (props: FilterProps) => {
  const { className, setSelectedFilter, onChange } = props;
  const [query, setQuery] = useState('');
  const [isPlaylistSelected, setIsPlaylistSelected] = useState(false);
  const [isBriefSelected, setIsBriefSelected] = useState(false);

  const { t } = useTranslation('sidebar');

  useEffect(() => {
    const collections = [];
    if (isPlaylistSelected) collections.push('playlists');
    if (isBriefSelected) collections.push('briefs');
    if (!isPlaylistSelected && !isBriefSelected) collections.push('playlists', 'briefs');
    setSelectedFilter?.(collections as ('briefs' | 'playlists')[]);
  }, [isPlaylistSelected, isBriefSelected]);

  function handleClick(e: MouseEvent) {
    e.stopPropagation();
  }

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    const newValue = e.target.value;
    setQuery(newValue);
    onChange(newValue);
  }

  return (
    <div
      className={classNames('tracklist-filter flex items-center gap-1', className)}
      onClick={(e) => handleClick(e)}
    >
      <input
        value={query}
        onChange={handleChange}
        className="bg-control-rest border-control-rest placeholder:text-muted text-muted flex-1 rounded-sm border px-2 py-1 text-xs outline-hidden"
        placeholder={t('briefs-playlists-list.filter.input.placeholder')}
      />
      <div className="tracklist-filter__button flex gap-1">
        <TypeSwitch
          icon="playlist-line"
          classSuffix="playlist"
          isSelected={isPlaylistSelected}
          onClick={() => setIsPlaylistSelected(!isPlaylistSelected)}
        />
        <TypeSwitch
          icon="brief-line"
          classSuffix="brief"
          isSelected={isBriefSelected}
          onClick={() => setIsBriefSelected(!isBriefSelected)}
        />
      </div>
    </div>
  );
};

const TypeSwitch = (props: {
  icon: string;
  isSelected: boolean;
  classSuffix?: string;
  onClick: () => void;
}) => {
  const { icon, isSelected, classSuffix, onClick } = props;
  return (
    <button
      type="button"
      onClick={onClick}
      className={classNames(
        `tracklist-filter__button--${classSuffix}`,
        'text-default flex aspect-square h-6 w-6 items-center justify-center rounded-sm text-xs',
        isSelected
          ? 'bg-neutral-muted hover:bg-muted'
          : 'border-neutral-emphasis hover:border-neutral-muted text-muted hover:text-default border',
      )}
    >
      <GeneralIcons icon={icon} />
    </button>
  );
};

export default Filter;
