import { SearchTrackItemLayout } from '~/components/layout/track-items/search-track-item.layout';
import { ListHeader } from '../common/list-header';
import { ListHeaderLabel } from '../common/list-header-label';

type SearchListHeaderProps = {
  isSimilarityEnabled: boolean;
};

export function SearchListHeader(props: SearchListHeaderProps) {
  const { isSimilarityEnabled } = props;

  return (
    <ListHeader className="top-10">
      <SearchTrackItemLayout>
        <ListHeaderLabel label="Title" className="col-start-[summary]" />
        <ListHeaderLabel label="Type" className="col-start-[music-type] justify-self-center" />
        <ListHeaderLabel
          label="Alt"
          tooltip="Alternative versions"
          className="col-start-[versions] justify-self-center"
        />
        <ListHeaderLabel
          icon="ph:trend-up-bold"
          tooltip="Popularity"
          className="col-start-[popularity] justify-self-center"
        />
        {isSimilarityEnabled ? (
          <ListHeaderLabel
            icon="tabler:chart-circles"
            tooltip="Similarity"
            className="col-start-[similarity] justify-self-center"
          />
        ) : null}
        <ListHeaderLabel
          icon="ri:time-line"
          tooltip="Duration"
          className="col-start-[duration] justify-self-end pr-1"
        />
      </SearchTrackItemLayout>
    </ListHeader>
  );
}
