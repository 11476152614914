import { TagsAccordion } from '~/components/entities/tags/tags-accordion/tags-accordion';
import { usePopulatedTagsSearch } from '~/hooks/api/tag';
import usePreSearchStore from '~/stores/presearch-store';
import { useSearchTagsAccordion } from '~/hooks/utils/use-tags-accordion';
import Tab from '../tab';

function TagTab() {
  const presearch = usePreSearchStore(['query', 'setIsOpen']);
  const {
    data: { categories, rawData },
  } = usePopulatedTagsSearch(presearch.query, 'TAG');

  const { subCategoryDefaultOpenState, categoryDefaultOpenState } = useSearchTagsAccordion(
    rawData?.categories,
  );

  return (
    <Tab id="presearch-tags-tab" value="tags">
      <div className="col-span-full">
        <TagsAccordion
          categories={categories}
          subCategoryDefaultOpen={subCategoryDefaultOpenState}
          categoryDefaultOpen={categoryDefaultOpenState}
        />
      </div>
    </Tab>
  );
}

export default TagTab;
