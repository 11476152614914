import { PinInput } from '@ark-ui/react';

type InputPinInputProps = PinInput.InputProps;

export function InputPinInput(props: InputPinInputProps) {
  return (
    <PinInput.Input
      className="border-control-rest bg-control-rest focus:border-control-accent-emphasis h-12 w-full rounded-sm border text-center outline-hidden"
      {...props}
    />
  );
}
