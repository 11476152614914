import { useParams } from 'react-router-dom';
import { BriefProvider } from '~/providers/briefs/brief-provider';
import { useBriefData } from '~/hooks/data/use-brief-data';
import { useMemo } from 'react';
import { BriefTrackItemList } from '~/components/entities/track/track-item-list/brief/brief-track-item-list';
import { BriefHeader } from './components/brief-header';
import { BriefContext } from './brief.context';

function BriefBase({ id }: { id: string }) {
  const { data, isLoading, targetLanguage, handleTargetLanguageChange } = useBriefData(id);

  const contextValue = useMemo(
    () => ({
      data,
      isLoading,
      targetLanguage,
      handleTargetLanguageChange,
    }),
    [data, isLoading, targetLanguage],
  );

  const scrollId = `brief-content-${id}`;

  return (
    <BriefContext.Provider value={contextValue}>
      <BriefProvider brief={data}>
        <section className="relative size-full self-stretch overflow-hidden">
          <div className="flex h-full">
            <div className="flex-1 pt-4">
              <div
                id={scrollId}
                className="flex h-full w-full flex-col items-stretch overflow-y-auto"
              >
                <BriefHeader className="pb-4" />
                <div className="h-full">
                  <div className="z-0 flex h-fit min-h-full flex-col px-4">
                    {data.id ? <BriefTrackItemList brief={data} scrollParentId={scrollId} /> : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </BriefProvider>
    </BriefContext.Provider>
  );
}

export function Brief() {
  const { id } = useParams<{ id: string }>();
  return id ? <BriefBase id={id} /> : null;
}
