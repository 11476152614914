import { useState } from 'react';
import classNames from 'classnames';
import { BriefDescriptionBlocks } from './blocks/blocks';
import { DescriptionHeader } from './header/header';
import DescriptionFooter from '~/components/description-footer';

export function BriefDescription() {
  const [extend, setExtend] = useState(false);

  return (
    <div className="flex w-full">
      <div className="w-full">
        <div
          className={classNames(
            'group/description bg-neutral-emphasis relative max-h-52 w-full overflow-hidden rounded-2xl transition-all',
            {
              'max-h-full': extend,
            },
          )}
        >
          <DescriptionHeader />
          <BriefDescriptionBlocks />
          <DescriptionFooter extend={extend} onExtendClick={() => setExtend(!extend)} />
        </div>
      </div>
    </div>
  );
}
