import { useBriefContext } from '~/pages/brief/brief.context';
import { BriefDescriptionBlock } from './block';

export function ScopeBlock() {
  const { data, isLoading } = useBriefContext();

  return (
    <BriefDescriptionBlock label="Scope" isLoading={isLoading}>
      {data.scope}
    </BriefDescriptionBlock>
  );
}
