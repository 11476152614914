import { components, GroupBase, DropdownIndicatorProps } from 'react-select';
import { OptionType } from '../../select';
import { Icon } from '@iconify/react';
import classNames from 'classnames';

const DropdownIndicator = (
  props: DropdownIndicatorProps<OptionType, boolean, GroupBase<OptionType>>,
) => {
  const { isDisabled } = props;

  const iconClasses = classNames('text-control-rest hover:text-control-hover', {
    'text-muted': isDisabled,
  });

  return (
    <components.DropdownIndicator
      className="flex size-4 h-full! cursor-pointer items-center justify-center p-0!"
      {...props}
    >
      <Icon icon="ri:arrow-down-s-line" className={iconClasses} />
    </components.DropdownIndicator>
  );
};

export default DropdownIndicator;
