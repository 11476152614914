import { useTranslation } from 'react-i18next';
import Select, { OptionType } from '~/components/ui/forms/select/select';

type MusicTypeSelectProps = {
  musicType?: string;
  onMusicTypeChange?: (values: OptionType) => void;
};

export function MusicTypeSelect(props: MusicTypeSelectProps) {
  const { musicType, onMusicTypeChange } = props;
  const isEditing = Boolean(onMusicTypeChange);
  const { t } = useTranslation(['tracks-upload-form', 'common']);
  const musicTypes = [
    { value: 'production', label: t('music-type.prod', { ns: 'common' }) },
    { value: 'core', label: t('music-type.core', { ns: 'common' }) },
  ];
  const defaultMusicType = musicTypes.find((type) => type.value === musicType);

  return (
    <div className="flex flex-1 flex-col gap-1">
      {isEditing && musicType && (
        <Select
          value={defaultMusicType}
          label={t('card.informations.music-type')}
          options={musicTypes}
          onSingleValueChange={onMusicTypeChange}
        />
      )}
      {!isEditing && (
        <span className="text-default line-clamp-1 pb-2 text-sm leading-4 break-all">
          {musicType ? musicTypes.find((type) => type.value === musicType)?.label : '-'}
        </span>
      )}
    </div>
  );
}
