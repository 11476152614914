import { useTracksUploadContext } from '~/components/entities/upload/tracks/tracks-upload.context';
import Button from '~/components/ui/control/button/button';
import { useTracksUploadItemContext } from '../../../tracks-upload-item.context';
import { useDeleteUpload } from '~/hooks/api/upload';
import errorHandler from '~/utils/error-handler';
import { useTranslation } from 'react-i18next';

export function DeleteTrackButton() {
  const { store } = useTracksUploadContext();
  const { data, presignedData } = useTracksUploadItemContext();
  const { t } = useTranslation('common');

  const { mutate: deleteTrack, isPending: isDeletePending } = useDeleteUpload(presignedData?.id);

  const setNextSelected = store.use.setNextSelected();
  const removeFromList = store.use.removeFromList();

  function handleDelete() {
    deleteTrack(undefined, {
      onSuccess: () => {
        setNextSelected();
        removeFromList(data.id);
      },
      onError: (error) => {
        errorHandler(error, {
          extra: {
            presignedData,
          },
        });
      },
    });
  }

  return (
    <Button
      color="default"
      onClick={handleDelete}
      isLoading={isDeletePending}
      disabled={isDeletePending}
      iconLeft="delete-bin-line"
    >
      {t('delete')}
    </Button>
  );
}
