import * as ContextMenu from '~/components/ui/control/menus/context-menu/common/context-menu';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SearchInput } from './search-input';
import { SearchResult } from './search-result';
import { CreatePlaylist } from './create-playlist';

export function AddToPlaylistContent() {
  const { t } = useTranslation('track-interactions');
  const [query, setQuery] = useState('');

  return (
    <ContextMenu.SubMenuContainer>
      <SearchInput
        query={query}
        setQuery={setQuery}
        placeholder={t('add-playlist.dropdown.input')}
      />
      <CreatePlaylist />
      <SearchResult query={query} collection="playlists" />
    </ContextMenu.SubMenuContainer>
  );
}
