import * as musicMetadata from 'music-metadata-browser';
import { UploadTrack } from '~/types/features/track-upload/track-upload.types';
import { bufferToImage } from '~/utils/common/buffer-to-image';
import errorHandler from '../error-handler';

type ErrorFile = string;

export async function fileToUploadTrack(file: File): Promise<UploadTrack | ErrorFile> {
  const fileNames = file.name;
  try {
    const metadata = await musicMetadata.parseBlob(file);
    const {
      common: { title, picture, artists },
    } = metadata;
    const bufferImage = picture?.[0]?.data;
    const image = bufferImage && bufferToImage(bufferImage);

    return {
      id: `${file.name}-${file.type}-${file.size}`,
      name: file.name,
      type: file.type,
      size: file.size,
      matchedMetadata: {
        musicType: 'core',
        version: file.name.match(/inst/gi)?.[0]
          ? '5989dad4d3f20f148cff8d59'
          : '5989daf4d3f20f148cff8d5f',
      },
      metadata: {
        title: title || file.name,
        album: metadata.common.album,
        artists,
        cover: image,
      },
      step: 'waiting',
      state: null,
      file,
    };
  } catch {
    const error = new Error(`Error parsing file: ${fileNames}`);
    errorHandler(error, {
      level: 'warning',
    });
    return fileNames;
  }
}

export function fileArrayToUploadTrackArray(files: File[]): Promise<(UploadTrack | ErrorFile)[]> {
  return Promise.all(files.map(fileToUploadTrack));
}
