import classNames from 'classnames';
import Icon from '~/components/ui/icon/icon';
import { Participant } from '~/types/schemas/chats/participant.schema';

type UserAvatarProps = {
  user: Participant;
  className?: string;
};

function UserAvatar(props: UserAvatarProps) {
  const { user, className } = props;
  return user.organization_settings.avatar ? (
    <img
      src={user.organization_settings.avatar}
      alt={`${user.first_name} ${user.last_name}`}
      className={classNames('aspect-square object-cover', className)}
    />
  ) : (
    <div
      className={classNames(
        'bg-neutral-muted text-xxs text-muted flex aspect-square h-4 w-4 items-center justify-center rounded-full',
        className,
      )}
    >
      <Icon name="user-line" />
    </div>
  );
}

export default UserAvatar;
