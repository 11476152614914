import Button from '~/components/ui/control/button/button';
import { useTranslation } from 'react-i18next';

type PlaylistFormHeaderProps = {
  onDiscardAndExitClick?: () => void;
};

function PlaylistFormHeader(props: PlaylistFormHeaderProps) {
  const { onDiscardAndExitClick } = props;
  const { t } = useTranslation('playlist-form');

  return (
    <div className="flex w-full items-center justify-between">
      <h1 className="text-2xl font-bold">{t('header.title')}</h1>
      <Button color="default" onClick={onDiscardAndExitClick}>
        Exit
      </Button>
    </div>
  );
}

export default PlaylistFormHeader;
