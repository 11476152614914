import { FocusEvent, MouseEvent, MutableRefObject, useEffect, useRef, useState } from 'react';
import useDebounce from '~/hooks/common/use-debounce';
import usePreSearchStore from '~/stores/presearch-store';
import { url, youtube } from '~/utils/common/regex';
import { toastr } from '~/components/ui/notifications/toast/utils/toast-call';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useHandleSimilarityUrlSearch } from '../hooks/use-handle-similarity-url-search';
import { useURLVideoTiltle } from '../hooks/use-url-video-title';
import { Icon } from '@iconify/react';

type PresearchInputProps = {
  className?: string;
};

const PresearchInput = (props: PresearchInputProps) => {
  const { className } = props;
  const presearch = usePreSearchStore([
    'query',
    'setQuery',
    'setIsOpen',
    'resetVideoURL',
    'setVideoURL',
  ]);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const { mutate: getVideoTitle } = useURLVideoTiltle();
  const videoLoader = useHandleSimilarityUrlSearch();
  const [searchTerm, setSearchTerm] = useState<string>(presearch.query);
  const { t } = useTranslation(['toast', 'pre-search']);

  const handleTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let term = e.target.value;
    const youtubeMatch = term.match(youtube);
    const urlMatch = term.match(url);

    if (youtubeMatch) {
      setSearchTerm('');
      // toastr('warning', i18n.t('maintenance.youtube-similarity.title', { ns: 'toast' }), i18n.t('maintenance.youtube-similarity.content', { ns: 'toast' }));

      const videoId = youtubeMatch[1]!;

      getVideoTitle(
        { videoId },
        {
          onSuccess: (data) => {
            const { title } = data.snippet;

            if (title) {
              presearch.setVideoURL(videoId, title);
              setSearchTerm('');
            }
          },
        },
      );
    } else if (urlMatch) {
      toastr(
        'warning',
        t('presearch.bad-link.title', { ns: 'toast' }),
        t('presearch.bad-link.content', { ns: 'toast' }),
      );
      term = '';
    }

    presearch.setIsOpen(true);

    if (term.length <= 100) {
      setSearchTerm(term);
    } else {
      toastr(
        'warning',
        t('search.term.too-long.title', { ns: 'toast' }),
        t('search.term.too-long.content', { ns: 'toast' }),
        'search-term-too-long',
      );
    }
  };

  const handleCancel = () => {
    presearch.resetVideoURL();
    presearch.setIsOpen(false);

    setSearchTerm('');
  };

  const handleFocus = (e: MouseEvent | FocusEvent) => {
    e.stopPropagation();
    if (!isFocused) {
      setIsFocused(true);
      (inputRef as MutableRefObject<HTMLInputElement>).current?.focus();
    }
  };

  const handleBlur = () => {
    if (isFocused) {
      setIsFocused(false);
      (inputRef as MutableRefObject<HTMLInputElement>).current?.blur();
    }
  };

  const debouncedTerm = useDebounce(searchTerm, 500);

  useEffect(() => {
    if (debouncedTerm !== undefined) {
      presearch.setQuery(debouncedTerm);
    }
  }, [debouncedTerm, presearch.setQuery]);

  // reset presearch on mount
  useEffect(() => {
    setSearchTerm('');
    presearch.setIsOpen(false);
  }, [presearch.setIsOpen]);

  useEffect(() => {
    // handle "enter" key press
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Enter' && isFocused) {
        toastr('warning', t('presearch.warning.title'), t('presearch.warning.content'));
      }
      if (event.key === 'Escape') {
        presearch.setIsOpen(false);
      }
    };

    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [isFocused]);

  return (
    <div className={classNames('relative', className)}>
      <div
        id="presearch-input-container"
        className={classNames(
          'border-control-rest bg-control-rest flex h-9 flex-1 items-center overflow-hidden rounded-lg border',
          {
            'outline-focus outline-hidden focus:outline-1': isFocused,
          },
        )}
        onClick={handleFocus}
      >
        <Icon
          className={classNames('text-control-placeholder ml-4 text-lg', {
            'text-default!': isFocused || searchTerm.length > 0,
          })}
          icon="ri:search-line"
        />
        <input
          ref={inputRef}
          id="presearch-input"
          type="text"
          className="text-default placeholder:text-control-placeholder h-full flex-1 bg-transparent px-4 outline-hidden"
          placeholder={t('input.placeholder', { ns: 'pre-search' })}
          onChange={handleTermChange}
          onClick={() => presearch.setIsOpen(true)}
          value={searchTerm}
          onFocus={handleFocus}
          onBlur={handleBlur}
          autoComplete="off"
        />
      </div>
      <AnimatePresence>
        {videoLoader.isLoading ? (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ ease: 'easeInOut', duration: 0.3 }}
            id="youtube-video-loader"
            className="group/url-loader bg-neutral-emphasis absolute top-1 left-full ml-3 flex h-9 w-fit items-center justify-between gap-2 rounded-lg px-4"
          >
            <span className="text-default line-clamp-1 w-32 text-xs">{videoLoader.title}</span>
            <div className="block size-4 h-fit group-hover/url-loader:hidden">
              <Icon icon="svg-spinners:180-ring-with-bg" className="text-muted size-4" />
            </div>
            <button
              type="button"
              onClick={handleCancel}
              className="bg-neutral-emphasis hover:bg-neutral-muted hidden aspect-square size-4 items-center justify-center rounded-full transition-colors group-hover/url-loader:flex"
            >
              <Icon
                icon="ri:close-line"
                className="text-neutral-emphasis hover:text-neutral-muted size-4"
              />
            </button>
          </motion.div>
        ) : null}
      </AnimatePresence>
    </div>
  );
};

export default PresearchInput;
