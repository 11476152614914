import { StepMatchProcess } from './step-match-process';
import { useRealtimeTrackMatch } from '~/hooks/realtime/use-realtime-track-match';
import { StepMatchDisplay } from './step-match-display/step-match-display';
import { useTracksUploadContext } from '~/components/entities/upload/tracks/tracks-upload.context';
import { UploadTrack } from '~/types/features/track-upload/track-upload.types';
import { memo } from 'react';
import { useTracksUploadItemContext } from '../../../tracks-upload-item.context';

type StepMatchProps = {
  file: UploadTrack;
};

export const StepMatch = memo((props: StepMatchProps) => {
  const { file } = props;
  const { presignedData } = useTracksUploadItemContext();

  const { store } = useTracksUploadContext();
  const setStep = store.use.setStep();

  const { status, progress } = useRealtimeTrackMatch({
    id: presignedData!.id,
    onMatchDone: () => {
      setStep(file.id, 'matchReady');
    },
  });

  if (file.step === 'waiting') {
    return <StepMatchProcess file={file} status={status} progress={progress} />;
  }

  return <StepMatchDisplay file={file} />;
});
