import * as Track from '~/components/entities/track/common/track';
import { SearchTrackItemLayout } from '~/components/layout/track-items/search-track-item.layout';
import { PlaylistTrackItemLayout } from '~/components/layout/track-items/playlist-track-item.layout';
import { BriefTrackItemLayout } from '~/components/layout/track-items/brief-track-item.layout';
import { Track as TrackType } from '~/types/schemas/music/track.schema';
import { TrackItemHeaderSummary } from '../../header/summary/summary';
import { TrackItemDropdownMenu } from '../../menus/dropdown-menu/dropdown-menu';
import { TrackItemProviders } from '../../track-item.providers';
import { useTrackContext } from '../../../common/track.context';
import { TrackVersionWrapper } from './track-version.wrapper';

type TrackVersionProps = {
  version: TrackType;
};

export function TrackVersion(props: TrackVersionProps) {
  const { version } = props;
  const store = useTrackContext();
  const context = store.use.context();

  if (context.type === 'search') {
    return (
      <TrackItemProviders track={version} context={context}>
        <TrackVersionWrapper>
          <SearchTrackItemLayout>
            <TrackItemHeaderSummary className="col-start-[summary]" />
            <Track.Waveform className="col-start-[waveform] hidden justify-self-end @[60rem]:block" />
            <Track.Duration className="col-start-[duration] justify-self-end" />
            <Track.ActionList className="col-start-[actions] justify-self-end">
              <TrackItemDropdownMenu />
            </Track.ActionList>
          </SearchTrackItemLayout>
        </TrackVersionWrapper>
      </TrackItemProviders>
    );
  }

  if (context.type === 'playlist') {
    return (
      <TrackItemProviders track={version} context={context}>
        <TrackVersionWrapper>
          <PlaylistTrackItemLayout>
            <TrackItemHeaderSummary className="col-start-[summary]" />
            <Track.Waveform className="col-start-[waveform] hidden justify-self-end @[68rem]:block" />
            <Track.Duration className="col-start-[duration] justify-self-end" />
            <Track.ActionList className="col-start-[actions] justify-self-end">
              <TrackItemDropdownMenu />
            </Track.ActionList>
          </PlaylistTrackItemLayout>
        </TrackVersionWrapper>
      </TrackItemProviders>
    );
  }

  if (context.type === 'brief') {
    return (
      <TrackItemProviders track={version} context={context}>
        <TrackVersionWrapper>
          <BriefTrackItemLayout>
            <TrackItemHeaderSummary className="col-start-[summary]" />
            <Track.Waveform className="col-start-[waveform] hidden justify-self-end @[68rem]:block" />
            <Track.Duration className="col-start-[duration] justify-self-end" />
            <Track.ActionList className="col-start-[actions] justify-self-end">
              <TrackItemDropdownMenu />
            </Track.ActionList>
          </BriefTrackItemLayout>
        </TrackVersionWrapper>
      </TrackItemProviders>
    );
  }

  return null;
}
