import { Icon, IconifyIconProps } from '@iconify/react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import classNames from 'classnames';
import { ForwardedRef, PropsWithChildren, MouseEvent, forwardRef } from 'react';

type DropdownMenuRadioItemProps = PropsWithChildren<{
  icon?: IconifyIconProps['icon'];
  onClick?: (e: MouseEvent) => void;
  className?: string;
}> &
  DropdownMenu.DropdownMenuRadioItemProps;

export const DropdownMenuRadioItem = forwardRef(
  (props: DropdownMenuRadioItemProps, ref?: ForwardedRef<HTMLDivElement>) => {
    const { icon, onClick, children, className, ...rest } = props;
    function handleClick(e: MouseEvent) {
      e.stopPropagation();
      onClick?.(e);
    }

    return (
      <DropdownMenu.RadioItem
        className={classNames(
          'group/radio text-default focus:bg-control-neutral flex h-8 items-center gap-3 rounded-sm border border-transparent px-2 text-sm outline-hidden select-none',
          className,
        )}
        onClick={handleClick}
        ref={ref}
        {...rest}
      >
        {icon && (
          <div className="flex size-4 items-center justify-center group-data-[state=checked]/radio:hidden">
            <Icon icon={icon} className="h-4 w-4" />
          </div>
        )}
        <DropdownMenu.ItemIndicator>
          <Icon icon="ri:check-line" className="text-accent size-4" />
        </DropdownMenu.ItemIndicator>
        <div className="flex-1 truncate">{children}</div>
      </DropdownMenu.RadioItem>
    );
  },
);
