import * as AlertDialogPrimitive from '@radix-ui/react-alert-dialog';
import {
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContainer,
  AlertDialogTitle,
} from '../common';
import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

type TracksUploadAlertDialogProps = PropsWithChildren<{
  onExit?: () => void;
}>;

export function TracksUploadAlertDialog(props: TracksUploadAlertDialogProps) {
  const { onExit, children } = props;
  const { t } = useTranslation('tracks-upload-form');

  return (
    <AlertDialogPrimitive.Root>
      <AlertDialogPrimitive.Trigger asChild>{children}</AlertDialogPrimitive.Trigger>
      <AlertDialogContainer>
        <AlertDialogTitle>{t('exit-dialog.title')}</AlertDialogTitle>
        <AlertDialogPrimitive.AlertDialogDescription>
          {t('exit-dialog.content')}
        </AlertDialogPrimitive.AlertDialogDescription>
        <div className="flex gap-4">
          <AlertDialogCancel label={t('header.exit.dialog.cancel')} />
          <AlertDialogAction label={t('header.exit.dialog.exit')} onClick={() => onExit?.()} />
        </div>
      </AlertDialogContainer>
    </AlertDialogPrimitive.Root>
  );
}
