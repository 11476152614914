import * as Track from '~/components/entities/track/common/track';
import { SearchTrackItemLayout } from '~/components/layout/track-items/search-track-item.layout';
import { TrackItemDropdownMenu } from '../menus/dropdown-menu/dropdown-menu';
import { TrackItemHeaderSummary } from './summary/summary';
import { MagicPreview } from './actions/magic-preview';
import { useTrackItem } from '~/hooks/entities/track/track-item/use-track-item';

export function SearchTrackItemHeader() {
  // named grid rows
  const { isOpen, isSelected } = useTrackItem();

  return (
    <SearchTrackItemLayout>
      <TrackItemHeaderSummary className="col-start-[summary]" />
      <Track.MusicType className="col-start-[music-type] justify-self-center" />
      <Track.Versions className="col-start-[versions] justify-self-center" />
      <Track.Popularity className="col-start-[popularity] justify-self-center" />
      <Track.Similarity className="col-start-[similarity] justify-self-center" />
      <Track.Waveform
        isSelected={isOpen || isSelected}
        className="col-start-[waveform] hidden justify-self-end @[50rem]:block"
      />
      <Track.Duration className="col-start-[duration] justify-self-end" />
      <Track.ActionList className="col-start-[actions] justify-self-center">
        <MagicPreview />
        <TrackItemDropdownMenu />
      </Track.ActionList>
    </SearchTrackItemLayout>
  );
}
