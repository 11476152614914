import { components, GroupBase, IndicatorsContainerProps } from 'react-select';
import { OptionType } from '../../select';

const IndicatorsContainer = (
  props: IndicatorsContainerProps<OptionType, boolean, GroupBase<OptionType>>,
) => {
  const { children } = props;

  return (
    <components.IndicatorsContainer
      className="flex h-full! items-center gap-2 p-0! px-2!"
      {...props}
    >
      {children}
    </components.IndicatorsContainer>
  );
};

export default IndicatorsContainer;
