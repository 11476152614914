import * as ContextMenu from '~/components/ui/control/menus/context-menu/common/context-menu';

type SubmenuTriggerProps = {
  label: string;
  numberOfTracks?: number;
};

export function SubmenuTrigger(props: SubmenuTriggerProps) {
  const { label, numberOfTracks } = props;

  return (
    <ContextMenu.SubItem icon="ri:add-line">
      {label}
      {numberOfTracks ? <span>{` (${numberOfTracks})`}</span> : null}
    </ContextMenu.SubItem>
  );
}
