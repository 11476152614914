/* eslint-disable indent */
export function stringContainsTerm(term: string, string: string): string {
  const words = string.toLowerCase().split(' ');
  let result = '';
  words.forEach((word) => {
    if (word.toLowerCase().startsWith(term)) {
      result = string;
    }
  });
  return result;
}
