import { useState } from 'react';
import Dropdown, { dropdownGroups } from '~/components/ui/control/dropdown/dropdown';
import { useTranslation } from 'react-i18next';
import { useDownloadTracklistCopyright } from '~/hooks/api/track-list';
import Button from '~/components/ui/control/button/button';
import { Icon } from '@iconify/react';

type MoreActionButtonProps = {
  id: string;
  isEmpty?: boolean;
};

const MoreActionButton = (props: MoreActionButtonProps) => {
  const { id, isEmpty } = props;
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const { mutate: getCopyright } = useDownloadTracklistCopyright();
  const { t } = useTranslation('brief-page');

  const groups: dropdownGroups = [
    {
      title: t('header.interactions.button.more.dropdown.title'),
      elements: [
        {
          label: t('header.interactions.button.more.dropdown.option.download-copyrights.label'),
          disabled: isEmpty,
          onclick: () => {
            getCopyright({ id, type: 'brief' });
            setIsDropDownOpen(false);
          },
        },
      ],
    },
  ];
  return (
    <Dropdown
      groups={groups}
      open={isDropDownOpen}
      onOpenChange={setIsDropDownOpen}
      itemClassName="outline-hidden"
      triggerClassname="w-fit"
      align="start"
    >
      <Button color="default">
        <Icon icon="ri:more-line" />
      </Button>
    </Dropdown>
  );
};

export default MoreActionButton;
