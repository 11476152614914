import { useEffect, useState } from 'react';
import { useAllTabData } from '~/components/features/presearch/data/all-tab.data';
import { TagDataType } from '~/hooks/api/tag';
import { useTagFilter } from '~/hooks/filter/use-tag-filter';
import { useBestTagResult } from '~/hooks/utils/use-best-tag-results';
import usePreSearchStore from '~/stores/presearch-store';

export function useTagResultBlock() {
  const { allTags: tags, isLoading } = useAllTabData();
  const presearch = usePreSearchStore(['query', 'setIsOpen']);

  const [currentTags, setCurrentTags] = useState<TagDataType | undefined>(undefined);

  useEffect(() => {
    if (!isLoading && JSON.stringify(tags) !== JSON.stringify(currentTags)) {
      setCurrentTags(tags);
    }
  }, [tags]);

  const { allTags: result, bestTag, otherTags } = useBestTagResult(tags?.subCategories, tags?.tags);

  const {
    onAddTagClick,
    onSubtractTagClick,
    onTagClick,
    onSubCategoryAddClick,
    onSubCategorySubtractClick,
  } = useTagFilter();

  function handleClick() {
    presearch.setIsOpen(false);
  }

  function handleAddTagClick(id: string, name: string) {
    onAddTagClick(id, name);
    handleClick();
  }

  function handleSubtractTagClick(id: string, name: string) {
    onSubtractTagClick(id, name);
    handleClick();
  }

  function handleTagClick(id: string, name: string) {
    onTagClick(id, name);
    handleClick();
  }

  function handleSubCategoryClick(id: string, name: string) {
    onTagClick(id, name, 'tag_categories');
    handleClick();
  }

  function handleSubCategoryAddClick(id: string, name: string) {
    onSubCategoryAddClick(id, name);
    handleClick();
  }

  function handleSubCategorySubtractClick(id: string, name: string) {
    onSubCategorySubtractClick(id, name);
    handleClick();
  }

  return {
    result,
    bestTag,
    otherTags,
    tags: currentTags,
    handleAddTagClick,
    handleSubtractTagClick,
    handleTagClick,
    handleSubCategoryClick,
    handleSubCategoryAddClick,
    handleSubCategorySubtractClick,
  };
}
