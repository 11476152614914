import classNames from 'classnames';
import { MouseEvent, useEffect, useState } from 'react';
import Checkbox, { CheckedState } from '~/components/ui/forms/checkbox/checkbox';
import Icon from '~/components/ui/icon/icon';

type AgentProps = {
  title?: string;
  subTitle?: string;
  categories?: string;
  checked?: CheckedState;
  className?: string;
  isFavorite?: boolean;
  onFavClick?: () => void;
  onCheckClick?: (b: CheckedState) => void;
};

function Agent(props: AgentProps) {
  const {
    title = 'Omnis molestias qui',
    subTitle,
    categories,
    checked = false,
    className,
    isFavorite = false,
    onFavClick,
    onCheckClick,
  } = props;
  const [isChecked, setIsChecked] = useState(checked);
  const [isFaved, setIsFaved] = useState(isFavorite);

  useEffect(() => {
    if (checked !== isChecked) {
      setIsChecked(checked);
    }
  }, [checked]);

  function handleFavClick(e: MouseEvent) {
    e.stopPropagation();
    setIsFaved(!isFaved);
    onFavClick?.();
  }

  function handleCheckClick(b: CheckedState) {
    setIsChecked(b === 'indeterminate' ? false : !b);
    onCheckClick?.(b === 'indeterminate' ? false : !b);
  }

  return (
    <div
      className={classNames(
        'agent bg-neutral-emphasis border-neutral-emphasis hover:border-neutral-muted flex w-full cursor-pointer items-center justify-between rounded-sm border px-3 py-2 transition-all',
        className,
        {
          'border-accent-emphasis! border': isChecked,
        },
      )}
      onClick={() => handleCheckClick(isChecked)}
    >
      <div className="agent__left flex gap-4">
        <Checkbox checked={isChecked} onChange={handleCheckClick} />
        <div className="flex flex-col">
          <h6 className="text-default">{title}</h6>
          {subTitle ? <p className="text-muted text-xs">{subTitle}</p> : null}
        </div>
      </div>
      <div className="agent__right flex flex-col items-end justify-center">
        <button type="button" className={classNames('agent__right__fav')} onClick={handleFavClick}>
          {isFaved ? (
            <Icon name="heart-fill" className="text-favorite-emphasis!" />
          ) : (
            <Icon name="heart-line" className="text-muted" />
          )}
        </button>
        <p className="text-muted text-xs capitalize">{categories}</p>
      </div>
    </div>
  );
}

export default Agent;
