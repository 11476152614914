import classNames from 'classnames';
import * as Tabs from '@radix-ui/react-tabs';
import { parseResultNumber } from './util/parse-result-number';

type TabTriggerProps = {
  label: string;
  value: string;
  resultNumber?: number;
  isActive?: boolean;
};

function TabTrigger(props: TabTriggerProps) {
  const { label, value, resultNumber: suffix, isActive } = props;
  return (
    <Tabs.Trigger
      id={`presearch__tab-trigger--${value}`}
      value={value}
      className={classNames(
        'presearch__tab-trigger',
        'flex h-8 w-fit items-center gap-2 rounded-lg px-3 py-1.5',
        suffix && 'pr-1',
        isActive ? 'bg-inverse text-on-inverse' : 'bg-neutral-muted text-neutral',
      )}
    >
      <span className="text-sm leading-none">{label}</span>
      {(suffix || 0) > 0 && (
        <span
          className={classNames(
            'rounded-md px-2 py-1 text-xs',
            isActive ? 'bg-accent-muted text-default' : 'bg-neutral-emphasis text-neutral',
          )}
        >
          {parseResultNumber(suffix!)}
        </span>
      )}
    </Tabs.Trigger>
  );
}

export default TabTrigger;
