import useAddFilterStore from '~/stores/add-filter-store';
import { useBestTagResult } from '~/hooks/utils/use-best-tag-results';
import { itemConditionsOptions, termOptions, useSearchStore } from '~/stores/search-store';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useTagSearch } from '~/hooks/api/tag';
import { useTagPath } from '~/hooks/utils/use-tag-path';
import { Tag as TagType } from '~/types/schemas/data/tags.schema';
import Tab from '../tab';
import TagSearch from '../../../search/tag-search';

function Tag() {
  const addFilter = useAddFilterStore(['setIsOpen', 'query', 'setQuery']);
  const { data: tags } = useTagSearch(addFilter.query.tag || '');
  const { allTags, allData } = useBestTagResult(tags.subCategories, tags.tags);
  const { findTagPath } = useTagPath();
  const search = useSearchStore(['addValueToNewBlock', 'facets']);
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation('filter');

  const tagIds = tags.tags?.map((tag) => tag.id);

  const isQueryEmpty = addFilter.query.tag === '';

  function handleClick(
    label: string,
    value: string,
    condition: 'is' | 'is_not',
    type: 'tag' | 'subCategory',
  ) {
    search.addValueToNewBlock(
      termOptions[type === 'tag' ? 'tags' : 'tag_categories']!,
      itemConditionsOptions[condition]!,
      {
        label,
        value,
      },
    );
    addFilter.setIsOpen(false);

    if (location.pathname !== '/search') {
      navigate('/search');
    }
  }

  return (
    <Tab label={t('panel.tags-audiences.tab.title')} value="tag">
      <TagSearch
        inputPlaceholder={t('panel.tags-audiences.tab.input.placeholder')}
        value={addFilter.query.tag}
        onValueChange={(q) => addFilter.setQuery('tag', q)}
        items={
          (isQueryEmpty ? allData.filter((item) => tagIds?.includes(item.id)) : allTags)?.map(
            (item) => {
              const isTag = tagIds?.includes(item.id);
              const isTarget = item.type === 1;
              return {
                title: `${item.name} ${isTag ? `(${search.facets?.tags?.[item.id] || 0})` : ''}`,
                subtitle: `${isTarget ? t('panel.tags-audiences.tab.results.type.target') : t('panel.tags-audiences.tab.results.type.tag')}: ${findTagPath(item as TagType)}`,
                color: item.color,
                icon: isTarget ? 'target' : undefined,
                isUnrelevant: isTag && !search.facets?.tags?.[item.id],
                onIncludeClick: () =>
                  handleClick(item.name, item.id, 'is', isTag ? 'tag' : 'subCategory'),
                onExcludeClick: () =>
                  handleClick(item.name, item.id, 'is_not', isTag ? 'tag' : 'subCategory'),
              };
            },
          ) || []
        }
      />
    </Tab>
  );
}

export default Tag;
