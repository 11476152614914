import { Icon, IconifyIconProps } from '@iconify/react';
import classNames from 'classnames';
import { MouseEvent } from 'react';
import { useTrackPlaybackState } from '~/hooks/entities/track/use-track-playback-state';

type TrackSummaryControlsProps = {
  playingIcon?: IconifyIconProps['icon'];
  onTogglePlay?: (e: MouseEvent) => void;
};

export function TrackSummaryControls(props: TrackSummaryControlsProps) {
  const { playingIcon = '', onTogglePlay } = props;
  const { isPlaying, isReady } = useTrackPlaybackState();

  return isReady ? (
    <div
      className={classNames(
        'absolute inset-0 flex cursor-pointer items-center justify-center rounded-sm transition-colors',
        'bg-black/70 opacity-0 group-hover/track-item-cover:opacity-100',
        {
          'opacity-100': isPlaying,
        },
      )}
      onClick={onTogglePlay}
    >
      {!isPlaying ? <Icon icon="ri:play-fill" className="text-default" /> : null}
      {isPlaying ? (
        <Icon
          icon={playingIcon}
          className="text-default block group-hover/track-item-cover:hidden"
        />
      ) : null}
      {isPlaying ? (
        <Icon
          icon="ic:baseline-pause"
          className="text-default hidden group-hover/track-item-cover:block"
        />
      ) : null}
    </div>
  ) : null;
}
