import classNames from 'classnames';
import { activeChannelStore } from '~/stores/chats-store';

export type UserItemProps = {
  userId: string;
  isFocused: boolean;
  onClick?: () => void;
};

function UserItem(props: UserItemProps) {
  const { userId, isFocused, onClick } = props;
  const { participants } = activeChannelStore;
  const user = participants.value[userId];
  const avatar = user?.organization_settings.avatar;

  return (
    <div
      className={classNames(
        'hover:bg-neutral-muted flex w-full cursor-pointer items-center gap-3 px-3 py-2 transition-colors',
        {
          'bg-neutral-muted': isFocused,
          'bg-neutral-emphasis': !isFocused,
        },
      )}
      onClick={onClick}
    >
      {avatar ? (
        <img src={avatar} alt="" className="h-8 w-8 rounded-full object-cover" />
      ) : (
        <div
          className="flex h-8 w-8 shrink-0 items-center justify-center rounded-full"
          style={{ backgroundColor: user?.avatar_color }}
        >
          <span className="text-default text-sm font-bold">
            {user?.first_name[0]!.toUpperCase()}
            {user?.last_name[0]!.toUpperCase()}
          </span>
        </div>
      )}
      <div>
        <h6 className="text-default text-sm">{`${user?.first_name} ${user?.last_name}`}</h6>
        <p className="text-muted text-xs">{user?.company_name}</p>
      </div>
    </div>
  );
}

export default UserItem;
