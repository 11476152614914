import classNames from 'classnames';
import { PlayerVolume } from './volume';
import * as Track from '~/components/entities/track/common/track';
import { MagicPreview } from '~/components/entities/track/track-item/header/actions/magic-preview';
import { PlayerDropdownMenu } from './player-dropdown-menu';

type ActionsProps = {
  className?: string;
};

export function Actions(props: ActionsProps) {
  const { className } = props;

  return (
    <div className={classNames('flex justify-end gap-2', className)}>
      <Track.ActionList className="col-start-[actions] justify-self-center">
        <PlayerVolume />
        <MagicPreview />
        <PlayerDropdownMenu />
      </Track.ActionList>
    </div>
  );
}
