import Dialog from '~/components/ui/overlays/dialog/dialog';
import { useState } from 'react';
import Chip from '~/components/ui/control/chip/chip';
import { mewoDataStore } from '~/stores/mewo-data-store-v2';
import Agent from './agent';
import SearchInput from '~/components/ui/search-input/search-input';

type AgentListProps = {
  recipients?: string[];
};

function AgentList(props: AgentListProps) {
  const { recipients } = props;
  const [query, setQuery] = useState<string>('');
  const { agents } = mewoDataStore;

  function searchProviderCompanyName(companyName: string) {
    let isMatching = false;

    const toLowerCase = companyName.toLowerCase();
    const splited = toLowerCase.split(' ');
    splited.forEach((word) => {
      if (word.startsWith(query.toLowerCase())) {
        isMatching = true;
      }
    });

    return isMatching;
  }

  const includedRecipients = agents?.array.value.filter(
    (agent) => recipients?.includes(agent.id) && searchProviderCompanyName(agent.company_name),
  );
  const excludedRecipients = agents?.array.value.filter(
    (agent) => !recipients?.includes(agent.id) && searchProviderCompanyName(agent.company_name),
  );

  return (
    <Dialog
      triggerClassName="w-fit"
      trigger={
        <Chip
          className="bg-neutral-emphasis border-neutral-emphasis hover:bg-neutral-muted border"
          iconLeft="bx:paper-plane"
        >
          {includedRecipients ? `Sent to ${includedRecipients?.length} providers` : 'Loading...'}
        </Chip>
      }
      containerClassName="flex flex-col items-strech h-full"
      title="Brief recipients"
      asChild
    >
      <div className="flex h-full flex-col items-stretch gap-4">
        <SearchInput value={query} onChange={(v) => setQuery(v)} placeholder="Search a recipient" />

        <div className="scrollbar-on-hover flex flex-col gap-4 overflow-y-auto pr-2">
          {includedRecipients?.length ? (
            <div className="flex flex-col gap-3">
              <span>Included recipients</span>
              {includedRecipients?.map((provider) => (
                <Agent
                  key={provider.id}
                  companyName={provider.company_name}
                  providerTypes={
                    provider.organization_settings.meta.provider_types?.filter(
                      (type) => type === 'independent' || type === 'major',
                    ) || []
                  }
                />
              ))}
            </div>
          ) : null}

          {excludedRecipients?.length ? (
            <div className="flex flex-col gap-3">
              <span>Excluded recipients</span>
              {excludedRecipients?.map((provider) => (
                <Agent
                  key={provider.id}
                  companyName={provider.company_name}
                  providerTypes={
                    provider.organization_settings.meta.provider_types?.filter(
                      (type) => type === 'independent' || type === 'major',
                    ) || []
                  }
                  className="opacity-50"
                />
              ))}
            </div>
          ) : null}
        </div>
      </div>
    </Dialog>
  );
}

export default AgentList;
