import classNames from 'classnames';
import Icon from '~/components/ui/icon/icon';
import * as Popover from '@radix-ui/react-popover';
import FilterPanel from '~/components/features/filter-panel';
import useAddFilterStore from '~/stores/add-filter-store';
import { useEffect } from 'react';

type AddFilterButtonProps = {
  onClick?: () => void;
};

function AddFilterButton(props: AddFilterButtonProps) {
  const { onClick } = props;
  const addFilter = useAddFilterStore(['isOpen', 'setIsOpen']);

  useEffect(() => {
    function closeOnScroll() {
      addFilter.setIsOpen(false);
    }

    document.getElementById('content-container')?.addEventListener('scroll', closeOnScroll);
    return () => {
      document.getElementById('content-container')?.removeEventListener('scroll', closeOnScroll);
    };
  }, []);

  return (
    <Popover.Root open={addFilter.isOpen} onOpenChange={addFilter.setIsOpen}>
      <Popover.Trigger asChild>
        <button
          type="button"
          onClick={onClick}
          className={classNames(
            'add-filter-button flex h-6 w-6 shrink-0 items-center rounded-sm outline-hidden',
            'bg-neutral-emphasis hover:bg-neutral-muted transition duration-100',
            addFilter.isOpen && 'bg-neutral-muted!',
          )}
        >
          <Icon name="add-line" className="text-muted mx-auto text-xl" />
        </button>
      </Popover.Trigger>
      <Popover.Anchor />
      <Popover.Portal>
        <Popover.Content align="start" style={{ zIndex: 20 }}>
          <FilterPanel />
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
}

export default AddFilterButton;
