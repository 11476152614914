import { useBriefContext } from '~/pages/brief/brief.context';
import TextLinkParser from '~/components/ui/text/text-link-parser/text-link-parser';
import { BriefDescriptionBlock } from './block';

export function ContentBlock() {
  const { data, targetLanguage, isLoading } = useBriefContext();

  return (
    <BriefDescriptionBlock label="Content" isLoading={isLoading}>
      <TextLinkParser>
        <p className="text-xs whitespace-pre-wrap">
          {data.descriptions?.find((d) => d.locale === (targetLanguage || 'en'))?.value}
        </p>
      </TextLinkParser>
    </BriefDescriptionBlock>
  );
}
