import classNames from 'classnames';
import { useMemo } from 'react';
import { timeDifferenceWithToday } from '~/utils/common/date-difference';

type StatusChipProps = {
  deadline?: string | null;
  sended?: boolean;
};

export function StatusChip(props: StatusChipProps) {
  const { deadline, sended } = props;

  const status = useMemo(() => {
    if (sended && deadline) {
      const timeBeforeDeadline = timeDifferenceWithToday(deadline);
      return timeBeforeDeadline >= 0 ? 'live' : 'expired';
    }

    if (!sended) {
      return 'draft';
    }

    return null;
  }, [deadline, sended]);

  return (
    <div
      className={classNames(
        'bg-chip-draft h-4 w-fit rounded-sm px-1 text-xs font-semibold capitalize select-none',
        {
          'bg-live-emphasis': status === 'live',
          'bg-draft-emphasis': status === 'draft',
          'bg-muted': status === 'expired',
        },
      )}
    >
      {status === 'live' ? 'Live' : null}
      {status === 'draft' ? 'Draft' : null}
      {status === 'expired' ? 'Exp' : null}
    </div>
  );
}
