import { uploadRoutes } from '~/routes/api';
import { UploadTrack } from '~/types/features/track-upload/track-upload.types';
import { PresignedUploadDataSchema } from '~/types/schemas/file-upload-session/upload.schema';
import { api } from '../api';
import { HTTPError } from 'ky';

export async function createPresignedData(session: string, data: UploadTrack) {
  try {
    if (!session) throw new Error('No session value');
    const file = {
      name: data?.name,
      size: data?.size,
      type: data?.type,
    };
    const json = {
      session,
      file,
      format: 'audio',
    };

    const upload = await api.post(uploadRoutes.createUpload, { json }).json();

    return PresignedUploadDataSchema.parse(upload);
  } catch (error) {
    if (error instanceof HTTPError) {
      throw new HTTPError(error.response, error.request, error.options);
    }
    throw new Error('Failed to create presigned data');
  }
}
