import { ChangeEvent, FocusEventHandler, MutableRefObject, Ref, forwardRef } from 'react';
import classNames from 'classnames';
import { Icon } from '@iconify/react';
import { InputProps } from '../input';

interface TimeInputProps extends InputProps {
  containerClass?: string;
  inputClass?: string;
  wrapperClass?: string;
  labelClass?: string;
  isFocused: boolean;
  onFocus: FocusEventHandler<HTMLInputElement>;
  onBlur: FocusEventHandler<HTMLInputElement>;
}

function Time(props: TimeInputProps, inputRef: Ref<HTMLInputElement>) {
  const {
    label,
    placeholder,
    className,
    containerClass = '',
    inputClass = '',
    wrapperClass = '',
    labelClass,
    error,
    required = false,
    onChange,
    onFocus,
    onBlur,
    ...inputProps
  } = props;

  function handleClick() {
    (inputRef as MutableRefObject<HTMLInputElement>).current?.click();
  }

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    onChange?.(e.target.value);
  }

  return (
    <div className={className} data-no-dnd="true">
      <div className={wrapperClass} onClick={handleClick}>
        {label && (
          <span className={labelClass}>
            {label}
            {required && <span className="text-danger">*</span>}
          </span>
        )}
        <div className={containerClass}>
          <div className="flex flex-1 items-center gap-3 overflow-hidden">
            <input
              ref={inputRef}
              aria-label={label}
              placeholder={placeholder || label}
              type="time"
              className={classNames(inputClass, 'invalid:text-control-placeholder opacity-100')}
              onChange={handleChange}
              onFocus={onFocus}
              onBlur={onBlur}
              required
              {...inputProps}
            />
          </div>
          <div className="flex size-6 shrink-0 items-center justify-center transition-all">
            <Icon icon="ri:time-line" className="text-control-rest text-xs" />
          </div>
        </div>
        {error ? <span className="error text-danger mt-1 w-full text-xs">{error}</span> : null}
      </div>
    </div>
  );
}

export const TimeInput = forwardRef(Time);
