import { IconifyIconProps, Icon } from '@iconify/react';
import classNames from 'classnames';
import { MouseEvent } from 'react';

interface ChipButtonProps {
  icon: IconifyIconProps['icon'];
  className?: string;
  onClick?: (e: MouseEvent) => void;
}

export function ChipButton(props: ChipButtonProps) {
  const { icon, className, onClick } = props;

  function handleClick(e: MouseEvent) {
    e.stopPropagation();
    onClick?.(e);
  }

  return (
    <div
      className={classNames(
        'bg-neutral-muted border-neutral-muted hover:bg-muted flex aspect-square size-4 items-center justify-center rounded-md border',
        className,
      )}
      onClick={handleClick}
    >
      <Icon icon={icon} className="text-default size-3" />
    </div>
  );
}
