import { useSearchStore } from '~/stores/search-store';
import { useEffect, useState } from 'react';
import { parseThousands } from '~/utils/common/parse-thousands';
import { useTranslation } from 'react-i18next';

function ResultNumber() {
  const search = useSearchStore(['nbResults', 'isSearchLoading']);
  const [currentNumber, setCurrentNumber] = useState(search.nbResults);
  const { t } = useTranslation('search');

  useEffect(() => {
    if (currentNumber === search.nbResults || search.isSearchLoading) {
      return;
    }

    setCurrentNumber(search.nbResults);
  }, [currentNumber, search.isSearchLoading, search.nbResults]);

  return (
    <div className="border-x-accent-emphasis text-default flex h-4 items-center border-l pl-2 text-xs">
      <span>
        {t('results.results-number', { number: parseThousands(currentNumber.toString(), ' ') })}
      </span>
    </div>
  );
}

export default ResultNumber;
