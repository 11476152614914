import { Playlist } from '~/types/schemas/music/playlists.schema';
import { parseDateNumeric } from '~/utils/common/parse-date';
import InfoChip from '~/pages/brief/components/info-chip';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Artwork } from '~/components/ui/cover/artwork';
import PlaylistInteractions from './playlist-interactions';
import PlaylistDescription from './playlist-description';
import { Tooltip } from '~/components/ui/overlays/tooltip/tooltip';

type PlaylistHeaderProps = {
  detail?: Playlist;
  className?: string;
};

const PlaylistHeader = (props: PlaylistHeaderProps) => {
  const { detail, className } = props;
  const { t } = useTranslation('playlist-page');

  if (!detail) return null;

  const { created_by, created_at, id, tracks, tenant } = detail!;
  const { company_name } = tenant!;
  const { first_name, last_name } = created_by!;

  return (
    <section className={classNames('flex h-fit flex-col gap-6', className)}>
      <div className="flex h-64 gap-6 px-4">
        <Artwork
          className="aspect-square w-fit"
          artworks={detail?.artworks?.map((artwork) => artwork.large)}
          alt="cover"
          icon="ri:headphone-line"
        />
        <div className="flex h-full flex-1 flex-col gap-4">
          <div className="relative mb-4 flex h-full flex-col justify-end self-stretch">
            <div className="flex w-full items-center justify-between">
              <h4 className="text-muted text-xs">
                {t('header.creation-info', {
                  company: company_name,
                  user: `${first_name} ${last_name}`,
                  date: parseDateNumeric(created_at || '', {
                    month: 'long',
                    day: '2-digit',
                  }),
                })}
              </h4>
            </div>
            <Tooltip label={detail?.title}>
              <h1
                id="playlist-title"
                className="line-clamp-2 text-start text-5xl leading-[3.5rem] font-bold break-words"
              >
                {detail?.title}
              </h1>
            </Tooltip>
          </div>
          <div className="flex gap-4">
            <InfoChip value={t('header.tracks-number', { count: tracks })} />
          </div>
          <PlaylistInteractions
            id={id!}
            data={detail!}
            ownedByTenant={detail?.owned_by_tenant}
            isEmpty={!((tracks || 0) > 0)}
          />
        </div>
      </div>
      <div className="px-4">
        {detail?.descriptions.length ? (
          <PlaylistDescription descriptions={detail?.descriptions} />
        ) : null}
      </div>
    </section>
  );
};

export default PlaylistHeader;
