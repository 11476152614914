import { Icon } from '@iconify/react';
import * as RadixDropdownMenu from '@radix-ui/react-dropdown-menu';
import { useTranslation } from 'react-i18next';
import { TranslateDropdownMenu } from '../menus/dropdown-menu/translate/translate';
import { useTranslateSelectContext } from './translate-select.context';
import Button from '../button/button';

export function TranslateButton() {
  const { userLanguage, onLanguageChange } = useTranslateSelectContext();
  const { t } = useTranslation('translate-select');

  return (
    <RadixDropdownMenu.Root>
      <RadixDropdownMenu.Trigger asChild>
        <Button color="default" iconLeft="translate-ai-2">
          {t('translate')}
        </Button>
      </RadixDropdownMenu.Trigger>
      <TranslateDropdownMenu
        userLanguage={userLanguage}
        onLanguageChange={onLanguageChange}
        align="end"
        sideOffset={8}
      />
    </RadixDropdownMenu.Root>
  );
}
