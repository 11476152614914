import { useTranslation } from 'react-i18next';
import { TrackStepHeaderLoader } from '../../../headers/track-step-header-loader';
import { TrackStepsContainer } from '../../../track-steps.container';
import { CancelListButton } from '../../common/cancel-list-button';

export function MatchDisplayLoader() {
  const { t } = useTranslation('tracks-upload-form');

  return (
    <TrackStepsContainer>
      <div className="relative flex size-full items-center justify-between pr-4">
        <TrackStepHeaderLoader
          title={t('card.display.fetch')}
          state={t('card.display.fetch.subtitle')}
          className="flex-1"
        />
        <CancelListButton />
      </div>
    </TrackStepsContainer>
  );
}
