import classNames from 'classnames';
import { ReactSVG } from 'react-svg';
import { useTrackWaveform } from '~/hooks/entities/track/use-track-waveform';
import { useTrackContext } from './track.context';
import { useCallback } from 'react';

export type WaveformProps = {
  className?: string;
  isSelected?: boolean;
};

const WAVEFORM_PLACEHOLDER =
  'https://mewo-preprod-api.s3.amazonaws.com/5e5e7fa5394c022e1ad07788/ingestions/waveforms/5e6103148ed15335b2707e40/import/ingestions/audiofiles/5e207d4f749a3512fc3ebcc0/cezame/GMT_8031_CHEMICAL%20CLUE/GMT_8031_021_SCIENCE%20LAB%20_BENJAMIN%20KANIEWSKI_320_300x45.svg';

export const TrackWaveform = (props: WaveformProps) => {
  const { className, isSelected } = props;
  const store = useTrackContext();
  const track = store.use.track();
  const { isPlaybackTrack, progress, isNotReady, seekTo } = useTrackWaveform();

  const waveformSrc = track.waveform || WAVEFORM_PLACEHOLDER;
  const handleSeek = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      if (isNotReady) return;

      const rect = (e.target as HTMLElement).getBoundingClientRect();

      if (rect) {
        const newProgress = ((e.clientX - rect.left) / rect.width) * 100;
        seekTo(newProgress);
      }
    },
    [isNotReady, seekTo],
  );

  return (
    <div
      onClick={handleSeek}
      className={classNames(
        'waveform group relative w-full cursor-pointer',
        {
          'blur-xs': !track.waveform,
        },
        className,
      )}
    >
      <ReactSVG
        src={waveformSrc}
        className={classNames(
          '[&_g]:group-hover/track-item:fill-waveform-selected! [&_g]:fill-neutral-emphasis [&_svg]:size-full',
          {
            '[&_g]:fill-waveform-selected!': isSelected,
          },
        )}
      />
      {isPlaybackTrack && progress && !isNotReady ? (
        <ReactSVG
          src={waveformSrc}
          className="[&_g]:fill-inverse! [&_g]:group-hover:fill-accent-emphasis! absolute inset-0 [&_g]:transition-all [&_svg]:size-full"
          style={{ clipPath: `inset(0 ${100 - progress}% 0 0)` }}
        />
      ) : null}
    </div>
  );
};
