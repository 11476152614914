import { useEffect } from 'react';
import { TracksUploadItemList } from './content/item-list/item-list';
import { TracksUploadHeader } from './header/header';
import { useTracksUploadContext } from './tracks-upload.context';
import { useUploadSessionContext } from '~/providers/upload/session/upload-session.context';

export function TracksUpload() {
  const { onExit, store } = useTracksUploadContext();
  const files = store.use.files();

  const { session } = useUploadSessionContext();

  useEffect(() => {
    if (files.length === 0) {
      onExit();
    }
  }, [files, onExit]);

  return session ? (
    <div className="relative flex h-full w-full flex-col items-center gap-6 pt-10">
      <TracksUploadHeader />
      <TracksUploadItemList />
    </div>
  ) : null;
}
