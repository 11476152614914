import { createContext, useContext } from 'react';
import { StoreApi } from 'zustand';
import { WithSelectors } from '~/stores/utilities';
import { Track } from '~/types/schemas/music/track.schema';
import { Action } from '~/types/types/actions';

export type TrackState = {
  track: Track;
  context: Action.Play;
  isTrackActive: boolean;
  setIsTrackActive: (isActive: boolean) => void;
};

const TrackContext = createContext<
  (StoreApi<TrackState> & WithSelectors<StoreApi<TrackState>>) | null
>(null);

function useTrackContext() {
  const store = useContext(TrackContext);

  if (!store) {
    throw new Error('useTrackContext must be used within a Track.Root');
  }
  return store;
}

export { TrackContext, useTrackContext };
