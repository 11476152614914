import { useCallback, useEffect, useRef } from 'react';
import { cva } from 'cva';
import Button from '~/components/ui/control/button/button';
import { useSignal } from '@preact/signals-react';
import { userSettingsStore } from '~/stores/user-settings-store';
import { SupportedLanguages } from '~/types/schemas/data/translate.schema';
import { LANGUAGES } from '~/constants/languages';
import { globalStore } from '~/stores/global-store-v2';
import Select from '~/components/ui/forms/select/select';
import Input from '~/components/ui/forms/input/input';

const wrapperStyles = cva(['bg-gray-800', 'fixed', 'left-0', 'top-0', 'z-50', 'transition-all'], {
  variants: {
    isOpen: {
      true: ['w-72', 'h-fit', 'opacity-100'],
      false: ['overflow-hidden'],
    },
    dev: {
      true: [],
      false: [],
    },
  },
  compoundVariants: [
    {
      isOpen: false,
      dev: false,
      className: ['h-0', 'w-0', 'opacity-0', 'select-none'],
    },
    {
      isOpen: false,
      dev: true,
      className: ['h-4', 'w-4', 'opacity-40'],
    },
  ],
});

function useKeySequence(code: string) {
  const sequence = useSignal<string[]>([]);

  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      sequence.value = [...sequence.value, event.key];

      if (sequence.value.length > code.length) {
        sequence.value = sequence.value.slice(1);
      }
    };

    document.addEventListener('keydown', listener);

    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, []);

  if (sequence.value.join('').endsWith(code)) {
    return true;
  }

  return false;
}

export function MewoDevTools() {
  const isOpen = useSignal(false);
  const { apiUrl, setApiUrl, userToken, setAdminToken } = globalStore;
  const { language, setLanguage } = userSettingsStore;
  const wrapper = useRef<HTMLDivElement>(null);

  const checkBody = useCallback((event: MouseEvent) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const path = event.composedPath ? event.composedPath() : ((event as any).path as EventTarget[]);

    if (!path.includes(wrapper.current as EventTarget)) {
      isOpen.value = false;
    }
  }, []);

  const mewodev = useKeySequence('mewodev');

  useEffect(() => {
    if (isOpen.value) {
      document.addEventListener('click', checkBody, true);
    } else {
      document.removeEventListener('click', checkBody, true);
    }
  }, [isOpen.value, checkBody]);

  useEffect(() => {
    if (mewodev) {
      isOpen.value = true;
    }
  }, [mewodev]);

  return (
    <div
      ref={wrapper}
      className={wrapperStyles({
        isOpen: isOpen.value,
        dev: import.meta.env.VITE_APP_ENV === 'dev' || import.meta.env.VITE_APP_ENV === 'preprod',
      })}
    >
      <div
        className="absolute top-0 left-0 h-4 w-4 bg-slate-700"
        onClick={() => {
          isOpen.value = !isOpen.value;
        }}
      />
      <div className="p-4">
        <div className="flex">
          <Button
            color="primary"
            className="m-2"
            onClick={() => setApiUrl('http://localhost:4040')}
          >
            Local
          </Button>
          <Button
            color="primary"
            className="m-2"
            onClick={() => setApiUrl('https://preprod-api-v2.mewo.io')}
          >
            Preprod
          </Button>
          <Button
            color="primary"
            className="m-2"
            onClick={() => setApiUrl('https://api-v2.mewo.io')}
          >
            Prod
          </Button>
        </div>

        <Input label="API Url" value={apiUrl.value} onChange={(value) => setApiUrl(value)} />

        <Input
          className="mt-3"
          label="Token"
          value={userToken.value || ''}
          onChange={(value) => setAdminToken(value, true)}
        />

        <Select
          containerClassName="mt-3"
          label="Language"
          options={LANGUAGES}
          value={LANGUAGES.find((l) => l.value === language.value) || LANGUAGES[0]}
          onSingleValueChange={(v) => setLanguage(v.value as SupportedLanguages)}
        />
        <div className="pt-3">
          <Button
            color="danger"
            onClick={() => {
              throw new Error('Test error');
            }}
          >
            Test Sentry Error
          </Button>
        </div>
      </div>
    </div>
  );
}
