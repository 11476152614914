import * as RadixContextMenu from '@radix-ui/react-context-menu';
import * as ContextMenu from '~/components/ui/control/menus/context-menu/common/context-menu';
import { PropsWithChildren } from 'react';
import { useTrackContext } from '~/components/entities/track/common/track.context';
import { TrackItemsContextItems } from './context-items';

type TrackItemContextMenuProps = PropsWithChildren;

function TrackItemContextMenuContent() {
  return (
    <ContextMenu.Content>
      <TrackItemsContextItems />
    </ContextMenu.Content>
  );
}

export function TrackItemContextMenu(props: TrackItemContextMenuProps) {
  const { children } = props;
  const store = useTrackContext();
  const setIsTrackActive = store.use.setIsTrackActive();

  return (
    <RadixContextMenu.Root onOpenChange={setIsTrackActive}>
      <RadixContextMenu.Trigger>{children}</RadixContextMenu.Trigger>
      <RadixContextMenu.Portal>
        <TrackItemContextMenuContent />
      </RadixContextMenu.Portal>
    </RadixContextMenu.Root>
  );
}
