import { Results } from './results/results';
import { useState } from 'react';
import useDebounce from '~/hooks/common/use-debounce';
import SearchInput, { SearchFilter } from './search/search-input';

export function TracklistList() {
  const [query, setQuery] = useState('');
  const [filter, setFilter] = useState<SearchFilter>(SearchFilter.All);

  const debounceQuery = useDebounce(query, 500);
  return (
    <div className="bg-default flex size-full flex-col gap-4 overflow-hidden rounded-2xl pt-4">
      <div className="w-full px-2">
        <SearchInput value={query} onChange={setQuery} onFilterChange={setFilter} filter={filter} />
      </div>
      <Results
        query={debounceQuery}
        collections={
          filter == SearchFilter.All ? [SearchFilter.Playlists, SearchFilter.Briefs] : [filter]
        }
      />
    </div>
  );
}
