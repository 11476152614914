import classNames from 'classnames';
import { PropsWithChildren } from 'react';
import { useTrackItem } from '~/hooks/entities/track/track-item/use-track-item';

export function TrackVersionWrapper(props: PropsWithChildren) {
  const { children } = props;
  const { isOpen, isTrackActive, isSelected, handleClick } = useTrackItem();

  return (
    <div
      className={classNames(
        'group/track-item',
        'overflow-hidden rounded-lg border border-transparent select-none',
        'hover:bg-neutral-muted! hover:border-neutral-muted',
        {
          'bg-neutral-muted! border-neutral-muted': isTrackActive,
          'bg-neutral-muted!': isSelected || isOpen,
        },
      )}
      onClick={handleClick}
    >
      <div className="p-2">{children}</div>
    </div>
  );
}
