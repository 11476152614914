import { filesize } from 'filesize';
import { useRef, ChangeEvent } from 'react';
import Icon from '~/components/ui/icon/icon';
import { useTranslation } from 'react-i18next';
import { toastr } from '~/components/ui/notifications/toast/utils/toast-call';

type FileButtonProps = {
  handleFile: (files: File[]) => void;
};

const SIZE_LIMIT = 5 * 1024 * 1024;

function FileButton(props: FileButtonProps) {
  const { handleFile } = props;
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const { t } = useTranslation('toast');

  function handleClick() {
    hiddenFileInput.current?.click();
  }

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    const filesUploaded = Array.from(e.target.files || []);

    const filterFiles = filesUploaded.filter((file) => {
      if (file.size > SIZE_LIMIT) {
        toastr(
          'error',
          t('brief.chat.file-too-big.title'),
          t('brief.chat.file-too-big.content', {
            'file-name': file.name,
            'file-size': filesize(SIZE_LIMIT, { standard: 'jedec' }),
          }),
        );
        return false;
      }

      return true;
    });

    handleFile(filterFiles);
  }

  return (
    <>
      <button
        type="button"
        className="group h-9 w-9 rounded-full transition-all duration-300"
        onClick={handleClick}
      >
        <Icon
          name="attachment-line"
          className="text-muted group-hover:text-default text-[1.5rem]"
        />
      </button>
      <input
        type="file"
        multiple
        size={SIZE_LIMIT}
        onChange={handleChange}
        ref={hiddenFileInput}
        style={{ display: 'none' }}
      />
    </>
  );
}

export default FileButton;
