import {
  ChangeEvent,
  FocusEventHandler,
  MouseEvent,
  MutableRefObject,
  Ref,
  forwardRef,
  useState,
} from 'react';
import classNames from 'classnames';
import { Icon } from '@iconify/react';
import { InputProps } from '../input';

interface FileInputProps extends InputProps {
  containerClass?: string;
  inputClass?: string;
  wrapperClass?: string;
  labelClass?: string;
  isFocused: boolean;
  onFocus: FocusEventHandler<HTMLInputElement>;
  onBlur: FocusEventHandler<HTMLInputElement>;
}

function File(props: FileInputProps, inputRef: Ref<HTMLInputElement>) {
  const {
    value,
    label,
    placeholder,
    className,
    containerClass = '',
    inputClass = '',
    wrapperClass = '',
    labelClass,
    error,
    required = false,
    isLoading,
    onChange,
    onFilesChange,
    onFocus,
    onBlur,
    ...inputProps
  } = props;

  const [files, setFiles] = useState<FileList | null>(
    (inputRef as MutableRefObject<HTMLInputElement>).current?.files,
  );

  function handleUploadCLick(e: MouseEvent<HTMLDivElement>) {
    e.preventDefault();
    (inputRef as MutableRefObject<HTMLInputElement>).current?.click();
  }

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    onChange?.(e.target.files?.[0]?.name || '');
    onFilesChange?.(Array.from(e.target.files || []));
    setFiles(e.target.files);
  }

  function handleFocus() {
    (inputRef as MutableRefObject<HTMLInputElement>).current?.focus();
  }

  return (
    <div className={className}>
      <div onClick={handleFocus} className={wrapperClass}>
        {label && (
          <span className={labelClass}>
            {label}
            {required && <span className="text-danger">*</span>}
          </span>
        )}
        <div className={containerClass}>
          <div className="flex flex-1 items-center gap-3 overflow-hidden">
            <span
              className={classNames(inputClass, 'line-clamp-1 break-all opacity-100', {
                'text-control-hover': files?.length || value,
                'text-control-neutral': !files?.length && !value,
              })}
            >
              {value || files?.length ? value || files?.item(0)?.name : placeholder || label}
            </span>
          </div>
          <div
            className="flex size-6 shrink-0 items-center justify-center transition-all"
            onClick={handleUploadCLick}
          >
            <Icon
              icon={isLoading ? 'svg-spinners:90-ring-with-bg' : 'ri:upload-line'}
              className="text-control-rest text-xs"
            />
          </div>
        </div>
      </div>
      <input
        id={`file-${label}`}
        ref={inputRef}
        aria-label={label}
        type="file"
        onChange={handleChange}
        onFocus={onFocus}
        onBlur={onBlur}
        {...inputProps}
        hidden
      />
      {error ? (
        <span className="error text-control-danger mt-1 w-full text-xs">{error}</span>
      ) : null}
    </div>
  );
}

export const FileInput = forwardRef(File);
