import classNames from 'classnames';
import { StepIndex } from './step-index';

export type FormStepProps = {
  title: string;
  description: string;
  state: 'active' | 'done' | 'todo' | 'error';
  index?: number;
  disabled?: boolean;
  onStepClick?: (index?: number) => void;
};

export function FormStep(props: FormStepProps) {
  const { state, index, disabled = false, onStepClick } = props;
  return (
    <div
      className={classNames('form-step flex items-center gap-4', {
        'text-muted! pointer-events-none opacity-50': disabled,
      })}
    >
      <div className="text-right">
        <h6 className="text-default text-xs font-bold">{props.title}</h6>
        <p className="text-muted text-xs">{props.description}</p>
      </div>
      <div className="aspect-square w-6">
        <StepIndex disabled={disabled} state={state} index={index} onStepClick={onStepClick} />
      </div>
    </div>
  );
}
