import { Icon } from '@iconify/react';

interface EmptyProps {
  alt?: string;
  icon: string;
}

function Empty(props: EmptyProps) {
  const { icon, alt } = props;

  return (
    <div
      aria-label={alt}
      className="bg-neutral-muted text-neutral-muted text-full flex h-full w-full items-center justify-center"
    >
      <Icon icon={icon} />
    </div>
  );
}

export default Empty;
