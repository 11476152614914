import { PropsWithChildren, useEffect } from 'react';
import { useCreateUploadSession } from '~/hooks/api/upload';
import { UploadSessionContextProvider } from './upload-session.context';

type UploadSessionProps = PropsWithChildren<{
  context: string;
  contextId: string;
}>;

export function UploadSessionProvider(props: UploadSessionProps) {
  const { context, contextId, children } = props;
  const { data: session, mutate: createSession } = useCreateUploadSession();

  useEffect(() => {
    if (!session) {
      createSession({ context, contextId });
    }
  }, [context, contextId, session, createSession]);

  return (
    <UploadSessionContextProvider session={session} context={context} contextId={contextId}>
      {children}
    </UploadSessionContextProvider>
  );
}
