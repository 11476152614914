import dayjs from 'dayjs';
import { useCardClick } from '~/components/features/presearch/hook/use-card-click';
import { useTranslation } from 'react-i18next';
import { usePreReleaseAlbumSearch } from '~/hooks/api/album';
import { ClickableNameList } from '~/components/ui/display/clickable-name-list';
import CardShelf from '../../../card-shelf/card-shelf';

function PreReleaseBlock() {
  const { t } = useTranslation('pre-search');
  const { data: albums } = usePreReleaseAlbumSearch('');
  const flatedAlbums = albums?.pages.flatMap((page) => page.hits);
  const { handleFocusClick, handleClick, handleArtistClick } = useCardClick('album');

  return flatedAlbums?.length ? (
    <CardShelf
      label={t('tab.home.pre-releases')}
      containerClassName="flex-1 col-span-full"
      rowLimit={1}
      cardsProps={flatedAlbums?.map((album) => ({
        type: 'album',
        artworks: album.artwork ? [album.artwork] : undefined,
        title: album.title,
        customSubtitle: album.artists.length ? (
          <ClickableNameList onNameClick={handleArtistClick} names={album.artists} />
        ) : null,
        subtitlePrefx: album.release_date ? dayjs(album.release_date).format('DD MMM') : undefined,
        onCardClick: () => handleFocusClick(album.title, album.id),
        onIncludeClick: () => handleClick(album.title, album.id, 'is'),
        onExcludeClick: () => handleClick(album.title, album.id, 'is_not'),
      }))}
    />
  ) : null;
}

export default PreReleaseBlock;
