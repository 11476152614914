import { lazy, Suspense, useEffect } from 'react';
import useMusemindStore from '~/stores/musemind-store';
import usePlayerStore from '~/stores/player-store';
import IconPause from 'virtual:icons/ri/pause-fill';
import IconPlay from 'virtual:icons/ri/play-fill';
import IconClose from 'virtual:icons/ri/eye-off-line';
import GeneralIcons from '~/components/ui/icon/general-icons';
import classNames from 'classnames';
import { Volume } from './volume';
import VideoSeek from './video-seek';
import VariationControlBase from './variation-control';
import { useTranslation } from 'react-i18next';

const VariationControlWithDownload = lazy(() => import('./variation-control-with-download'));

type ControlsProps = {
  className?: string;
};

const Controls = (props: ControlsProps) => {
  const { className = '' } = props;
  const player = usePlayerStore(['setPlay', 'isPlaying']);
  const musemind = useMusemindStore([
    'audioTitle',
    'isPlaying',
    'isCreateVariationsLoading',
    'togglePlay',
    'setIsPipOpen',
  ]);
  const { t } = useTranslation('magic-preview');

  const handleTogglePlay = () => {
    if (player.isPlaying) {
      player.setPlay(false);
    }
    musemind.togglePlay();
  };

  useEffect(() => {
    if (musemind.isPlaying) {
      player.setPlay(false);
    }
  }, [musemind.isPlaying, player.setPlay]);

  return (
    <div
      className={classNames(
        className,
        musemind.isCreateVariationsLoading ? 'block' : 'hidden group-hover:block',
      )}
    >
      <div className="from-neutral-emphasis to-neutral-muted absolute inset-0 flex h-full w-full items-center justify-center bg-linear-to-t opacity-80 transition-all duration-100">
        {musemind.isCreateVariationsLoading ? (
          <div>
            <GeneralIcons icon="musemind-variation-loader" className="text-muted text-9xl" />
          </div>
        ) : (
          <button
            data-no-dnd="true"
            type="button"
            className="musemind-pip__controls__button text-6xl"
            onClick={() => handleTogglePlay()}
          >
            {musemind.isPlaying ? (
              <IconPause className="musemind-pip__controls__button--pause" />
            ) : (
              <IconPlay className="musemind-pip__controls__button--play" />
            )}
          </button>
        )}
      </div>
      <div className="absolute top-0 left-0 flex w-full items-center justify-between p-4">
        <span className="musemind-pip__controls__title text-default max-w-full truncate text-sm font-bold">
          {musemind.audioTitle || ''}
        </span>
        <button
          type="button"
          data-no-dnd="true"
          onClick={() => musemind.setIsPipOpen(false)}
          className="musemind-pip__controls__hide flex gap-2 text-xs"
        >
          {t('pip.header.hide')} <IconClose />
        </button>
      </div>
      <div
        className={classNames(
          'absolute bottom-0 flex w-full flex-col gap-2 p-4',
          musemind.isCreateVariationsLoading ? 'pointer-events-none opacity-50' : 'block',
        )}
      >
        <VideoSeek />
        <div className="flex w-full items-center justify-between">
          <Suspense fallback={<VariationControlBase />}>
            <VariationControlWithDownload />
          </Suspense>
          <Volume />
        </div>
      </div>
    </div>
  );
};

export default Controls;
