import classNames from 'classnames';
import PresearchBlock from '../presearch-block/presearch-block';
import Card, { CardProps } from '../card/card';
import { useEffect, useMemo, useRef, useState } from 'react';

type CardShelfProps = {
  label: string;
  containerClassName?: string | undefined;
  childrenClassName?: string | undefined;
  rowLimit?: number;
  cardsProps?: CardProps[];
};

function CardShelf(props: CardShelfProps) {
  const { label, rowLimit, cardsProps, containerClassName } = props;
  const [columnCount, setColumnCount] = useState(1);
  const containerRef = useRef<HTMLDivElement>(null);

  const maxItems = useMemo(() => {
    if (rowLimit) {
      return rowLimit * columnCount;
    }

    return cardsProps?.length;
  }, [rowLimit, columnCount, cardsProps]);

  useEffect(() => {
    const container = containerRef.current;

    if (!container) return;

    const observer = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const width = entry.contentRect.width;
        const newColumnCount = Math.round(width / 224);
        setColumnCount(newColumnCount);
      }
    });

    observer.observe(container);

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <PresearchBlock
      ref={containerRef}
      label={label}
      containerClassName={classNames('flex-1 @container', containerClassName)}
      childrenClassName="w-full grid gap-6"
      childrenStyle={{ gridTemplateColumns: `repeat(${columnCount}, minmax(0, 1fr))` }}
    >
      {cardsProps
        ?.slice(0, maxItems)
        .map((card, index) => <Card key={`${card.type}-${card.title}-${index}`} {...card} />)}
    </PresearchBlock>
  );
}

export default CardShelf;
