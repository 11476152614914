import { useForm } from 'react-hook-form';
import { usePostTrackLicenseQuote } from '~/hooks/api/track';
import { useTrackContext } from '~/components/entities/track/common/track.context';
import {
  LicensingQuote,
  LicensingQuoteForm,
  LicensingQuoteFormSchema,
} from '~/types/schemas/form/licensing-quote.schema';
import { zodResolver } from '@hookform/resolvers/zod';

type UseLicensingQuoteDialogProps = {
  onSuccess: () => void;
};

export function useLicensingQuoteDialog(props: UseLicensingQuoteDialogProps) {
  const store = useTrackContext();
  const track = store.use.track();
  const { mutate: sendLicenseQuote, isPending } = usePostTrackLicenseQuote(track.id);

  const storedValues = localStorage.getItem('last_interest_form');
  const defaultValues = storedValues ? (JSON.parse(storedValues) as LicensingQuote) : null;

  const { handleSubmit, control, reset, register } = useForm<LicensingQuoteForm>({
    resolver: zodResolver(LicensingQuoteFormSchema),
    defaultValues: {
      productionType: defaultValues?.production_type || '',
      productionName: defaultValues?.production_name || '',
      requestedRights: defaultValues?.requested_rights || '',
      licensingClientName: defaultValues?.licensing_client_name || '',
      licensingCountry: defaultValues?.licensing_country || '',
      creativeDescription: defaultValues?.creative_description || '',
      maxLength: defaultValues?.max_length || '',
      videoLinks: defaultValues?.video_links || '',
      medias: defaultValues?.medias || '',
      duration: defaultValues?.duration || '',
      launchDate: defaultValues?.launch_date || '',
      territories: defaultValues?.territories || '',
      budget: defaultValues?.budget || 0,
    },
    mode: 'onChange',
  });

  function clearFields() {
    localStorage.removeItem('last_interest_form');
    reset({
      productionType: '',
      productionName: '',
      requestedRights: '',
      licensingClientName: '',
      licensingCountry: '',
      creativeDescription: '',
      maxLength: '',
      videoLinks: '',
      medias: '',
      duration: '',
      launchDate: '',
      territories: '',
      budget: 0,
    });
  }

  function onSubmit(data: LicensingQuoteForm) {
    sendLicenseQuote(
      {
        production_name: data.productionName,
        production_type: data.productionType,
        requested_rights: data.requestedRights,
        licensing_client_name: data.licensingClientName,
        licensing_country: data.licensingCountry,
        creative_description: data.creativeDescription,
        max_length: data.maxLength,
        video_links: data.videoLinks,
        medias: data.medias,
        duration: data.duration,
        launch_date: data.launchDate,
        territories: data.territories,
        budget: data.budget || undefined,
      },
      {
        onSuccess: props.onSuccess,
      },
    );
  }

  return {
    handleSubmit: handleSubmit(onSubmit),
    clearFields,
    control,
    register,
    track,
    isPending,
  };
}
