import { useCardClick } from '~/components/features/presearch/hook/use-card-click';
import useAddFilterStore from '~/stores/add-filter-store';
import { useTranslation } from 'react-i18next';
import { usePlaylistSearch } from '~/hooks/api/playlist';
import ItemSearch from '../../../search/item-search';
import Tab from '../tab';

function Playlist() {
  const addFilter = useAddFilterStore(['setIsOpen', 'query', 'setQuery']);
  const {
    data: catalogs,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = usePlaylistSearch(addFilter.query.playlist || '');
  const { handleClick: addToFilter } = useCardClick('internal_refs');
  const { t } = useTranslation('filter');

  const flattenPlaylists = catalogs?.pages.flatMap((page) => page.hits) || [];

  function handleClick(name: string, id: string, operator: 'is' | 'is_not') {
    addToFilter(name, id, operator);
    addFilter.setIsOpen(false);
  }

  return (
    <Tab label={t('panel.playlists.tab.title')} value="playlist">
      <ItemSearch
        itemType="playlist"
        inputPlaceholder={t('panel.playlists.tab.input.placeholder')}
        value={addFilter.query.playlist}
        onValueChange={(q) => addFilter.setQuery('playlist', q)}
        items={flattenPlaylists?.map((playlist) => ({
          type: 'playlist',
          artworks: playlist.artworks || [],
          title: playlist.title,
          subtitle: playlist.tenant?.company_name || undefined,
          onIncludeClick: () => handleClick(playlist.title, playlist.id, 'is'),
          onExcludeClick: () => handleClick(playlist.title, playlist.id, 'is_not'),
        }))}
        totalCount={flattenPlaylists.length}
        hasNextPage={hasNextPage}
        isFetchingNextPage={isFetchingNextPage}
        fetchNextPage={fetchNextPage}
        isEndReached={!hasNextPage}
      />
    </Tab>
  );
}

export default Playlist;
