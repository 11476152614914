import { DragEndEvent, useDndMonitor } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { useDraggedProps } from '~/providers/dragged-props-provider';
import { Track } from '~/types/schemas/music/track.schema';

type useTrackItemSortParams = {
  tracks?: Track[];
  onSort: (ids: string[]) => void;
  onNewTrack?: (track: Track, over: DragEndEvent['over']) => void;
};

export function useTrackItemSort(params: useTrackItemSortParams) {
  const { tracks, onSort, onNewTrack } = params;
  const { draggedProps } = useDraggedProps();

  const activeTrack = draggedProps?.data.current?.track;

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    const trackIds = tracks?.map((track) => track.id);

    if (trackIds?.length && over?.data.current?.type === 'track' && active.id !== over?.id) {
      if (over?.data.current?.sortable.items.includes(active.id)) {
        const oldIndex = trackIds?.findIndex((id) => id === active.id);
        const newIndex = trackIds?.findIndex((id) => id === over?.id);
        const newTracks = arrayMove(trackIds, oldIndex, newIndex);

        onSort(newTracks);
      } else {
        onNewTrack?.(activeTrack as Track, over);
      }
    }
  }

  useDndMonitor({
    onDragEnd: handleDragEnd,
  });

  return {
    activeTrack,
  };
}
