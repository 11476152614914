import { Steps } from '~/types/features/track-upload/track-upload.types';

export const QUEUE_SIZE = 3;

export const STEPS_ORDER: Record<Steps, number> = {
  noMatch: 1,
  match: 1,
  failed: 1,
  matchReady: 2,
  waiting: 2,
};
