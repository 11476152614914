import classNames from 'classnames';
import { PropsWithChildren } from 'react';

type ListHeaderProps = PropsWithChildren<{
  className?: string;
}>;

export function ListHeader(props: ListHeaderProps) {
  const { className, children } = props;

  return (
    <div
      className={classNames(
        'bg-default border-neutral-muted sticky top-0 z-5 w-full border-b p-2',
        className,
      )}
    >
      {children}
    </div>
  );
}
