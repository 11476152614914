import Pubnub from 'pubnub';
import { useEffect } from 'react';
import { useGetRealtimeData } from '~/hooks/api/dashboard';
import { useRealtimeUser } from '~/components/features/brief-chats/components/chat-list/use-realtime-user';
import { useRealtime } from '~/hooks/realtime/use-realtime';
import { globalStore } from '~/stores/global-store-v2';
import { realtimeStore } from '~/stores/realtime-store';
import { User } from '~/types/schemas/auth/user';

async function setUserMetadata(pubnub: Pubnub, user: User) {
  await pubnub.objects.setUUIDMetadata({
    data: {
      name: `${user.first_name} ${user.last_name}`,
      custom: {
        company: user.company_name || '',
        avatar: '',
      },
    },
  });
}

async function getUserMetadata(pubnub: Pubnub, user: User) {
  return pubnub.objects
    .getUUIDMetadata()
    .then(async (result) => {
      // check if metadata is up to date
      if (
        result.data.name !== `${user.first_name} ${user.last_name}` ||
        result.data.custom?.company !== user.company_name
      ) {
        await setUserMetadata(pubnub, user);
      }
    })
    .catch(async (e: { status: Pubnub.PubnubStatus }) => {
      if (e.status.statusCode === 404) {
        await setUserMetadata(pubnub, user);
      } else {
        throw e;
      }
    });
}

export function useRealtimeInit() {
  const { user } = globalStore;
  const realtime = useGetRealtimeData();
  const { pubnub } = useRealtime();

  useRealtimeUser();

  useEffect(() => {
    if (!user || !realtime.data) {
      return;
    }

    pubnub.setUUID(user.value!.id);
    pubnub.setToken(realtime.data.token);

    getUserMetadata(pubnub, user.value!)
      .then(() => {
        realtimeStore.ready.value = true;
        console.info('Realtime ready');
      })
      .catch((error: { status: Pubnub.PubnubStatus }) => {
        realtimeStore.error.value = error.status;
        console.error(error.status);
      });
  }, [user, realtime.data]);
}
