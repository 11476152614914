import DownloadButton from '~/components/entities/tracklist/brief/interactions/download-button';
import NewBriefRound from '~/components/entities/tracklist/brief/interactions/edit-brief-button';
import { Brief } from '~/types/schemas/music/brief.schema';
import DeleteBrief from '~/components/entities/tracklist/brief/interactions/delete-brief';
import classNames from 'classnames';
import MoreActionButton from './more-action-button';
import { UploadTrack } from './upload-track';

type InteractionsProps = {
  id: string;
  data: Brief;
  isEmpty?: boolean;
  ownedByTenant?: boolean;
  className?: string;
};

const BriefInteractions = (props: InteractionsProps) => {
  const { id, ownedByTenant, isEmpty, data, className } = props;
  const isDeletable = ownedByTenant && data.public;

  if (ownedByTenant) {
    return (
      <div className={classNames('tracklist__actions flex h-8 flex-wrap gap-4 text-xs', className)}>
        {!isEmpty ? <DownloadButton context="brief" id={id!} /> : null}
        <NewBriefRound data={data} />
        <UploadTrack id={id} />
        {isDeletable && (
          <DeleteBrief id={id!} className="tracklist__actions--delete h-full w-fit" />
        )}
        {!isEmpty ? <MoreActionButton id={id!} isEmpty={isEmpty} /> : null}
      </div>
    );
  }

  return (
    <div className={classNames('tracklist__actions flex h-8 flex-wrap gap-4 text-xs', className)}>
      {!isEmpty ? <DownloadButton context="brief" id={id!} /> : null}
      <UploadTrack id={id} />
      {isDeletable && (
        <DeleteBrief
          id={id!}
          className="tracklist__actions--delete h-full w-fit bg-red-500 hover:bg-red-400"
        />
      )}
      {!isEmpty ? <MoreActionButton id={id!} isEmpty={isEmpty} /> : null}
    </div>
  );
};

export default BriefInteractions;
