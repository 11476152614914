import { nanoid } from 'nanoid';
import { BlockValue, FilterCondition, FilterTerm, useSearchStore } from '~/stores/search-store';
import { FilterList } from '~/types/schemas/search/filter.schema';

export type ParsedFilter = {
  id: string;
  blockId: string;
  value: BlockValue;
  condition: FilterCondition;
};

export type FilterBlock = {
  id: string;
  term: FilterTerm;
  filters: Record<string, ParsedFilter>;
};

type ParsedFilterBlockType = Partial<Record<keyof FilterList, FilterBlock>>;

export function useParsedFilterBlock() {
  const search = useSearchStore(['blocks']);
  const parsedFilterBlock: ParsedFilterBlockType = {};

  search.blocks?.forEach((block) => {
    const { term, condition, values } = block;

    if (!parsedFilterBlock[term.value]) {
      parsedFilterBlock[term.value] = {
        id: nanoid(),
        term,
        filters: {},
      };
    }

    values?.forEach((value) => {
      parsedFilterBlock[term.value]!.filters[value.id] = {
        id: value.id,
        blockId: block.id,
        value,
        condition,
      };
    });
  });

  const filterPriority: Partial<Record<keyof FilterList, number>> = {
    music_type: 1,
  };

  return Object.keys(parsedFilterBlock)
    .sort((a, b) => {
      const priorityResult = -(
        (filterPriority[a as keyof FilterList] || 0) - (filterPriority[b as keyof FilterList] || 0)
      );
      if (priorityResult !== 0) return priorityResult;
      return a.localeCompare(b);
    })
    .reduce<ParsedFilterBlockType>((obj, key) => {
      obj[key as keyof FilterList] = parsedFilterBlock[key as keyof FilterList];
      return obj;
    }, {});
}
