import { UploadTrack } from '~/types/features/track-upload/track-upload.types';
import { HeaderContainer } from '../../../../common/header-container';
import Button from '~/components/ui/control/button/button';
import { Artwork } from '~/components/ui/cover/artwork';
import { TrackStepsHeaderArtists } from '../../../headers/track-step-header-summary/track-step-header-artists';
import { TrackStepHeaderTitle } from '../../../headers/track-step-header-summary/track-step-header-title';
import { memo, MouseEvent, useMemo } from 'react';
import Chip from '~/components/ui/control/chip/chip';
import { useTracksUploadItemContext } from '../../../../tracks-upload-item.context';
import { Icon } from '@iconify/react';
import { useTranslation } from 'react-i18next';

type MatchDisplayHeaderProps = {
  file: UploadTrack;
  data: UploadTrack['matchedMetadata'] | UploadTrack['metadata'] | null;
  onEditClick?: () => void;
  onHeaderClick?: () => void;
};

export const MatchDisplayHeader = memo((props: MatchDisplayHeaderProps) => {
  const { file, data, onEditClick, onHeaderClick } = props;
  const { cover, title, artists } = data || {};
  const { t } = useTranslation('tracks-upload-form');

  const { isSelected } = useTracksUploadItemContext();

  const isValidated = useMemo(() => file?.state === 'validated', [file.state]);
  const isFailed = useMemo(() => file?.state === 'failed', [file.state]);

  function handleEditClick(e: MouseEvent) {
    e.stopPropagation();
    onEditClick?.();
  }

  return (
    <HeaderContainer onClick={onHeaderClick}>
      <div className="size-full">
        {cover && (
          <div className="from-neutral-muted absolute inset-0 h-full w-full bg-linear-to-r" />
        )}
        <img src={cover} alt="" className="h-full w-full object-cover object-center blur-3xl" />
      </div>
      <div className="absolute inset-0 flex flex-1 items-center gap-2 px-3">
        <div className="bg-neutral-emphasis group relative size-14 rounded-lg">
          <Artwork artworks={cover ? [cover] : undefined} className="text-xs" />
          {isValidated ? (
            <div className="absolute inset-0 flex size-full items-center justify-center bg-black/10 transition-opacity group-hover:opacity-0">
              <div className="bg-success-emphasis flex aspect-square size-1/2 items-center justify-center rounded-full">
                <Icon icon="ri:check-line" className="text-xl text-white" />
              </div>
            </div>
          ) : null}
          {isFailed ? (
            <div className="bg-neutral-emphasis absolute inset-0 flex size-full items-center justify-center transition-opacity group-hover:opacity-0">
              <div className="bg-danger-emphasis flex aspect-square size-1/2 items-center justify-center rounded-full">
                <Icon icon="ri:close-line" className="text-xl text-white" />
              </div>
            </div>
          ) : null}
        </div>
        <div className="flex flex-1 flex-col justify-center overflow-hidden">
          <TrackStepHeaderTitle title={title || ''} />
          <TrackStepsHeaderArtists artists={artists} />
        </div>
        <div className="flex items-center gap-2">
          {isSelected ? (
            <Button color="default" iconLeft="edit-2-line" onClick={handleEditClick}>
              {t('card.edit')}
            </Button>
          ) : null}
          {isFailed && !isSelected ? (
            <Chip onClick={undefined} className="bg-error-500">
              {t('card.failed')}
            </Chip>
          ) : null}
        </div>
      </div>
    </HeaderContainer>
  );
});
