import classNames from 'classnames';
import { PropsWithChildren } from 'react';

type TrackStepsContainerProps = PropsWithChildren<{
  className?: string;
}>;

export function TrackStepsContainer(props: TrackStepsContainerProps) {
  const { children, className } = props;

  return (
    <div
      className={classNames(
        'bg-overlay-default border-neutral-emphasis flex w-full flex-col items-stretch overflow-hidden rounded-lg',
        className,
      )}
    >
      {children}
    </div>
  );
}
