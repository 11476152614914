import { useTranslation } from 'react-i18next';
import { useTrackContext } from '~/components/entities/track/common/track.context';
import { DialogDescription, DialogTitle } from '../common';
import { DialogExit } from '../common/exit';

export function LyricsHeader() {
  const store = useTrackContext();
  const track = store.use.track();
  const { t } = useTranslation('track-interactions');

  return (
    <div className="mb-3 flex w-full items-center justify-between">
      <div>
        <DialogTitle>{t('lyrics.tooltip')}</DialogTitle>
        <DialogDescription className="mr-4 line-clamp-1">
          {`${track.title} - ${track.display_artists.map((artist) => artist.name).join(', ')}`}
        </DialogDescription>
      </div>
      <div className="flex items-center gap-3">
        <DialogExit>{t('button.exit.label', { ns: 'common' })}</DialogExit>
      </div>
    </div>
  );
}
