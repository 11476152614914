import { PinInput } from '@ark-ui/react';
import { InputPinInput } from './input';

type InputPinProps = {
  label: string;
  disabled?: boolean;
  autoFocus?: boolean;
  onValueComplete: (e: string) => void;
};

export function InputPin(props: InputPinProps) {
  const { label, disabled, autoFocus, onValueComplete } = props;

  return (
    <PinInput.Root
      autoFocus={autoFocus}
      disabled={disabled}
      onValueComplete={(e) => onValueComplete(e.valueAsString)}
    >
      <PinInput.Label className="text-muted text-xxs!">{label}</PinInput.Label>
      <PinInput.Control className="flex gap-2">
        {[0, 1, 2, 4, 5, 6].map((id, index) => (
          <InputPinInput key={id} index={index} />
        ))}
      </PinInput.Control>
      <PinInput.HiddenInput />
    </PinInput.Root>
  );
}
