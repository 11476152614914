import * as Slider from '@radix-ui/react-slider';
import classNames from 'classnames';

type RangeSliderProps = {
  value: [number, number];
  min: number;
  max: number;
  ariaLabel?: string;
  trackClassName?: string;
  rangeClassName?: string;
  className?: string;
  onValueChange?: (value: [number, number]) => void;
  onSlideEnd?: (value: [number, number]) => void;
};

function RangeSlider(props: RangeSliderProps) {
  const {
    value,
    min,
    max,
    ariaLabel = 'slider',
    trackClassName,
    rangeClassName,
    className,
    onValueChange,
    onSlideEnd,
  } = props;
  const handleValueChange = (v: number[]) => {
    onValueChange?.([v[0]!, v[1]!]);
  };

  const handleCommit = (v: number[]) => {
    onSlideEnd?.([v[0]!, v[1]!]);
  };
  return (
    <Slider.Root
      className={classNames(
        'relative flex h-5 w-40 touch-none items-center select-none',
        className,
      )}
      value={value}
      onValueChange={handleValueChange}
      onValueCommit={handleCommit}
      min={min}
      max={max}
      step={1}
      aria-label={ariaLabel}
    >
      <Slider.Track
        className={classNames('bg-default relative h-1 grow rounded-full', trackClassName)}
      >
        <Slider.Range
          className={classNames('bg-inverse absolute h-full rounded-full', rangeClassName)}
        />
      </Slider.Track>
      <Thumb />
      <Thumb />
    </Slider.Root>
  );
}

function Thumb(props: { className?: string }) {
  const { className } = props;
  return (
    <Slider.Thumb
      className={classNames(
        'bg-inverse hover:bg-accent-emphasis outline-focus block size-3 rounded-sm outline-hidden hover:cursor-pointer focus:outline-1',
        className,
      )}
    />
  );
}

export default RangeSlider;
