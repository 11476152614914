import * as AlertDialogPrimitive from '@radix-ui/react-alert-dialog';
import classNames from 'classnames';
import { MouseEvent, PropsWithChildren } from 'react';

type AlertDialogContainerProps = PropsWithChildren<{
  className?: string;
}>;

export function AlertDialogContainer(props: AlertDialogContainerProps) {
  const { children, className } = props;

  function handleClick(e: MouseEvent) {
    e.stopPropagation();
  }

  return (
    <AlertDialogPrimitive.Portal>
      <AlertDialogPrimitive.Overlay className="bg-overlay-backdrop fixed inset-0 z-40 backdrop-blur-sm" />
      <AlertDialogPrimitive.Content
        onClick={handleClick}
        className={classNames(
          'fixed top-[50%] left-[50%] z-40 translate-x-[-50%] translate-y-[-50%]',
          'max-h-[85vh] w-[90vw] max-w-2xl',
          'bg-overlay-default flex flex-col items-stretch gap-4 overflow-hidden rounded-lg p-4 shadow-2xl',
          className,
        )}
      >
        {children}
      </AlertDialogPrimitive.Content>
    </AlertDialogPrimitive.Portal>
  );
}
