import { createWithEqualityFn } from 'zustand/traditional';
import { Brief } from '~/types/schemas/music/brief.schema';
import { createStorePicker } from '../utilities';
import { withImmer } from '../middlewares/immer-combine';

const useBriefFormStore = createStorePicker(
  createWithEqualityFn(
    withImmer({
      isOpen: false,
      isExitDialogOpen: false,
      brief: undefined as Brief | undefined,
    })((set) => ({
      open: (brief?: Brief) =>
        set((state) => {
          state.isOpen = true;
          state.brief = brief;
        }),
      close: () =>
        set((state) => {
          state.isOpen = false;
          state.brief = undefined;
          state.isExitDialogOpen = false;
        }),
      openExitDialog: () =>
        set((state) => {
          state.isExitDialogOpen = true;
        }),
      closeExitDialog: () =>
        set((state) => {
          state.isExitDialogOpen = false;
        }),
      changeExitDialogState: (b: boolean) =>
        set((state) => {
          state.isExitDialogOpen = b;
        }),
      clear: () =>
        set((state) => {
          state.brief = undefined;
        }),
      updateBrief: (brief?: Brief) =>
        set((state) => {
          state.brief = brief;
        }),
    })),
  ),
);

export default useBriefFormStore;
