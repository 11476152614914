import { useTranslation } from 'react-i18next';
import { useGetBriefDetails } from '~/hooks/api/brief';
import { useUploadSessionContext } from '~/providers/upload/session/upload-session.context';
import { useTracksUploadContext } from '../tracks-upload.context';

export function TracksUploadHeaderSubTitle() {
  const { contextId } = useUploadSessionContext();

  const { store } = useTracksUploadContext();
  const files = store.use.files();

  const { t } = useTranslation('tracks-upload-form');
  const { data } = useGetBriefDetails(contextId);

  return (
    <span className="text-muted">
      {t('header.description', {
        'tracks-count': files.length,
        'context-title': data?.title,
        company: data?.tenant.company_name,
      })}
    </span>
  );
}
