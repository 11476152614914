import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { createWithEqualityFn } from 'zustand/traditional';
import { UploadTrack } from '~/types/features/track-upload/track-upload.types';
import { createSelectors } from './utilities';
import { TrackMetadata } from '~/types/features/track-upload/track-metadata.types';
import { ResultMatchMetadata } from '~/types/schemas/track-upload/track-match.schema';
import errorHandler from '~/utils/error-handler';
import { STEPS_ORDER } from '~/components/entities/upload/tracks/track-uploads.constants';

type TracksUploadStoreState = {
  files: UploadTrack[];
  selected: UploadTrack['id'] | null;
  editing: UploadTrack['id'] | null;
};

type TracksUploadStoreActions = {
  setStep: (fileId: UploadTrack['id'], step: UploadTrack['step']) => void;
  setState: (fileId: UploadTrack['id'], newState: UploadTrack['state']) => void;
  setSelected: (fileId: UploadTrack['id'], step?: UploadTrack['step']) => void;
  setNextSelected: () => void;
  setEditing: (fileId: UploadTrack['id'] | null) => void;
  sortFiles: () => void;
  initSelected: (fileId: UploadTrack['id'], step: UploadTrack['step']) => void;
  setTrackMetadata: (fileId: UploadTrack['id'], data: TrackMetadata) => void;
  setMatchedMetadata: (fileId: UploadTrack['id'], metadata: ResultMatchMetadata) => void;
  setSpotifySelect: (fileId: UploadTrack['id'], spotifyId: string) => void;
  removeFromList: (fileId: string) => void;
};

type TracksUploadStoreType = TracksUploadStoreState & TracksUploadStoreActions;

const createTrackUploadStore = ({ files, editing, selected }: TracksUploadStoreState) => {
  return createSelectors(
    createWithEqualityFn<TracksUploadStoreType>()(
      devtools(
        immer((set, get) => ({
          files,
          editing,
          selected,
          sortFiles: () => {
            set((state) => {
              state.files.sort((a, b) => STEPS_ORDER[a.step] - STEPS_ORDER[b.step]);
            });
          },
          setSpotifySelect: (fileId, spotifyId) => {
            set((state) => {
              state.files.map((file) => {
                if (file.id === fileId) {
                  file.matchedMetadata!.spotifyId = spotifyId;
                }
                return file;
              });
            });
          },
          setStep: (fileId, step) => {
            set((state) => {
              state.files.map((file) => {
                if (file.id === fileId) {
                  file.step = step;
                }
                return file;
              });
            });
          },
          setState: (fileId, newState) => {
            set((state) => {
              state.files.map((file) => {
                if (file.id === fileId) {
                  file.state = newState;
                }
                return file;
              });
            });
          },
          setMatchedMetadata: (fileId, metadata) => {
            set((state) => {
              state.files.map((file) => {
                if (file.id === fileId) {
                  if (metadata == null) {
                    return errorHandler('Unable to set Matched Metadata', {
                      extra: { fileId },
                      level: 'warning',
                    });
                  }
                  const { release_date, ...rest } = metadata;
                  file.matchedMetadata = {
                    ...file.matchedMetadata,
                    ...rest,
                    releaseDate: release_date,
                  };
                }
                return file;
              });
            });
          },
          setTrackMetadata: (fileId, metadata) => {
            if (fileId == null || metadata == null) {
              return errorHandler('fileId or metadata is null', {
                extra: { fileId, metadata },
                level: 'warning',
              });
            }
            set((state) => {
              state.files.map((file) => {
                if (file.id === fileId) {
                  file.matchedMetadata = metadata;
                }
                return file;
              });
            });
          },
          initSelected: (fileId, step) => {
            if (get().selected === null) {
              set((state) => {
                if (step === 'noMatch') {
                  state.editing = fileId;
                }
                state.selected = fileId;
              });
            }
          },
          setSelected: (fileId) => {
            if (get().selected === fileId) {
              return;
            }
            const file = get().files.find((f) => f.id === fileId);

            set((state) => {
              state.editing =
                file?.step === 'noMatch' && file.matchedMetadata?.type == null ? fileId : null;
              state.selected = fileId;
            });
          },
          setNextSelected: () => {
            set((state) => {
              state.editing = null;

              const currentIndex = state.files.findIndex((f) => f.id === state.selected);
              const nextIndex = currentIndex + 1;
              const nextFile = state.files[nextIndex];

              // Set next file to select/editing
              if (nextFile) {
                if (nextFile?.state == 'validated' || nextFile?.step == 'waiting') {
                  state.selected = null;
                  return;
                }
                if (nextFile?.step === 'noMatch') {
                  state.editing = nextFile.id;
                  state.selected = nextFile.id;
                }
              }

              // Scroll to next file
              if (nextFile?.id) {
                scrollToNext(nextFile.id);
              } else {
                const firstIdNotValideId = state.files.find((f) => f.state != 'validated')?.id;
                if (firstIdNotValideId) {
                  state.selected = firstIdNotValideId;
                  return scrollToNext(firstIdNotValideId);
                }
              }

              if (nextIndex < state.files.length && nextFile) {
                state.selected = nextFile.id;
              } else {
                state.selected = null;
              }
            });
          },
          setEditing: (fileId) => {
            set((state) => {
              state.editing = fileId;
              if (fileId !== null) {
                state.selected = fileId;
              }
            });
          },
          removeFromList: (fileId) => {
            set((state) => {
              state.files = state.files.filter((f) => f.id !== fileId);
            });
          },
        })),
      ),
    ),
  );
};

export default createTrackUploadStore;

function scrollToNext(id: string) {
  const nextEl = document.getElementById(id as string);
  if (nextEl) {
    const parent = document.querySelector('#tracks-upload-scroll-parent');
    const resizeObserver = new ResizeObserver(() => {
      resizeObserver.disconnect();
      if (parent) {
        parent.scrollTo({ top: nextEl.offsetTop, behavior: 'smooth' });
      }
    });

    resizeObserver.observe(nextEl);
  }
}
