import useMusemindStore from '~/stores/musemind-store';
import Controls from './controls';

const VideoController = () => {
  const musemind = useMusemindStore(['videoElement']);

  return (
    <section className="musemind-pip group relative h-full w-full shrink-0">
      <div
        className="musemind-pip__video"
        ref={(ref) => ref?.appendChild(musemind.videoElement!)}
      ></div>
      <Controls className="musemind-pip__controls" />
    </section>
  );
};

export default VideoController;
