import * as DropdownMenu from '~/components/ui/control/menus/dropdown-menu/common/dropdown-menu';
import Avatar from './avatar';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { useLogout } from '~/hooks/api/auth';

function UserMenu() {
  const { t } = useTranslation('sidebar');
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { mutate: logout } = useLogout();

  function handleLogout() {
    logout(undefined, {
      onSuccess: () => {
        queryClient.removeQueries();
        navigate('/login');
      },
    });
  }

  function navigateToSettings() {
    navigate('/settings');
  }

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger className="outline-focus rounded-full outline-hidden focus:outline-1">
        <Avatar />
      </DropdownMenu.Trigger>
      <DropdownMenu.Content align="end" sideOffset={6}>
        <DropdownMenu.Item onClick={navigateToSettings}>
          {t('profil.settings.dropdown.settings')}
        </DropdownMenu.Item>
        <DropdownMenu.Item onClick={handleLogout}>
          {t('profil.settings.dropdown.logout')}
        </DropdownMenu.Item>
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
}

export default UserMenu;
