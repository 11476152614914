import { useSimilaritySearch } from '~/hooks/entities/track/actions/use-similarity-search';
import { useTranslation } from 'react-i18next';
import { useTrackContext } from '~/components/entities/track/common/track.context';
import * as DropdownMenu from '../common/dropdown-menu';

export function TrackActionsSearchSimilarTracks() {
  const store = useTrackContext();
  const track = store.use.track();
  const { findSimilarTracks } = useSimilaritySearch(track);
  const { t } = useTranslation('track-interactions');

  return (
    <DropdownMenu.Item onClick={findSimilarTracks} icon="tabler:chart-circles">
      {t('similarity.tooltip')}
    </DropdownMenu.Item>
  );
}
