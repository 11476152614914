import { components, GroupBase, ClearIndicatorProps } from 'react-select';
import { OptionType } from '../../select';
import { Icon } from '@iconify/react';

const ClearIndicator = (props: ClearIndicatorProps<OptionType, boolean, GroupBase<OptionType>>) => {
  return (
    <components.ClearIndicator
      className="flex size-4 h-full! cursor-pointer items-center justify-center p-0!"
      {...props}
    >
      <Icon icon="ri:close-line" className="text-control-rest hover:text-control-hover" />
    </components.ClearIndicator>
  );
};

export default ClearIndicator;
