/* eslint-disable @typescript-eslint/no-explicit-any */
import { Message } from '~/utils/chat/message';
import { ChatNotificationMessage } from '~/types/types/chat-notification';
import NotificationCard from './notification-card/notification-card';
import { Channel } from '~/utils/chat/channel';
import Button from '~/components/ui/control/button/button';

export type NotificationGroupPanelProps = {
  notifications: Message<ChatNotificationMessage, Record<string, any>>[];
  channel?: Channel<ChatNotificationMessage, Record<string, any>, any>;
  onCardClick: (notification: Message<ChatNotificationMessage, Record<string, any>>) => void;
  showLoadMoreButton: boolean;
};

function NotificationGroup(props: NotificationGroupPanelProps) {
  const { notifications, channel, onCardClick, showLoadMoreButton } = props;

  return (
    <div className="custom-scrollbar flex h-fit max-h-[28rem] flex-col items-center gap-2 overflow-y-auto">
      {notifications.map((notification) => (
        <NotificationCard
          key={notification.timetoken}
          notification={notification}
          onClick={() => onCardClick(notification)}
          markRead={() => notification.markAsRead()}
        />
      ))}
      {showLoadMoreButton ? (
        <Button color="default" onClick={() => channel?.getOlderMessages()}>
          Load more
        </Button>
      ) : null}
    </div>
  );
}

export default NotificationGroup;
