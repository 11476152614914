import { MessageSchema, MessageStatus } from '~/types/schemas/notifications/message.schema';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTracksUploadContext } from '~/components/entities/upload/tracks/tracks-upload.context';
import Pubnub from 'pubnub';
import errorHandler from '~/utils/error-handler';

type useRealtimeTrackMatchProps = {
  id: string;
  onMatchDone?: () => void;
};

export function useRealtimeTrackMatch({ id, onMatchDone }: useRealtimeTrackMatchProps) {
  const [progress, setProgress] = useState(0);
  const [status, setStatus] = useState<MessageStatus>('queued');
  const init = useRef(false);

  const { realtime } = useTracksUploadContext();

  const handleMessageEvent = useCallback(
    async (messageEvent: Pubnub.MessageEvent) => {
      const { data: message, success } = MessageSchema.safeParse(messageEvent.message);
      if (success) {
        if (message.type === 'SYNC_MATCHING') {
          if (message.data.uploadId === id) {
            if (message.status !== status) {
              setStatus(message.status);

              if (message.status === 'done') {
                onMatchDone?.();
              }
            }
            setProgress(message.data.progress);
          }
        }
      }
    },
    [id, status, onMatchDone],
  );

  useEffect(() => {
    if (status === 'processing') {
      const timer = setTimeout(() => {
        errorHandler('Matching is taking too long', {
          extra: {
            presignedId: id,
            progress,
          },
          level: 'warning',
        });
      }, 30000);
      return () => clearTimeout(timer);
    }
  }, [status, progress, id]);

  // Track match notifications
  useEffect(() => {
    if (init.current) return;
    realtime.pubnub.addListener({
      message: handleMessageEvent,
    });
    init.current = true;
    return () => {
      realtime.pubnub.removeListener({
        message: handleMessageEvent,
      });
    };
  }, [realtime, handleMessageEvent]);

  return { progress, status };
}
