import { components, GroupBase, InputProps } from 'react-select';
import { OptionType } from '../select';

const Input = (props: InputProps<OptionType, boolean, GroupBase<OptionType>>) => {
  const { children } = props;

  return (
    <components.Input
      className={'text-control-rest! placeholder:text-control-placeholder! m-0! flex-1 text-sm'}
      {...props}
    >
      {children}
    </components.Input>
  );
};

export default Input;
