import { MouseEvent, ReactNode, useState } from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import classNames from 'classnames';
import FilterSection from './filter-section';
import { Icon } from '@iconify/react';

type FilterSliderProps = {
  label: string;
  className?: string;
  triggerContent?: ReactNode;
  isActive?: boolean;
  children?: ReactNode;
  onDelete?: (e: MouseEvent) => void;
};

const FilterDropDown = (props: FilterSliderProps) => {
  const { label, className, triggerContent, isActive = false, children, onDelete } = props;
  const [isOpen, setIsOpen] = useState(false);

  return (
    <FilterSection label={label} className={className} isActive={isActive} onDelete={onDelete}>
      <DropdownMenu.Root open={isOpen} onOpenChange={setIsOpen}>
        <DropdownMenu.Trigger className="text-control-rest bg-control-rest border-control-rest flex h-8 w-full items-center justify-between gap-2 rounded-lg border px-2 outline-hidden">
          {triggerContent}

          <Icon
            icon="ri:arrow-down-s-line"
            className={classNames(
              'text-control-rest hover:text-control-hover',
              isOpen ? 'rotate-180' : 'rotate-0',
              'transition-all',
            )}
          />
        </DropdownMenu.Trigger>
        <DropdownMenu.Portal>
          <DropdownMenu.Content
            align="start"
            alignOffset={-8}
            sideOffset={12}
            className="bg-control-rest border-control-rest z-50 rounded-lg! border px-2 py-1 text-xs shadow-md"
          >
            {children}
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
    </FilterSection>
  );
};

export default FilterDropDown;
