import classNames from 'classnames';
import GeneralIcons from '~/components/ui/icon/general-icons';
import Icon from '~/components/ui/icon/icon';

type StepIndexProps = {
  index?: number;
  state: 'active' | 'done' | 'todo' | 'error';
  disabled?: boolean;
  onStepClick?: (index?: number) => void;
};

export function StepIndex(props: StepIndexProps) {
  const { index, state, disabled, onStepClick } = props;
  return (
    <div className="group/step relative aspect-square w-6">
      <div
        className={classNames(
          'step-index box-border flex h-full w-full items-center justify-center rounded-full text-xs',
          {
            'border-accent-emphasis text-default border': state === 'active',
            'bg-accent-emphasis': state === 'done',
            'border-neutral-emphasis text-neutral border': state === 'todo',
            'bg-danger-emphasis': state === 'error',
          },
        )}
      >
        {state === 'active' || state === 'todo' ? index : null}
        {state === 'done' ? <Icon name="check-line" /> : null}
        {state === 'error' ? <GeneralIcons icon="warning" /> : null}
      </div>
      <div
        className={classNames(
          'group-hover/step:bg-accent-emphasis absolute inset-0 box-border hidden h-full w-full items-center justify-center rounded-full',
          {
            'cursor-pointer group-hover/step:flex': !disabled,
          },
        )}
        onClick={() => onStepClick?.(index ? index - 1 : 0)}
      >
        <Icon name="pencil-line" className="text-xs" />
      </div>
    </div>
  );
}
