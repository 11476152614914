import { useTranslation } from 'react-i18next';
import Button from '~/components/ui/control/button/button';
import { TracksUploadDialog } from '~/components/ui/overlays/dialogs/tracks-upload/tracks-upload';

type UploadTrackProps = {
  id: string;
};

export function UploadTrack(props: UploadTrackProps) {
  const { id } = props;
  const { t } = useTranslation('brief-page');

  return (
    <TracksUploadDialog context="brief" contextId={id}>
      <Button
        color="default"
        className="playlist__edit-button h-full w-fit"
        iconRight="upload-line"
      >
        {t('header.interactions.button.upload-tracks')}
      </Button>
    </TracksUploadDialog>
  );
}
