import { useEffect, useState } from 'react';
import { Agent } from '~/types/schemas/common/agent.schema';
import { ProviderGroup } from '~/types/schemas/music/brief.schema';
import { useAddFavoritesAgent, useRemoveFavoritesAgent } from '~/hooks/api/dashboard';
import { useGetUser } from '~/hooks/api/auth';
import { OptionType } from '~/components/ui/forms/select/select';
import { useAgentTypes } from './use-agents-types';

function sortingByName(a: Agent, b: Agent) {
  const nameA = a.company_name.toUpperCase().replace(/\s/g, ''); // ignore upper and lowercase
  const nameB = b.company_name.toUpperCase().replace(/\s/g, ''); // ignore upper and lowercase
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  // names must be equal
  return 0;
}

export function useProviderList(defaultProviders: Agent[]) {
  const { defaultAgentTypes, agentsTypesOptions } = useAgentTypes();

  const [displayedProviders, setDisplayedProviders] = useState<Agent[]>(
    defaultProviders.sort(sortingByName),
  );
  const [query, setQuery] = useState<string>('');
  const [agentTypes, setAgentTypes] = useState<OptionType[]>(defaultAgentTypes);
  const [isFavoriteSelected, setIsFavoriteSelected] = useState<boolean>(false);
  const { data: user } = useGetUser();
  const favoriteAgents = user?.favorite_agents;
  const { mutate: addFavoriteAgent } = useAddFavoritesAgent();
  const { mutate: removeFavoriteAgent } = useRemoveFavoritesAgent();

  function handleCheckClick(
    provider: string,
    onChange: (value: ProviderGroup[]) => void,
    groups: ProviderGroup[],
    groupIndex: number,
  ) {
    const newGroups = [...groups];
    let newSelectedProviders: string[] = [];
    const selectedProviders = groups[groupIndex]?.providers;

    if (selectedProviders?.includes(provider)) {
      newSelectedProviders = selectedProviders.filter(
        (selectedProvider) => selectedProvider !== provider,
      );
    } else {
      newSelectedProviders = [...(selectedProviders || []), provider];
    }

    newGroups[groupIndex]!.providers = newSelectedProviders;

    onChange(newGroups);
  }

  function handleMainCheckClick(
    onChange: (value: ProviderGroup[]) => void,
    groups: ProviderGroup[],
    groupIndex: number,
  ) {
    const newGroups = [...groups];
    let newSelectedProviders: string[] = [];
    const displayProviderIds = displayedProviders.map((provider) => provider.id);
    const groupProviders = newGroups[groupIndex]?.providers || [];

    if (displayedProviders.every((provider) => groupProviders?.includes(provider.id))) {
      newSelectedProviders = groupProviders.filter(
        (provider) => !displayProviderIds.includes(provider),
      );
    } else {
      newSelectedProviders = [...(groupProviders || []), ...displayProviderIds];
    }

    newGroups[groupIndex]!.providers = newSelectedProviders;

    onChange(newGroups);
  }

  function toggleFavorites() {
    setIsFavoriteSelected(!isFavoriteSelected);
  }

  function handleFavoriteClick(agentId: string) {
    if (favoriteAgents?.includes(agentId)) {
      removeFavoriteAgent(agentId);
    } else {
      addFavoriteAgent(agentId);
    }
  }

  useEffect(() => {
    let filteredProviders = defaultProviders.sort(sortingByName);

    if (query) {
      filteredProviders = filteredProviders.filter((provider) => {
        const providerName = provider.company_name.toLowerCase();
        const queryLowerCase = query.toLowerCase();

        return providerName.includes(queryLowerCase);
      });
    }

    if (agentTypes.length) {
      filteredProviders = filteredProviders.filter((provider) => {
        const providerType = provider.organization_settings.meta.provider_types;
        const selectedCategories = agentTypes.map((category) => category.value);

        return providerType?.some((type) => selectedCategories.includes(type));
      });
    }

    if (isFavoriteSelected) {
      filteredProviders = filteredProviders.filter((provider) => {
        return favoriteAgents?.includes(provider.id);
      });
    }

    setDisplayedProviders(filteredProviders);
  }, [query, agentTypes, isFavoriteSelected]);

  return {
    providers: displayedProviders,
    agentTypes,
    agentsTypesOptions,
    favoriteAgents,
    isFavoriteSelected,
    setCategories: setAgentTypes,
    toggleFavorites,
    handleCheckClick,
    handleMainCheckClick,
    handleFavoriteClick,
    setQuery,
  };
}
