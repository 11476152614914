import classNames from 'classnames';
import Cover from '~/components/ui/cover/cover';
import Icon from '~/components/ui/icon/icon';
import { Track } from '~/types/schemas/music/track.schema';
import { parseDateNumeric } from '~/utils/common/parse-date';
import { useTranslation } from 'react-i18next';
import { ClickableNameList } from '~/components/ui/display/clickable-name-list';
import { memo } from 'react';

type ThreadHeaderProps = {
  track?: Track;
  onBack: () => void;
  className?: string;
};

const ChatHeader = memo((props: ThreadHeaderProps) => {
  const { track, onBack, className } = props;
  const addedBy = track?.metadata?.added_by;
  const { t } = useTranslation('brief-chat');

  function handleBack() {
    onBack();
  }

  return (
    <div className={classNames('flex flex-col gap-3', className)}>
      <button
        type="button"
        className="group/back outline-focus flex w-fit items-center gap-2 rounded-lg pr-2 text-xs outline-hidden focus:outline-1"
        onClick={handleBack}
      >
        <Icon name="arrow-left-s-line" className="text-default" />
        <span className="transition-transform group-hover/back:-translate-x-1">
          {t('thread.header.go-back.label')}
        </span>
      </button>
      <div className="flex w-full items-center gap-3 overflow-hidden">
        <Cover
          artworks={track?.artwork && [track.artwork]}
          alt={track?.title ?? ''}
          className="h-10! w-10! shrink-0 rounded-sm"
          iconClassName="text-base bg-neutral-emphasis"
        />
        <div>
          <h6 className="text-default text-sm">{track?.title}</h6>
          <ClickableNameList names={track?.display_artists} />
        </div>
      </div>
      <p className="text-muted text-xs">
        {t('thread.header.submitted-by', {
          user: `${addedBy?.user.first_name} ${addedBy?.user.last_name}`,
          company: addedBy?.user.company_name,
          date:
            addedBy?.date &&
            parseDateNumeric(addedBy?.date, {
              month: 'short',
              day: 'numeric',
              year: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
            }),
        })}
      </p>
    </div>
  );
});

export default ChatHeader;
