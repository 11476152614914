import { ReactNode, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import FullPageLoader from '~/components/layout/full-page-loader';
import { globalStore } from '~/stores/global-store-v2';
import { useFetchData } from './use-fetch-data';

type DataProviderProps = {
  children: ReactNode;
};

const DataProvider = (props: DataProviderProps) => {
  const { children } = props;
  const [searchParams, setParams] = useSearchParams();
  const { isLoading, isDataReady } = useFetchData();
  const { setAdminToken } = globalStore;

  const token = searchParams.get('adminToken');

  useEffect(() => {
    if (token) {
      setAdminToken(token);
      setParams({}, { replace: true });
    }
  }, [setAdminToken, setParams, token]);

  if (isLoading) {
    return <FullPageLoader />;
  }

  if (!isDataReady) {
    return <div className="text-center">Something went wrong please reload the page</div>;
  }

  return children as JSX.Element;
};

export default DataProvider;
