import classNames from 'classnames';

type TrackInformationProps = {
  label: string;
  value?: string;
  className?: string;
};

export default function TrackInfoItem(props: TrackInformationProps) {
  const { label, className, value = '-' } = props;

  return (
    <li className={classNames('flex h-12 w-full flex-col justify-center self-center', className)}>
      <h6 className="text-muted text-sm leading-[0.875rem]">{label}</h6>
      <p className="text-default line-clamp-1 text-sm leading-4 break-all">{value || '-'}</p>
    </li>
  );
}
