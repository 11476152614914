import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import IconArrowRight from 'virtual:icons/ri/arrow-right-s-line';
import { MouseEventHandler } from 'react';
import { dropdownElement } from './dropdown';
import { NavLink } from 'react-router-dom';

export interface DropdownItemProps extends dropdownElement {
  className?: string;
  menuClassName?: string;
  width?: string;
}

const DropdownItem = (props: DropdownItemProps) => {
  const {
    label,
    subtitle,
    href,
    onclick,
    menu,
    width,
    className,
    menuClassName,
    disabled,
    navlink,
  } = props;

  const itemDisabledClassName = 'text-gray-500 hover:bg-transparent';
  const itemClassName = `w-full p-1 transition outline-hidden ease-in duration-100 border-none shadow-none text-left flex justify-between rounded-sm hover:bg-neutral-muted focus:outline-hidden ${
    disabled && itemDisabledClassName
  } ${className}`;

  const handleClick: MouseEventHandler = (e) => {
    e.stopPropagation();
    onclick?.();
  };

  return menu ? (
    <DropdownMenu.Sub>
      <DropdownMenu.SubTrigger disabled={disabled} className={itemClassName}>
        <span className="flex-1">{label}</span>
        <IconArrowRight />
      </DropdownMenu.SubTrigger>
      <DropdownMenu.Portal>
        <DropdownMenu.SubContent
          sideOffset={0}
          className={`drop-shadow-sm ${menuClassName}`}
          style={{ width }}
        >
          {menu.map((group, groupIndex) => (
            <>
              <DropdownMenu.Group key={`group-${groupIndex}`}>
                {group.title && (
                  <DropdownMenu.Label className="p-1 text-gray-400">
                    {group.title}
                  </DropdownMenu.Label>
                )}
                {group.elements.map((item, itemIndex) => (
                  <DropdownItem
                    key={`group-${groupIndex}-item-${itemIndex}`}
                    width={width}
                    className={itemClassName}
                    menuClassName={menuClassName}
                    {...item}
                  />
                ))}
              </DropdownMenu.Group>
              {groupIndex < menu.length - 1 && (
                <DropdownMenu.Separator className="bg-muted my-1 h-px w-full" />
              )}
            </>
          ))}
        </DropdownMenu.SubContent>
      </DropdownMenu.Portal>
    </DropdownMenu.Sub>
  ) : (
    <DropdownMenu.Item>
      {href ? (
        <a className={itemClassName} href={href}>
          <span className="flex-1">{label}</span>
          {subtitle && <span className="text-gray-400">{subtitle}</span>}
        </a>
      ) : null}
      {navlink ? (
        <NavLink className={itemClassName} to={navlink}>
          <span className="flex-1">{label}</span>
          {subtitle && <span className="text-gray-400">{subtitle}</span>}
        </NavLink>
      ) : null}
      {!href && !navlink ? (
        <button data-no-dnd="true" className={itemClassName} type="button" onClick={handleClick}>
          <span className="flex-1">{label}</span>
          {subtitle && <span className="text-gray-400">{subtitle}</span>}
        </button>
      ) : null}
    </DropdownMenu.Item>
  );
};

export default DropdownItem;
