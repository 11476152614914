import * as Tabs from '@radix-ui/react-tabs';
import { ReactNode } from 'react';
import useElementSizeStore from '~/stores/elements-size-store';
import EmptyPlaceholder from '../../empty-placeholder/empty-placeholder';

type TabProps = {
  id?: string;
  value: string;
  children: ReactNode;
  isEmpty?: boolean;
};

function Tab(props: TabProps) {
  const { id, value, children, isEmpty } = props;
  const { columnNumber } = useElementSizeStore(['columnNumber']);

  return (
    <Tabs.Content
      id={id}
      value={value}
      className="grid gap-6"
      style={{ gridTemplateColumns: `repeat(${columnNumber},1fr)` }}
    >
      {isEmpty ? <EmptyPlaceholder /> : children}
    </Tabs.Content>
  );
}

export default Tab;
