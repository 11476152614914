import { InfiniteData, keepPreviousData, useInfiniteQuery } from '@tanstack/react-query';
import { InfinitePage } from '~/types/schemas/api/infinite-page.schema';
import { ZodSchema } from 'zod';
import { QueryKeyT, infiniteFetcher } from '../../../utils/react-query/fetcher';

export type useLoadMoreOptions = {
  url: string;
  queryParams?: object;
  schema: ZodSchema;
};

export const useLoadMore = <T>(params: useLoadMoreOptions) => {
  const { url, schema, queryParams } = params;
  const context = useInfiniteQuery<
    InfinitePage<ZodSchema<T>>,
    Error,
    InfiniteData<InfinitePage<ZodSchema<T>>>,
    QueryKeyT
  >({
    queryKey: [url!, queryParams],
    queryFn: ({ pageParam = 1, ...rest }) =>
      infiniteFetcher<InfinitePage<ZodSchema<T>>>({
        ...rest,
        queryKey: [url!, queryParams],
        pageParam: pageParam as number,
        schema,
      }),
    getNextPageParam: (lastPage, pages) => {
      return lastPage.hits.length ? pages.length + 1 : undefined;
    },
    placeholderData: keepPreviousData,
    initialPageParam: 1,
    staleTime: 0,
  });

  return context;
};
