import { z } from 'zod';
import { briefRoutes } from '~/routes/api';
import { Participant, ParticipantSchema } from '~/types/schemas/chats/participant.schema';
import { TrackSchema } from '~/types/schemas/music/track.schema';
import { pathToUrl } from '~/utils/path-to-url';
import { useAlternativeFetch } from '~/hooks/utils/react-query/use-alternative-fetch';
import { useFetch } from '~/hooks/utils/react-query/use-fetch';

export const useGetThreadChannelParticipants = (briefId?: string, trackId?: string) => {
  return useFetch<Participant[]>({
    url: pathToUrl(briefRoutes.getThreadChannelParticipants, { id: briefId, trackId }),
    schema: ParticipantSchema.array(),
  });
};

export const useGetThreadChannels = (briefId: string) => {
  return useFetch<Record<string, string>>({
    url: pathToUrl(briefRoutes.getThreadChannels, { id: briefId }),
    schema: z.record(z.string()),
  });
};

export const useGetThreadTracks = (briefId?: string, trackIds?: string[]) => {
  return useAlternativeFetch({
    url: pathToUrl(briefRoutes.getThreadTracksDetail, { id: briefId }),
    queryParams: {
      json: {
        ids: trackIds,
      },
    },
    schema: z.array(TrackSchema.nullable()),
  });
};
