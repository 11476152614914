const CardLoader = () => {
  return (
    <div className="border-neutral-emphasis flex w-full animate-pulse gap-4 rounded-lg border p-4">
      <div className="bg-neutral-muted aspect-square flex-1 rounded-md" />
      <div className="flex flex-3 flex-col">
        <div className="bg-neutral-muted mt-2 mb-2 h-3 w-[30%] rounded-sm" />
        <div className="my-3">
          <div className="bg-neutral-muted mb-2 h-4 w-[50%] rounded-sm" />
          <div className="bg-neutral-muted h-3 w-[70%] rounded-sm" />
        </div>
      </div>
    </div>
  );
};

export default CardLoader;
