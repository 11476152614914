import { globalStore } from '~/stores/global-store-v2';
import ky from 'ky';
import { userSettingsStore } from '~/stores/user-settings-store';
import { KyOptions } from 'ky/distribution/types/options';

export type ApiError = {
  code: string;
  message: string;
};

type HeadersOptions = {
  withAuth?: boolean;
};

type CustomOptions = {
  headers: HeadersOptions;
};

const { apiUrl, userToken } = globalStore;
const { language } = userSettingsStore;

function getHeaders(
  options: HeadersOptions = {
    withAuth: true,
  },
) {
  return {
    'Content-Type': 'application/json',
    'x-preferred-locale': language.value.split('-')[0],
    ...(userToken.value && options.withAuth
      ? {
          'x-auth': userToken.value,
        }
      : {}),
  };
}

export const api = {
  get: (url: string, params?: KyOptions, options?: CustomOptions) =>
    ky.get(`${apiUrl.value}/${url}`, {
      headers: getHeaders(options?.headers),
      ...(params || {}),
    }),
  post: (url: string, params?: KyOptions, options?: CustomOptions) =>
    ky.post(`${apiUrl.value}/${url}`, {
      headers: getHeaders(options?.headers),
      ...(params || {}),
    }),
  put: (url: string, params?: KyOptions, options?: CustomOptions) =>
    ky.put(`${apiUrl.value}/${url}`, {
      headers: getHeaders(options?.headers),
      ...(params || {}),
    }),
  patch: (url: string, params?: KyOptions, options?: CustomOptions) =>
    ky.patch(`${apiUrl.value}/${url}`, {
      headers: getHeaders(options?.headers),
      ...(params || {}),
    }),
  delete: (url: string, params?: KyOptions, options?: CustomOptions) =>
    ky.delete(`${apiUrl.value}/${url}`, {
      headers: getHeaders(options?.headers),
      ...(params || {}),
    }),
};
