import Cover, { CoverType } from '~/components/ui/cover/cover';
import { Artwork } from '~/types/schemas/common/image.schema';
import IconAdd from 'virtual:icons/ri/add-fill';
import IconSubtract from 'virtual:icons/ri/subtract-fill';
import React, { MouseEvent } from 'react';
import classNames from 'classnames';

export type ItemProps = {
  title?: string;
  subtitle?: string;
  customSubtitle?: React.ReactNode;
  subtitlePrefix?: string;
  type?: CoverType;
  artworks: Artwork[];
  onIncludeClick?: () => void;
  onExcludeClick?: () => void;
};

function FilterItem(props: ItemProps) {
  const {
    title,
    subtitle,
    customSubtitle,
    subtitlePrefix,
    type,
    artworks,
    onIncludeClick,
    onExcludeClick,
  } = props;

  const handleIncludeClick = (e: MouseEvent) => {
    e.stopPropagation();
    onIncludeClick?.();
  };

  const handleExcludeClick = (e: MouseEvent) => {
    e.stopPropagation();
    onExcludeClick?.();
  };

  const buttonClassName =
    'hidden group-hover:flex rounded-sm items-center justify-center transition-colors bg-neutral-emphasis hover:bg-muted  w-4 h-4 text-default text-xs';

  return (
    <div className="group filter-item hover:bg-neutral-muted flex items-center gap-2 rounded-lg bg-transparent p-2">
      <div className="filter-item__cover h-8 w-8 shrink-0">
        <Cover artworks={artworks} alt={title || ''} type={type} iconClassName="text-sm" />
      </div>
      <div className="filter-item__information flex flex-1 flex-col overflow-hidden">
        <h6 className="filter-item__information__title text-default line-clamp-1 text-xs">
          {title}
        </h6>
        {(subtitle || customSubtitle || subtitlePrefix) && (
          <div className="text-muted flex h-4 gap-1 text-xs">
            {subtitlePrefix && (
              <>
                <span className="flex shrink-0">{subtitlePrefix}</span>
                {(subtitle || customSubtitle) && <span>•</span>}
              </>
            )}
            {subtitle || customSubtitle}
          </div>
        )}
      </div>
      <div className="filter-item__actions ml-auto flex gap-2">
        <button
          type="button"
          className={classNames('filter-item__actions--subtract', buttonClassName)}
          onClick={handleExcludeClick}
        >
          <IconSubtract />
        </button>
        <button
          type="button"
          className={classNames('filter-item__actions--add', buttonClassName)}
          onClick={handleIncludeClick}
        >
          <IconAdd />
        </button>
      </div>
    </div>
  );
}

export default React.memo(FilterItem);
