import { useTranslation } from 'react-i18next';
import Button from '~/components/ui/control/button/button';
import { DeleteTrackButton } from '../common/delete-track-button';

type FormFooterProps = {
  isPending: boolean;
};

export function FormFooter(props: FormFooterProps) {
  const { isPending } = props;
  const { t } = useTranslation('tracks-upload-form');

  return (
    <div className="flex w-full items-center justify-end gap-2 px-4">
      <DeleteTrackButton />
      <Button
        color="primary"
        type="submit"
        iconLeft="save-line"
        isLoading={isPending}
        disabled={isPending}
      >
        {t('card.footer.save')}
      </Button>
    </div>
  );
}
