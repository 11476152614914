import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Input from '~/components/ui/forms/input/input';
import Textarea from '~/components/ui/forms/textarea/textarea';
import { LicensingQuoteForm } from '~/types/schemas/form/licensing-quote.schema';

type LicensingQuoteFormContentProps = {
  control: Control<LicensingQuoteForm>;
};

export function LicensingQuoteFormContent(props: LicensingQuoteFormContentProps) {
  const { control } = props;

  const { t } = useTranslation('request-form');

  return (
    <div className="my-1 grid size-full grid-cols-4 gap-3">
      <Controller
        name="productionType"
        control={control}
        render={({ field: { value, onChange, ref }, fieldState }) => {
          return (
            <Input
              ref={ref}
              label={t('input.production-type.label')}
              placeholder={t('input.production-type.placeholder')}
              className="col-span-2"
              value={value}
              onChange={onChange}
              required
              error={
                fieldState.error?.message ? t('input.production-type.empty-warning') : undefined
              }
            />
          );
        }}
      />
      <Controller
        name="productionName"
        control={control}
        render={({ field: { value, onChange, ref }, fieldState }) => (
          <Input
            ref={ref}
            label={t('input.production-name.label')}
            placeholder={t('input.production-name.placeholder')}
            className="col-span-2"
            value={value}
            onChange={onChange}
            error={fieldState.error?.message}
          />
        )}
      />
      <Controller
        name="licensingClientName"
        control={control}
        render={({ field: { value, onChange, ref }, fieldState }) => (
          <Input
            ref={ref}
            label={t('input.licensing-name.label')}
            className="col-span-2"
            info={t('input.licensing-name.tooltip')}
            value={value}
            onChange={onChange}
            error={fieldState.error?.message}
          />
        )}
      />
      <Controller
        name="licensingCountry"
        control={control}
        render={({ field: { value, onChange, ref }, fieldState }) => (
          <Input
            ref={ref}
            label={t('input.licensing-country.label')}
            info={t('input.licensing-country.tooltip')}
            className="col-span-2"
            value={value}
            onChange={onChange}
            error={fieldState.error?.message}
          />
        )}
      />
      <Controller
        name="creativeDescription"
        control={control}
        render={({ field: { value, onChange, ref }, fieldState }) => (
          <Textarea
            ref={ref}
            value={value}
            onChange={onChange}
            label={t('input.description.label')}
            info={t('input.description.tooltip')}
            boxClassName="h-full min-h-64 col-span-full"
            className="h-full"
            error={fieldState.error?.message}
          />
        )}
      />
      <Controller
        name="videoLinks"
        control={control}
        render={({ field: { value, onChange, ref }, fieldState }) => (
          <Textarea
            ref={ref}
            value={value}
            onChange={onChange}
            label={t('input.video-links.label')}
            info={t('input.video-links.tooltip')}
            rows={2}
            boxClassName="h-fit col-span-full"
            className="h-full"
            error={fieldState.error?.message}
          />
        )}
      />
      <Controller
        name="maxLength"
        control={control}
        render={({ field: { value, onChange, ref }, fieldState }) => (
          <Input
            ref={ref}
            label={t('input.max-length.label')}
            placeholder={t('input.max-length.placeholder')}
            className="col-span-2"
            value={value}
            onChange={onChange}
            error={fieldState.error?.message}
          />
        )}
      />
      <Controller
        name="requestedRights"
        control={control}
        render={({ field: { value, onChange, ref }, fieldState }) => (
          <Input
            ref={ref}
            label={t('input.rights.label')}
            placeholder={t('input.rights.placeholder')}
            className="col-span-2"
            info={t('input.rights.tooltip')}
            value={value}
            onChange={onChange}
            required
            error={fieldState.error?.message ? t('input.rights.empty-warning') : undefined}
          />
        )}
      />
      <Controller
        name="medias"
        control={control}
        render={({ field: { value, onChange, ref }, fieldState }) => (
          <Input
            ref={ref}
            label={t('input.medias.label')}
            placeholder={t('input.medias.placeholder')}
            className="col-span-full"
            info={t('input.medias.tooltip')}
            value={value}
            onChange={onChange}
            error={fieldState.error?.message}
          />
        )}
      />
      <Controller
        name="territories"
        control={control}
        render={({ field: { value, onChange, ref }, fieldState }) => (
          <Input
            ref={ref}
            value={value}
            onChange={onChange}
            label={t('input.territories.label')}
            info={t('input.territories.tooltip')}
            placeholder={t('input.territories.placeholder')}
            className="col-span-full"
            error={fieldState.error?.message}
          />
        )}
      />
      <div className="col-span-full flex gap-3">
        <Controller
          name="duration"
          control={control}
          render={({ field: { value, onChange, ref }, fieldState }) => (
            <Input
              ref={ref}
              label={t('input.duration.label')}
              value={value || ''}
              info={t('input.duration.tooltip')}
              onChange={onChange}
              placeholder={t('input.duration.placeholder')}
              className="flex-1"
              error={fieldState.error?.message}
            />
          )}
        />
        <Controller
          name="launchDate"
          control={control}
          render={({ field: { value, onChange, ref }, fieldState }) => (
            <Input
              ref={ref}
              value={value}
              onChange={onChange}
              label={t('input.launch-date.label')}
              type="date"
              info={t('input.launch-date.tooltip')}
              placeholder="Day"
              className="flex-1"
              error={fieldState.error?.message}
            />
          )}
        />
        <Controller
          name="budget"
          control={control}
          render={({ field: { value, onChange, ref }, fieldState }) => (
            <Input
              ref={ref}
              type="number"
              label={t('input.budget.label')}
              value={value?.toString()}
              onNumberChange={onChange}
              placeholder={t('input.budget.placeholder')}
              info={t('input.budget.tooltip')}
              className="flex-1"
              separator=","
              error={fieldState.error?.message}
            />
          )}
        />
      </div>
    </div>
  );
}
