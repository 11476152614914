import usePlayerStore from '~/stores/player-store';
import classNames from 'classnames';
import { useEffect } from 'react';
import * as Track from '~/components/entities/track/common/track';
import { Controls } from './controls';
import { Time } from './time';

type PlayerControlsProps = {
  className?: string;
};

export const PlayerControls = (props: PlayerControlsProps) => {
  const { className } = props;
  const player = usePlayerStore(['setIsPlaying', 'currentTrack', 'audioFile']);

  useEffect(() => {
    const onPlay = () => player.setIsPlaying(true);
    const onPause = () => player.setIsPlaying(false);

    player.audioFile?.addEventListener('play', onPlay);
    player.audioFile?.addEventListener('pause', onPause);

    return () => {
      player.audioFile?.removeEventListener('play', onPlay);
      player.audioFile?.removeEventListener('pause', onPause);
    };
  }, [player.audioFile, player.setIsPlaying]);

  return (
    <div
      className={classNames(
        'flex h-full w-full items-center justify-center gap-4 select-none',
        className,
      )}
    >
      <Controls />
      <div className="hidden items-center gap-4 @4xl:flex">
        <div className="w-[18rem]">
          <Track.Waveform />
        </div>
        <Time track={player.currentTrack} />
      </div>
    </div>
  );
};
