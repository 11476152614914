import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from '~/components/ui/control/button/button';

export function PlaylistTrackItemListEmpty() {
  const navigate = useNavigate();
  const { t } = useTranslation('music-item-list');

  return (
    <div className="flex flex-1 flex-col items-center justify-center">
      <span className="text-xl font-bold">{t('playlist.empty.title')}</span>
      <p className="text-muted">{t('playlist.empty.content')}</p>
      <Button color="primary" className="mt-4" onClick={() => navigate('/search')}>
        {t('playlist.empty.button')}
      </Button>
    </div>
  );
}
