import { Icon } from '@iconify/react';
import classNames from 'classnames';
import { MouseEvent } from 'react';
import { NavLink } from 'react-router-dom';

type NavItemProps = {
  label: string;
  icon: string;
  to: string;
  addClick?: () => void;
};

export function NavItem(props: NavItemProps) {
  const { label, icon, to, addClick: addAction } = props;

  function handleActionClick(e: MouseEvent) {
    e.preventDefault();
    addAction?.();
  }

  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        classNames(
          'group text-neutral hover:bg-button-default-rest outline-focus flex h-8 items-center gap-2 rounded-md bg-transparent px-2 outline-hidden transition-all focus:outline-1',
          {
            'bg-button-default-rest! text-default!': isActive,
          },
        )
      }
    >
      <Icon icon={icon} className="size-4" />
      <span className="flex-1 text-sm">{label}</span>
      {addAction ? (
        <button
          className="text-default bg-neutral-muted hover:bg-muted hidden size-5 items-center justify-center rounded group-hover:flex"
          onClick={handleActionClick}
        >
          <Icon icon="ri:add-line" />
        </button>
      ) : null}
    </NavLink>
  );
}
