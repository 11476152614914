import { UseInfiniteQueryResult, InfiniteData } from '@tanstack/react-query';
import { Range } from '@tanstack/react-virtual';
import { useState, useMemo } from 'react';
import { ZodSchema } from 'zod';
import { useGetBriefTracks } from '~/hooks/api/brief';
import { InfinitePage } from '~/types/schemas/api/infinite-page.schema';
import { Track } from '~/types/schemas/music/track.schema';

export function useBriefTrackItemList(id: string) {
  const {
    data: tracks,
    fetchNextPage,
    isLoading,
    hasNextPage,
    isFetchingNextPage,
  } = useGetBriefTracks(id, 100) as UseInfiniteQueryResult<
    InfiniteData<InfinitePage<ZodSchema<Track>>>
  >;

  const flatTracks = tracks?.pages.map((page) => page.hits).flat();
  const [currentLimit, setCurrentLimit] = useState(50);
  const currentTracks = useMemo(() => flatTracks?.slice(0, currentLimit), [tracks, currentLimit]);

  function handleNextPageFetch({ endIndex }: Range) {
    if (endIndex >= (currentTracks?.length || 0) - 30 && !isFetchingNextPage) {
      if ((flatTracks?.length || 0) > currentLimit) {
        setCurrentLimit(currentLimit + 50);
      } else if (hasNextPage) fetchNextPage();
    }
  }

  return {
    tracks: currentTracks || [],
    hasNextPage,
    isLoading,
    isFetching: isFetchingNextPage,
    handleNextPageFetch,
  };
}
