import { Icon } from '@iconify/react';
import { ChangeEvent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as DropdownMenu from '~/components/ui/control/menus/dropdown-menu/common/dropdown-menu';

export enum SearchFilter {
  All = 'all',
  Playlists = 'playlists',
  Briefs = 'briefs',
}

export type SearchFilterType = `${SearchFilter}`;

type SearchInputProps = {
  value: string;
  filter: SearchFilterType;
  onFilterChange: (v: SearchFilter) => void;
  onChange: (v: string) => void;
};

function SearchInput(props: SearchInputProps) {
  const { value, onFilterChange, onChange, filter } = props;
  const { t } = useTranslation('navbar');

  const placeholder = useMemo(() => {
    switch (filter) {
      case SearchFilter.All:
        return t('tracklist.filter.search.placeholder.all');
      case SearchFilter.Playlists:
        return t('tracklist.filter.search.placeholder.playlists');
      case SearchFilter.Briefs:
        return t('tracklist.filter.search.placeholder.briefs');
    }
  }, [filter, t]);

  const label = useMemo(() => {
    switch (filter) {
      case SearchFilter.All:
        return t('tracklist.filter.option.all');
      case SearchFilter.Playlists:
        return t('tracklist.filter.option.playlists');
      case SearchFilter.Briefs:
        return t('tracklist.filter.option.briefs');
    }
  }, [filter, t]);

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    onChange?.(e.target.value);
  }

  return (
    <div className="border-control-rest bg-control-rest hover:border-control-hover hover:text-control-hover relative flex h-8 w-full items-center justify-between gap-2 rounded-lg border pr-1.5 pl-1">
      <DropdownMenu.Root>
        <DropdownMenu.Trigger asChild>
          <button className="bg-accent-emphasis outline-focus rounded-sm px-1.5 py-0.5 text-xs whitespace-nowrap outline-hidden focus:outline-1">
            {label}
          </button>
        </DropdownMenu.Trigger>
        <input
          value={value}
          onChange={handleChange}
          type="text"
          placeholder={placeholder}
          className="text-control-rest placeholder:text-control-placeholder relative h-full w-full bg-transparent text-xs outline-hidden"
        />
        <Icon icon="ri:search-line" className="min-h-3 w-0 min-w-3" width={'auto'} />
        <DropdownMenu.Content
          style={{ width: 'calc(16rem - 1rem)' }}
          align="start"
          alignOffset={-5}
          sideOffset={8}
        >
          <DropdownMenu.RadioGroup
            value={filter}
            onValueChange={(f) => onFilterChange(f as SearchFilter)}
          >
            <DropdownMenu.RadioItem icon={'ri:filter-line'} value={SearchFilter.All}>
              {t('tracklist.filter.option.all')}
            </DropdownMenu.RadioItem>
            <DropdownMenu.RadioItem icon={'ri:headphone-line'} value={SearchFilter.Playlists}>
              {t('tracklist.filter.option.playlists')}
            </DropdownMenu.RadioItem>
            <DropdownMenu.RadioItem icon={'ri:megaphone-line'} value={SearchFilter.Briefs}>
              {t('tracklist.filter.option.briefs')}
            </DropdownMenu.RadioItem>
          </DropdownMenu.RadioGroup>
        </DropdownMenu.Content>
      </DropdownMenu.Root>
    </div>
  );
}

export default SearchInput;
