import * as DialogPrimitive from '@radix-ui/react-dialog';
import classNames from 'classnames';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { useClickOutside } from '~/hooks/common/use-click-outside';

type DialogProps = {
  trigger: ReactNode;
  title?: string;
  description?: string;
  containerClassName?: string;
  triggerClassName?: string;
  portalClassName?: string;
  overlayClassName?: string;
  children: ReactNode;
  asChild?: boolean;
  isOpen?: boolean;
  disabled?: boolean;
  onOpenChange?: (isOpen: boolean) => void;
};

const Dialog = (props: DialogProps) => {
  const {
    title,
    trigger,
    containerClassName,
    triggerClassName,
    overlayClassName,
    description,
    asChild,
    isOpen,
    disabled = false,
    onOpenChange,
    children,
  } = props;
  const [dialogIsOpen, setDialogIsOpen] = useState(isOpen || false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setDialogIsOpen(isOpen || false);
  }, [isOpen]);

  const handleOpenChange = (newIsOpen: boolean) => {
    if (isOpen !== undefined && onOpenChange === undefined) return;
    setDialogIsOpen(newIsOpen);
    onOpenChange?.(newIsOpen);
  };

  useClickOutside(ref, () => handleOpenChange(false));

  return (
    <DialogPrimitive.Root open={dialogIsOpen}>
      <DialogPrimitive.Trigger
        onClick={() => handleOpenChange(true)}
        className={triggerClassName}
        asChild={asChild}
        disabled={disabled}
      >
        {trigger}
      </DialogPrimitive.Trigger>
      <DialogPrimitive.Portal data-no-dnd="true">
        <DialogPrimitive.Overlay
          className={classNames(
            'bg-overlay-backdrop fixed inset-0 z-90 backdrop-blur-sm',
            overlayClassName,
          )}
        />
        <DialogPrimitive.Content
          data-no-dnd="true"
          ref={ref}
          className={classNames(
            'bg-neutral-emphasis fixed top-[50%] left-[50%] z-100 flex max-h-[80vh] w-full max-w-[50rem] -translate-x-1/2 -translate-y-1/2 flex-col items-stretch overflow-hidden rounded-lg p-4',
            containerClassName,
          )}
        >
          {title ? (
            <DialogPrimitive.Title className={`text-xl font-bold ${description ? 'mb-1' : 'mb-5'}`}>
              {title}
            </DialogPrimitive.Title>
          ) : null}
          {description ? (
            <DialogPrimitive.Description className="text-muted mb-5 text-xs">
              {description}
            </DialogPrimitive.Description>
          ) : null}
          <div className="custom-scrollbar h-full">{children}</div>
        </DialogPrimitive.Content>
      </DialogPrimitive.Portal>
    </DialogPrimitive.Root>
  );
};

export default Dialog;
