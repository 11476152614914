import Icon from '~/components/ui/icon/icon';
import { AnimatePresence, motion } from 'framer-motion';
import { memo, useRef } from 'react';
import GeneralIcons from '~/components/ui/icon/general-icons';
import { useComputed } from '@preact/signals-react';
import { useTranslation } from 'react-i18next';
import { globalStore } from '~/stores/global-store-v2';
import { useChatTrigger } from './use-chat-trigger';
import ChatPreview from './chat-preview/chat-preview';

type ChatTriggerProps = {
  briefId: string;
  trackId: string;
};

const ChatTrigger = memo((props: ChatTriggerProps) => {
  const { user } = globalStore;
  const { briefId, trackId } = props;
  const { channel, isLoading, isHovered, from, handleClick, handleMouseEnter, handleMouseLeave } =
    useChatTrigger(briefId, trackId);
  const lastMessage = channel?.lastMessage.value;
  const isUnread = useComputed(() => !channel?.lastMessage.value?.isReadByUser(user.value!.id));
  const { t } = useTranslation('music-item');

  const ref = useRef<HTMLDivElement>(null);
  const refPosition = ref.current?.getBoundingClientRect();

  return (
    <div
      ref={ref}
      className="relative"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
    >
      <div className="bg-neutral-emphasis hover:bg-neutral-muted flex h-6 w-9 cursor-pointer items-center justify-center rounded-sm text-xs font-semibold">
        <AnimatePresence>
          {isLoading ? (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
            >
              <GeneralIcons icon="loader" className="text-default h-4 w-4" />
            </motion.div>
          ) : (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
            >
              <Icon name={lastMessage ? 'chat-4-line' : 'chat-new-line'} className="text-sm" />
              {lastMessage && isUnread.value && (
                <span className="bg-accent-emphasis absolute top-0 right-0 h-2 w-2 -translate-y-1/4 translate-x-1/4 rounded-full" />
              )}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <AnimatePresence>
        {!channel?.isLoading.value && isHovered && lastMessage?.content ? (
          <ChatPreview
            user={from.value}
            message={
              lastMessage?.deleted.value
                ? t('chat.hover.last-message.delete')
                : lastMessage?.content.text
            }
            totalMessages={channel?.totalMessageCount.value}
            timetoken={lastMessage?.timetoken}
            portalStyle={{
              top: (refPosition?.top ?? 0) + (ref.current?.clientHeight ?? 0),
              left: refPosition?.left,
            }}
          />
        ) : null}
      </AnimatePresence>
    </div>
  );
});

export default ChatTrigger;
