import { useNavigate } from 'react-router-dom';
import Logo from '~/assets/mewo/logo.svg';
import usePreSearchStore from '~/stores/presearch-store';
import classNames from 'classnames';
import NavButton from './components/nav-button';
import HeaderContent from './components/content/content';

const Header = () => {
  const navigate = useNavigate();
  const presearch = usePreSearchStore(['isOpen', 'setIsOpen']);

  function handleNavigate(direction: number) {
    navigate(direction);
    presearch.setIsOpen(false);
  }

  return (
    <header
      className={classNames(
        'bg-default flex h-20 w-full items-center px-6 py-5 transition-all duration-300',
      )}
    >
      <div className="w-64">
        <img src={Logo} alt="Logo Sync" className="translate-y-1" />
      </div>
      <div className="flex flex-1 items-center gap-6">
        <div className="flex h-fit gap-2">
          <NavButton icon="ri:arrow-left-s-line" onClick={() => handleNavigate(-1)} />
          <NavButton icon="ri:arrow-right-s-line" onClick={() => handleNavigate(1)} />
        </div>
        <HeaderContent />
      </div>
    </header>
  );
};

export default Header;
