import { components, OptionProps, GroupBase } from 'react-select';
import { OptionType } from '../select';
import classNames from 'classnames';
import { Icon } from '@iconify/react';

const OptionComponent = (props: OptionProps<OptionType, boolean, GroupBase<OptionType>>) => {
  const { children, isFocused, isSelected, data } = props;

  return (
    <components.Option
      className={classNames(
        'text-control-rest min-h-7 w-full rounded-sm py-1.5! pr-3! pl-2.5! text-sm!',
        {
          'hover:bg-control-neutral! text-control-rest bg-transparent!': isSelected,
          'bg-control-neutral! text-control-hover': isFocused,
        },
      )}
      {...props}
    >
      <div className="flex items-center gap-2">
        <div className="flex-1">{children}</div>
        {isSelected ? <Icon icon="ri:check-line" className="text-accent size-4" /> : null}
      </div>
      {data.description ? (
        <span className="text-control-neutral w-full text-xs">{data.description}</span>
      ) : null}
    </components.Option>
  );
};

export default OptionComponent;
