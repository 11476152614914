import Cover from './cover';

type TrackProps = {
  title: string;
  artists: string[];
  album: string;
  cover: string;
};

export function Track(props: TrackProps) {
  const { title, artists, album, cover } = props;

  return (
    !!title &&
    !!artists &&
    !!album && (
      <div className="hover:bg-neutral-emphasis group/list-item flex items-center justify-between gap-4 rounded-sm px-3 py-2 pr-4 text-left">
        <Cover
          height="3rem"
          title={title}
          artists={`${artists.join(', ')}`}
          album={`${album}`}
          img={cover}
          className="flex-1"
        />
      </div>
    )
  );
}
