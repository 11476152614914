import type { KeyboardEvent } from 'react';
import { KeyboardSensor as LibKeyboardSensor } from '@dnd-kit/core';
import { shouldHandleEvent } from '../should-handle-event';

export class KeyboardSensor extends LibKeyboardSensor {
  static activators = [
    {
      eventName: 'onKeyDown' as const,
      handler: ({ nativeEvent: event }: KeyboardEvent<Element>) =>
        shouldHandleEvent(event.target as HTMLElement),
    },
  ];
}
