import * as DialogPrimitive from '@radix-ui/react-dialog';
import classNames from 'classnames';

type DialogDescriptionProps = {
  children: string;
  className?: string;
};

export function DialogDescription(props: DialogDescriptionProps) {
  const { children, className } = props;

  return (
    <DialogPrimitive.Description className={classNames('text-muted text-sm', className)}>
      {children}
    </DialogPrimitive.Description>
  );
}
