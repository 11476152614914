import { useTranslation } from 'react-i18next';
import { NavItem } from './nav-item';
import { useBriefs } from '~/pages/briefs/hooks/use-briefs';
import { usePlaylists } from '~/pages/playlists/hooks/use-playlists';

export function NavItemList() {
  const { t } = useTranslation('sidebar');
  const { handleCreateClick: handleCreateBrief } = useBriefs();
  const { handleCreatePlaylist: handleCreatePlaylist } = usePlaylists();

  return (
    <nav className="bg-default flex flex-col gap-2 rounded-2xl p-4">
      <NavItem label={t('link.search')} icon="ri:search-line" to="/search" />
      <NavItem
        label={t('link.briefs')}
        icon="ri:megaphone-line"
        to="/briefs"
        addClick={handleCreateBrief}
      />
      <NavItem
        label={t('link.playlists')}
        icon="ri:headphone-line"
        to="/playlists"
        addClick={handleCreatePlaylist}
      />
    </nav>
  );
}
