import { useTranslation } from 'react-i18next';

export function SearchTrackItemListEmpty() {
  const { t } = useTranslation('search');

  return (
    <div className="flex flex-1 flex-col items-center justify-center">
      <span className="text-xl font-bold">{t('empty-search-list.title')}</span>
      <p className="text-muted">{t('empty-search-list.description')}</p>
    </div>
  );
}
