import classNames from 'classnames';
import { ContentBlock } from './content';
import { MusicTypeBlock } from './music-type';
import { ScopeBlock } from './scope';
import { VideoLinksBlock } from './video-links';

export function BriefDescriptionBlocks() {
  return (
    <div className={classNames('flex h-full gap-2 overflow-hidden p-2')}>
      <div className="flex-1">
        <ContentBlock />
      </div>
      <div className="flex w-[30%] flex-col items-stretch gap-2">
        <div className="h-14">
          <MusicTypeBlock />
        </div>
        <ScopeBlock />
        <VideoLinksBlock />
      </div>
    </div>
  );
}
