import { useSignal } from '@preact/signals-react';
import { KeyboardEvent } from 'react';
import { useRealtime } from '~/hooks/realtime/use-realtime';
import { Channel } from '~/utils/chat/channel';
import { ChatChannelCustom, ChatMessageContent, ChatMessageMeta } from '~/types/types/chat';
import { activeChannelStore, briefChatsStore } from '~/stores/chats-store';
import { ChatNotificationMessage } from '~/types/types/chat-notification';
import { globalStore } from '~/stores/global-store-v2';
import { useGetRealtimeData } from '~/hooks/api/dashboard';
import { Track } from '~/types/schemas/music/track.schema';

export function useChatInput(
  channel: Channel<ChatMessageContent, ChatMessageMeta, ChatChannelCustom>,
  track: Track,
) {
  const files = useSignal<File[]>([]);
  const text = useSignal('');
  const { pubnub } = useRealtime();
  const realtime = useGetRealtimeData();
  const { user } = globalStore;
  const { participants } = activeChannelStore;
  const { brief } = briefChatsStore;
  const channelBase = realtime.data?.channels.sync_chat_notifications.split('.')[0];

  function handleSubmit() {
    const value = text.value.trim();
    const mentionRegex = /@\[[^\]]+\]\(([^)]+)\)/g;
    const mentionIds = [...value.matchAll(mentionRegex)].map((m) => m[1]);

    if (value.length || files.value.length) {
      channel.sendMessage(value, {
        files: files.value,
      });

      Object.values(participants.value).forEach((participant) => {
        if (participant.id !== user.value?.id) {
          pubnub.objects.setMemberships({
            uuid: user.value?.id,
            channels: [`${channelBase}.${participant.id}`],
          });

          if (!mentionIds.includes(participant.id)) {
            console.log(brief.value, track?.title);

            pubnub.publish({
              channel: `${channelBase}.${participant.id}`,
              message: {
                type: 'message',
                data: {
                  text: value,
                  brief: {
                    id: brief.value!.id,
                    title: brief.value!.title,
                  },
                  track: {
                    id: track?.id,
                    title: track?.title,
                  },
                },
              } as ChatNotificationMessage,
            });
          } else {
            pubnub.publish({
              channel: `${channelBase}.${participant.id}`,
              message: {
                type: 'message',
                data: {
                  text: value,
                  brief: {
                    id: brief.value!.id,
                    title: brief.value!.title,
                  },
                  track: {
                    id: track?.id,
                    title: track?.title,
                  },
                },
              } as ChatNotificationMessage,
            });
          }
        }
      });
    }

    text.value = '';
    files.value = [];
  }

  function handlePresssEnter(
    e: KeyboardEvent<HTMLTextAreaElement> | KeyboardEvent<HTMLInputElement>,
  ) {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit();
    }
  }

  return {
    handleSubmit,
    handlePresssEnter,
    text,
    files,
  };
}
