import { useTrackContext } from '../track.context';
import * as Track from '../track';

type TrackPopularityProps = {
  className?: string;
};

export function TrackPopularity(props: TrackPopularityProps) {
  const { className } = props;
  const store = useTrackContext();
  const { popularity } = store.use.track();

  return <Track.Label className={className}>{popularity}</Track.Label>;
}
