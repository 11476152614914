import Button from '~/components/ui/control/button/button';
import { useCardClick } from '~/components/features/presearch/hook/use-card-click';
import { useTranslation } from 'react-i18next';
import { usePlaylistSearch } from '~/hooks/api/playlist';
import usePreSearchStore from '~/stores/presearch-store';
import CardShelf from '../../../card-shelf/card-shelf';
import Tab from '../tab';

function PlaylistTab() {
  const { query } = usePreSearchStore(['query']);
  const { data: playlists, fetchNextPage } = usePlaylistSearch(query);
  const total = playlists?.pages[0]?.total.value || 0;
  const flatedPlaylists = playlists?.pages.flatMap((page) => page.hits);
  const { handleFocusClick, handleClick } = useCardClick('internal_refs');
  const { t } = useTranslation(['pre-search', 'common']);

  return (
    <Tab value="playlists" isEmpty={!flatedPlaylists?.length || !flatedPlaylists}>
      {flatedPlaylists ? (
        <>
          <CardShelf
            label={t('tab.playlists.title')}
            containerClassName="flex-1 col-span-full"
            cardsProps={flatedPlaylists?.map((playlist) => ({
              type: 'playlist',
              artworks: playlist.artworks,
              title: playlist.title,
              onCardClick: () => handleFocusClick(playlist.title, playlist.id),
              onIncludeClick: () => handleClick(playlist.title, playlist.id, 'is'),
              onExcludeClick: () => handleClick(playlist.title, playlist.id, 'is_not'),
            }))}
          />
          {flatedPlaylists.length < total ? (
            <Button
              color="primary"
              onClick={() => fetchNextPage()}
              className="col-span-full mx-auto"
            >
              {t('button.load-more.label', 'common')}
            </Button>
          ) : null}
        </>
      ) : null}
    </Tab>
  );
}

export default PlaylistTab;
