import { ClickableNameList } from '~/components/ui/display/clickable-name-list';
import { Tooltip } from '~/components/ui/overlays/tooltip/tooltip';

type TracksUploadHeaderSummaryArtistsProps = {
  artists?: string[];
};

export function TrackStepsHeaderArtists(props: TracksUploadHeaderSummaryArtistsProps) {
  const { artists } = props;

  const joinedDisplayArtists = artists?.join(', ');
  const names = artists?.map((name) => ({ name, id: name }));

  return names ? (
    <Tooltip
      label={joinedDisplayArtists}
      side="top"
      align="start"
      delayDuration={1000}
      asChild
      triggerClassName="inline-block overflow-hidden whitespace-nowrap text-ellipsis w-fit max-w-full line-clamp-1 h-fit"
    >
      <ClickableNameList names={names} />
    </Tooltip>
  ) : null;
}
