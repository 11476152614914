import AlbumsTab from './tabs/albums/albums-tab';
import All from './tabs/all/all';
import ArtistsTab from './tabs/artists/artists-tab';
import BriefsTab from './tabs/briefs/briefs-tab';
import CatalogsTab from './tabs/catalogs/catalogs-tab';
import Home from './tabs/home/home';
import PlaylistTab from './tabs/playlists/playlists-tab';
import SamplersTab from './tabs/samplers/samplers.tab';
import TagTab from './tabs/tags/tags-tab';
import TargetTab from './tabs/targets/target-tab';

function Content() {
  return (
    <div className="hide-scrollbar h-full overflow-y-auto pb-24">
      <Home />
      <All />
      <AlbumsTab />
      <CatalogsTab />
      <ArtistsTab />
      <PlaylistTab />
      <SamplersTab />
      <BriefsTab />
      <TagTab />
      <TargetTab />
    </div>
  );
}

export default Content;
