import { Navigate, createBrowserRouter } from 'react-router-dom';
import Briefs from '~/pages/briefs/briefs';
import Playlists from '~/pages/playlists/playlists';
import Notificiations from '~/pages/notifications/notifications';
import { Settings } from '~/pages/settings/settings';
import Search from '~/pages/search/search';
import AppProviders from '~/providers/app-providers';
import { BriefCheck } from '~/pages/brief/brief-check';
import { PlaylistCheck } from '~/pages/playlist/playlist-check';
import Authentication from '~/pages/auth/login/authentication';
import AppLayout from '~/components/layout/app-layout';
import { Protected } from '~/pages/auth/protected';
import { SensitiveContent } from '~/components/layout/sensitive-content';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <AppProviders />,
    children: [
      {
        path: '/login',
        element: <Authentication />,
      },
      {
        element: <Protected />,
        children: [
          {
            element: <AppLayout />,
            children: [
              {
                path: '/search',
                element: <Search />,
              },
              {
                path: '/briefs',
                element: <SensitiveContent />,
                children: [
                  {
                    path: '/briefs/:id',
                    element: <BriefCheck />,
                  },
                  {
                    path: '/briefs',
                    element: <Briefs />,
                  },
                ],
              },
              {
                path: '/playlists/:id',
                element: <PlaylistCheck />,
              },
              {
                path: '/playlists',
                element: <Playlists />,
              },
              {
                path: '/notifications',
                element: <Notificiations />,
              },
              {
                path: '/settings',
                element: <Settings />,
              },
            ],
          },
          {
            path: '/',
            element: <Navigate to="/search" replace />,
          },
        ],
      },
    ],
  },
  {
    path: '*',
    element: <div>404</div>,
  },
]);
