import { TrackStepHeaderLoader } from '../../headers/track-step-header-loader';
import { useMatchTrack } from '~/hooks/api/upload';
import { useEffect, useRef } from 'react';
import { TrackStepsContainer } from '../../track-steps.container';
import { UploadTrack } from '~/types/features/track-upload/track-upload.types';
import { useTracksUploadItemContext } from '../../../tracks-upload-item.context';
import { MessageStatus } from '~/types/schemas/notifications/message.schema';
import { CancelListButton } from '../common/cancel-list-button';
import { useTranslation } from 'react-i18next';
import errorHandler from '~/utils/error-handler';
import { useTracksUploadContext } from '~/components/entities/upload/tracks/tracks-upload.context';

type StepMatchProcessProps = {
  file: UploadTrack;
  status: MessageStatus;
  progress: number;
};

export function StepMatchProcess(props: StepMatchProcessProps) {
  const { status, progress, file } = props;
  const { presignedData } = useTracksUploadItemContext();
  const { store } = useTracksUploadContext();
  const setStep = store.use.setStep();
  const setState = store.use.setState();
  const { mutate: launchTrackMatch } = useMatchTrack(presignedData!.id);
  const { t } = useTranslation('tracks-upload-form');

  const progressRef = useRef(progress);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    launchTrackMatch(undefined);
  }, [launchTrackMatch, file.step]);

  useEffect(() => {
    // Check if the progress is stuck
    if (progress === progressRef.current) {
      if (!timeoutRef.current) {
        timeoutRef.current = setTimeout(() => {
          setStep(file.id, 'failed');
          errorHandler(new Error('Progress has not advanced for 15 seconds.'));
        }, 15000); // 15 seconds
      }
    } else {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
      progressRef.current = progress;
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [progress, file.id, setStep, setState]);

  const titles: Record<MessageStatus, string> = {
    queued: t('card.match.queue'),
    processing: t('card.match.process'),
    done: t('card.match.done'),
    failed: t('card.match.fail'),
  };

  return (
    <TrackStepsContainer>
      <div className="relative flex size-full items-center justify-between pr-4">
        <TrackStepHeaderLoader title={titles[status]} state={`${progress}%`} className="flex-1" />
        <CancelListButton />
      </div>
    </TrackStepsContainer>
  );
}
