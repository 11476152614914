import classNames from 'classnames';
import { Tooltip } from '~/components/ui/overlays/tooltip/tooltip';

type TracksUploadHeaderSummaryTitleProps = {
  title: string;
  className?: string;
};

export function TrackStepHeaderTitle(props: TracksUploadHeaderSummaryTitleProps) {
  const { title, className } = props;

  return (
    <Tooltip label={title} align="start" delayDuration={1000} asChild>
      <h6
        className={classNames(
          'track-info__text--title text-default line-clamp-1 shrink-0 text-left text-sm font-medium select-none',
          'after:text-muted after:text-xs after:font-normal after:content-[attr(data-version)]',
          className,
        )}
      >
        {title}
      </h6>
    </Tooltip>
  );
}
