import { MouseEvent } from 'react';

type TagChipProps = {
  label: string;
  color?: string;
  onClick?: () => void;
};

export function ColoredTagChip(props: TagChipProps) {
  const { label, color, onClick } = props;

  function handleClick(e: MouseEvent) {
    e.stopPropagation();
    onClick?.();
  }

  return (
    <button
      type="button"
      className="bg-neutral-muted hover:bg-neutral-emphasis hover:border-neutral-muted flex h-6 w-fit items-center gap-2 rounded-sm border border-transparent pr-2 pl-1 text-xs font-semibold transition-colors"
      onClick={handleClick}
    >
      <div className="size-2 rounded-xs" style={{ backgroundColor: color }} />
      <span>{label}</span>
    </button>
  );
}
