import {
  BpmRangeType,
  FilterBlock,
  PopularityRangeType,
  ReleaseDateRangeType,
} from '~/stores/search-store';

export const trackKeys = {
  root: ['track'] as const,
  versions: (trackId?: string) => [...trackKeys.root, 'versions', trackId] as const,
  presearch: (query?: string) => [...trackKeys.root, 'presearch', query] as const,
  search: (
    blocks?: FilterBlock[],
    similarityTrackId?: string,
    similarityURL?: string,
    sort?: string,
    releaseDate?: ReleaseDateRangeType,
    popularity?: PopularityRangeType,
    bpm?: BpmRangeType,
    query?: string,
    musicType?: string[],
  ) =>
    [
      ...trackKeys.root,
      ...(blocks
        ? blocks.flatMap((block) => {
            const values = block.values.filter((value) => value.value);

            if (values.length) {
              return [
                block.term.value,
                block.condition.value,
                ...values.map((value) => value.value),
              ];
            }

            return [];
          })
        : []),
      similarityTrackId,
      similarityURL,
      sort,
      releaseDate,
      popularity,
      bpm,
      query,
      musicType,
    ] as const,
  detailList: (briefId?: string, trackId?: string) =>
    [...trackKeys.root, 'detailList', briefId, trackId] as const,
};
