import { createWithEqualityFn } from 'zustand/traditional';
import { createSelectors } from '../utilities';
import errorHandler from '~/utils/error-handler';
const CONCURRENCY = 3;

type Task = (controller: AbortController) => Promise<unknown>;
type QueueTask = { task: Task; controller: AbortController; fileId: string };

type UploadQueueStoreState = {
  queue: Array<QueueTask>;
  activeTasks: Array<QueueTask>;
};

type UploadQueueStoreActions = {
  addTask: ({ task, controller, fileId }: QueueTask) => void;
  removeTask: (fileId: string) => void;
  processQueue: () => void;
  addActiveTask: (task: QueueTask) => void;
  removeActiveTask: (fileId: string) => void;
};

export const useUploadQueueStore = createSelectors(
  createWithEqualityFn<UploadQueueStoreState & UploadQueueStoreActions>((set, get) => ({
    queue: [],
    activeTasks: [],
    addTask: ({ task, controller, fileId }) => {
      set((state) => ({
        queue: [...state.queue, { task, controller, fileId }],
      }));
      get().processQueue();
    },
    removeTask: (fileId: string) => {
      const { queue, activeTasks } = get();
      const findActiveTask = activeTasks.find((t) => t.fileId === fileId);
      const findQueueTask = queue.find((t) => t.fileId === fileId);

      if (findActiveTask) {
        findActiveTask.controller.abort();
        set((state) => ({
          activeTasks: state.activeTasks.filter((task) => task.fileId !== fileId),
        }));
      }

      if (findQueueTask) {
        findQueueTask.controller.abort();
        set((state) => ({
          queue: state.queue.filter((task) => task.fileId !== fileId),
        }));
      }
    },
    addActiveTask: (task: QueueTask) => {
      set(() => ({
        activeTasks: [...get().activeTasks, task],
      }));
    },
    removeActiveTask: (fileId: string) => {
      if (get().activeTasks.length === 0) return;
      set((state) => ({
        activeTasks: state.activeTasks.filter((task) => task.fileId !== fileId),
      }));
    },
    processQueue: () => {
      if (get().activeTasks.length < CONCURRENCY && get().queue.length > 0) {
        const element = get().queue.shift()!;
        get().addActiveTask(element);

        // Use the controller's signal to handle cancellation
        element
          .task(element.controller)
          .finally(() => {
            get().removeActiveTask(element.fileId);
            get().processQueue();
          })
          .catch((error) => {
            const err = error as Error;
            if (err.name === 'AbortError') {
              console.log('Task was aborted');
            } else {
              errorHandler(err);
            }
            get().removeActiveTask(element.fileId);
            get().processQueue();
          });
      }
    },
  })),
);
