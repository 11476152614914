import Linkify from 'linkify-react';
import React from 'react';
import AnchorDecorator from './anchor';

interface TextLinkParserProps {
  children: React.ReactNode;
}

function TextLinkParser(props: TextLinkParserProps) {
  const { children } = props;

  return (
    <Linkify options={{ render: (args) => <AnchorDecorator {...args} /> }}>{children}</Linkify>
  );
}

export default TextLinkParser;
