import { TracksUploadItem } from '../item/tracks-upload-item';
import { useTracksUploadContext } from '../../tracks-upload.context';
import { TracksUploadItemListHeader } from './header';
import { motion } from 'framer-motion';
import { useAutoAnimate } from '@formkit/auto-animate/react';
export function TracksUploadItemList() {
  const { store } = useTracksUploadContext();
  const files = store.use.files();

  const [animationParent] = useAutoAnimate();

  return (
    <>
      <TracksUploadItemListHeader />
      <div
        id="tracks-upload-scroll-parent"
        className="custom-scrollbar size-full overflow-x-hidden overflow-y-auto pb-10"
      >
        <div ref={animationParent} className="flex flex-col gap-3 pr-1">
          {files.map((track, index) => (
            <motion.div
              initial={{ opacity: 0, x: 100 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: 10, transition: { duration: 0.3 } }}
              transition={{ delay: 0.1 * index, duration: 0.3 }}
              custom={index}
              key={track.id}
            >
              <TracksUploadItem index={index} file={track} />
            </motion.div>
          ))}
        </div>
      </div>
    </>
  );
}
