import { Icon, IconifyIconProps } from '@iconify/react';
import * as ContextMenu from '@radix-ui/react-context-menu';
import classNames from 'classnames';
import React, { ForwardedRef, MouseEvent, PropsWithChildren } from 'react';

type ContextMenuItemProps = PropsWithChildren<{
  icon?: IconifyIconProps['icon'];
  onClick?: (e: MouseEvent) => void;
  className?: string;
}> &
  ContextMenu.ContextMenuItemProps;

export const ContextMenuItem = React.forwardRef(
  (props: ContextMenuItemProps, ref?: ForwardedRef<HTMLDivElement>) => {
    const { icon, onClick, children, className, ...rest } = props;
    return (
      <ContextMenu.Item
        className={classNames(
          'text-default focus:bg-control-neutral flex h-8 items-center gap-3 rounded-sm border border-transparent px-2 text-sm outline-hidden select-none',
          className,
        )}
        onClick={onClick}
        ref={ref}
        {...rest}
      >
        <div className="flex size-4 items-center justify-center">
          {icon && <Icon icon={icon} className="h-4 w-4" />}
        </div>
        <div className="flex-1">{children}</div>
      </ContextMenu.Item>
    );
  },
);
