import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import Cover from '~/components/ui/cover/cover';
import Chip from '~/components/ui/control/chip/chip';
import usePreSearchStore from '~/stores/presearch-store';
import { Artwork } from '~/types/schemas/common/image.schema';

type HighlightProps = {
  title: string;
  description: string;
  chips: string[];
  artwork?: Artwork;
  linkParams?: string;
};

function Highlight(props: HighlightProps) {
  const { title, description, chips, artwork, linkParams } = props;
  const navigate = useNavigate();
  const presearch = usePreSearchStore(['setIsOpen']);

  const handleClick = () => {
    navigate(`/${linkParams}`);
    presearch.setIsOpen(false);
  };

  return (
    <div
      onClick={handleClick}
      className={classNames(
        'group flex h-full gap-3 rounded-lg p-4 text-left',
        'bg-neutral-emphasis hover:bg-neutral-muted cursor-pointer',
      )}
    >
      <Cover className="w-48!" type="catalog" artworks={artwork && [artwork]} alt={title} />
      <div className="flex h-full flex-1 flex-col gap-2">
        <h5 className="text-default text-2xl font-bold">{title}</h5>
        <p className="text-muted line-clamp-6 text-sm">{description}</p>
        <div className="mt-auto flex gap-3">
          {chips?.map((chip) => (
            <Chip key={chip} className="bg-neutral-muted group-hover:bg-neutral-emphasis">
              {chip}
            </Chip>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Highlight;
