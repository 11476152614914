import { Icon } from '@iconify/react';
import { ChangeEvent, FocusEventHandler, MutableRefObject, Ref, forwardRef } from 'react';
import { InputProps } from '../input';

interface TextInputProps extends InputProps {
  containerClass?: string;
  wrapperClass?: string;
  inputClass?: string;
  labelClass?: string;
  isFocused: boolean;
  onFocus: FocusEventHandler<HTMLInputElement>;
  onBlur: FocusEventHandler<HTMLInputElement>;
}

function Text(props: TextInputProps, inputRef: Ref<HTMLInputElement>) {
  const {
    label,
    placeholder,
    className,
    containerClass = '',
    wrapperClass = '',
    inputClass = '',
    labelClass,
    error,
    iconLeft,
    iconRight,
    required = false,
    onChange,
    ...inputProps
  } = props;

  function handleClick() {
    (inputRef as MutableRefObject<HTMLInputElement>).current?.focus();
  }

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    onChange?.(e.target.value);
  }

  return (
    <div className={className}>
      <div className={wrapperClass} onClick={handleClick}>
        {label && (
          <span className={labelClass}>
            {label}
            {required && <span className="text-control-danger">*</span>}
          </span>
        )}
        <div className={containerClass}>
          {iconLeft ? <Icon icon={iconLeft} className="text-control-rest" /> : null}
          <input
            ref={inputRef}
            aria-label={label}
            placeholder={placeholder || label}
            className={inputClass}
            type="text"
            onChange={handleChange}
            {...inputProps}
          />
          {iconRight ? <Icon icon={iconRight} className="text-control-rest" /> : null}
        </div>
        {error ? <span className="error text-control-danger w-full text-xs">{error}</span> : null}
      </div>
    </div>
  );
}

export const TextInput = forwardRef(Text);
