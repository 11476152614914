import classNames from 'classnames';
import { FormStep, FormStepProps } from './components/form-step';

type FormStepsProps = {
  steps: FormStepProps[];
  className?: string;
  onStepClick?: (index?: number) => void;
};

export function FormSteps(props: FormStepsProps) {
  const { steps, className, onStepClick } = props;

  return (
    <div className={classNames('form-steps flex w-fit flex-col items-end', className)}>
      {steps.map((step, index) => (
        <div
          key={`form-step-${index}`}
          className="group/step relative -mt-1 flex flex-col items-end first:mt-0"
        >
          <FormStep {...step} index={index + 1} onStepClick={onStepClick} />
          <div className="bg-muted top-7 right-3 -mt-1 mr-3 inline-block h-6 w-[1px] group-last/step:hidden" />
        </div>
      ))}
    </div>
  );
}
