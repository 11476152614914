import classNames from 'classnames';
import { Brief } from '~/types/schemas/music/brief.schema';
import { parseDateNumeric } from '~/utils/common/parse-date';
import { parseThousands } from '~/utils/common/parse-thousands';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import BriefState from '~/components/entities/tracklist/brief/brief-state';
import { Artwork } from '~/components/ui/cover/artwork';
import { Icon } from '@iconify/react';

type BriefCardProps = {
  brief: Brief;
  onClick?: () => void;
};

const LOCALE = 'en';

const BriefCard = (props: BriefCardProps) => {
  const { brief, onClick } = props;
  const {
    title,
    artworks,
    tenant,
    public: isSended,
    created_at,
    created_by,
    descriptions,
    budget,
    deadline,
    currency,
    tracks,
  } = brief;
  const { first_name, last_name, country: userCountry } = created_by!;
  const { company_name, country: tenantCountry } = tenant;
  const { t } = useTranslation('libraries');

  return (
    <button
      type="button"
      onClick={onClick}
      className="hover:bg-neutral-emphasis border-neutral-emphasis hover:border-neutral-muted flex w-full shrink-0 rounded-lg border p-4 text-left transition-all duration-100"
    >
      <div className="mr-4 aspect-square w-[25%] shrink-0">
        <Artwork
          icon="ri:megaphone-line"
          artworks={artworks?.map((artwork) => artwork.large)}
          alt="cover"
        />
      </div>
      <div className="flex h-full flex-1 flex-col items-stretch">
        <div className="mt-2 flex w-full items-center justify-between text-xs">
          <h4 className="text-muted">
            {t('brief-card.attribution', {
              'company-name': company_name
                ? `${company_name} (${userCountry || tenantCountry})`
                : '',
              'user-fullname': `${first_name} ${last_name}`,
              date: parseDateNumeric(created_at || '', {
                month: 'long',
                day: '2-digit',
                year: dayjs(created_at).year() === dayjs().year() ? undefined : 'numeric',
              }),
            })}
          </h4>
          <span>
            {budget ? (
              <div className="text-muted flex items-center">
                <Icon icon="mdi:money" className="mr-1 size-4" />
                {`${parseThousands(budget.toString(), ' ')} ${currency}`}
              </div>
            ) : null}
          </span>
        </div>
        <div className="mt-3 mb-3 h-full flex-1 break-all">
          <h3 className="text-default line-clamp-1 font-bold">{title}</h3>
          <p className="text-muted mt-2 line-clamp-2 text-xs">
            {descriptions.find((description) => description.locale === LOCALE)?.value}
          </p>
        </div>
        <div className="text-muted mt-auto mb-2 flex w-full flex-wrap items-center justify-between gap-3 text-xs">
          <span
            className={classNames(
              'text-default flex items-center gap-2 rounded-sm px-2 py-1',
              tracks ? 'bg-button-success-rest' : 'bg-button-danger-rest',
            )}
          >
            {t('brief-card.tracks-count', {
              'track-count': tracks || 0,
            })}
          </span>
          {deadline ? <BriefState deadline={deadline} isSended={isSended} /> : null}
        </div>
      </div>
    </button>
  );
};

export default BriefCard;
