import { Icon } from '@iconify/react';
import { useTranslation } from 'react-i18next';

export function EmptyPlaceholder() {
  const { t } = useTranslation('notification-center');

  return (
    <div className="flex h-48 flex-col items-center justify-center">
      <Icon icon="ri:notification-off-line" className="text-muted mb-2 size-8 text-4xl" />
      <h4 className="text-muted w-[70%] text-center text-sm">{t('pop-in.placeholder')}</h4>
    </div>
  );
}
