import classNames from 'classnames';
import Cover, { CoverType } from '~/components/ui/cover/cover';
import Chip from '~/components/ui/control/chip/chip';
import { Artwork } from '~/types/schemas/common/image.schema';
import { MouseEvent } from 'react';
import { Icon } from '@iconify/react';

export type CardProps = {
  type?: CoverType;
  artworks?: Artwork[];
  title: string;
  subtitle?: string;
  customSubtitle?: React.ReactNode;
  subtitlePrefx?: string;
  chips?: string[];
  onCardClick?: () => void;
  onIncludeClick?: () => void;
  onExcludeClick?: () => void;
};

function Card(props: CardProps) {
  const {
    type,
    artworks,
    title,
    subtitle,
    customSubtitle,
    subtitlePrefx,
    chips,
    onCardClick,
    onIncludeClick,
    onExcludeClick: onExcludelick,
  } = props;

  const handleCardClick = (e: MouseEvent) => {
    e.stopPropagation();
    onCardClick?.();
  };

  const handleIncludeClick = (e: MouseEvent) => {
    e.stopPropagation();
    onIncludeClick?.();
  };

  const handleExcludeClick = (e: MouseEvent) => {
    e.stopPropagation();
    onExcludelick?.();
  };

  const buttonClassName =
    'rounded-lg flex items-center justify-center transition-delay-400 transition-colors bg-neutral-emphasis hover:bg-neutral-muted w-8 aspect-square text-default';

  return (
    <div
      onClick={handleCardClick}
      className={classNames(
        'presearch-card group/presearch-card flex h-full w-full cursor-pointer flex-col gap-4 rounded-lg p-4 transition duration-100',
        'bg-neutral-emphasis hover:bg-neutral-muted',
        chips?.length ? 'pb-5' : 'pb-7',
      )}
    >
      <div className="relative aspect-square w-full overflow-hidden drop-shadow-xl">
        <Cover artworks={artworks} alt={title} type={type} />
        <div
          className={classNames(
            'from-neutral-emphasis/30 to-neutral-muted/0 absolute top-full flex h-full w-full items-end overflow-hidden rounded-xs bg-linear-to-t p-3 transition-transform',
            'group-hover/presearch-card:-translate-y-full',
          )}
        >
          <div className="mt-full flex w-full justify-between">
            <button
              type="button"
              className={classNames(buttonClassName, 'presearch-card-subtract')}
              onClick={handleExcludeClick}
            >
              <Icon icon="ri:add-fill" className="size-4" />
            </button>
            <button
              type="button"
              className={classNames(buttonClassName, 'presearch-card-add')}
              onClick={handleIncludeClick}
            >
              <Icon icon="ri:subtract-fill" className="size-4" />
            </button>
          </div>
        </div>
      </div>
      <div className="presearch-card__information flex w-full flex-col items-start gap-2 overflow-hidden">
        <h5 className="presearch-card__information__title text-default line-clamp-1 text-left font-bold">
          {title}
        </h5>
        {chips?.length ? (
          <div className="flex gap-2">
            {chips.map((chip) => (
              <Chip key={chip} className="bg-neutral-muted rounded-sm! capitalize">
                {chip}
              </Chip>
            ))}
          </div>
        ) : subtitle || customSubtitle || subtitlePrefx ? (
          <div className="text-muted flex h-4 gap-1 text-xs">
            {subtitlePrefx && (
              <>
                <span className="flex shrink-0">{subtitlePrefx}</span>
                {(subtitle || customSubtitle) && <span>•</span>}
              </>
            )}
            {subtitle || customSubtitle}
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default Card;
