import classNames from 'classnames';
import GeneralIcons from '~/components/ui/icon/general-icons';
import useMusemindStore from '~/stores/musemind-store';
import UploadDialog from '~/components/features/musemind/components/upload-dialog';
import Icon from '~/components/ui/icon/icon';
import VideoThumbnail from './video-thumbnail';

type HideVideoProps = {
  className?: string;
};

const HideVideo = (props: HideVideoProps) => {
  const { className = '' } = props;
  const musemind = useMusemindStore([
    'audioTitle',
    'videoElement',
    'videoName',
    'isPipOpen',
    'setIsPipOpen',
    'resetStore',
  ]);

  const handleClick = () => {
    musemind.setIsPipOpen(!musemind.isPipOpen);
  };

  const handleVideoDelete = () => {
    musemind.resetStore();
  };

  return (
    <div className={`flex items-center gap-2 ${className}`}>
      {musemind.videoElement ? (
        <>
          <button
            type="button"
            onClick={handleClick}
            className="group/hide-button bg-neutral-emphasis relative flex h-10 w-14 shrink-0 items-center justify-center overflow-hidden rounded-md"
          >
            <VideoThumbnail />
            <div
              className={classNames(
                'text-default bg-neutral-muted absolute inset-0 flex items-center justify-center text-lg transition-opacity group-hover/hide-button:opacity-100',
                !musemind.isPipOpen ? 'opacity-100' : 'opacity-0',
              )}
            >
              {musemind.isPipOpen ? (
                <GeneralIcons icon="eye-line" />
              ) : (
                <GeneralIcons icon="eye-off-line" />
              )}
            </div>
          </button>
          <div className="flex h-full w-full max-w-sm flex-col overflow-hidden">
            <h3 className="line-clamp-1 w-full text-sm font-bold text-ellipsis">
              {musemind.videoName}
            </h3>
            <h4 className="text-muted w-full text-xs leading-3 text-ellipsis">
              {musemind.audioTitle}
            </h4>
          </div>
          <button
            type="button"
            className="text-muted hover:text-default bg-neutral-emphasis hover:bg-neutral-muted flex h-5 w-5 shrink-0 items-center justify-center rounded-full transition-colors"
            onClick={handleVideoDelete}
          >
            <Icon name="close-line" />
          </button>
        </>
      ) : null}
      {!musemind.videoElement ? <UploadDialog /> : null}
    </div>
  );
};

export default HideVideo;
