import { Icon } from '@iconify/react';
import classNames from 'classnames';
import BriefChatsPanel from '~/components/features/brief-chats/brief-chats-panel';
import { useGetBriefDetails } from '~/hooks/api/brief';
import { briefChatsStore } from '~/stores/chats-store';

type BriefAsideChannelProps = {
  id: string;
};

export function BriefAsideChannel(props: BriefAsideChannelProps) {
  const { id } = props;
  const { data } = useGetBriefDetails(id);
  const { isPanelOpen } = briefChatsStore;

  function handleClick() {
    isPanelOpen.value = !isPanelOpen.value;
  }

  return (
    <aside
      className={classNames('bg-default relative h-full rounded-2xl transition-all', {
        'w-0': !isPanelOpen.value,
        'w-96': isPanelOpen.value,
      })}
    >
      <div className="h-full w-full">
        <button
          type="button"
          className={classNames(
            'bg-neutral-emphasis hover:bg-neutral-muted absolute top-4 -left-12 z-10 h-8 w-8 rounded-sm transition-all hover:scale-105 active:scale-95',
            {
              'opacity-0': isPanelOpen.value,
              'opacity-100': !isPanelOpen.value,
            },
          )}
          onClick={handleClick}
        >
          <Icon icon="ri:chat-4-line" className="m-auto h-4 w-4 text-white" />
        </button>
        <BriefChatsPanel
          brief={{ id, title: data.title }}
          onClose={() => {
            isPanelOpen.value = false;
          }}
        />
      </div>
    </aside>
  );
}
