import Button from '~/components/ui/control/button/button';
import DeleteDialog from '~/pages/brief/components/delete-dialog';
import { useTranslation } from 'react-i18next';

type BriefFormFooterProps = {
  isLastStep?: boolean;
  isFirstStep?: boolean;
  onNextClick?: () => void;
  onPreviousClick?: () => void;
  onDeleteClick?: () => void;
  onSendClick?: () => void;
};

function BriefFormFooter(props: BriefFormFooterProps) {
  const { isLastStep, isFirstStep, onNextClick, onDeleteClick, onSendClick, onPreviousClick } =
    props;
  const { t } = useTranslation('brief-form');

  return (
    <div className="flex h-8 w-full items-center justify-between">
      <DeleteDialog handleDeleteClick={() => onDeleteClick?.()}>
        <Button color="danger" className="tracklist__actions--delete">
          {t('footer.delete')}
        </Button>
      </DeleteDialog>
      <div className="flex items-center justify-end gap-3">
        {!isFirstStep ? (
          <Button
            color="primary"
            className="bg-transparent hover:bg-transparent"
            onClick={onPreviousClick}
          >
            {t('footer.previous')}
          </Button>
        ) : null}
        {!isLastStep ? (
          <Button color="primary" onClick={onNextClick} className="brief-form__button--next-step">
            {t('footer.next')}
          </Button>
        ) : (
          <Button color="primary" onClick={onSendClick}>
            {t('footer.submit')}
          </Button>
        )}
      </div>
    </div>
  );
}

export default BriefFormFooter;
