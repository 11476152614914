import { api } from '~/utils/api';
import { useGenericMutation } from '~/hooks/utils/react-query/use-generic-mutation';

export const useGenerateMixAction = () => {
  return useGenericMutation<{ trackId: string }, null>({
    func: (data) =>
      api
        .post('action', {
          json: {
            context: 'SEARCHMODE',
            action: {
              type: 'GENERATE_MIX',
              target: data?.trackId,
            },
          },
        })
        .json<null>(),
    url: 'action',
  });
};
