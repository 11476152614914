import { Controller } from 'react-hook-form';
import Textarea from '~/components/ui/forms/textarea/textarea';
import { useTranslation } from 'react-i18next';
import Input from '~/components/ui/forms/input/input';
import { PlaylistStepProps } from '../playlist-form';
import PlaylistFormHeader from '../components/header';

function General(props: PlaylistStepProps) {
  const { t } = useTranslation('playlist-form');
  const { playlist, control, onDiscardAndExitClick } = props;

  return (
    <>
      <PlaylistFormHeader onDiscardAndExitClick={onDiscardAndExitClick} />
      <div className="form-content flex h-full flex-col items-stretch gap-4">
        <div className="form-content__row">
          <Controller
            name="title"
            defaultValue={playlist?.title || ''}
            control={control}
            rules={{ required: t('input.title.empty-warning') }}
            render={({ field: { value, onChange }, fieldState }) => (
              <Input
                label={t('input.title.label')}
                placeholder={t('input.title.placeholder')}
                className="playlist-form__title"
                value={value}
                onChange={onChange}
                required
                error={fieldState.error?.message}
              />
            )}
          />
        </div>
        <div className="form-content__row h-full">
          <Controller
            name="description"
            defaultValue={playlist?.descriptions.find((d) => d.locale === 'en')?.value || ''}
            control={control}
            render={({ field: { value, onChange }, fieldState }) => (
              <Textarea
                value={value}
                onChange={onChange}
                label={t('input.description.label')}
                placeholder={t('input.description.placeholder')}
                boxClassName="h-full"
                className="h-full"
                error={fieldState.error?.message}
              />
            )}
          />
        </div>
      </div>
    </>
  );
}

export default General;
