import classNames from 'classnames';
import { memo } from 'react';
import { Message as MessageType } from '~/utils/chat/message';
import { ChatMessageContent, ChatMessageMeta } from '~/types/types/chat';
import { activeChannelStore } from '~/stores/chats-store';
import { globalStore } from '~/stores/global-store-v2';
import { useComputed } from '@preact/signals-react';
import Message from './message/message';
import { useMessageTime } from './use-message-time';
import UserAvatar from '../user-avatar';

export type MessageGroupType = {
  timetoken: string;
  userId: string;
  messages: MessageType<ChatMessageContent, ChatMessageMeta>[];
};

type MessageGroupProps = {
  group: MessageGroupType;
  className?: string;
};

function MessageGroup(props: MessageGroupProps) {
  const { group, className } = props;
  const time = useMessageTime(group.timetoken);
  const { user } = globalStore;
  const isSender = useComputed(() => user.value?.id === group.userId).value;
  const { participants } = activeChannelStore;
  const sender = participants.value[group.userId];

  return (
    <div className="flex w-full flex-col items-center gap-6">
      <span className="text-muted text-xs">{time}</span>
      <div
        className={classNames('flex w-full', {
          'gap-2': !isSender,
        })}
      >
        {!isSender && sender ? (
          <UserAvatar className="h-6 w-6 self-end rounded-md" user={sender} />
        ) : null}
        <div className="w-full">
          {!isSender && (
            <span className="text-muted self-start pl-1.5 text-xs capitalize">{`${sender?.first_name} ${sender?.last_name}`}</span>
          )}
          <div className={classNames('flex w-full flex-col-reverse items-center gap-1', className)}>
            {group.messages
              .slice()
              .reverse()
              .map((message) => (
                <Message
                  key={message.timetoken}
                  user={participants.value[message.userId]}
                  message={message}
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(MessageGroup);
