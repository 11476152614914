import { UseQueryResult, keepPreviousData, useQuery } from '@tanstack/react-query';
import { ZodSchema } from 'zod';
import { QueryKeyT, fetcher } from '../../../utils/react-query/fetcher';

export type useFetchOptions<T> = {
  url?: string;
  schema: ZodSchema<T>;
  staleTime?: number;
  enabled?: boolean;
  keepPreviousData?: boolean;
  onSuccess?: (data: T) => void;
  onError?: (error: Error) => void;
};

export const useFetch = <T>(params: useFetchOptions<T>): UseQueryResult<T> => {
  const { url, schema, staleTime, enabled = true, onSuccess, onError } = params;

  const query = useQuery<T, Error, T, QueryKeyT>({
    queryKey: [url!, undefined],
    queryFn: ({ queryKey, ...rest }) =>
      fetcher({
        options: { ...rest, queryKey },
        schema,
        onSuccess,
        onError,
      }),
    enabled: !!url && enabled,
    staleTime,
    ...(params.keepPreviousData
      ? {
          placeholderData: keepPreviousData,
        }
      : {}),
  });

  return query;
};
