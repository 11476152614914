import { Icon, IconifyIconProps } from '@iconify/react';
import { PropsWithChildren } from 'react';
import { TrackChipBpm } from './bpm';
import { TrackChipTonality } from './tonality';
import { TrackChipTag } from './tag';

type TrackChip = PropsWithChildren<{
  icon?: IconifyIconProps['icon'];
  color?: string;
  label: string;
  onClick?: () => void;
}>;

function TrackChip(props: TrackChip) {
  const { icon, color, label, onClick } = props;

  return (
    <button
      type="button"
      className="bg-neutral-muted hover:bg-neutral-emphasis hover:border-neutral-muted flex h-6 w-fit items-center gap-2 rounded-sm border border-transparent pr-2 pl-1 text-xs font-semibold transition-colors"
      onClick={onClick}
    >
      {icon ? <Icon icon={icon} className="text-base" /> : null}
      {color ? <div className="size-2 rounded-sm" style={{ backgroundColor: color }} /> : null}
      {label}
    </button>
  );
}

const Bpm = TrackChipBpm;
const Tonality = TrackChipTonality;
const Tag = TrackChipTag;

export default TrackChip;

export { Bpm, Tonality, Tag };
