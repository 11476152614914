import { useTranslation } from 'react-i18next';
import { useIngestUploadSession } from '~/hooks/api/upload';
import Button from '~/components/ui/control/button/button';
import { useUploadSessionContext } from '~/providers/upload/session/upload-session.context';
import { useTracksUploadContext } from '../tracks-upload.context';
import { briefStore } from '~/stores/briefs-store';
import blinkElement from '~/utils/blink-element';
import { HTTPError } from 'ky';
import { toastr } from '~/components/ui/notifications/toast/utils/toast-call';

export function SubmitButton() {
  const { session, contextId } = useUploadSessionContext();
  const { store, onExit } = useTracksUploadContext();
  const setSelected = store.use.setSelected();
  const selected = store.use.selected();
  const files = store.use.files();

  const { t } = useTranslation('tracks-upload-form');
  const { mutate: ingestUploadSession, isPending } = useIngestUploadSession(session);

  const unvalidatedFile = files.find((file) => file.state !== 'validated');

  function handleLocalStorage() {
    const ingestedFiles = files
      .map((file) => {
        const matchedData = file.matchedMetadata;
        if (!matchedData) return undefined;

        return {
          title: matchedData.title,
          artists: matchedData.artists,
          cover: matchedData.cover,
          version: matchedData.version,
        };
      })
      .filter(Boolean);

    if (ingestedFiles.length) {
      briefStore.createIngest(contextId, ingestedFiles);
    }
  }

  function handleClick() {
    if (unvalidatedFile) {
      const scrollParent = document.getElementById('tracks-upload-scroll-parent');
      const unvalidatedFileId = document.getElementById(unvalidatedFile.id);
      const unvalidatedFileTop = document.getElementById(unvalidatedFile.id)?.offsetTop;

      if (selected !== unvalidatedFile.id) {
        setSelected(unvalidatedFile.id);
      }

      scrollParent?.scrollTo({
        top: unvalidatedFileTop,
        behavior: 'smooth',
      });

      blinkElement(unvalidatedFileId!);
      return;
    }

    ingestUploadSession(undefined, {
      onSuccess: () => {
        handleLocalStorage();
        onExit();
      },
      onError: async (error) => {
        if (error instanceof HTTPError) {
          const status = error.response.status;
          if (status == 500) {
            toastr(
              'error',
              t('submit.ingest.blocked.title', { ns: 'toast' }),
              t('submit.ingest.blocked.content', { ns: 'toast' }),
            );
          }
        }
      },
    });
  }

  return (
    <Button
      color="primary"
      iconRight="send-plane-fill"
      isLoading={isPending}
      disabled={isPending}
      onClick={handleClick}
    >
      {t('header.send')}
    </Button>
  );
}
