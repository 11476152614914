import { useCallback, useEffect, useState } from 'react';
import { TrackStepHeaderLoader } from '../headers/track-step-header-loader';
import { UploadTrack } from '~/types/features/track-upload/track-upload.types';
import { useUploadQueueStore } from '~/stores/upload/upload-queue-store';
import { TrackStepsContainer } from '../track-steps.container';
import { uploadOnS3 } from '~/utils/s3/upload-on-s3';
import { useUploadSessionContext } from '~/providers/upload/session/upload-session.context';
import { createPresignedData } from '~/utils/s3/create-presigned-data';
import { useTracksUploadItemContext } from '../../tracks-upload-item.context';
import { CancelQueueButton } from './common/cancel-queue-button';
import errorHandler from '~/utils/error-handler';
import { useTranslation } from 'react-i18next';

type StepProcessProps = {
  file: UploadTrack;
};

export function StepUploadProcess(props: StepProcessProps) {
  const { file } = props;
  const { presignedData, setPresignedData, setUploadComplete } = useTracksUploadItemContext();
  const [progress, setProgress] = useState(0);
  const { t } = useTranslation('tracks-upload-form');

  const { session } = useUploadSessionContext();

  const addTask = useUploadQueueStore.use.addTask();

  const uploadFile = useCallback(
    async (controller: AbortController) => {
      if (!session) {
        return errorHandler('No session found', 'warning');
      }
      try {
        const data = await createPresignedData(session, file);
        if (data) {
          setPresignedData(data);
          await uploadOnS3({
            presignedData: data!,
            file: file.file,
            onProgress: (p) => setProgress(p),
            onUploadCompleted: () => setUploadComplete(true),
            controller,
          });
        } else {
          throw new Error('No presigned data found');
        }
      } catch (error) {
        errorHandler(error as Error);
      }
    },
    [file, setUploadComplete, session, setPresignedData],
  );

  useEffect(() => {
    if (file.step !== 'waiting') return;
    const controller = new AbortController();
    addTask({
      task: uploadFile,
      controller,
      fileId: file.id,
    });
  }, [addTask, file.id, uploadFile, file.step]);

  return (
    <TrackStepsContainer>
      <div className="relative flex size-full items-center justify-between pr-4">
        <TrackStepHeaderLoader
          title={t('card.upload.process')}
          state={presignedData ? `${progress}%` : t('card.upload.process.queue')}
          className="flex-1"
        />
        <CancelQueueButton />
      </div>
    </TrackStepsContainer>
  );
}
