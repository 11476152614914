/* eslint-disable jsx-a11y/control-has-associated-label */
import { useState } from 'react';
import Dropzone from 'react-dropzone';
import IconCloseLine from 'virtual:icons/ri/close-line';
import IconFileAddLine from 'virtual:icons/ri/file-add-line';
import Button from '../../control/button/button';

type FileProps = {
  onChange?: (files: globalThis.File[]) => void;
  maxFiles?: number;
  maxSize?: number;
  accept?: { [key: string]: string[] };
  disabled?: boolean;
  className?: string;
  multiple?: boolean;
  placeholder?: string;
};

const FileDrop = (props: FileProps) => {
  const {
    onChange,
    maxFiles = 1,
    maxSize = 1,
    accept,
    disabled = false,
    className = '',
    multiple = false,
    placeholder = "Drag'n'Drop your file",
  } = props;
  const [dragOver, setDragOver] = useState(false);
  const sizeConversion = maxSize * 1000000;
  const [fileName, setFileName] = useState<string[]>([]);

  const handleDrop = (acceptedFiles: File[]) => {
    setFileName(acceptedFiles.map((file) => file.name));
    if (onChange) onChange(acceptedFiles);
  };

  const deleteFile = (e: React.MouseEvent) => {
    e.preventDefault();
    setFileName([]);
  };

  return (
    <Dropzone
      onDrop={handleDrop}
      onDragEnter={() => setDragOver(true)}
      onDragLeave={() => setDragOver(false)}
      maxFiles={maxFiles}
      multiple={multiple}
      accept={accept}
      maxSize={sizeConversion}
      disabled={disabled}
    >
      {({ getRootProps, getInputProps }) => (
        <section className="h-full w-full">
          <div
            {...getRootProps()}
            className={`border-neutral-muted bg-neutral-emphasis hover:border-accent-muted flex h-52 w-full cursor-pointer flex-col items-center justify-center gap-3 rounded-md border-2 border-dashed transition-colors duration-300 ${
              dragOver ? 'border-accent-emphasis!' : ''
            } ${
              disabled ? 'bg-neutral-emphasis hover:border-neutral-muted cursor-not-allowed' : ''
            } ${className}`}
          >
            <IconFileAddLine className="mb-4 text-3xl" />
            <input {...getInputProps()} />
            {fileName.length === 0 && <p className="text-xs">{placeholder}</p>}
            {fileName.length > 0 && (
              <div className="flex gap-2">
                <p className="text-xs">{fileName.join(', ')}</p>
                <Button
                  color="default"
                  type="button"
                  className="transition-color flex h-4 w-4 items-center justify-center rounded-full duration-100"
                  onClick={deleteFile}
                >
                  <IconCloseLine className="text-[8px]" />
                </Button>
              </div>
            )}
          </div>
        </section>
      )}
    </Dropzone>
  );
};

export default FileDrop;
