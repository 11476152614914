import classNames from 'classnames';
import { PropsWithChildren } from 'react';

type HeaderContainerProps = PropsWithChildren<{
  onClick?: () => void;
  className?: string;
}>;

export function HeaderContainer(props: HeaderContainerProps) {
  const { onClick, className, children } = props;

  return (
    <div
      className={classNames(
        'relative h-20 w-full shrink-0 items-center overflow-hidden',
        className,
      )}
      onClick={onClick}
    >
      {children}
    </div>
  );
}
