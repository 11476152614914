import { useTranslation } from 'react-i18next';
import { InputPin } from '~/components/ui/forms/input-pin/input-pin';
import { motion } from 'framer-motion';
import Button from '~/components/ui/control/button/button';

type OTPVerificationFormProps = {
  onOtpSubmit: (otp: string) => void;
  onCancel: () => void;
  isPending: boolean;
  error?: string;
};

export function OTPVerificationForm(props: OTPVerificationFormProps) {
  const { onOtpSubmit, onCancel, isPending, error } = props;
  const { t } = useTranslation('login');

  return (
    <div className="flex w-full flex-col items-center justify-center gap-4">
      <div className="flex h-44 w-full items-center justify-center">
        <div className="mx-auto w-fit">
          <motion.div
            className={'flex w-full flex-col items-center justify-center'}
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -100 }}
            transition={{ duration: 0.1, ease: 'easeOut' }}
          >
            <div className="text-muted mb-2 flex flex-col gap-2 text-xs">
              <span>{t('form.verify-otp.description-1')}</span>
              <span>{t('form.verify-otp.description-2')}</span>
            </div>
            <InputPin label={t('form.pin.label')} onValueComplete={onOtpSubmit} autoFocus={true} />
            {error ? <span className="text-danger mt-4 text-xs">{error}</span> : null}
          </motion.div>
        </div>
      </div>
      <div className="mx-auto flex w-[70%] flex-col items-center gap-3">
        <Button
          color="default"
          className="flex w-full items-center justify-center"
          onClick={onCancel}
          isLoading={isPending}
        >
          {t('form.verify-otp.cancel')}
        </Button>
      </div>
    </div>
  );
}
