import Pubnub from 'pubnub';
import { useEffect, useMemo } from 'react';
import { useRealtime } from '~/hooks/realtime/use-realtime';

export function useRealtimeListener(
  data: () => { channels: string[]; listener: Pubnub.ListenerParameters },
  deps: unknown[],
) {
  const realtime = useRealtime();

  const { channels, listener } = useMemo(data, deps);

  useEffect(() => {
    if (realtime.ready && channels.length) {
      realtime.pubnub.subscribe({ channels });
      realtime.pubnub.addListener(listener);

      return () => {
        realtime.pubnub.unsubscribe({ channels });
        realtime.pubnub.removeListener(listener);
      };
    }

    return () => {};
  }, [channels, listener, realtime.ready]);
}
