import { useParams } from 'react-router-dom';
import { useGetPlaylistDetails } from '~/hooks/api/playlist';
import { PlaylistTrackItemList } from '~/components/entities/track/track-item-list/playlist/playlist-track-item-list';
import PlaylistHeader from './components/playlist-header';

export function Playlist() {
  const { id } = useParams<{ id: string }>();
  const { data, isLoading } = useGetPlaylistDetails(id);
  const isReady = id && data && !isLoading;

  const scrollId = `playlist-content-${id}`;

  return (
    isReady && (
      <section className="relative size-full self-stretch overflow-hidden">
        <div className="flex h-full">
          <div className="flex-1 pt-4">
            <div
              id={scrollId}
              className="flex h-full w-full flex-col items-stretch overflow-y-auto"
            >
              <PlaylistHeader detail={data} />
              <div className="h-full">
                <div className="z-0 flex h-fit min-h-full flex-col px-4">
                  <PlaylistTrackItemList playlist={data} scrollParentId={scrollId} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  );
}

export default Playlist;
