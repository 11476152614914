import { useTranslation } from 'react-i18next';
import { parseDateNumeric } from '~/utils/common/parse-date';
import { useBriefContext } from '../../brief.context';

export function CreationInfos() {
  const { t } = useTranslation('brief-page');
  const { data } = useBriefContext();
  const { tenant, created_at: createdAt, created_by: createdBy } = data;

  return (
    <h4 className="text-muted text-xs">
      {t('header.creation-info', {
        company: `${tenant?.company_name} (${createdBy?.country || tenant?.country})`,
        user: `${createdBy?.first_name} ${createdBy?.last_name}`,
        date: parseDateNumeric(createdAt || '', {
          year: 'numeric',
          month: 'long',
          day: '2-digit',
          hour: 'numeric',
          minute: 'numeric',
        }),
      })}
    </h4>
  );
}
