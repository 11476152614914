import { Tracklist as TracklistType } from '~/types/schemas/music/tracklist.schema';
import { Tracklist } from './tracklist';

type TracklistGroupProps = {
  tracklists?: TracklistType[];
};

export function TracklistGroup(props: TracklistGroupProps) {
  const { tracklists } = props;

  return (
    <div className="flex h-full w-full flex-col gap-0.5 overflow-y-auto">
      {tracklists?.map((tracklist) => (
        <Tracklist
          key={tracklist.value.id}
          tracklist={tracklist}
          link={`/${tracklist.type}s/${tracklist.value.id}`}
        />
      ))}
    </div>
  );
}
