import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import classNames from 'classnames';
import { forwardRef, ReactNode, Ref } from 'react';
import DropdownItem from './dropdown-item';
import './dropdown.scss';

export type dropdownElement = {
  label: string;
  subtitle?: string;
  href?: string;
  navlink?: string;
  disabled?: boolean;
  onclick?: (() => void) | undefined;
  menu?: {
    title?: string;
    elements: dropdownElement[];
  }[];
};

export type dropdownGroups = {
  title?: string;
  elements: dropdownElement[];
}[];

export const animationClassName: Record<string, string> = {
  top: 'slide-down-fade',
  right: 'slide-left-fade',
  bottom: 'slide-up-fade',
  left: 'slide-right-fade',
};

export const contentClassName = (side: 'top' | 'right' | 'bottom' | 'left', className: string) =>
  `p-1 select-none rounded-md bg-neutral-emphasis border border-neutral-muted text-xs z-100 ${animationClassName[side]} ${className}`;

type DropdownProps = {
  groups: dropdownGroups;
  align?: 'start' | 'center' | 'end';
  side?: 'top' | 'right' | 'bottom' | 'left';
  children: ReactNode;
  disabled?: boolean;
  open: boolean;
  onOpenChange?: (open: boolean) => void;
  width?: string;
  className?: string;
  itemClassName?: string;
  triggerClassname?: string;
  asChild?: boolean;
};
/**
 * @deprecated
 */
const Dropdown = forwardRef((props: DropdownProps, forwardRef: Ref<HTMLButtonElement>) => {
  const {
    groups,
    disabled,
    children,
    triggerClassname,
    asChild,
    align = 'center',
    side = 'bottom',
    open = false,
    width = '192px',
    className = '',
    onOpenChange,
    itemClassName = '',
  } = props;

  return (
    <DropdownMenu.Root open={open} onOpenChange={onOpenChange}>
      <DropdownMenu.Trigger
        className={classNames(triggerClassname, disabled && 'pointer-events-none opacity-50')}
        asChild={asChild}
        ref={forwardRef}
      >
        {children}
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content
          className={contentClassName(side, className)}
          style={{ width }}
          align={align}
          side={side}
          sideOffset={5}
        >
          {groups.map((group, groupIndex) => (
            <div key={`group-${groupIndex}`}>
              <DropdownMenu.Group>
                {group.title && (
                  <DropdownMenu.Label className="text-muted p-1">{group.title}</DropdownMenu.Label>
                )}
                {group.elements.map((item, itemIndex) => (
                  <DropdownItem
                    key={`group-${groupIndex}-item-${itemIndex}`}
                    width={width}
                    className={itemClassName}
                    menuClassName={contentClassName(side, className)}
                    {...item}
                  />
                ))}
              </DropdownMenu.Group>
              {groupIndex < groups.length - 1 && (
                <DropdownMenu.Separator className="bg-neutral-muted my-1 h-px w-full" />
              )}
            </div>
          ))}
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
});

export default Dropdown;
