import { useCardClick } from '~/components/features/presearch/hook/use-card-click';
import { useEffect, useState } from 'react';
import { useAllTabData } from '~/components/features/presearch/data/all-tab.data';
import { useTranslation } from 'react-i18next';
import { useGetProviders } from '~/hooks/api/dashboard';
import CardShelf from '../../../card-shelf/card-shelf';

function CatalogResultBlock() {
  const { catalogs, isLoading } = useAllTabData();
  const [currentCatalogs, setCurrentCatalogs] = useState(catalogs);
  const { data: providers } = useGetProviders();
  const { handleFocusClick, handleClick } = useCardClick('catalog');
  const { t } = useTranslation('pre-search');

  useEffect(() => {
    if (!isLoading) {
      setCurrentCatalogs(catalogs);
    }
  }, [isLoading, JSON.stringify(catalogs)]);

  return currentCatalogs?.length ? (
    <CardShelf
      label={t('tab.all.catalogs.title')}
      containerClassName="flex-1 col-span-full"
      rowLimit={1}
      cardsProps={currentCatalogs?.map((catalog) => {
        const providerType = providers
          ?.find((provider) => provider.id === catalog.tenant.id)
          ?.organization_settings.meta.provider_types?.map((type) => {
            if (type.includes('core')) return t('music-type.core');
            if (type.includes('production')) return t('music-type.prod');
            return undefined;
          });
        return {
          type: 'album',
          artworks: catalog.artwork ? [catalog.artwork] : undefined,
          title: catalog.name,
          chips: providerType
            ?.filter((item, index) => item && providerType.indexOf(item) === index)
            .sort() as string[],
          onCardClick: () => handleFocusClick(catalog.name, catalog.id),
          onIncludeClick: () => handleClick(catalog.name, catalog.id, 'is'),
          onExcludeClick: () => handleClick(catalog.name, catalog.id, 'is_not'),
        };
      })}
    />
  ) : null;
}

export default CatalogResultBlock;
