import { useState } from 'react';
import Dropdown, { dropdownGroups } from '~/components/ui/control/dropdown/dropdown';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDuplicatePlaylist } from '~/hooks/api/playlist';
import { useDownloadTracklistCopyright } from '~/hooks/api/track-list';
import { Icon } from '@iconify/react';
import Button from '~/components/ui/control/button/button';

type MoreActionButtonProps = {
  id: string;
  isEmpty?: boolean;
};

const MoreActionButton = (props: MoreActionButtonProps) => {
  const { id, isEmpty } = props;
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const navigate = useNavigate();
  const { mutate: getCopyright } = useDownloadTracklistCopyright();
  const { mutate: duplicatePlaylist } = useDuplicatePlaylist(id);
  const { t } = useTranslation('playlist-page');

  const groups: dropdownGroups = [
    {
      title: t('header.interactions.button.more.dropdown.title'),
      elements: [
        {
          label: t('header.interactions.button.more.dropdown.option.copyrights.label'),
          disabled: isEmpty,
          onclick: () => {
            getCopyright({ id, type: 'playlist' });
            setIsDropDownOpen(false);
          },
        },
        {
          label: t('header.interactions.button.more.dropdown.option.duplicate.label'),
          onclick: () => {
            duplicatePlaylist(undefined, {
              onSuccess: (data) => {
                navigate(`/playlists/${data?.id}`);
              },
            });
            setIsDropDownOpen(false);
          },
        },
      ],
    },
  ];
  return (
    <Dropdown
      groups={groups}
      open={isDropDownOpen}
      onOpenChange={setIsDropDownOpen}
      itemClassName="outline-hidden"
      triggerClassname="w-fit"
      align="start"
    >
      <Button color="default">
        <Icon icon="ri:more-line" />
      </Button>
    </Dropdown>
  );
};

export default MoreActionButton;
