interface ArtworkProps {
  artworks: string[];
  alt?: string;
}

function Mosaic(props: ArtworkProps) {
  const { artworks, alt } = props;
  return (
    <figure aria-labelledby="mosaic-description" className="flex flex-wrap">
      {artworks.map((artwork, index) => (
        <img
          key={`${alt}-artwork-${index}`}
          src={artwork}
          loading="lazy"
          alt=""
          aria-hidden="true"
          className="aspect-square w-1/2"
        />
      ))}
      <figcaption id="mosaic-description" className="hidden" aria-label={alt}>
        {alt}
      </figcaption>
    </figure>
  );
}

export default Mosaic;
