import classNames from 'classnames';
import { MouseEvent, useEffect, useState } from 'react';
import Checkbox, { CheckedState } from '~/components/ui/forms/checkbox/checkbox';
import Button from '~/components/ui/control/button/button';
import Icon from '~/components/ui/icon/icon';
import { useTranslation } from 'react-i18next';

type GroupProps = {
  title?: string;
  subTitle?: string;
  checked?: CheckedState;
  disabled?: boolean;
  isFavoriteCheck?: boolean;
  className?: string;
  onEditClick?: () => void;
  onCheckClick?: (b: CheckedState) => void;
  onFavoriteClick?: () => void;
};

function Group(props: GroupProps) {
  const { t } = useTranslation('brief-form');
  const {
    title = 'Omnis molestias qui',
    subTitle = 'Lorem ipsum',
    checked = false,
    disabled = false,
    isFavoriteCheck = false,
    className,
    onEditClick,
    onCheckClick,
    onFavoriteClick,
  } = props;
  const [isChecked, setIsChecked] = useState(checked);

  function handleEditClick(e: MouseEvent) {
    e.stopPropagation();
    onEditClick?.();
  }

  function handleClick(e: MouseEvent) {
    e.stopPropagation();
    setIsChecked?.(isChecked === 'indeterminate' ? false : !isChecked);
  }

  function handleFavClick(e: MouseEvent) {
    e.stopPropagation();
    onFavoriteClick?.();
  }

  function handleChange(s: CheckedState) {
    setIsChecked(s);
  }

  useEffect(() => {
    if (isChecked !== checked) onCheckClick?.(isChecked === 'indeterminate' ? false : !isChecked);
  }, [isChecked]);

  useEffect(() => {
    if (checked !== isChecked) {
      setIsChecked(checked);
    }
  }, [checked]);

  return (
    <div
      className={classNames(
        'group bg-neutral-emphasis border-neutral-emphasis hover:border-neutral-muted flex w-full cursor-pointer items-center justify-between rounded-sm border px-3 py-2 transition-all select-none',
        className,
        {
          'border-accent-emphasis! border': isChecked,
          'pointer-events-none opacity-50!': disabled,
        },
      )}
      onClick={handleClick}
    >
      <div className="group__left flex flex-2 gap-4">
        <Checkbox checked={isChecked} onChange={handleChange} />
        <div className="flex flex-col">
          <h6 className="text-default text-sm">{title}</h6>
          <p className="text-muted text-xs">{subTitle}</p>
        </div>
      </div>
      {isChecked ? (
        <Button color={isChecked ? 'primary' : 'default'} onClick={handleFavClick}>
          {isFavoriteCheck ? (
            <Icon name="heart-fill" className="text-danger" />
          ) : (
            <Icon name="heart-line" className="text-muted" />
          )}
          {t('step-three.providers-group.favorites')}
        </Button>
      ) : (
        <div />
      )}
      <div className="group__right flex flex-1 flex-col items-end">
        <Button
          color="default"
          className={classNames('group__right__edit')}
          onClick={handleEditClick}
        >
          <Icon name="pencil-line" className="text-muted hover:text-default" />
        </Button>
      </div>
    </div>
  );
}

export default Group;
