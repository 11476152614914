import { components, MultiValueGenericProps } from 'react-select';
const MultiValueContainer = (props: MultiValueGenericProps) => {
  const { children } = props;

  return (
    <components.MultiValueContainer {...props}>
      <div className="text-neutral bg-neutral-muted flex h-5 w-fit shrink-0 items-center gap-1 rounded-sm pr-1 pl-2 text-sm">
        {children}
      </div>
    </components.MultiValueContainer>
  );
};

export default MultiValueContainer;
