import classNames from 'classnames';
import { MouseEvent } from 'react';
import { ChipButton } from '~/components/ui/control/chip-button/chip-button';
import Chip from '~/components/ui/control/chip/chip';

type TagChipProps = {
  label: string;
  color?: string;
  relevant?: boolean;
  onClick?: () => void;
  onAddClick?: () => void;
  onSubtractClick?: () => void;
};

export function TagChip(props: TagChipProps) {
  const { label, color, relevant, onClick, onAddClick, onSubtractClick } = props;

  function handleClick(e: MouseEvent) {
    e.stopPropagation();
    onClick?.();
  }

  function handleAddClick(e: MouseEvent) {
    e.stopPropagation();
    onAddClick?.();
  }

  function handleSubtractClick(e: MouseEvent) {
    e.stopPropagation();
    onSubtractClick?.();
  }

  return (
    <Chip
      color={color}
      className={classNames(
        'bg-neutral-muted hover:bg-muted hover:border-neutral-muted flex w-fit justify-center border border-transparent p-1! transition',
      )}
      onClick={handleClick}
    >
      <ChipButton icon="ri:subtract-line" onClick={handleSubtractClick} />
      <span>{label}</span>
      <ChipButton
        icon="ri:add-line"
        onClick={handleAddClick}
        className={classNames({
          'opacity-10!': !relevant,
        })}
      />
    </Chip>
  );
}
