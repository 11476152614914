import { useSearchStore } from '~/stores/search-store';
import dayjs from 'dayjs';

import RangeSlider from '~/components/ui/control/slider/range.slider';
import { useEffect, useState } from 'react';
import FilterDropDown from './filter-dropdown';
import { useTranslation } from 'react-i18next';
import Toggle from '~/components/ui/control/switch/toggle';

const ReleaseDate = () => {
  const search = useSearchStore(['releaseDate', 'setReleaseDate', 'resetReleaseDate']);
  const today = dayjs().endOf('day').year();
  const { t } = useTranslation(['filter']);
  const from = search.releaseDate.from ? dayjs(search.releaseDate?.from).year() : undefined;
  const to = search.releaseDate.to
    ? dayjs(search.releaseDate?.to).year() > today
      ? today
      : dayjs(search.releaseDate?.to).year()
    : undefined;

  const [currentValue, setCurrentValue] = useState<[number | undefined, number | undefined]>([
    from,
    to,
  ]);
  const [isPreReleaseDisplayed, setIsPreReleaseDisplayed] = useState<boolean>(
    dayjs(search.releaseDate?.to).year() === 2099,
  );

  useEffect(() => {
    setCurrentValue([from, to]);
  }, [from, to]);

  useEffect(() => {
    setIsPreReleaseDisplayed(dayjs(search.releaseDate?.to).year() === 2099);
  }, [search.releaseDate.to]);

  const handleCommit = (value: number[]) => {
    search.setReleaseDate(value[0]!, value[1]!);
  };

  const handleChange = (value: number[]) => {
    if (value[1] !== today) setIsPreReleaseDisplayed(false);
    setCurrentValue(value as [number, number]);
  };

  const handleDelete = () => {
    search.resetReleaseDate();
    setCurrentValue([undefined, undefined]);
  };

  const handleCheck = () => {
    setIsPreReleaseDisplayed(!isPreReleaseDisplayed);
    if (currentValue[0] && currentValue[1]) {
      if (!isPreReleaseDisplayed) {
        search.setReleaseDate(currentValue[0], 2099);
        setCurrentValue([currentValue[0], today]);
      } else {
        search.setReleaseDate(currentValue[0], currentValue[1]);
        setCurrentValue([currentValue[0], currentValue[1]]);
      }
    }
  };

  const isActive =
    currentValue[0] !== undefined && (currentValue[1] !== undefined || isPreReleaseDisplayed);

  return isActive ? (
    <FilterDropDown
      label={t('release-date.title')}
      isActive={isActive}
      triggerContent={
        <span className="text-xs">
          {`${currentValue![0]} - ${isPreReleaseDisplayed ? t('release-date.value.upcoming') : currentValue![1] === today ? t('release-date.value.today') : currentValue![1]}`}
        </span>
      }
      className="w-fit min-w-40"
      onDelete={handleDelete}
    >
      <div className="flex flex-col gap-3 px-1 py-2">
        <RangeSlider
          value={currentValue as [number, number]}
          min={1930}
          max={2023}
          aria-label="release-date-slider"
          onValueChange={handleChange}
          onSlideEnd={handleCommit}
          className="w-64"
        />
        <label className="flex items-center gap-3">
          <Toggle checked={isPreReleaseDisplayed} onChange={handleCheck} />
          <span className="text-muted text-xs">{t('release-date.option.upcoming')}</span>
        </label>
      </div>
    </FilterDropDown>
  ) : null;
};

export default ReleaseDate;
