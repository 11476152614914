import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Button from '~/components/ui/control/button/button';
import { parseThousands } from '~/utils/common/parse-thousands';

type LibraryHeaderProps = {
  total?: number;
  onCreateClick?: () => void;
  className?: string;
};

const PlaylistLibraryHeader = (props: LibraryHeaderProps) => {
  const { total, onCreateClick, className } = props;
  const { t } = useTranslation('libraries');

  return (
    <div className={classNames('flex w-full items-center justify-between', className)}>
      <div className="bg-default flex h-12 w-full items-center gap-4">
        <h1 className="text-xl font-bold">{t('playlists.title')}</h1>
        {total ? (
          <div className="border-x-accent-emphasis text-default flex h-4 items-center border-l pl-2 text-xs">
            <span>
              {t('results.results-number', {
                ns: 'search',
                number: parseThousands(total.toString(), ' '),
              })}
            </span>
          </div>
        ) : null}
      </div>
      <Button color="default" iconLeft="add-line" onClick={onCreateClick}>
        {t('playlists.create')}
      </Button>
    </div>
  );
};

export default PlaylistLibraryHeader;
