import { useTracksUploadContext } from '~/components/entities/upload/tracks/tracks-upload.context';
import Button from '~/components/ui/control/button/button';
import { useTracksUploadItemContext } from '../../../tracks-upload-item.context';
import { useTranslation } from 'react-i18next';

export function CancelListButton() {
  const { store } = useTracksUploadContext();
  const { data } = useTracksUploadItemContext();
  const { t } = useTranslation('common');

  const removeFromList = store.use.removeFromList();

  function handleRemoveFromList() {
    removeFromList(data.id);
  }

  return (
    <Button color="default" onClick={handleRemoveFromList}>
      {t('cancel')}
    </Button>
  );
}
