import classNames from 'classnames';
import { BriefFormUpdate, ProviderGroup } from '~/types/schemas/music/brief.schema';
import { Control, Controller } from 'react-hook-form';
import { Agent as ProviderType } from '~/types/schemas/common/agent.schema';
import Button from '~/components/ui/control/button/button';
import { useTranslation } from 'react-i18next';
import Input from '~/components/ui/forms/input/input';
import Icon from '~/components/ui/icon/icon';
import Checkbox from '~/components/ui/forms/checkbox/checkbox';
import Provider from './provider';
import { useProviderList } from '../../hooks/use-provider-list';
import Select, { OptionType } from '~/components/ui/forms/select/select';

type ProviderListProps = {
  providers?: ProviderType[];
  defaultProvidersValue: ProviderGroup[];
  groupTitle?: string;
  groupIndex: number;
  control: Control<BriefFormUpdate>;
  onBack?: () => void;
};

function ProviderList(props: ProviderListProps) {
  const { t } = useTranslation('brief-form');
  const {
    providers: defaultProviders = [],
    defaultProvidersValue,
    groupIndex,
    groupTitle,
    control,
    onBack,
  } = props;
  const {
    providers,
    agentTypes,
    agentsTypesOptions,
    favoriteAgents,
    isFavoriteSelected,
    toggleFavorites,
    setCategories,
    handleCheckClick,
    handleMainCheckClick,
    handleFavoriteClick,
    setQuery: searchByQuery,
  } = useProviderList(defaultProviders);

  function handleProviderTypeChange(options: OptionType[]) {
    setCategories(options);
  }

  return (
    <div className="form-content flex h-full flex-col items-stretch gap-4">
      <div className="mb-3 flex flex-col gap-3">
        <div className="flex h-9 gap-3">
          <Button color="default" iconLeft="arrow-left-line" onClick={onBack}>
            {t('step-three.providers-group.edit.back-button.label')}
          </Button>
          <Input
            type="text"
            placeholder={t('step-three.providers-group.edit.input.placeholder', {
              type: groupTitle?.toLocaleLowerCase(),
            })}
            iconLeft="search-line"
            className="flex-1"
            onChange={(value) => searchByQuery(value)}
          />
        </div>
        <div className="flex gap-3">
          <div className="bg-neutral-emphasis hover:border-neutral-muted flex size-8 items-center justify-center rounded-lg border border-transparent">
            <Controller
              name="agents"
              control={control}
              defaultValue={defaultProvidersValue || []}
              render={({ field: { value: groups, onChange } }) => {
                const selectedProviders = groups?.[groupIndex]?.providers || [];
                const isSomeChecked = providers.some((provider) =>
                  selectedProviders?.includes(provider.id),
                );
                const isAllChecked = providers.every((provider) =>
                  selectedProviders?.includes(provider.id),
                );
                return (
                  <Checkbox
                    checked={isSomeChecked ? (isAllChecked ? true : 'indeterminate') : false}
                    onChange={() => handleMainCheckClick(onChange, groups || [], groupIndex)}
                  />
                );
              }}
            />
          </div>
          <Select
            placeholder={t('step-three.providers-group.edit.filter.provider-type.placeholder')}
            options={agentsTypesOptions}
            value={agentTypes}
            onMultiValueChange={handleProviderTypeChange}
            isMulti
          />
          <Button color="default" onClick={toggleFavorites}>
            {t('step-three.providers-group.edit.filter.favorites')}
            <Icon
              name={`heart-${isFavoriteSelected ? 'fill' : 'line'}`}
              className={classNames('transition-colors', {
                'text-danger': isFavoriteSelected,
                'text-muted': !isFavoriteSelected,
              })}
            />
          </Button>
        </div>
      </div>
      <div className="hide-scrollbar z-0 flex h-full flex-col gap-3 overflow-y-auto pb-16">
        {providers?.map((provider) => (
          <Controller
            key={provider.id}
            name="agents"
            control={control}
            defaultValue={defaultProvidersValue}
            render={({ field: { value: groups, onChange } }) => {
              const groupSelectedProviders = groups?.[groupIndex]?.providers || [];

              return (
                <Provider
                  key={provider.id}
                  className={classNames('form-content__row')}
                  title={provider.company_name}
                  categories={provider.organization_settings.meta.provider_types
                    .filter(
                      (type) => type === 'independent' || type === 'major' || type === 'production',
                    )
                    .map((item) => {
                      switch (item) {
                        case 'independent':
                          return t(
                            'step-three.providers-group.edit.filter.provider-type.independent',
                          );
                        case 'major':
                          return t('step-three.providers-group.edit.filter.provider-type.major');
                        case 'production':
                          return t(
                            'step-three.providers-group.edit.filter.provider-type.production',
                          );
                        default:
                          return t('step-three.providers-group.edit.filter.provider-type.unknown');
                      }
                    })
                    .join(', ')}
                  checked={groupSelectedProviders?.includes(provider.id)}
                  isFavorite={favoriteAgents?.includes(provider.id)}
                  onCheckClick={() =>
                    handleCheckClick(provider.id, onChange, groups || [], groupIndex)
                  }
                  onFavClick={() => handleFavoriteClick(provider.id)}
                />
              );
            }}
          />
        ))}
      </div>
    </div>
  );
}

export default ProviderList;
