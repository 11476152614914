/* eslint-disable indent */
import usePreSearchStore from '~/stores/presearch-store';
import { useSearchStore, termOptions, itemConditionsOptions } from '~/stores/search-store';
import {
  PopulatedTagSubCategory,
  PopulatedTagCategory,
  Tag,
} from '~/types/schemas/data/tags.schema';
import { stringContainsTerm } from '~/utils/common/string-contains-term';
import removeAccents from 'remove-accents';
import { useTagFilter } from '../filter/use-tag-filter';
import search from '~/pages/search/search';
import { tagSuffix } from '~/utils/tags/tag-suffix';

export function useSearchTagsAccordion(matchedCategoriesIds?: string[]) {
  const presearch = usePreSearchStore(['query', 'setIsOpen']);
  const { onTagClick } = useTagFilter();

  function handleClick({
    id,
    name,
    type,
    condition,
    reset = false,
  }: {
    id: string;
    name: string;
    type: 'tags' | 'tag_categories';
    condition: keyof typeof itemConditionsOptions;
    reset?: boolean;
  }) {
    onTagClick(id, name, type, condition, reset);
    presearch.setIsOpen(false);
  }

  function subCategoryDefaultOpenState(subCategory?: PopulatedTagSubCategory) {
    const someTagsAreInFacets = subCategory?.tags?.some((tag) => tag.relevant);

    return !!presearch.query && !!someTagsAreInFacets;
  }

  function categoryDefaultOpenState(category?: PopulatedTagCategory) {
    const someSubCategoryAreOpenedByDefault = category?.subCategories?.some((subCategory) => {
      const isSubCategoryHadRelevantTags = subCategory.tags?.some((tag) => tag.relevant);
      const isSubCategoryFoundInQuery = matchedCategoriesIds?.includes(subCategory.id);

      return isSubCategoryHadRelevantTags || isSubCategoryFoundInQuery;
    });
    const someTagsAreInFacets = category?.tags?.some((tag) => tag.relevant);

    return !!presearch.query && (!!someSubCategoryAreOpenedByDefault || !!someTagsAreInFacets);
  }

  function handleTagSuffix(tag: Tag) {
    return tagSuffix(presearch.query, tag);
  }

  return {
    handleClick,
    subCategoryDefaultOpenState,
    categoryDefaultOpenState,
  };
}
