import {
  FocusEvent,
  MouseEvent,
  MutableRefObject,
  Ref,
  createRef,
  forwardRef,
  useState,
} from 'react';
import classNames from 'classnames';
import { TextInput } from './components/text';
import { FileInput } from './components/file';
import { DateInput } from './components/date';
import { TimeInput } from './components/time';
import { TagInput } from './components/tag';
import { NumberInput } from './components/number';
import { PasswordInput } from './components/password';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface InputProps<T = any> {
  value?: string;
  defaultValue?: string;
  label?: string;
  labelInfo?: string;
  error?: string;
  iconLeft?: string;
  iconRight?: string;
  className?: string;
  containerClassName?: string;
  labelClassName?: string;
  info?: string;
  placeholder?: string;
  autofocus?: boolean;
  type?: 'text' | 'date' | 'email' | 'password' | 'time' | 'file' | 'tags' | 'number';
  separator?: string;
  options?: T[];
  accept?: string;
  isLoading?: boolean;
  isFocused?: boolean;
  success?: boolean;
  required?: boolean;
  disabled?: boolean;
  render?: (props: T) => JSX.Element;
  onChange?: (value: string) => void;
  onNumberChange?: (value: number) => void;
  onOptionClick?: (e: MouseEvent, item: T) => void;
  onFilesChange?: (files: File[]) => void;
}

const Input = forwardRef((props: InputProps, ref: Ref<HTMLInputElement>) => {
  const {
    type = 'text',
    label,
    className,
    containerClassName,
    labelClassName,
    error,
    defaultValue = '',
    separator,
    value,
    onChange,
    onNumberChange,
    ...inputProps
  } = props;

  const [isFocused, setIsFocused] = useState(false);
  const inputRef = ref || createRef<HTMLInputElement>();

  const boxClassName = classNames('w-full', className);

  const wrapperClass = classNames('flex flex-col gap-2');

  const containerClass = classNames(
    'w-full h-8  px-2 flex items-center gap-2 rounded-lg border border-control-rest bg-control-rest hover:border-control-hover hover:text-control-hover',
    containerClassName,
    {
      'border-control-danger-emphasis!': !!error,
      'border-control-disabled! bg-control-disabled!': inputProps.disabled,
      'outline outline-focus focus:outline-1 outline-offset-2': isFocused,
    },
  );

  const inputClass = classNames(
    'h-full relative flex-1 text-sm outline-hidden bg-transparent text-control-rest placeholder:text-control-placeholder',
  );

  const labelClass = classNames(
    'text-control-neutral text-xs text-control-neutral',
    {
      'text-control-danger': error !== undefined,
    },
    labelClassName,
  );

  function handleChange(v: string) {
    onChange?.(v);
  }

  function handleNumberChange(v: number) {
    onNumberChange?.(v);
  }

  function handleFocus(e: FocusEvent) {
    e.stopPropagation();
    setIsFocused(true);
    (inputRef as MutableRefObject<HTMLInputElement>).current?.focus();
  }

  function handleBlur(e: FocusEvent) {
    e.stopPropagation();
    setIsFocused(false);
    (inputRef as MutableRefObject<HTMLInputElement>).current?.blur();
  }

  const commonProps = {
    ref: inputRef,
    type,
    label,
    value,
    defaultValue,
    isFocused,
    className: boxClassName,
    containerClass,
    wrapperClass,
    labelClass,
    inputClass,
    separator,
    error,
    onFocus: handleFocus,
    onBlur: handleBlur,
    onChange: handleChange,
    onNumberChange: handleNumberChange,
    ...inputProps,
  };

  if (type === 'tags') {
    return <TagInput {...commonProps} />;
  }

  if (type === 'file') {
    return <FileInput {...commonProps} />;
  }

  if (type === 'date') {
    return <DateInput {...commonProps} />;
  }

  if (type === 'time') {
    return <TimeInput {...commonProps} />;
  }

  if (type === 'number') {
    return <NumberInput {...commonProps} />;
  }

  if (type === 'password') {
    return <PasswordInput {...commonProps} />;
  }

  return <TextInput {...commonProps} />;
});

export default Input;
