import classNames from 'classnames';

type TitleProps = {
  title: string;
  isActive?: boolean;
};

export function Title(props: TitleProps) {
  const { title, isActive } = props;

  return (
    <h6
      className={classNames(
        'text-default line-clamp-1 shrink-0 text-left text-sm leading-none font-medium select-none',
        {
          'text-accent!': isActive,
        },
      )}
    >
      {title}
    </h6>
  );
}
