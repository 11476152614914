import { TranslatedFilterSchema } from '~/types/schemas/data/translated-filter.schema';
import { FilterBlock } from '../utils/parse-filters';
import FilterSection from './filter-section';
import translatedFiltersRaw from '~/data/filters.json';
import { FilterKeys } from '~/types/schemas/search/filter.schema';
import { SupportedLanguages } from '~/types/schemas/data/translate.schema';
import i18n from '~/i18n/config';
import FilterChip from './filter-chip';
import { useTranslation } from 'react-i18next';
import useDataStore from '~/stores/mewo-data-store';

const translatedFilters = TranslatedFilterSchema.parse(translatedFiltersRaw);

type TagsProps = {
  block: FilterBlock;
};

export function Tags(props: TagsProps) {
  const { block } = props;
  const { t } = useTranslation('filter');
  const { tags, categories, subCategories } = useDataStore(['tags', 'categories', 'subCategories']);

  return (
    <FilterSection
      key={block.id}
      label={
        translatedFilters[i18n.language as SupportedLanguages]?.[block?.term.value as FilterKeys] ||
        ''
      }
      isActive
    >
      {Object.values(block.filters).map((filter, i) => {
        const label =
          tags.object[filter.value.value as string]?.name ||
          categories.object[filter.value.value as string]?.name ||
          subCategories.object[filter.value.value as string]?.name ||
          filter.value.label;

        const translatedFilter = {
          ...filter,
          value: {
            ...filter.value,
            label,
          },
        };

        return (
          <div key={`block-${filter.id}-${i}`}>
            <FilterChip block={block} filter={translatedFilter} />
            {Object.values(block.filters).length - 1 > i ? (
              <span className="text-muted ml-2 text-xs font-bold">{t('filter.operator.and')}</span>
            ) : null}
          </div>
        );
      })}
    </FilterSection>
  );
}
