/* eslint-disable indent */
import {
  PopulatedTagCategory,
  PopulatedTagSubCategory,
  Tag,
} from '~/types/schemas/data/tags.schema';

function filterTags(tagsIds?: string[], relevantTags?: string[], tags?: Tag[]) {
  const filteredTags = tags?.filter((tag) => tagsIds?.includes(tag.id));

  return filteredTags?.map((tag) => ({
    ...tag,
    relevant: relevantTags?.includes(tag.id),
  }));
}

function filterSubCategoryTags(
  tagsIds?: string[],
  categoriesIds?: string[],
  relevantTags?: string[],
  subCategory?: PopulatedTagSubCategory,
) {
  const tags = filterTags(tagsIds, relevantTags, subCategory?.tags)?.filter(Boolean);

  if (!subCategory) return undefined;
  if (!tags?.length && !categoriesIds?.includes(subCategory?.id)) return undefined;

  return {
    ...subCategory,
    tags,
  };
}

export function filterAndMarkRelevantPopulatedTagByTagIds({
  tagIds,
  categoriesIds,
  relevantTags,
  populatedTagCategories,
}: {
  tagIds?: string[];
  categoriesIds?: string[];
  relevantTags?: string[];
  populatedTagCategories?: PopulatedTagCategory[];
}) {
  return populatedTagCategories
    ?.map((category) => {
      const subCategories = category.subCategories
        ?.map((subCategory) =>
          filterSubCategoryTags(tagIds, categoriesIds, relevantTags, subCategory),
        )
        .filter(Boolean);
      const tags = filterTags(tagIds, relevantTags, category.tags)?.filter(Boolean);

      if (!tags?.length && !subCategories?.length) return undefined;

      return {
        ...category,
        tags,
        subCategories,
      };
    })
    .filter(Boolean);
}
