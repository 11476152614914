import { z } from 'zod';
import { TenantSchema } from './tenant.schema';

import { ArtworkSchema } from '../common/image.schema';
import { createSearchResultSchema } from '../search/search.schema';
import { DisplayArtist } from './artist.schema';
import { UserWithOrgSchema } from '../common/user.schema';

export const OwnerSchema = z.object({
  id: z.string(),
  name: z.string(),
  rights_type: z.string(),
  alias: z.string().optional(),
});

export const OwnerShipsSchema = z.object({
  master: z.object({
    artists: OwnerSchema.array(),
    labels: OwnerSchema.array(),
  }),
  publishing: z.object({
    artists: OwnerSchema.array(),
    publishers: OwnerSchema.array(),
  }),
});

export const TrackSimplifiedSchema = z.object({
  artwork: ArtworkSchema.optional(),
  display_title: z.string().nullable().optional(),
  duration: z.number().optional(),
  id: z.string(),
  public: z.boolean(),
  ref_id: z.string(),
  tenant: TenantSchema.optional(),
  title: z.string(),
  track_number: z.number().nullable().optional(),
  variation_ready: z.boolean().optional(),
});

export const TrackSchema = z.object({
  album: z
    .object({
      id: z.string(),
      name: z.string(),
      display_artists: z
        .object({
          id: z.string(),
          name: z.string().optional(),
          alias: z.string().optional(),
        })
        .array()
        .optional()
        .nullable(),
    })
    .optional(),
  artwork: ArtworkSchema.optional(),
  audiofile: z.string().optional(),
  audiofile_error: z.boolean().optional(),
  bpm: z.number().optional(),
  catalog: z
    .object({
      id: z.string(),
      name: z.string(),
    })
    .optional(),
  description: z.string().optional(),
  display_artists: DisplayArtist.array(),
  duration: z.number().optional(),
  id: z.string(),
  lyrics: z.string().optional(),
  metadata: z
    .object({
      added_by: z
        .object({
          user: UserWithOrgSchema,
          date: z.string(),
        })
        .optional(),
    })
    .optional(),
  music_type: z.string(),
  ownerships: OwnerShipsSchema,
  popularity: z.number(),
  ref_id: z.string(),
  release_date: z.string().optional(),
  similarity: z.number().optional(),
  stems: z.string().optional(),
  tags: z.string().array(),
  tenant: TenantSchema.optional(),
  title: z.string(),
  tonality_key: z.string().optional(),
  track_number: z.number().optional(),
  variation_ready: z.boolean().optional(),
  version: z.string().optional(),
  versions: z.number(),
  waveform: z.string().optional(),
});

export const FacetRangeSchema = z.object({
  key: z.string(),
  from: z.number(),
  to: z.number().optional(),
  doc_count: z.number(),
});

export const FacetSchema = z.object({
  duration: z.array(FacetRangeSchema).optional(),
  year: z.array(FacetRangeSchema).optional(),
  bpm: z.array(FacetRangeSchema).optional(),
  tags: z.record(z.number()).optional(),
  versions: z.record(z.number()).optional(),
});

export const TrackSearchResultSchema = createSearchResultSchema(TrackSchema).extend({
  facets: FacetSchema.optional(),
});

export const TrackInterestFormSchema = z.object({
  production_type: z.string(),
  production_name: z.string().optional(),
  requested_rights: z.string(),
  licensing_client_name: z.string().optional(),
  licensing_country: z.string().optional(),
  creative_description: z.string().optional(),
  max_length: z.string().optional(),
  video_links: z.string().optional(),
  medias: z.string().optional(),
  duration: z.string().optional(),
  launch_date: z.string().optional(),
  territories: z.string().optional(),
  budget: z.number().optional(),
  currency: z.string().optional(),
});

export type Track = z.infer<typeof TrackSchema>;
export type TrackSimplified = z.infer<typeof TrackSimplifiedSchema>;
export type TrackSearchResult = z.infer<typeof TrackSearchResultSchema>;

export type Facet = z.infer<typeof FacetSchema>;
export type Owner = z.infer<typeof OwnerSchema>;
export type OwnerShips = z.infer<typeof OwnerShipsSchema>;
export type TrackInterestForm = z.infer<typeof TrackInterestFormSchema>;
