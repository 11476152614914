import { useLocation } from 'react-router-dom';
import usePreSearchStore from '~/stores/presearch-store';
import classNames from 'classnames';
import useGlobalFormStore from '~/stores/forms/global-form-store';
import PresearchInput from '../presearch-input';
import HeaderBrief from './brief';
import HideVideo from '../hide-video-button';
import ClosePresearchButton from '../close-presearch';
import UserMenu from '../user-menu/user-menu';
import NotificationPanel from '../notification-panel';

const HeaderContent = () => {
  const location = useLocation();
  const presearch = usePreSearchStore(['isOpen']);
  const globalForm = useGlobalFormStore(['isOpen']);

  return (
    <div
      className={classNames('flex w-full items-center justify-between transition-opacity', {
        'pointer-events-none opacity-0': globalForm.isOpen,
      })}
    >
      {location.pathname === '/search' ? (
        <PresearchInput
          className={classNames(
            'transition-all duration-300',
            presearch.isOpen ? 'w-1/2' : 'w-[34rem]',
          )}
        />
      ) : null}
      {location.pathname.includes('/briefs/') ? <HeaderBrief /> : null}
      {presearch.isOpen && location.pathname === '/search' ? (
        <span className="flex items-center gap-3 text-xs">
          <ClosePresearchButton />
        </span>
      ) : (
        <section className="flex w-full items-center justify-end gap-4">
          <HideVideo />
          <NotificationPanel />
          <UserMenu />
        </section>
      )}
    </div>
  );
};

export default HeaderContent;
