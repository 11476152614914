import { createContext, PropsWithChildren, useContext, useEffect, useMemo, useRef } from 'react';
import { UploadTrack } from '~/types/features/track-upload/track-upload.types';
import { useGetRealtimeData } from '~/hooks/api/dashboard';
import { useRealtime } from '~/hooks/realtime/use-realtime';

import createTrackUploadStore from '~/stores/track-upload-storev2';

type TracksUploadContextType = {
  store: ReturnType<typeof createTrackUploadStore>;
  realtime: ReturnType<typeof useRealtime>;
  errorTracks: string[];
  onExit: () => void;
};

type TracksUploadProviderProps = PropsWithChildren<{
  files: UploadTrack[];
  errorTracks: string[];
  onExit: () => void;
}>;

const TracksUploadContext = createContext<TracksUploadContextType | null>(null);

export function useTracksUploadContext() {
  const context = useContext(TracksUploadContext);

  if (!context) {
    throw new Error('useTracksUploadContext must be used within a TracksUploadContext.Provider');
  }

  if (!context.store) {
    throw new Error(
      'useTracksUploadContext must be used within a TracksUploadContext.Provider with a store',
    );
  }

  return context;
}

export function TracksUploadProvider(props: TracksUploadProviderProps) {
  const { children, files, onExit, errorTracks } = props;
  const storeRef = useRef<ReturnType<typeof createTrackUploadStore>>();
  const realtime = useRealtime();
  const { data } = useGetRealtimeData();

  useEffect(() => {
    if (data) {
      if (realtime.ready) {
        realtime.pubnub.subscribe({ channels: [data.channels.sync_notifications] });
      }
      return () => {
        realtime.pubnub.unsubscribe({ channels: [data.channels.sync_notifications] });
      };
    }
  }, [realtime.pubnub, realtime.ready, data]);

  if (storeRef.current === undefined) {
    storeRef.current = createTrackUploadStore({
      files,
      editing: null,
      selected: null,
    });
  }

  const values = useMemo(
    () => ({
      realtime,
      errorTracks,
      onExit,
    }),
    [realtime, onExit, errorTracks],
  );

  return (
    <TracksUploadContext.Provider
      value={{
        store: storeRef.current,
        ...values,
      }}
    >
      {children}
    </TracksUploadContext.Provider>
  );
}
