import * as Tabs from '@radix-ui/react-tabs';
import useAddFilterStore from '~/stores/add-filter-store';
import Navigation from './components/navigation/navigation';
import Content from './components/content/content';

function FilterPanel() {
  const addFilter = useAddFilterStore(['currentTab', 'setCurrentTab']);

  return (
    <section className="filter-panel animate-slide-in-bottom bg-control-rest border-control-rest z-20 h-80 w-[40rem] overflow-hidden rounded-lg border">
      <Tabs.Root
        className="flex h-full"
        defaultValue="tag"
        value={addFilter.currentTab}
        onValueChange={addFilter.setCurrentTab}
      >
        <Navigation />
        <Content />
      </Tabs.Root>
    </section>
  );
}

export default FilterPanel;
