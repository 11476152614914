import { createWithEqualityFn } from 'zustand/traditional';
import { Track } from '~/types/schemas/music/track.schema';
import { createStorePicker } from './utilities';
import { withImmer } from './middlewares/immer-combine';

const usePlayerStore = createStorePicker(
  createWithEqualityFn(
    withImmer({
      audioFile: undefined as HTMLAudioElement | undefined,
      currentTrack: undefined as Track | undefined,
      isPlaying: false,
      isMuted: false,
      volume: 50,
    })((set) => ({
      toggleMute: () =>
        set((state) => {
          state.isMuted = !state.isMuted;

          if (state.audioFile) {
            state.audioFile.muted = state.isMuted;
          }
        }),
      changeVolume: (volume: number) =>
        set((state) => {
          if (state.audioFile) {
            state.audioFile.volume = volume / 100;
            if (volume === 0) {
              state.audioFile.muted = true;
              state.isMuted = true;
            }
            if (volume > 0) {
              state.audioFile.muted = false;
              state.isMuted = false;
            }
          }

          state.volume = volume;
        }),
      togglePlay: () =>
        set((state) => {
          if (state.audioFile && state.isPlaying) {
            state.audioFile.pause();
          }

          if (state.audioFile && !state.isPlaying) {
            state.audioFile.play();
          }

          state.isPlaying = !state.isPlaying;
        }),
      setPlay: (isPlaying: boolean) =>
        set((state) => {
          state.isPlaying = isPlaying;
          if (isPlaying) {
            state.audioFile?.play();
          } else {
            state.audioFile?.pause();
          }
        }),
      playTrack: async (track: Track, progress = 0) =>
        set((state) => {
          // If the track is already playing, toggle the play state
          if (state.currentTrack?.id === track.id) {
            state.isPlaying = !state.isPlaying;

            // Reflect the change in the audio file
            if (state.isPlaying) {
              state.audioFile?.play();
            } else {
              state.audioFile?.pause();
            }

            return;
          }

          // Reset the audio file
          if (state.audioFile) {
            state.audioFile.pause();
            state.audioFile.currentTime = 0;
          }

          // Play a new Track
          const audio = new Audio(track.audiofile);
          const duration = track.duration || audio.duration || 0;

          audio.volume = state.volume / 100;
          audio.currentTime = (progress / 100) * duration;
          audio.muted = state.isMuted;
          audio.play();

          state.audioFile = audio;

          state.audioFile.muted = state.isMuted;
          state.audioFile.volume = state.volume / 100;
          state.isPlaying = true;
          state.currentTrack = track;
        }),
      seekTo: (progress: number) =>
        set((state) => {
          if (state.audioFile && state.currentTrack) {
            state.audioFile.currentTime = state.currentTrack.duration
              ? (progress / 100) * state.currentTrack.duration
              : 0;
            state.audioFile.play();
          }
        }),
      setIsPlaying: (isPlaying: boolean) =>
        set((state) => {
          state.isPlaying = isPlaying;
        }),
    })),
  ),
);

export default usePlayerStore;
