import classNames from 'classnames';
import { memo, useEffect } from 'react';
import { Tooltip } from '~/components/ui/overlays/tooltip/tooltip';

import { Participant } from '~/types/schemas/chats/participant.schema';
import { ChatMessageContent, ChatMessageMeta } from '~/types/types/chat';
import { Message as MessageType } from '~/utils/chat/message';
import { globalStore } from '~/stores/global-store-v2';
import TextLinkParser from '~/components/ui/text/text-link-parser/text-link-parser';
import { useMessageTime } from '../use-message-time';
import { parseMessage } from './message-parser';
import File from './file';
import Menu from './menu';

import './message.styles.scss';
import Reader from './reader';
import { useTranslation } from 'react-i18next';

type MessageProps = {
  user?: Participant;
  message: MessageType<ChatMessageContent, ChatMessageMeta>;
};

function Message(props: MessageProps) {
  const { user, message } = props;
  const time = useMessageTime(message.timetoken);
  const { t } = useTranslation('brief-chat');
  const textParts = parseMessage(
    message.deleted.value ? t('chat.message.deleted') : message.content.text || '',
  );
  const isSender = globalStore.user.value?.id === user?.id;

  useEffect(() => {
    const unsub = message.watchUpdates();

    return () => {
      unsub();
    };
  }, []);

  return (
    <div
      className={classNames('flex h-fit w-full flex-col gap-2', {
        message: !message.deleted.value,
        'message--received': !isSender,
        'message--sent': isSender,
      })}
    >
      {message.content.text ? (
        <div
          className={classNames('group/message flex w-full items-center', {
            'justify-start': !isSender,
            'flex-row-reverse': isSender,
          })}
        >
          <TextLinkParser>
            <Tooltip
              label={`${time}`}
              delayDuration={50}
              triggerClassName={classNames('max-w-[80%] text-start')}
              align="center"
              side={isSender ? 'left' : 'right'}
              sideOffset={16}
            >
              <p
                className={classNames('message h-fit w-fit py-1.5 pr-3 pl-3 text-sm', {
                  'bg-neutral-emphasis text-default rounded-r-md': !isSender,
                  'bg-inverse text-on-inverse rounded-l-md': isSender,
                  'border-neutral-emphasis border bg-transparent': message.deleted.value,
                })}
              >
                {textParts.map((part, i) => (
                  <span
                    key={`${part.text}-${part.type}-${i}`}
                    className={classNames({
                      'text-default [&>a]:text-default self-start': !isSender,
                      'text-on-inverse [&>a]:text-on-inverse self-end': isSender,
                      'text-muted!': message.deleted.value,
                      'font-semibold': part.type === 'mention',
                    })}
                  >
                    {part.text}
                  </span>
                ))}
              </p>
            </Tooltip>
          </TextLinkParser>
          <div className="mx-4 hidden group-hover/message:block">
            <Menu
              onDeleteClick={
                !message.deleted.value && isSender ? () => message.delete() : undefined
              }
            />
          </div>
        </div>
      ) : null}
      {!message.deleted.value && message.content.files?.length ? (
        <div
          className={classNames('flex w-full flex-col gap-2', {
            'items-start': !isSender,
            'items-end': isSender,
          })}
        >
          {message.content.files.map((file) => (
            <File
              key={file.id}
              name={file.name}
              extension={file.name.split('.').pop()}
              size={file.size}
              onClick={() => message.download(file)}
            />
          ))}
        </div>
      ) : null}
      {!!(message.receipts && message.receipts.length) && (
        <span className="text-xxs flex gap-1 self-end">
          {message.receipts?.length
            ? message.receipts.map(
                (receipt) =>
                  receipt.userId !== user?.id && (
                    <Reader
                      key={receipt.userId}
                      userId={receipt.userId}
                      timetoken={receipt.timetoken}
                    />
                  ),
              )
            : null}
        </span>
      )}
    </div>
  );
}

export default memo(Message);
