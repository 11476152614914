import classNames from 'classnames';

type DialogSubTitleProps = {
  children: React.ReactNode;
  className?: string;
};

export function DialogSubTitle(props: DialogSubTitleProps) {
  const { children, className } = props;

  return <h3 className={classNames('mb-0.5 font-bold', className)}>{children}</h3>;
}
