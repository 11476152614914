import classNames from 'classnames';
import Icon from '~/components/ui/icon/icon';

type FilePreviewProps = {
  fileName?: string;
  fileExtension?: string;
  className?: string;
  onRemove?: () => void;
};

function FilePreview(props: FilePreviewProps) {
  const { fileName, fileExtension, className, onRemove } = props;

  return (
    <div
      className={classNames(
        'text-muted bg-neutral-muted group/file-preview relative flex h-fit w-full items-center gap-3 rounded-sm px-2 py-1.5 text-xs',
        className,
      )}
    >
      <span className="ellipsis flex-1 overflow-hidden">{fileName}</span>
      <div className="text-xxs bg-muted rounded-sm p-1 px-2">{`${fileExtension?.toUpperCase()}`}</div>
      {onRemove ? (
        <button
          className="bg-neutral-emphasis hover:bg-neutral-muted absolute top-0 right-0 hidden h-4 w-4 -translate-y-1/4 translate-x-1/4 items-center justify-center rounded-sm group-hover/file-preview:flex"
          onClick={onRemove}
        >
          <Icon name="close-line" className="text-neutral-emphasis text-xs" />
        </button>
      ) : null}
    </div>
  );
}

export default FilePreview;
