import { z } from 'zod';
import { ArtworkSchema } from '../common/image.schema';
import { createSearchResultSchema } from '../search/search.schema';
import { TenantSchema } from './tenant.schema';

const SocialStatsSchema = z.object({
  followers: z.number(),
  posts: z.number(),
});

export const ArtistSchema = z.object({
  artwork: ArtworkSchema.optional(),
  created_at: z.string(),
  description: z.string().optional(),
  id: z.string(),
  full_name: z.string(),
  master_artist: z.string(),
  tenant: TenantSchema,
  music_type: z.string().array(),
  stats: z.object({
    spotify: z
      .object({
        followers: z.number(),
        monthly_listeners: z.number(),
        world_rank: z.number(),
      })
      .optional(),
    socials: z.object({
      instagram: SocialStatsSchema.optional(),
      tiktok: SocialStatsSchema.optional(),
      youtube: SocialStatsSchema.optional(),
    }),
  }),
});

export const DisplayArtist = z.object({
  id: z.string(),
  name: z.string(),
  alias: z.string().optional(),
});

export const ArtistSearchResultSchema = createSearchResultSchema(ArtistSchema);

export type Artist = z.infer<typeof ArtistSchema>;
export type DisplayArtist = z.infer<typeof DisplayArtist>;
export type ArtistSearchResult = z.infer<typeof ArtistSearchResultSchema>;
