import { useTranslation } from 'react-i18next';
import { useCatalogCard } from '~/hooks/entities/track/cards/use-catalog-card';
import TrackCard from './card';

export function TrackCardCatalog() {
  const { content, onClick } = useCatalogCard();
  const { t } = useTranslation('music-item');

  return <TrackCard label={t('catalog.title')} content={content} onContentClick={onClick} />;
}
