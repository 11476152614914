import * as ContextMenu from '@radix-ui/react-context-menu';

type ContextMenuItemProps = {
  children: React.ReactNode;
};

export function ContextSubMenu(props: ContextMenuItemProps) {
  const { children } = props;

  return <ContextMenu.Sub>{children}</ContextMenu.Sub>;
}
