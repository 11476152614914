import classNames from 'classnames';
import { ReactNode } from 'react';
import TextLinkParser from '~/components/ui/text/text-link-parser/text-link-parser';

type InformationProps = {
  title: string;
  children?: ReactNode;
  className?: string;
};

const Information = (props: InformationProps) => {
  const { title, children, className } = props;

  return (
    <div
      className={classNames(
        'bg-neutral-muted flex w-full flex-col gap-1 rounded-lg p-2',
        className,
      )}
    >
      <h5 className="text-muted text-xs">{title}</h5>
      <TextLinkParser>{children}</TextLinkParser>
    </div>
  );
};

export default Information;
