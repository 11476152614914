import { NavItemList } from './nav-item-list/nav-item-list';
import { TracklistList } from './tracklist-list/tracklist-list';

function Navbar() {
  return (
    <div className="relative flex w-64 flex-col gap-2">
      <NavItemList />
      <TracklistList />
    </div>
  );
}

export default Navbar;
