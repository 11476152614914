import { useLocation, useParams } from 'react-router-dom';
import { BriefAsideChannel } from '~/pages/brief/components/brief-aside-chat';

export function Sidebar() {
  const location = useLocation();
  const { id } = useParams();

  if (location.pathname.includes('/briefs/') && id) {
    return (
      <div>
        <BriefAsideChannel id={id} />
      </div>
    );
  }

  return null;
}
