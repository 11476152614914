import classNames from 'classnames';
import { Tooltip } from '~/components/ui/overlays/tooltip/tooltip';
import { useTrackVersionLabel } from '~/hooks/entities/track/versions/use-track-version-label';
import { useTrackPlaybackState } from '~/hooks/entities/track/use-track-playback-state';
import { useTrackTitle } from '~/hooks/entities/track/use-track-title';
import { useTrackContext } from '../track.context';

type TrackSummaryTitleProps = {
  className?: string;
};

export function TrackSummaryTitle(props: TrackSummaryTitleProps) {
  const { className } = props;
  const store = useTrackContext();
  const track = store.use.track();

  const { isCurrentTrack } = useTrackPlaybackState();
  const title = useTrackTitle();
  const { version } = useTrackVersionLabel(track.version);

  return (
    <Tooltip label={track.title} align="start" delayDuration={1000} asChild>
      <h6
        data-version={version && ` - ${version}`}
        className={classNames(
          'track-info__text--title text-default line-clamp-1 shrink-0 text-left text-sm font-medium select-none',
          'after:text-muted after:text-xs after:font-normal after:content-[attr(data-version)]',
          {
            'text-primary-400': isCurrentTrack,
          },
          className,
        )}
      >
        {title}
      </h6>
    </Tooltip>
  );
}
