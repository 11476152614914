import { useState } from 'react';
import Logo from '~/assets/mewo/logo.svg';
import { AuthenticationForm } from './authentication-form';
import { OTPVerificationForm } from './otp-verification-form';
import { useLogin, useVerifyOTP } from '~/hooks/api/auth';
import { useNavigate, useLocation } from 'react-router-dom';
import { pageRoutes } from '~/routes/pages';
import { globalStore } from '~/stores/global-store-v2';
import { User } from '~/types/schemas/auth/user';

import * as Sentry from '@sentry/react';
import { useTranslation } from 'react-i18next';
import { HTTPError } from 'ky';
import { ApiError } from '~/utils/api';

function Authentication() {
  const { mutate: login, isPending: isCredentialCheckPending } = useLogin();
  const { mutate: verifyOtp, isPending: isOtpCheckPending } = useVerifyOTP();

  const [isOtpRequired, setIsOtpRequired] = useState(false);
  const [jwtToken, setJwtToken] = useState('');

  const [credentialError, setCredentialError] = useState('');
  const [otpError, setOtpError] = useState('');
  const { t } = useTranslation('login');

  const navigate = useNavigate();
  const location = useLocation();

  const { setUser } = globalStore;

  function handleSuccess(data: { user: User; token: string }) {
    const { user, token } = data;
    setCredentialError('');
    setUser(user, token);
    navigate(location.state?.from ? location.state.from : pageRoutes.main);
  }

  function handleCredentialSubmit(data: { email: string; password: string }) {
    login(data, {
      onSuccess: handleSuccess,
      onError: async (error) => {
        const errorResponse = await (
          error as HTTPError<ApiError & { jwt: string }>
        ).response.json();
        setCredentialError('');

        if (errorResponse.code === 'OTP_REQUIRED') {
          setJwtToken(errorResponse.jwt);
          setIsOtpRequired(true);
        }
        if (errorResponse.code === 'INVALID_CREDENTIALS') {
          setCredentialError(t('form.credentials.incorrect'));
        } else {
          Sentry.captureException(error);
        }
      },
    });
  }

  function handleVerifyOtp(otp: string) {
    verifyOtp(
      { otp, jwt: jwtToken },
      {
        onSuccess: (data) => {
          const { user, token } = data;
          setUser(user, token);
          navigate(location.state?.from ? location.state.from : pageRoutes.main);
        },
        onError: async (error) => {
          const errorResponse = await (
            error as HTTPError<ApiError & { jwt: string }>
          ).response.json();

          if (errorResponse.code === 'INVALID_OTP') {
            setOtpError(t('form.verify-otp.incorrect'));
          } else {
            Sentry.captureException(error);
          }
        },
      },
    );
  }

  function handleCancelOtp() {
    setOtpError('');
    setIsOtpRequired(false);
    setJwtToken('');
  }

  return (
    <div className="flex h-screen flex-col items-center justify-center">
      <div className="flex w-72 flex-col items-center justify-start">
        <img src={Logo} alt="mewo" className="mb-6 w-[70%]" />
        <div className="h-80 w-full">
          {isOtpRequired ? (
            <OTPVerificationForm
              onOtpSubmit={handleVerifyOtp}
              onCancel={handleCancelOtp}
              isPending={isOtpCheckPending}
              error={otpError}
            />
          ) : (
            <AuthenticationForm
              onCredentialSubmit={handleCredentialSubmit}
              isPending={isCredentialCheckPending}
              error={credentialError}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default Authentication;
