import { useTranslation } from 'react-i18next';
import { TrackStepHeaderLoader } from '../headers/track-step-header-loader';
import { TrackStepsContainer } from '../track-steps.container';
import { CancelListButton } from './common/cancel-list-button';

export function StepWaiting() {
  const { t } = useTranslation('tracks-upload-form');

  return (
    <TrackStepsContainer>
      <div className="relative flex size-full flex-col items-center justify-between pr-4">
        <TrackStepHeaderLoader
          title={t('card.upload.waiting')}
          state={t('card.upload.waiting.subtitle')}
          className="flex-1"
        />
        <CancelListButton />
      </div>
    </TrackStepsContainer>
  );
}
