import { ReactNode } from 'react';
import GeneralIcons from '~/components/ui/icon/general-icons';

type FilterHightLightProps = {
  id: string;
  title: string;
  description: string;
  icon: string;
  color: string;
  queryExample: ReactNode;
  exampleLabel: string;
  onCardClick?: () => void;
};

function FilterHighLight(props: FilterHightLightProps) {
  const { id, title, description, icon, color, queryExample, exampleLabel, onCardClick } = props;
  return (
    <div
      id={id}
      className="text-default flex flex-1 cursor-pointer gap-6 rounded-lg p-4 pr-6! transition-all hover:brightness-110"
      style={{ backgroundColor: color }}
      onClick={onCardClick}
    >
      <GeneralIcons icon={icon} className="text-[7vw]" />
      <div className="flex flex-col gap-4">
        <h5 className="text-2xl font-bold">{title}</h5>
        <p className="text-default text-sm">{description}</p>
        <div className="mt-auto flex flex-col gap-2">
          <span className="text-xs font-bold">{exampleLabel}</span>
          {queryExample}
        </div>
      </div>
    </div>
  );
}

export default FilterHighLight;
