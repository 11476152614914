import * as ContextMenu from '@radix-ui/react-context-menu';

type ContextMenuItemProps = {
  children: React.ReactNode;
};

export function ContextSubMenuContainer(props: ContextMenuItemProps) {
  const { children } = props;

  return (
    <ContextMenu.Portal>
      <ContextMenu.SubContent
        sideOffset={5}
        className="bg-control-rest border-control-rest w-fit min-w-60 rounded-md border p-1 shadow-xl"
      >
        {children}
      </ContextMenu.SubContent>
    </ContextMenu.Portal>
  );
}
