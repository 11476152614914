import { signal } from '@preact/signals-react';
import { Agent } from '~/types/schemas/common/agent.schema';
import { OthersResponse } from '~/types/schemas/data/mewo-data.schema';
import { Rights } from '~/types/schemas/data/rights.schema';
import { Tonality } from '~/types/schemas/data/tonalities.schema';
import { Version } from '~/types/schemas/data/versions.schema';

export const mewoDataStore = {
  rights: {
    array: signal<Rights[]>([]),
    object: signal<Record<string, Rights>>({}),
  },
  tonalities: {
    array: signal<Tonality[]>([]),
    object: signal<Record<string, Tonality>>({}),
  },
  versions: {
    array: signal<Version[]>([]),
    object: signal<Record<string, Version>>({}),
  },
  agents: {
    array: signal<Agent[]>([]),
    object: signal<Record<string, Agent>>({}),
  },
  setData: (data: OthersResponse) => {
    mewoDataStore.versions.array.value = data.versions;
    mewoDataStore.versions.object.value = Object.fromEntries(
      data.versions.map((version) => [version.id, version]),
    );

    mewoDataStore.tonalities.array.value = data.tonalities;
    mewoDataStore.tonalities.object.value = Object.fromEntries(
      data.tonalities.map((tonality) => [tonality.id, tonality]),
    );

    mewoDataStore.rights.array.value = data.rights;
    mewoDataStore.rights.object.value = Object.fromEntries(
      data.rights.map((right) => [right.id, right]),
    );
  },
  setDataAgents: (data: Agent[]) => {
    mewoDataStore.agents.array.value = data;
    mewoDataStore.agents.object.value = Object.fromEntries(data.map((agent) => [agent.id, agent]));
  },
};
