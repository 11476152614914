import { globalStore } from '~/stores/global-store-v2';
import { TrackMetadata } from '~/types/features/track-upload/track-metadata.types';

import {
  DiscriminatedValidateData,
  ValidateParams,
} from '~/types/schemas/track-upload/validate.schema';
import { findVersionByLabel } from '~/utils/common/find-version';

export function getValidateParams(data: TrackMetadata): ValidateParams {
  let params: DiscriminatedValidateData;

  switch (data?.type) {
    case 'identified':
      params = {
        type: 'identified',
      };
      break;
    case 'spotify':
      params = {
        type: 'spotify',
        spotifyId: data?.spotifyId || '',
      };
      break;
    default:
      params = {
        type: 'manual',
        title: data?.title || '',
        artists: data?.artists || [],
        album: data?.album || '',
        release_date: data?.releaseDate || '',
      };
      break;
  }
  return {
    ...params,
    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
    version: data?.version || findVersionByLabel('original')?.id!,
    music_type: data?.musicType || 'core',
    catalog: data?.catalog || globalStore.user.value?.company_name || 'Catalog',
    use_id3_cover: data?.cover?.includes('blob') ? false : !data?.useId3Cover,
  };
}
