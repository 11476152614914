import Button from '~/components/ui/control/button/button';
import usePreSearchStore from '~/stores/presearch-store';
import { useTranslation } from 'react-i18next';

const EmptyPlaceholder = () => {
  const presearch = usePreSearchStore(['query', 'setCurrentTab']);
  const { t } = useTranslation('pre-search');

  return (
    <div className="col-span-full mt-[25vh] flex h-full w-full flex-col items-center justify-center">
      <h1 className="text-default">{t('tab.empty.title', { query: presearch.query })}</h1>
      <p className="text-muted mt-2">{t('tab.empty.description')}</p>
      <Button color="primary" className="mt-6" onClick={() => presearch.setCurrentTab('all')}>
        {t('tab.empty.button.label')}
      </Button>
    </div>
  );
};

export default EmptyPlaceholder;
