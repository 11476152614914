import { components, ControlProps, GroupBase } from 'react-select';
import classNames from 'classnames';
import { OptionType } from '../select';

const Control = (props: ControlProps<OptionType, boolean, GroupBase<OptionType>>) => {
  const { children, isFocused } = props;

  return (
    <components.Control
      className={classNames(
        'bg-control-rest! border-control-rest! h-8! min-h-8! rounded-lg! shadow-none!',
        {
          'outline-focus! outline! outline-offset-2! focus:outline-1!': isFocused,
        },
      )}
      {...props}
    >
      {children}
    </components.Control>
  );
};

export default Control;
