import Button from '~/components/ui/control/button/button';
import { UploadTrack } from '~/types/features/track-upload/track-upload.types';
import { useTracksUploadContext } from '~/components/entities/upload/tracks/tracks-upload.context';
import { useValidateTrack } from '~/hooks/api/upload';
import { useTracksUploadItemContext } from '../../../../tracks-upload-item.context';
import errorHandler from '~/utils/error-handler';
import { DeleteTrackButton } from '../../common/delete-track-button';
import { getValidateParams } from '../../step-form/content/validate-match-helper';
import { useTranslation } from 'react-i18next';

type MatchDisplayFooterProps = {
  file: UploadTrack;
};

export function MatchDisplayFooter(props: MatchDisplayFooterProps) {
  const { file } = props;
  const { store } = useTracksUploadContext();
  const { presignedData } = useTracksUploadItemContext();
  const { mutate: validateTrack, isPending: isValidatePending } = useValidateTrack(
    presignedData?.id,
  );

  const { t } = useTranslation('tracks-upload-form');
  const setTrackMetadata = store.use.setTrackMetadata();
  const setNextSelected = store.use.setNextSelected();
  const setState = store.use.setState();

  function handleValidate() {
    if (file.matchedMetadata) {
      setTrackMetadata(file.id, file.matchedMetadata);
      validateTrack(getValidateParams(file.matchedMetadata), {
        onSuccess: () => {
          setState(file?.id, 'validated');
          setNextSelected();
        },
        onError: (error) => {
          setState(file?.id, 'failed');
          errorHandler(error);
        },
      });
    } else {
      errorHandler(new Error('Matched metadata is not available'));
    }
  }

  return (
    <div className="flex w-full items-center justify-end gap-2 p-3">
      <DeleteTrackButton />
      <Button
        color="success"
        type="button"
        onClick={handleValidate}
        isLoading={isValidatePending}
        disabled={isValidatePending}
        iconLeft="check-line"
      >
        {t('card.display.validate')}
      </Button>
    </div>
  );
}
