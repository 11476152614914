import { useTranslation } from 'react-i18next';
import { useTrackContext } from '~/components/entities/track/common/track.context';
import { Tracklist } from '~/types/schemas/music/tracklist.schema';
import { useRemoveFromTracklist } from '~/hooks/entities/track/actions/use-remove-track-from-tracklist';
import { useSelectionContext } from '~/providers/selection-provider/selection.context';
import { useTrackItemListContext } from '~/components/entities/track/track-item-list/track-item-list.context';
import * as ContextMenu from '../common/context-menu';

export function TrackActionsRemoveFromTracklist() {
  const { t } = useTranslation('track-interactions');
  const store = useTrackContext();
  const context = store.use.context() as {
    type: Tracklist['type'];
    id: string;
  };
  const { context: trackItemListContext } = useTrackItemListContext();

  const isTrackRemovable =
    trackItemListContext?.type !== 'brief' || !trackItemListContext?.value.owned_by_tenant;

  const { removeTracksFromTracklist } = useRemoveFromTracklist();
  const { values } = useSelectionContext();

  if (!isTrackRemovable) return null;

  if (context.type === 'brief') {
    return (
      <ContextMenu.Item icon="ri:delete-bin-line" onClick={removeTracksFromTracklist}>
        {t('remove-from-brief.trigger')}
        {values.length > 1 ? <span>{` (${values.length})`}</span> : null}
      </ContextMenu.Item>
    );
  }

  if (context.type === 'playlist') {
    return (
      <ContextMenu.Item icon="ri:delete-bin-line" onClick={removeTracksFromTracklist}>
        {t('remove-from-playlist.trigger')}
        {values.length > 1 ? <span>{` (${values.length})`}</span> : null}
      </ContextMenu.Item>
    );
  }

  return null;
}
