import { useTranslation } from 'react-i18next';
import { useSearchStore } from '~/stores/search-store';

export function FilterCount() {
  const { t } = useTranslation('filter');
  const search = useSearchStore(['nbFilters']);

  return (
    <div className="flex gap-2">
      <h4 className="text-default text-xs">{t('title')}</h4>
      {search.nbFilters ? (
        <span className="text-xxs text-default bg-accent-emphasis flex size-4 items-center justify-center rounded-sm">
          {search.nbFilters}
        </span>
      ) : null}
    </div>
  );
}
