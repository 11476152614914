import { Icon, IconifyIconProps } from '@iconify/react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { MouseEvent } from 'react';

type DropdownMenuItemProps = {
  icon?: IconifyIconProps['icon'];
  onClick?: () => void;
  children: React.ReactNode;
};

export function DropdownMenuSubItem(props: DropdownMenuItemProps) {
  const { icon, onClick, children } = props;

  function handleClick(e: MouseEvent) {
    e.stopPropagation();
    onClick?.();
  }

  return (
    <DropdownMenu.SubTrigger
      className="text-default focus:bg-control-neutral flex h-8 items-center gap-3 rounded-sm border border-transparent px-2 text-sm outline-hidden select-none"
      onClick={handleClick}
    >
      <div className="flex size-4 items-center justify-center">
        {icon && <Icon icon={icon} className="h-4 w-4" />}
      </div>
      <div className="flex-1">{children}</div>
      <div className="flex size-4 items-center justify-center">
        <Icon icon="ri:arrow-right-s-line" className="h-4 w-4" />
      </div>
    </DropdownMenu.SubTrigger>
  );
}
