import { Playlist } from '~/types/schemas/music/playlists.schema';
import Button from '~/components/ui/control/button/button';
import usePlaylistFormStore from '~/stores/forms/playlist-form-store';
import { useTranslation } from 'react-i18next';

type EditPlaylistProps = {
  data: Playlist;
};

const EditPlaylist = (props: EditPlaylistProps) => {
  const { data } = props;
  const playlistForm = usePlaylistFormStore(['open', 'close']);
  const { t } = useTranslation('playlist-page');

  function handleEdit() {
    playlistForm.open(data);
  }

  return data.owned_by_tenant ? (
    <Button
      color="primary"
      className="playlist__edit-button h-full w-fit"
      iconRight="pencil-line"
      onClick={() => handleEdit()}
    >
      {t('header.interactions.button.edit.label')}
    </Button>
  ) : null;
};

export default EditPlaylist;
