import { motion } from 'framer-motion';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { z } from 'zod';
import Input from '~/components/ui/forms/input/input';
import Button from '~/components/ui/control/button/button';

type AuthenticationFormProps = {
  isPending: boolean;
  className?: string;
  error?: string;
  onCredentialSubmit: (data: { email: string; password: string }) => void;
};

export function AuthenticationForm(props: AuthenticationFormProps) {
  const { onCredentialSubmit, error, isPending } = props;
  const { handleSubmit, control } = useForm();

  const { t } = useTranslation('login');

  function handleLogin(formData: FieldValues) {
    const parsedData = z
      .object({
        email: z.string(),
        password: z.string(),
      })
      .parse(formData);

    onCredentialSubmit(parsedData);
  }

  return (
    <form
      onSubmit={handleSubmit(handleLogin)}
      className="flex w-full flex-col items-center justify-center gap-4"
    >
      <div className="flex h-44 w-full items-center justify-center">
        <motion.div
          className={'flex w-full flex-col items-center justify-center gap-4'}
          initial={{ opacity: 0, x: 100 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: -100 }}
          transition={{ duration: 0.1, ease: 'easeOut' }}
        >
          <Controller
            name="email"
            control={control}
            defaultValue=""
            rules={{
              required: t('form.email.rules.required'),
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: t('form.email.rules.pattern'),
              },
            }}
            render={({ field: { onChange, value } }) => (
              <Input
                disabled={isPending}
                className="w-full"
                label={t('form.email.label')}
                placeholder={t('form.email.placeholder')}
                type="email"
                onChange={onChange}
                value={value}
              />
            )}
          />
          <Controller
            name="password"
            control={control}
            defaultValue=""
            rules={{
              required: t('form.password.rules.required'),
            }}
            render={({ field: { onChange, value } }) => (
              <Input
                disabled={isPending}
                className="w-full"
                label={t('form.password.label')}
                placeholder={t('form.password.placeholder')}
                type="password"
                onChange={onChange}
                value={value}
              />
            )}
          />
          {error ? <span className="text-danger text-xs">{error}</span> : null}
        </motion.div>
      </div>
      <div className="flex w-[70%] flex-col items-center gap-3">
        <Button
          color="primary"
          className="flex w-full items-center justify-center"
          type="submit"
          disabled={isPending}
          isLoading={isPending}
        >
          {t('form.submit.label')}
        </Button>
        <Link
          to="https://dashboard.mewo.io/register"
          className="text-muted hover:text-default text-xs transition-colors hover:underline"
        >
          {t('form.register.label')}
        </Link>
      </div>
    </form>
  );
}
