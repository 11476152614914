import { useTranslation } from 'react-i18next';
import Button from '~/components/ui/control/button/button';

type LicensingQuoteFooterProps = {
  isPending: boolean;
};

export function LicensingQuoteFooter({ isPending }: LicensingQuoteFooterProps) {
  const { t } = useTranslation('request-form');

  return (
    <div className="flex justify-end gap-3 px-4">
      <Button
        color="primary"
        disabled={isPending}
        isLoading={isPending}
        form="licensing"
        type="submit"
        className="playlist-form__submit-button"
      >
        {t('footer.button.send')}
      </Button>
    </div>
  );
}
