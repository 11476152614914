import LibraryLayout from '~/components/layout/library-layout';
import Button from '~/components/ui/control/button/button';
import { useTranslation } from 'react-i18next';
import CardLoader from './components/card-loader';
import PlaylistCard from './components/playlist-card';
import PlaylistLibraryHeader from './components/header';
import { usePlaylists } from './hooks/use-playlists';

function Playlists() {
  const { t } = useTranslation('common');
  const {
    playlists,
    nbHits,
    isEachDataDisplayed,
    isLoading,
    fetchNextPage,
    handleCardClick,
    handleCreatePlaylist,
  } = usePlaylists();

  return (
    <LibraryLayout
      nbHits={nbHits}
      header={<PlaylistLibraryHeader total={nbHits} onCreateClick={handleCreatePlaylist} />}
    >
      {!isLoading ? (
        <>
          {playlists.map((playlist) => (
            <PlaylistCard
              key={`playlist-card-${playlist.id}`}
              onClick={() => handleCardClick(playlist.id)}
              playlist={playlist}
            />
          ))}
          {!isEachDataDisplayed && (
            <Button
              color="default"
              type="button"
              onClick={() => fetchNextPage()}
              className="col-span-full mx-auto mb-3 w-fit"
            >
              {t('button.load-more.label')}
            </Button>
          )}
        </>
      ) : (
        Array.from({ length: 10 }).map((_, index) => <CardLoader key={`card-loader-${index}`} />)
      )}
    </LibraryLayout>
  );
}

export default Playlists;
