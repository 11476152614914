import classNames from 'classnames';
import { memo, useEffect } from 'react';
import { activeChannelStore, briefChatsStore } from '~/stores/chats-store';
import { useTranslation } from 'react-i18next';
import { Icon } from '@iconify/react';
import ChatList from './components/chat-list/chat-list';
import { useChatList } from './components/chat-list/use-chat-list';
import Chat from './components/chat/chat';

type ThreadsProps = {
  brief: {
    id: string;
    title: string;
  };
  onClose: () => void;
  className?: string;
};

function BriefChatsPanel(props: ThreadsProps) {
  const { brief, onClose, className } = props;
  const { isLoading, handleChatClick, handleBackClick } = useChatList(brief);
  const { t } = useTranslation(['brief-chat', 'common']);

  useEffect(() => {
    if (!isLoading && activeChannelStore.trackId.value) {
      activeChannelStore.channel.value = briefChatsStore.channels.value.find(
        (channel) => channel.custom?.trackId === activeChannelStore.trackId.value,
      );
      activeChannelStore.trackId.value = undefined;
    }
  }, [isLoading, activeChannelStore.trackId.value]);

  const isListEmpty = !briefChatsStore.channels.value.length;

  return (
    <div className={classNames('relative h-full w-full overflow-hidden rounded-lg', className)}>
      {!activeChannelStore.channel.value ? (
        <div className="flex h-full w-full flex-col gap-2 px-3 py-6">
          <div className="flex items-center justify-between px-3 pb-3">
            <span className="text-default text-center text-xs">{t('threads-list.header')}</span>
            <button
              type="button"
              onClick={onClose}
              className="outline-focus rounded-sm outline-hidden focus:outline-1"
            >
              <Icon icon="ri:close-line" className="text-default" onClick={onClose} />
            </button>
          </div>
          <div className="scrollbar-on-hover h-full self-stretch overflow-y-auto pr-2">
            {isLoading && (
              <div className="flex h-full flex-col items-center justify-center">
                <span className="text-muted text-sm">{t('loading', { ns: 'common' })}</span>
              </div>
            )}
            {isListEmpty && !isLoading ? (
              <div className="flex h-full flex-col items-center justify-center">
                <span className="text-muted text-sm">{t('threads-list.list-empty')}</span>
              </div>
            ) : (
              <ChatList onClick={handleChatClick} />
            )}
          </div>
        </div>
      ) : (
        <Chat
          trackId={activeChannelStore.channel.value.custom!.trackId}
          onChatExit={handleBackClick}
        />
      )}
    </div>
  );
}

export default memo(BriefChatsPanel);
