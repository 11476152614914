import { PropsWithChildren } from 'react';
import { Track as TrackType } from '~/types/schemas/music/track.schema';
import { Action } from '~/types/types/actions';
import { createStore } from 'zustand';
import { createSelectors } from '~/stores/utilities';
import * as Track from '../common/track';
import { TrackItemContext, TrackItemState } from './track-item.context';
import { TrackItemContextMenu } from './menus/context-menu/context-menu';
import TrackDragProvider from '../common/providers/track-drag.provider';

type TrackItemProvidersProps = PropsWithChildren<{
  track: TrackType;
  context: Action.Play;
}>;

export function TrackItemProviders(props: TrackItemProvidersProps) {
  const { track, context, children } = props;

  const TrackItemStoreBase = createStore<TrackItemState>((set) => ({
    isOpen: false,
    isAnimationComplete: false,
    onAnimationComplete: (b: boolean) => set({ isAnimationComplete: b }),
    onOpenChange: (b: boolean) => set({ isOpen: b }),
  }));

  const isTrackSortable = context.type === 'playlist';

  const TrackItemStore = createSelectors(TrackItemStoreBase);

  return (
    <TrackItemContext.Provider value={TrackItemStore}>
      <Track.Root track={track} context={context}>
        <TrackItemContextMenu>
          <TrackDragProvider isSortable={isTrackSortable}>{children}</TrackDragProvider>
        </TrackItemContextMenu>
      </Track.Root>
    </TrackItemContext.Provider>
  );
}
