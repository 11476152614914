import classNames from 'classnames';
import * as Tabs from '@radix-ui/react-tabs';
import usePreSearchStore from '~/stores/presearch-store';
import Navigation from './components/navigation/navigation';
import Content from './components/content/content';

type PresearchProps = {
  className?: string;
};

function Presearch(props: PresearchProps) {
  const { className } = props;
  const presearch = usePreSearchStore(['isOpen', 'currentTab', 'setCurrentTab']);

  return (
    <div
      id="presearch"
      className={classNames(
        'animate-slide-in-bottom bg-overlay-backdrop px-6 pt-6 pb-0! backdrop-blur-md',
        className,
      )}
    >
      <Tabs.Root
        value={presearch.currentTab}
        onValueChange={presearch.setCurrentTab}
        className="flex h-full flex-col items-stretch gap-6"
      >
        <Navigation />
        <Content />
      </Tabs.Root>
    </div>
  );
}

export default Presearch;
