import * as RadixAvatar from '@radix-ui/react-avatar';
import { globalStore } from '~/stores/global-store-v2';

function Avatar() {
  const { user } = globalStore;
  return (
    <RadixAvatar.Root className="bg-avatar-rest border-avatar-rest flex h-[2.625rem] w-[2.625rem] items-center justify-center rounded-full border-2">
      <RadixAvatar.Fallback
        style={{ backgroundColor: user.value?.avatar_color ?? '#FFF' }}
        className="flex h-7 w-7 items-center justify-center rounded-full text-sm"
      >
        {user.value?.first_name[0]}
      </RadixAvatar.Fallback>
    </RadixAvatar.Root>
  );
}

export default Avatar;
