import { useTrackContext } from '~/components/entities/track/common/track.context';
import { VersionList } from './version-list';

export function ContentVersions() {
  const store = useTrackContext();
  const track = store.use.track();

  if (track.versions > 0) {
    return <VersionList />;
  }

  return null;
}
