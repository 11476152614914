import useMusemindStore from '~/stores/musemind-store';
import Slider from '~/components/ui/control/slider/slider';
import TrackInteraction from '~/components/features/track-interactions/components/interaction';

type VolumeProps = {
  className?: string;
};

export const Volume = (props: VolumeProps) => {
  const { className } = props;
  const musemind = useMusemindStore(['toggleMute', 'changeVolume', 'volume', 'isMuted']);

  const handleVolumeIcon = () => {
    if (musemind.isMuted) {
      return 'volume-mute-line';
    }

    if (musemind.volume > 0 && musemind.volume <= 50) {
      return 'volume-down-line';
    }

    return 'volume-up-line';
  };

  const handleVolumeChange = ([v]: number[]) => {
    if (v !== undefined) {
      musemind.changeVolume(v);
    }
  };

  return (
    <div className={`Volume flex items-center gap-2 ${className}`} data-no-dnd="true">
      <TrackInteraction
        icon={handleVolumeIcon()}
        tooltip={musemind.isMuted ? 'Unmute' : 'Mute'}
        onClick={musemind.toggleMute}
      />
      <Slider
        min={0}
        max={100}
        value={[musemind.volume]}
        className="w-24!"
        onValueChange={(v) => handleVolumeChange(v)}
      />
    </div>
  );
};
