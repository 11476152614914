import * as AlertDialog from '@radix-ui/react-alert-dialog';
import classNames from 'classnames';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '~/components/ui/control/button/button';

type DeleteDialogProps = {
  handleDeleteClick: () => void;
  children: ReactNode;
};

const DeleteDialog = (props: DeleteDialogProps) => {
  const { handleDeleteClick, children: trigger } = props;
  const { t } = useTranslation('brief-page');

  return (
    <AlertDialog.Root>
      <AlertDialog.Trigger asChild>{trigger}</AlertDialog.Trigger>
      <AlertDialog.Portal>
        <AlertDialog.Overlay className="bg-overlay-backdrop fixed inset-0 z-90 backdrop-blur-sm" />
        <AlertDialog.Content
          data-no-dnd="true"
          className={classNames(
            'delete-modal fixed top-[50%] left-[50%] z-100 flex max-h-[80vh] w-full max-w-[40rem] -translate-x-1/2 -translate-y-1/2 flex-col items-stretch overflow-hidden rounded-lg p-4',
          )}
        >
          <AlertDialog.Title className="mb-4 text-2xl font-bold">
            {t('modal.delete.title')}
          </AlertDialog.Title>
          <AlertDialog.Description className="text-md text-muted">
            {t('modal.delete.content')}
          </AlertDialog.Description>
          <div className="mt-4 flex justify-end gap-6">
            <AlertDialog.Cancel className="outline-hidden" asChild>
              <Button color="default" type="button">
                {t('modal.delete.button.cancel.label')}
              </Button>
            </AlertDialog.Cancel>
            <AlertDialog.Action
              className="bg-danger-emphasis hover:bg-danger-muted outline-hidden"
              asChild
            >
              <Button color="danger" type="button" onClick={handleDeleteClick}>
                {t('modal.delete.button.confirm.label')}
              </Button>
            </AlertDialog.Action>
          </div>
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  );
};

export default DeleteDialog;
