import { Icon } from '@iconify/react';
import classNames from 'classnames';
import { useState } from 'react';

type AlertProps = {
  type: 'success' | 'error' | 'warning' | 'info';
  title: string;
  className?: string;
  closable?: boolean;
} & ({ message: string; children?: never } | { message?: never; children: React.ReactNode });

export function Alert(props: AlertProps) {
  const { type, title, message, className, children, closable = false } = props;
  const [isVisible, setIsVisible] = useState(true);

  const colorSelector = {
    'bg-success-500/10 text-success-500': type === 'success',
    'bg-error-500/10 text-danger': type === 'error',
    'bg-warning-500/10 text-warning-500': type === 'warning',
    'bg-primary-400/10 text-primary-400': type === 'info',
  };

  if (!isVisible) return null;

  return (
    <section
      className={classNames(
        'animate-slide-left flex w-full gap-3 rounded-lg p-3',
        className,
        colorSelector,
      )}
    >
      <Icon icon="ri:information-2-line" className="" />
      <div className="flex-1">
        <h3 className="text-sm">{title}</h3>
        {message && <p className="text-default text-xs">{message}</p>}
        <div className="text-default text-xs">{children}</div>
      </div>
      {closable && (
        <button
          onClick={() => setIsVisible(false)}
          className="flex h-6 w-6 justify-center rounded-full text-white hover:text-white/70"
        >
          <Icon icon="eva:close-fill" className="h-4 w-4" />
        </button>
      )}
    </section>
  );
}
