import classNames from 'classnames';
import { ComponentPropsWithoutRef, forwardRef, useImperativeHandle, useRef, useState } from 'react';
import GeneralIcons from '~/components/ui/icon/general-icons';
import { Tooltip } from '~/components/ui/overlays/tooltip/tooltip';

interface TextareaProps extends ComponentPropsWithoutRef<'textarea'> {
  label?: string;
  labelClassName?: string;
  boxClassName?: string;
  className?: string;
  success?: boolean;
  error?: string;
  dataTestId?: string;
  info?: string;
}

const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>((props, ref) => {
  const {
    label,
    className = '',
    labelClassName,
    boxClassName,
    error,
    defaultValue = '',
    value = '',
    dataTestId = '',
    info,
    ...textareaProps
  } = props;

  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [isFocused, setIsFocused] = useState(false);

  useImperativeHandle(ref, () => textareaRef.current!);

  const wrapperClass = classNames('flex flex-col gap-2 relative', className);

  const containerClass = classNames(
    'w-full min-h-8 h-full px-2 py-1.5 flex items-center gap-2 rounded-lg border border-control-rest bg-control-rest hover:border-control-hover hover:text-control-hover',
    {
      'border-control-danger-emphasis!': !!error,
      'border-control-disabled! bg-control-disabled!': textareaProps.disabled,
      'outline outline-focus focus:outline-1 outline-offset-2': isFocused,
    },
  );

  const textareaClass = classNames(
    'h-full flex-1 text-sm outline-hidden bg-transparent text-control-rest placeholder:text-control-placeholder ',
  );

  const labelClass = classNames(
    'text-control-neutral text-xs text-control-neutral',
    {
      'text-control-danger': error !== undefined,
    },
    labelClassName,
  );

  const handleFocus = () => {
    if (!isFocused) {
      setIsFocused(true);
      textareaRef.current?.focus();
    }
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <div className={classNames('w-full', boxClassName)}>
      <div className={wrapperClass} onClick={handleFocus}>
        {label && (
          <div className="flex items-center justify-between">
            <span className={labelClass}>{label}</span>
            {info ? (
              <Tooltip label={info} triggerClassName="absolute right-0 top-0.5">
                <GeneralIcons icon="info" className="text-muted hover:text-default text-xs" />
              </Tooltip>
            ) : null}
          </div>
        )}
        <div className={containerClass}>
          <textarea
            aria-label={label}
            ref={textareaRef}
            data-testid={dataTestId}
            className={textareaClass}
            defaultValue={defaultValue}
            value={value}
            onFocus={handleFocus}
            onBlur={handleBlur}
            {...textareaProps}
          />
        </div>
      </div>
      <span className="text-danger mt-1 w-full text-xs">{error}</span>
    </div>
  );
});

export default Textarea;
