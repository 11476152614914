import { signal } from '@preact/signals-react';
import { Participant } from '~/types/schemas/chats/participant.schema';
import { Track } from '~/types/schemas/music/track.schema';
import { ChatChannelCustom, ChatMessageContent, ChatMessageMeta } from '~/types/types/chat';
import { Channel } from '~/utils/chat/channel';

export const briefChatsStore = {
  isLoading: signal(true),
  isPanelOpen: signal(false),
  tracks: signal<Record<string, Track>>({}),
  channels: signal<Channel<ChatMessageContent, ChatMessageMeta, ChatChannelCustom>[]>([]),
  brief: signal<{ id: string; title: string } | undefined>(undefined),
  reset() {
    this.isLoading.value = true;
    this.channels.value = [];

    activeChannelStore.channel.value = undefined;
  },
};

export const activeChannelStore = {
  trackId: signal<string | undefined>(undefined),
  channel: signal<Channel<ChatMessageContent, ChatMessageMeta, ChatChannelCustom> | undefined>(
    undefined,
  ),
  participants: signal<Record<string, Participant>>({}),
  setParticipants(participants: Participant[]) {
    this.participants.value = participants.reduce(
      (acc, participant) => {
        acc[participant.id] = participant;

        return acc;
      },
      {} as Record<string, Participant>,
    );
  },
};
