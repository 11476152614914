import Chip from '~/components/ui/control/chip/chip';
import { parseThousands } from '~/utils/common/parse-thousands';
import { useTranslation } from 'react-i18next';
import { useBriefContext } from '../../brief.context';

export function BudgetChip() {
  const { t } = useTranslation('brief-page');
  const { data } = useBriefContext();
  const { budget, currency } = data;

  function chooseCurrency() {
    switch (currency) {
      case 'USD':
        return '$';
      case 'EUR':
        return '€';
      case 'GBP':
        return '£';
      case 'YEN':
        return '¥';
      default:
        return currency;
    }
  }

  return (
    <Chip iconLeft="ri:cash-line" className="bg-neutral-emphasis cursor-auto px-2!">
      {budget
        ? `${parseThousands(budget.toString(), ' ')} ${chooseCurrency()}`
        : t('header.unspecified').toUpperCase()}
    </Chip>
  );
}
