import Musemind from '~/components/features/musemind';
import { Outlet } from 'react-router-dom';
import { Player } from '~/components/entities/player/player';
import MinWidthWindow from '~/components/layout/min-width-window/min-width-window';
import SearchTopPage from '~/pages/search/components/search-top-page/search-top-page';
import FormHandler from '~/components/features/forms/form-handler';
import { useOpenReplay } from '~/hooks/utils/use-open-replay';
import { useRealtimeInit } from '~/hooks/realtime/use-realtime-init';
import { useIngestProgress } from '~/hooks/realtime/use-ingest-progress';
import { useNotifications } from '~/hooks/utils/use-notifications';
import { useSearchParams } from '~/hooks/utils/use-search-params';
import { useGetAgents } from '~/hooks/api/dashboard';
import Navbar from '../entities/navbar/navbar';
import Header from '../entities/header';
import { Sidebar } from '../entities/sidebar/sidebar';

// AppLayout is inside the Protected route
// Here user will always be set so we can init everything that uses user
const AppLayout = () => {
  useOpenReplay();
  useIngestProgress();
  useRealtimeInit();
  useNotifications();
  useGetAgents();
  useSearchParams();

  return (
    <div className="flex h-screen flex-col">
      <MinWidthWindow />
      <Header />
      <Musemind />
      <main className="scrollbar-on-hover bg-surface-main flex h-full flex-1 items-stretch gap-2 overflow-y-hidden p-2">
        <Navbar />
        <section className="bg-default relative flex flex-1 overflow-hidden rounded-2xl">
          <FormHandler />
          <SearchTopPage />
          <Outlet />
        </section>
        <Sidebar />
      </main>
      <Player />
    </div>
  );
};

export default AppLayout;
