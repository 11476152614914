import { useLocation } from 'react-router-dom';
import PresearchContent from '~/components/features/presearch';
import usePreSearchStore from '~/stores/presearch-store';

function SearchTopPage() {
  const location = useLocation();
  const isSearch = location.pathname === '/search';
  const presearch = usePreSearchStore(['isOpen']);

  return isSearch && presearch.isOpen ? (
    <section className="absolute top-0 z-20 flex size-full flex-col">
      <PresearchContent className="absolute inset-0 z-10 h-[calc(100vh_-_4rem)] w-full overflow-hidden" />
    </section>
  ) : null;
}

export default SearchTopPage;
