import classNames from 'classnames';
import Icon from '~/components/ui/icon/icon';
import { MouseEvent } from 'react';

type BestTagProps = {
  title: string;
  subtitle?: string;
  color?: string;
  className?: string;
  onCardClick?: () => void;
  onIncludeClick?: () => void;
  onExcludeClick?: () => void;
};

function BestTag(props: BestTagProps) {
  const { title, subtitle, color, className, onCardClick, onIncludeClick, onExcludeClick } = props;

  const buttonClassName =
    'flex items-center justify-center w-6 h-6 shrink-0 bg-neutral-muted group-hover:bg-neutral-emphasis hover:bg-muted! transition-all text-default rounded-lg';

  function handleIncludeClick(e: MouseEvent) {
    e.stopPropagation();
    onIncludeClick?.();
  }

  function handleExcludeClick(e: MouseEvent) {
    e.stopPropagation();
    onExcludeClick?.();
  }

  return (
    <div
      onClick={onCardClick}
      className={classNames(
        'group flex w-fit max-w-full cursor-pointer items-center justify-between gap-6 rounded-lg p-3 transition-all',
        'bg-neutral-emphasis hover:bg-neutral-muted',
        className,
      )}
    >
      <div className="flex items-center gap-2">
        <div className="h-3 w-3 shrink-0 rounded-sm" style={{ backgroundColor: color }} />
        <div className="flex flex-col">
          <h5 className="text-default text-sm font-bold">{title}</h5>
          <p className="text-muted text-xs">{subtitle}</p>
        </div>
      </div>
      <div className="flex gap-3">
        <button type="button" className={buttonClassName} onClick={handleExcludeClick}>
          <Icon name="subtract-line" />
        </button>
        <button type="button" className={buttonClassName} onClick={handleIncludeClick}>
          <Icon name="add-line" />
        </button>
      </div>
    </div>
  );
}

export default BestTag;
