import classNames from 'classnames';
import { Artwork } from '~/components/ui/cover/artwork';
import BriefInteractions from '~/pages/brief/components/brief-interactions';
import { BriefDescription } from '~/components/entities/brief/description/description';
import { useBriefContext } from '../brief.context';
import { AgentListChip } from './header/agent-list';
import { ConfidentialityChip } from './header/confidentiality';
import { BudgetChip } from './header/budget';
import { DeadlineChip } from './header/deadline';
import { TrackNumberChip } from './header/tracks-number';
import { CreationInfos } from './header/creation-info';
import { Tooltip } from '~/components/ui/overlays/tooltip/tooltip';

type BriefHeaderProps = {
  className?: string;
};

export function BriefHeader(props: BriefHeaderProps) {
  const { className } = props;
  const { data } = useBriefContext();
  const { id, title, artworks, owned_by_tenant: ownedByTenant, tracks: numberOfTracks } = data;

  return (
    <section className={classNames('@container flex h-fit flex-col gap-6 select-none', className)}>
      <div className="flex h-32 gap-6 px-4 @[52rem]:h-64">
        <Artwork
          className="hidden aspect-square w-fit @[52rem]:block"
          artworks={artworks?.map((artwork) => artwork.large)}
          alt={title}
          icon="ri:megaphone-line"
        />
        <div className="flex h-full flex-1 flex-col">
          <div className="relative flex h-full flex-col justify-start gap-2 self-stretch">
            <div className="flex h-full flex-col justify-center">
              <div className="flex w-full items-center justify-between">
                <CreationInfos />
              </div>
              <Tooltip label={title}>
                <span className="line-clamp-2 text-start text-5xl leading-[3.5rem] font-bold break-words">
                  {title}
                </span>
              </Tooltip>
            </div>
          </div>
          <div className="hidden flex-col gap-3 @[52rem]:flex">
            <div className="flex gap-4">
              <AgentListChip />
              <ConfidentialityChip />
            </div>
            <div className="flex gap-4">
              <BudgetChip />
              <DeadlineChip />
              <TrackNumberChip />
            </div>
            <BriefInteractions
              id={id!}
              data={data}
              ownedByTenant={ownedByTenant}
              isEmpty={!((numberOfTracks || 0) > 0)}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-3 px-4 @[52rem]:hidden">
        <div className="flex gap-4">
          <AgentListChip />
          <ConfidentialityChip />
        </div>
        <div className="flex gap-4">
          <BudgetChip />
          <DeadlineChip />
          <TrackNumberChip />
        </div>
        <BriefInteractions
          id={id!}
          data={data}
          ownedByTenant={ownedByTenant}
          isEmpty={!((numberOfTracks || 0) > 0)}
        />
      </div>
      <div className="px-4">
        <BriefDescription />
      </div>
    </section>
  );
}
