import { IntermediateRepresentation } from 'linkifyjs';
import { Link } from 'react-router-dom';

type AnchorProps = IntermediateRepresentation;

function AnchorDecorator(props: AnchorProps) {
  const { content, attributes } = props;
  const { href, ...attr } = attributes;

  return (
    <Link {...attr} to={href} target="blank" className="text-default underline">
      {content}
    </Link>
  );
}

export default AnchorDecorator;
