import { createContext, KeyboardEvent, MouseEvent, useContext } from 'react';
import { Track } from '~/types/schemas/music/track.schema';

type SelectionContextType = {
  values: Track[];
  handleItemClick: (e: MouseEvent | KeyboardEvent, item: Track) => void;
};

export const SelectionContext = createContext<SelectionContextType | null>(null);

export function useSelectionContext() {
  const context = useContext(SelectionContext);

  if (!context) {
    throw new Error('useSelectionContext must be used within a SelectionProvider');
  }

  return context;
}
