import { useTranslation } from 'react-i18next';
import { DownloadCopyrights } from './copyrights';
import { DownloadHDMP3 } from './hd-mp3';
import { DownloadOriginal } from './original';
import * as DropdownMenu from '../../common/dropdown-menu';

type TrackActionsDownloadDropdownMenuProps = {
  children: React.ReactNode;
};

function TrackActionsDownload(props: TrackActionsDownloadDropdownMenuProps) {
  const { children } = props;
  const { t } = useTranslation('track-interactions');

  return (
    <DropdownMenu.SubMenu>
      <DropdownMenu.SubItem icon="ri:download-line">{t('download.tooltip')}</DropdownMenu.SubItem>
      <DropdownMenu.SubMenuContainer>{children}</DropdownMenu.SubMenuContainer>
    </DropdownMenu.SubMenu>
  );
}

const Container = TrackActionsDownload;
const Original = DownloadOriginal;
const HDMP3 = DownloadHDMP3;
const Copyrights = DownloadCopyrights;

export { Container, Original, HDMP3, Copyrights };
