import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Outlet } from 'react-router-dom';
import { MewoDevTools } from '~/components/features/dev-tool/dev-tool';
import { Toaster } from 'react-hot-toast';
import DataProvider from './data-provider/data-provider';
import PubnubProvider from './pubnub-provider';
import '../i18n/config';
import { DndProvider } from './dnd-provider';
import { ContextProvider } from './context-provider';
import CustomQueryClientProvider from './custom-query-client-provider';

const AppProviders = () => {
  return (
    <CustomQueryClientProvider>
      <PubnubProvider>
        <MewoDevTools />
        <Toaster position="top-right" />
        <DataProvider>
          <ContextProvider>
            <DndProvider>
              <Outlet />
            </DndProvider>
          </ContextProvider>
        </DataProvider>
        <ReactQueryDevtools initialIsOpen={false} buttonPosition="top-left" />
      </PubnubProvider>
    </CustomQueryClientProvider>
  );
};

export default AppProviders;
