import { signal } from '@preact/signals-react';

export type IngestTrack = {
  title?: string;
  artists?: string[];
  cover?: string;
  version?: string;
};

type BriefIngest = {
  briefId: string;
  tracks: IngestTrack[];
  progress: number;
};

export const briefStore = {
  ingest: signal<BriefIngest | undefined>(undefined),
  addBriefIngest(briefId: string, tracks: IngestTrack[]) {
    briefStore.ingest.value = {
      briefId,
      tracks,
      progress: 0,
    };
  },
  createIngest(briefId: string, tracks: IngestTrack[]) {
    briefStore.addBriefIngest(briefId, tracks);
  },
  completeIngest() {
    setTimeout(() => {
      briefStore.ingest.value = undefined;
    }, 10);
  },
  updateIngestProgress(progress: number) {
    if (!briefStore.ingest.value) return;

    briefStore.ingest.value = {
      ...briefStore.ingest.value,
      progress,
    };
  },
  removeIngestTrack(track: IngestTrack) {
    if (!briefStore.ingest.value) return;

    briefStore.ingest.value = {
      ...briefStore.ingest.value,
      tracks: briefStore.ingest.value.tracks.filter((t) => t.title !== track.title),
    };
  },
};
