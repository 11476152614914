import { ClickableNameList, NameBase } from '~/components/ui/display/clickable-name-list';
import { DialogSubTitle } from '../common';

export type RightTypeBlockProps<T> = {
  title: string;
  rightHolders?: T[];
  onRightsHolderClick?: (name: T) => void;
};

export function RightTypeBlock<T extends NameBase>(props: RightTypeBlockProps<T>) {
  const { title, rightHolders, onRightsHolderClick } = props;

  return rightHolders?.length ? (
    <div className="mb-3">
      <DialogSubTitle className="mb-1 text-lg">{title}</DialogSubTitle>
      <ClickableNameList names={rightHolders} onNameClick={onRightsHolderClick} />
    </div>
  ) : null;
}
