import * as DialogPrimitive from '@radix-ui/react-dialog';
import { DialogContainer } from '../common';
import { LicensingQuoteHeader } from './header';
import { LicensingQuoteForm } from './form';
import { useState } from 'react';
import { useLicensingQuoteDialog } from '~/hooks/dialog/licensing-quote/use-licensing-quote-dialog';
import { LicensingQuoteFooter } from './footer';

type RightHoldersDialogProps = {
  children: React.ReactNode;
};

export function LicensingQuoteDialog(props: RightHoldersDialogProps) {
  const { children } = props;
  const [open, setOpen] = useState(false);
  const { handleSubmit, control, clearFields, track, isPending } = useLicensingQuoteDialog({
    onSuccess: () => setOpen(false),
  });

  return (
    <DialogPrimitive.Root open={open} onOpenChange={setOpen}>
      <DialogPrimitive.Trigger asChild>{children}</DialogPrimitive.Trigger>
      <DialogContainer className="px-0">
        <LicensingQuoteHeader clearFields={clearFields} track={track} />
        <LicensingQuoteForm onSubmit={handleSubmit} control={control} />
        <LicensingQuoteFooter isPending={isPending} />
      </DialogContainer>
    </DialogPrimitive.Root>
  );
}
