import { useSearchStore } from '~/stores/search-store';
import { useTranslation } from 'react-i18next';
import Select, { OptionType } from '~/components/ui/forms/select/select';
import { useMemo } from 'react';
const TrackSort = () => {
  const search = useSearchStore(['similarityTrack', 'similarityURL', 'sort', 'setSort', 'query']);
  const { t } = useTranslation('search');

  const options = useMemo(
    () => [
      { value: 'relevance', label: t('results.sort.relevance') },
      { value: 'popularity', label: t('results.sort.popularity') },
      { value: 'release_date', label: t('results.sort.recently') },
    ],
    [t],
  );

  const isSimilaritySearch = useMemo(
    () => search.similarityTrack.id !== undefined || search.similarityURL !== undefined,
    [search.similarityTrack.id, search.similarityURL],
  );

  const defaultValue = useMemo(() => {
    switch (search.sort) {
      case 'relevance':
        return options[0];
      case 'popularity':
        return options[1];
      case 'release_date':
        return options[2];
    }
  }, [options, search.sort]);

  function handleChange(option: OptionType) {
    if (option.value) search.setSort(option.value);
  }

  return (
    <Select
      id="track-sort"
      value={defaultValue}
      onSingleValueChange={handleChange}
      options={options}
      searchable={false}
      disabled={isSimilaritySearch}
    />
  );
};

export default TrackSort;
