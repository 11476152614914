import { TrackStepsContainer } from '../../../track-steps.container';
import TrackInfoGroup from '../../../../common/track-infos/track-info-group';
import { MatchDisplayFooter } from './match-display-footer';
import { useTracksUploadContext } from '~/components/entities/upload/tracks/tracks-upload.context';
import { useCallback, useEffect, useMemo } from 'react';
import { UploadTrack } from '~/types/features/track-upload/track-upload.types';
import { MatchDisplayHeader } from './match-display-header';
import { StepForm } from '../../step-form/form';
import { useTracksUploadItemContext } from '../../../../tracks-upload-item.context';
import { useGetMatchByUploadId } from '~/hooks/api/upload';
import { ResultMatchMetadata } from '~/types/schemas/track-upload/track-match.schema';
import { MatchDisplayLoader } from './match-display-loader';

type StepMatchDisplayProps = {
  file: UploadTrack;
};

export const StepMatchDisplay = (props: StepMatchDisplayProps) => {
  const { file } = props;
  const { store } = useTracksUploadContext();
  const { isEditing, presignedData } = useTracksUploadItemContext();

  const setStep = store.use.setStep();
  const sortFiles = store.use.sortFiles();
  const setSelected = store.use.setSelected();
  const initSelected = store.use.initSelected();
  const setEditing = store.use.setEditing();
  const setMatchedMetadata = store.use.setMatchedMetadata();

  const { data, isLoading, isSuccess, isError } = useGetMatchByUploadId(presignedData?.id);

  useEffect(() => {
    if (isError) {
      setStep(file.id, 'noMatch');
      initSelected(file.id, 'noMatch');
    }
  }, [file.id, isError, setStep, initSelected]);

  useEffect(() => {
    if (!data) return;
    const isMatch = isSuccess && data.metadata?.match?.result;
    if (isMatch) {
      setStep(file.id, 'match');
      setMatchedMetadata(file.id, data.metadata?.match?.result as ResultMatchMetadata);
      initSelected(file.id, 'match');
    } else {
      setStep(file.id, 'noMatch');
      initSelected(file.id, 'noMatch');
    }
    sortFiles();
  }, [data, isSuccess, file.id, initSelected, setStep, sortFiles, setSelected, setMatchedMetadata]);

  const { isSelected } = useTracksUploadItemContext();

  const handleEditClick = useCallback(() => {
    setEditing(file.id);
  }, [setEditing, file.id]);

  const handleHeaderClick = useCallback(() => {
    setSelected(file.id);
  }, [setSelected, file.id]);

  const fileData = useMemo(
    () => (file.matchedMetadata?.title ? file.matchedMetadata : file.metadata),
    [file.metadata, file.matchedMetadata],
  );

  if (isLoading) {
    return <MatchDisplayLoader />;
  }

  if (isEditing) {
    return <StepForm file={file} />;
  }

  return (
    <TrackStepsContainer>
      <MatchDisplayHeader
        file={file}
        data={fileData}
        onHeaderClick={handleHeaderClick}
        onEditClick={handleEditClick}
      />
      {isSelected ? (
        <div className="h-fit">
          <TrackInfoGroup track={fileData} />
          <MatchDisplayFooter file={file} />
        </div>
      ) : null}
    </TrackStepsContainer>
  );
};
