import Chip from '~/components/ui/control/chip/chip';
import { useTranslation } from 'react-i18next';
import { useBriefContext } from '../../brief.context';

export function ConfidentialityChip() {
  const { data } = useBriefContext();
  const { confidentiality } = data;
  const { t } = useTranslation('brief-page');

  let confidentialityLabel: string | null = '';

  switch (confidentiality) {
    case 'confidential':
      confidentialityLabel = t('confidentiality.confidential');
      break;

    case 'high':
      confidentialityLabel = t('confidentiality.high');
      break;

    case 'extreme':
      confidentialityLabel = t('confidentiality.extreme');
      break;

    default:
      confidentialityLabel = confidentiality;
  }

  return confidentialityLabel ? (
    <Chip iconLeft="ri:key-2-fill" className="bg-neutral-emphasis w-fit cursor-auto capitalize">
      {confidentialityLabel}
    </Chip>
  ) : null;
}
