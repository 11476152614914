import { UseInfiniteQueryResult, InfiniteData } from '@tanstack/react-query';
import { Range } from '@tanstack/react-virtual';
import { useState, useMemo } from 'react';
import { ZodSchema } from 'zod';
import { useGetPlaylistTracks, useReorderTracksInPlaylist } from '~/hooks/api/playlist';
import { useTrackItemSort } from '~/hooks/interactions/use-music-item-sort';
import { InfinitePage } from '~/types/schemas/api/infinite-page.schema';
import { Track } from '~/types/schemas/music/track.schema';
import { flattenInfiniteData } from '~/utils/common/parse-infinite-data';

export function usePlaylistTrackItemList(id: string) {
  const {
    data: tracks,
    fetchNextPage,
    isLoading,
    hasNextPage,
    isFetchingNextPage,
  } = useGetPlaylistTracks(id, 100) as UseInfiniteQueryResult<
    InfiniteData<InfinitePage<ZodSchema<Track>>>
  >;
  const { mutate: reorderPlaylistTracks } = useReorderTracksInPlaylist(id);

  const flatTracks = flattenInfiniteData(tracks);

  useTrackItemSort({
    tracks: (flatTracks || []) as Track[],
    onSort: (newIds: string[]) => reorderPlaylistTracks({ ids: newIds }),
  });

  const [currentLimit, setCurrentLimit] = useState(50);
  const currentTracks = useMemo(
    () => flatTracks?.slice(0, currentLimit),
    [flatTracks, currentLimit],
  );

  function handleNextPageFetch({ endIndex }: Range) {
    if (endIndex >= (currentTracks?.length || 0) - 30 && !isFetchingNextPage) {
      if ((flatTracks?.length || 0) > currentLimit) {
        setCurrentLimit(currentLimit + 50);
      } else if (hasNextPage) fetchNextPage();
    }
  }

  return {
    tracks: currentTracks || [],
    hasNextPage,
    isLoading,
    isFetching: isFetchingNextPage,
    handleNextPageFetch,
  };
}
