/* eslint-disable no-console */
import * as Sentry from '@sentry/react';
import type { CaptureContext, SeverityLevel } from '@sentry/core';

const isDevEnv = import.meta.env.VITE_APP_ENV?.toString().toUpperCase() === 'DEV';

// Overload signatures
function errorHandler(err: Error, captureContext?: CaptureContext): void;
function errorHandler(err: string, captureContext?: CaptureContext | SeverityLevel): void;

// Implementation
function errorHandler(err: Error | string, captureContext?: CaptureContext | SeverityLevel): void {
  if (isDevEnv) {
    console.group('ERROR HANDLER');
    if (typeof err !== 'string') {
      console.error(err?.message);
      console.error(err?.stack);
      console.error(captureContext);
    } else {
      console.error(err);
      console.error(captureContext);
    }
    console.groupEnd();
  } else if (!isDevEnv) {
    if (typeof err !== 'string') {
      const context = captureContext as CaptureContext;
      Sentry.captureException(err, context);
    } else if (typeof err === 'string') {
      Sentry.captureMessage(err, captureContext);
    }
  }
}

export default errorHandler;
