import { Icon, IconifyIconProps } from '@iconify/react';
import classNames from 'classnames';
import { Tooltip } from '~/components/ui/overlays/tooltip/tooltip';

type ListHeaderLabelProps = {
  label?: string;
  icon?: IconifyIconProps['icon'];
  tooltip?: string;
  className?: string;
};

export function ListHeaderLabel(props: ListHeaderLabelProps) {
  const { label, icon, tooltip, className } = props;

  return (
    <Tooltip label={tooltip} asChild>
      <span className={classNames('text-muted text-xs', className)}>
        {icon ? <Icon icon={icon} className="text-sm" /> : null}
        {label}
      </span>
    </Tooltip>
  );
}
