import * as DropdownMenu from '~/components/ui/control/menus/dropdown-menu/common/dropdown-menu';

type SubmenuTriggerProps = {
  label: string;
  numberOfTracks?: number;
};

export function SubmenuTrigger(props: SubmenuTriggerProps) {
  const { label, numberOfTracks } = props;

  return (
    <DropdownMenu.SubItem icon="ri:add-line">
      {label}
      {numberOfTracks ? <span>{` (${numberOfTracks})`}</span> : null}
    </DropdownMenu.SubItem>
  );
}
