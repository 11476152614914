import { z } from 'zod';
import { ArtworkSchema } from '../schemas/common/image.schema';

const supportedLocales = z.union([
  z.literal('en-US'),
  z.literal('es-ES'),
  z.literal('fr-FR'),
  z.literal('de-DE'),
  z.literal('pt-PT'),
  z.literal('ja-JP'),
]);

export const SuggestedSearchesSchema = z.object({
  artworks: ArtworkSchema.optional(),
  searchParams: z.string(),
  locales: z.record(
    supportedLocales,
    z.object({
      title: z.string(),
    }),
  ),
});

export type SuggestedSearches = z.infer<typeof SuggestedSearchesSchema>;
