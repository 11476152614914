import * as ContextMenu from '~/components/ui/control/menus/context-menu/common/context-menu';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AddToPlaylistContent } from './playlist-content';
import { AddToBriefContent } from './brief-content';
import { SubmenuTrigger } from './submenu-trigger';
import { Track } from '~/types/schemas/music/track.schema';
import { AddToTracklistProvider } from '../../../dropdown-menu/track-actions/add-to-tracklist/add-to-tracklist.context';

type TrackActionsAddToTracklistProps = {
  type: 'brief' | 'playlist';
  selectedTracks?: Track[];
};

export function TrackActionsAddToTracklist(props: TrackActionsAddToTracklistProps) {
  const { type, selectedTracks } = props;
  const { t } = useTranslation('track-interactions');

  useEffect(() => {
    // disable navigation with keyboard
    document.addEventListener('keydown', (e) => {
      if (e.key === 'Escape') {
        e.preventDefault();
        e.stopPropagation();
      }
    });
  }, []);

  if (type === 'brief') {
    return (
      <AddToTracklistProvider selectedTracks={selectedTracks}>
        <ContextMenu.SubMenu>
          <SubmenuTrigger label={t('add-brief.trigger')} numberOfTracks={selectedTracks?.length} />
          <AddToBriefContent />
        </ContextMenu.SubMenu>
      </AddToTracklistProvider>
    );
  }

  if (type === 'playlist') {
    return (
      <AddToTracklistProvider selectedTracks={selectedTracks}>
        <ContextMenu.SubMenu>
          <SubmenuTrigger
            label={t('add-playlist.trigger')}
            numberOfTracks={selectedTracks?.length}
          />
          <AddToPlaylistContent />
        </ContextMenu.SubMenu>
      </AddToTracklistProvider>
    );
  }

  return null;
}
