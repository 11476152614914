import * as AlertDialogPrimitive from '@radix-ui/react-alert-dialog';
import Button from '~/components/ui/control/button/button';

type AlertDialogCancelProps = {
  label: string;
  onClick?: () => void;
};

export function AlertDialogCancel(props: AlertDialogCancelProps) {
  const { label, onClick } = props;

  function handleClick() {
    onClick?.();
  }

  return (
    <AlertDialogPrimitive.Cancel asChild>
      <Button color="default" iconLeft="close-line" onClick={handleClick}>
        {label}
      </Button>
    </AlertDialogPrimitive.Cancel>
  );
}
