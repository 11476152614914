import Tab from '../tab';
import ComplexSearchExamples from './complex-search-examples';
import FiltersHighlightBlock from './filters-highlight-block';
import HighlightResultBlock from './highlight-result-block';
import NewReleaseBlock from './new-release-block';
import PreReleaseBlock from './pre-release-block';

function Home() {
  return (
    <Tab id="presearch-home-tab" value="home">
      <div className="col-span-full flex gap-6">
        <HighlightResultBlock />
        <ComplexSearchExamples />
      </div>
      <FiltersHighlightBlock />
      <NewReleaseBlock />
      <PreReleaseBlock />
    </Tab>
  );
}

export default Home;
