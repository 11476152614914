import classNames from 'classnames';
import BriefFormFooter from '../components/footer';
import BriefFormHeader from '../components/header';

type BriefFormTabProps = {
  title: string;
  briefTitle?: string;
  isFirstStep?: boolean;
  isLastStep?: boolean;
  displayFooter?: boolean;
  children: React.ReactNode;
  onExitClick?: () => void;
  onDiscardAndExitClick?: () => void;
  onSaveAndExitClick?: () => void;
  onNextClick?: () => void;
  onPreviousClick?: () => void;
  onSendClick?: () => void;
  onDeleteClick?: () => void;
};

function Tab(props: BriefFormTabProps) {
  const {
    title = '',
    briefTitle = '',
    isFirstStep = false,
    isLastStep = false,
    displayFooter = true,
    children,
    ...actions
  } = props;
  console.log('actions', actions);
  return (
    <>
      <BriefFormHeader title={title} briefTitle={briefTitle} {...actions} />
      <div className="form-content flex h-full flex-col gap-4">{children}</div>
      {displayFooter ? (
        <div
          className={classNames('pointer-events-auto pb-6 opacity-100 transition-all duration-400')}
        >
          <BriefFormFooter {...actions} isFirstStep={isFirstStep} isLastStep={isLastStep} />
        </div>
      ) : null}
    </>
  );
}

export default Tab;
