import { Artwork } from '~/components/ui/cover/artwork';
import { useTrackSummaryContext } from './track-summary.context';

export function TrackSummaryCover() {
  const { title, artwork } = useTrackSummaryContext();

  return (
    <div className="relative size-full rounded-sm select-none">
      <Artwork alt={title} artworks={artwork?.small} icon="ri:music-line" className="text-base" />
    </div>
  );
}
