import { Icon } from '@iconify/react';
import * as RadixDropdownMenu from '@radix-ui/react-dropdown-menu';
import { useTranslation } from 'react-i18next';
import { useBriefContext } from '~/pages/brief/brief.context';
import classNames from 'classnames';
import { SHORT_LANGUAGES } from '~/constants/languages';
import { TranslateDropdownMenu } from '../menus/dropdown-menu/translate/translate';
import { useTranslateSelectContext } from './translate-select.context';

export function TranslateToAnotherLanguageButton() {
  const { userLanguage, targetLanguage, onLanguageChange } = useTranslateSelectContext();
  const { isLoading } = useBriefContext();
  const { t } = useTranslation('translate-select');

  return (
    <RadixDropdownMenu.Root>
      <div
        className={classNames(
          'bg-button-default-rest border-button-default-rest flex h-10 w-fit items-center gap-2 rounded-lg border p-2',
          {
            'pointer-events-none animate-pulse': isLoading,
          },
        )}
      >
        <Icon icon="ri:translate-ai-2" />
        <div className="flex flex-col pr-1.5 text-xs">
          <div className="flex gap-1">
            <span>
              {t('detected-language')} →{' '}
              {SHORT_LANGUAGES.find((l) => l.value === targetLanguage)?.label}
            </span>
          </div>
          <span
            onClick={() => onLanguageChange(undefined)}
            className="cursor-pointer hover:underline"
          >
            {t('show-original')}
          </span>
        </div>
        <RadixDropdownMenu.Trigger
          type="button"
          className="group/trigger hover:bg-neutral-emphasis outline-focus flex size-6 items-center justify-center rounded-lg border border-transparent outline-hidden transition-all focus:outline-1"
        >
          <Icon
            icon="ri:settings-3-line"
            className="text-muted group-focus/trigger:text-default group-hover/trigger:text-default text-lg"
          />
        </RadixDropdownMenu.Trigger>
      </div>
      <TranslateDropdownMenu
        userLanguage={userLanguage}
        targetLanguage={targetLanguage}
        onLanguageChange={onLanguageChange}
        align="end"
        alignOffset={-8}
        sideOffset={16}
      />
    </RadixDropdownMenu.Root>
  );
}
