import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import SearchFilters from '~/components/features/search-filters';
import { SearchTrackItemList } from '~/components/entities/track/track-item-list/search/search-track-item-list';
import ResultNumber from './components/search-top-page/components/result-number';
import TrackSort from './components/track-sort';

const Search = () => {
  const { t } = useTranslation('search');
  const location = useLocation();

  function handleScrollUp() {
    const scrolParent = document.getElementById('content-container');
    if (!scrolParent) return;
    scrolParent.scrollTo({ top: 0 });
  }

  const sectionId = `${location.pathname.split('/').pop()}-${location.search}-section`;

  return (
    <section
      id={sectionId}
      className="relative flex h-full flex-1 flex-col items-stretch overflow-y-auto"
    >
      <SearchFilters className="px-4 pt-4" />
      <div className="sticky -top-1 z-10 px-4" onClick={handleScrollUp}>
        <div className="bg-default flex h-12 w-full items-center gap-4">
          <h1 className="text-xl font-bold">{t('results.title')}</h1>
          <ResultNumber />
          <div className="ml-auto flex items-center gap-3">
            <TrackSort />
          </div>
        </div>
      </div>
      <div className="h-full">
        <div className="relative flex h-fit min-h-full flex-col px-4">
          <SearchTrackItemList scrollParentId={sectionId} />
        </div>
      </div>
    </section>
  );
};

export default Search;
