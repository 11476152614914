import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { globalStore } from '~/stores/global-store-v2';
import TrackInfoItem from './track-info-item';
import { UploadTrack } from '~/types/features/track-upload/track-upload.types';
import { mewoDataStore } from '~/stores/mewo-data-store-v2';

type TrackCardInformationsProps = {
  track: UploadTrack['metadata'] | UploadTrack['matchedMetadata'] | null;
};

export default function TrackInfoGroup(props: TrackCardInformationsProps) {
  const { track } = props;
  const { user } = globalStore;
  const { versions } = mewoDataStore;
  const { t } = useTranslation('tracks-upload-form');

  const musicType =
    (track?.musicType === 'core' && t('music-type.core', { ns: 'common' })) ||
    (track?.musicType === 'commercial' && t('music-type.prod', { ns: 'common' }));

  // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
  const version = versions.object.value[track?.version!]?.name;

  return (
    <ul className="flex flex-col gap-3 px-5 py-4">
      <div className="flex shrink-0 items-end gap-4">
        <TrackInfoItem
          className="flex-1"
          label={t('card.informations.music-type')}
          value={musicType || '-'}
        />
        <TrackInfoItem
          className="flex-1"
          label={t('card.informations.version')}
          value={version || '-'}
        />
      </div>
      <TrackInfoItem label={t('card.informations.title')} value={track?.title} />
      <TrackInfoItem label={t('card.informations.artists')} value={track?.artists?.join(', ')} />
      <div className="flex items-center gap-3">
        <TrackInfoItem
          className="flex-1"
          label={t('card.informations.album')}
          value={track?.album}
        />
        <TrackInfoItem
          className="flex-1"
          label={t('card.informations.catalog')}
          value={track?.catalog || user.value?.company_name || 'Catalog'}
        />
      </div>
      <TrackInfoItem
        label={t('card.informations.release')}
        value={track?.releaseDate ? dayjs(track?.releaseDate).format('MMMM DD, YYYY') : '-'}
      />
    </ul>
  );
}
