import { FormFooter } from './form-footer';
import { StepFormContent } from './content/step-form-content';
import { FormProvider, useForm } from 'react-hook-form';
import { UploadTrack } from '~/types/features/track-upload/track-upload.types';
import { TrackStepsContainer } from '../../track-steps.container';
import { FormHeader } from './form-header';
import { memo, useMemo } from 'react';
import { useTracksUploadContext } from '~/components/entities/upload/tracks/tracks-upload.context';
import { useTracksUploadItemContext } from '../../../tracks-upload-item.context';
import { TrackMetadata } from '~/types/features/track-upload/track-metadata.types';
import { useValidateTrack } from '~/hooks/api/upload';
import errorHandler from '~/utils/error-handler';
import { getValidateParams } from './content/validate-match-helper';

type StepFormProps = {
  file: UploadTrack;
};

export type StepFormData = TrackMetadata;

export const StepForm = memo((props: StepFormProps) => {
  const { file } = props;
  const methods = useForm<StepFormData>({
    defaultValues: {
      musicType: file.matchedMetadata?.musicType,
      version: file.matchedMetadata?.version,
    },
  });
  const { handleSubmit, setError, getValues } = methods;
  const { store } = useTracksUploadContext();
  const { presignedData, isCoverUploaded } = useTracksUploadItemContext();
  const setTrackMetadata = store.use.setTrackMetadata();
  const setSelected = store.use.setSelected();
  const setState = store.use.setState();
  const setNextSelected = store.use.setNextSelected();
  const { isSelected } = useTracksUploadItemContext();
  const { mutate: validateTrack, isPending } = useValidateTrack(presignedData?.id);

  const data = useMemo(
    () => (file.matchedMetadata?.title ? file.matchedMetadata : file.metadata),
    [file.metadata, file.matchedMetadata],
  );

  function handleSubmitForm(formData: StepFormData) {
    if (!isCoverUploaded) {
      setError('cover', { message: 'Uploading ...' });
      return;
    }
    setTrackMetadata(file.id, formData);
    validateTrack(getValidateParams(formData), {
      onSuccess: () => {
        setState(file?.id, 'validated');
        setNextSelected();
      },
      onError: (error) => {
        setState(file?.id, 'failed');
        errorHandler(error, {
          extra: {
            file: file,
            form: getValues(),
          },
        });
      },
    });
  }

  //
  return (
    <TrackStepsContainer>
      <FormHeader track={data} onHeaderClick={() => setSelected(file.id)} />
      {isSelected ? (
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(handleSubmitForm)} className="grid h-full grid-rows-5">
            <StepFormContent file={file} data={data} />
            <FormFooter isPending={isPending} />
          </form>
        </FormProvider>
      ) : null}
    </TrackStepsContainer>
  );
});
