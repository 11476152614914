import * as AlertDialogPrimitive from '@radix-ui/react-alert-dialog';
import Button from '~/components/ui/control/button/button';

type AlertDialogActionProps = {
  label: string;
  onClick: () => void;
};

export function AlertDialogAction(props: AlertDialogActionProps) {
  const { label, onClick } = props;

  return (
    <AlertDialogPrimitive.Action asChild>
      <Button color="danger" iconLeft="check-line" onClick={onClick}>
        {label}
      </Button>
    </AlertDialogPrimitive.Action>
  );
}
