import classNames from 'classnames';
import Cover from '~/components/ui/cover/cover';
import { Artwork } from '~/types/schemas/common/image.schema';

type SuggestedSearchProps = {
  artwork?: Artwork;
  title: string;
  onClick?: () => void;
};

function SuggestedSearch(props: SuggestedSearchProps) {
  const { artwork, title, onClick } = props;
  return (
    <button
      type="button"
      onClick={onClick}
      className={classNames(
        'flex items-center gap-3 rounded-lg p-2 text-left',
        'hover:bg-neutral-muted cursor-pointer',
      )}
    >
      <Cover artworks={artwork && [artwork]} alt={title} className="aspect-2/1! w-20!" />
      <h5 className="line-clamp-1 text-sm font-bold">{title}</h5>
    </button>
  );
}

export default SuggestedSearch;
