import Icon from '~/components/ui/icon/icon';

type MenuProps = {
  onDeleteClick?: () => void;
};

function Menu(props: MenuProps) {
  const { onDeleteClick } = props;
  return (
    <div className="flex items-center gap-2">
      {onDeleteClick ? (
        <button
          type="button"
          className="flex h-3 w-3 items-center justify-center"
          onClick={onDeleteClick}
        >
          <Icon name="delete-bin-line" className="text-muted" />
        </button>
      ) : null}
    </div>
  );
}

export default Menu;
