import { t } from 'i18next';
import { useSearchStore } from '~/stores/search-store';
import ContainChip from './contains-chip';
import FilterSection from './filter-section';

export function Query() {
  const search = useSearchStore(['query']);
  return search.query ? (
    <FilterSection label={t('contains.title')}>
      <ContainChip />
    </FilterSection>
  ) : null;
}
