import { motion } from 'framer-motion';
import { CategoryProps, Category } from './category';

export function CategoryList(props: { categories?: CategoryProps[] }) {
  const { categories } = props;

  const listVariants = {
    hidden: {
      transition: {
        staggerChildren: 0.01,
        staggerDirection: -1,
      },
    },
    visible: {
      transition: {
        staggerChildren: 0.01,
      },
    },
  };

  const itemVariants = {
    hidden: {
      opacity: 0,
      x: -16,
    },
    visible: {
      opacity: 1,
      x: 0,
    },
  };

  return (
    <motion.ul variants={listVariants} initial="hidden" animate="visible" exit="hidden">
      {categories?.map((category) => (
        <motion.li variants={itemVariants}>
          <Category key={category.id} {...category} />
        </motion.li>
      ))}
    </motion.ul>
  );
}
