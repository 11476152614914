import { useSearchStore } from '~/stores/search-store';
import { useEffect, useState } from 'react';
import RangeSlider from '~/components/ui/control/slider/range.slider';
import FilterDropDown from './filter-dropdown';
import { useTranslation } from 'react-i18next';

const Popularity = () => {
  const search = useSearchStore(['popularity', 'setPopularity', 'resetPopularity']);
  const { from, to } = search.popularity || { from: 0, to: 100 };
  const [currentValue, setCurrentValue] = useState<[number | undefined, number | undefined]>([
    from,
    to,
  ]);
  const { t } = useTranslation(['filter']);

  const handleCommit = (value: number[]) => {
    search.setPopularity(value[0]!, value[1]!);
  };

  const handleDelete = () => {
    search.resetPopularity();
    setCurrentValue([undefined, undefined]);
  };

  useEffect(() => {
    setCurrentValue([from, to]);
  }, [from, to]);

  const isActive = currentValue[0] !== undefined && currentValue[1] !== undefined;

  return isActive ? (
    <FilterDropDown
      label={t('popularity.title')}
      isActive={isActive}
      triggerContent={
        <span className="text-muted text-xs">{`${currentValue[0]} - ${currentValue[1]}`}</span>
      }
      className="w-36"
      onDelete={handleDelete}
    >
      <div className="flex flex-col gap-3">
        <RangeSlider
          value={currentValue as [number, number]}
          min={0}
          max={100}
          aria-label="popularity-slider"
          onValueChange={setCurrentValue}
          onSlideEnd={handleCommit}
          className="w-64"
        />
      </div>
    </FilterDropDown>
  ) : null;
};

export default Popularity;
