import * as ContextMenuPrimitive from '@radix-ui/react-context-menu';
import { ContextMenuDialogItem } from './dialog-item';
import { ContextMenuSubItem } from './sub-item';
import { ContextSubMenu } from './sub-menu';
import { ContextSubMenuContainer } from './sub-menu-container';
import { ContextMenuItem } from './item';

type ContextMenuContainerProps = {
  children: React.ReactNode;
};

export function ContextMenu(props: ContextMenuContainerProps) {
  const { children } = props;

  return (
    <ContextMenuPrimitive.Portal>
      <ContextMenuPrimitive.Content className="bg-control-rest border-control-rest z-0 w-fit min-w-60 rounded-md border p-1">
        {children}
      </ContextMenuPrimitive.Content>
    </ContextMenuPrimitive.Portal>
  );
}

const Content = ContextMenu;
const DialogItem = ContextMenuDialogItem;
const Item = ContextMenuItem;
const SubItem = ContextMenuSubItem;
const SubMenu = ContextSubMenu;
const SubMenuContainer = ContextSubMenuContainer;

export { Content, DialogItem, Item, SubItem, SubMenu, SubMenuContainer };
