import { PresignedUploadData } from '~/types/schemas/file-upload-session/upload.schema';
import errorHandler from '../error-handler';

type Params = {
  presignedData: PresignedUploadData;
  file?: File;
  onProgress?: (progress: number) => void;
  onUploadCompleted?: () => void;
  onError?: (err: string) => void;
};

export function uploadOnS3(params: Params & { controller?: AbortController }) {
  return new Promise((resolve, reject) => {
    const {
      presignedData: upload,
      file,
      onProgress,
      onUploadCompleted,
      onError,
      controller,
    } = params || {};
    const { url, fields } = upload || {};

    if (!file || !url) {
      errorHandler('Upload failed: file or url missing', {
        extra: { file, upload },
      });
      reject(new Error('File or URL is missing'));
      return;
    }

    const body = new FormData();
    if (fields) {
      Object.entries(fields).forEach(([key, value]) => {
        body.append(key, value);
      });
    }
    body.append('file', file);

    const xhr = new XMLHttpRequest();

    if (controller) {
      // Check for abort signal
      controller.signal.addEventListener('abort', () => {
        xhr.abort();
        resolve(xhr);
      });
    }

    xhr.upload.addEventListener('progress', (ev) => {
      if (ev.lengthComputable) {
        const progress = Math.round((ev.loaded / ev.total) * 100);
        onProgress?.(progress);
      } else {
        errorHandler('Upload progress not computable', {
          extra: { ev, file, upload },
        });
      }
    });

    xhr.onloadend = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        onUploadCompleted?.();
        return resolve(xhr); // Resolve the promise with the xhr object on success
      }
    };

    xhr.onreadystatechange = function (ev) {
      if (xhr.readyState === 4 && xhr.status > 204) {
        errorHandler('Http error upload', {
          extra: { error: ev, file, upload },
        });
        onError?.(`Upload failed with http status: ${xhr.status}`);
      }
    };

    xhr.onerror = (ev) => {
      errorHandler('Network or upload error occurred', {
        extra: { error: ev, file, upload },
      });
      onError?.('Network or upload error occurred');
      reject(new Error('Network or upload error occurred'));
    };

    xhr.open('POST', url, true);
    xhr.send(body);
  });
}
