/* eslint-disable camelcase */
import { useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Locale } from '~/types/schemas/common/locale.schema';
import Information from '~/pages/brief/components/information';
import DescriptionFooter from '~/components/description-footer';

type PlaylistDescriptionProps = {
  descriptions?: Locale[];
};

const LOCALE = 'en';

const PlaylistDescription = (props: PlaylistDescriptionProps) => {
  const { descriptions } = props;
  const [isExpanded, setIsExpanded] = useState(false);
  const { t } = useTranslation('playlist-page');

  if (!props) return null;
  const description =
    descriptions?.find((d) => d.locale === LOCALE)?.value ||
    descriptions?.find((d) => d.value)?.value ||
    '';

  return (
    <div
      className={classNames(
        'group/description bg-neutral-emphasis relative h-fit w-full overflow-hidden rounded-lg p-2',
        {
          'max-h-36': !isExpanded,
        },
      )}
    >
      <div className="flex">
        <Information
          title={t('header.playlist-content.description.label')}
          className="w-[70%] pb-6"
        >
          <div className="gap-2 text-xs whitespace-pre-wrap">{description}</div>
        </Information>
      </div>
      <DescriptionFooter extend={isExpanded} onExtendClick={() => setIsExpanded(!isExpanded)} />
    </div>
  );
};

export default PlaylistDescription;
