import useMusemindStore from '~/stores/musemind-store';
import MuseMindDndContext from '~/pages/brief/components/musemind-dnd-context';
import usePreSearchStore from '~/stores/presearch-store';
import DraggableDiv from './components/draggable-div';
import ResizableDiv from './components/resizable-div';
import VideoController from './components/video-controller';
import { createPortal } from 'react-dom';

const Musemind = () => {
  const presearch = usePreSearchStore(['isOpen']);
  const musemind = useMusemindStore(['isPipOpen', 'videoElement']);

  return createPortal(
    <MuseMindDndContext>
      {musemind.videoElement && musemind.isPipOpen && !presearch.isOpen ? (
        <DraggableDiv className="animate-fade-in absolute right-0 bottom-0 z-20 h-fit w-[35%]">
          <ResizableDiv minWidth={480}>
            <VideoController />
          </ResizableDiv>
        </DraggableDiv>
      ) : null}
    </MuseMindDndContext>,
    document.body,
  );
};

export default Musemind;
