import classNames from 'classnames';
import { useTrackSummaryChip } from '~/hooks/entities/track/summary/use-track-summary-chip';

export function TrackSummaryStatusChip() {
  const { status } = useTrackSummaryChip();

  return status ? (
    <div
      className={classNames('h-4 w-fit rounded-sm px-1 text-xs font-medium select-none', {
        [`bg-${status?.color}`]: status?.color,
      })}
    >
      {status?.label}
    </div>
  ) : null;
}
