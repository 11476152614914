import classNames from 'classnames';
import { FooterExtendButton } from '~/components/ui/control/extend-button';

type DescriptionFooterProps = {
  extend: boolean;
  onExtendClick: () => void;
};

export default function DescriptionFooter(props: DescriptionFooterProps) {
  const { extend, onExtendClick } = props;

  return (
    <div
      className={classNames(
        'absolute bottom-0 left-0 flex h-12 w-full items-center justify-center bg-linear-to-b from-transparent transition-all',
        {
          'to-neutral-muted!': !extend,
        },
      )}
    >
      <div className="relative size-full">
        <FooterExtendButton
          extend={extend}
          onExtendClick={onExtendClick}
          className="absolute top-0 left-1/2 -translate-x-1/2 translate-y-20 transition-transform duration-100 group-hover/description:translate-y-0"
        />
      </div>
    </div>
  );
}
