export const userRoutes = {
  login: 'dashboard/user/login',
  logout: 'dashboard/user/logout',
  user: 'dashboard/user',
  verifyOtp: 'dashboard/user/otp/verify',
};

export const optionRoutes = {
  other: 'searchmode/options/others',
};

export const dashboardRoutes = {
  providers: 'dashboard/users/providers/all',
  agents: 'dashboard/users/agents/all',
  favoritesAgents: 'dashboard/user/agent/:id/favorites',
};

export const briefRoutes = {
  briefs: 'meta/briefs',
  briefMeta: 'meta/briefs/:id',
  brief: 'searchmode/brief/:id',
  translateBrief: 'searchmode/brief/:id/translate/:language',
  tracksMeta: 'meta/briefs/:id/tracks',
  tracks: 'searchmode/brief/:id/tracks',
  getThreadChannels: 'searchmode/brief/:id/thread/channels',
  getThreadChannelParticipants: 'searchmode/brief/:id/thread/channel/:trackId/participants',
  getThreadTracksDetail: 'searchmode/brief/:id/thread/tracks',
  duplicate: 'meta/briefs/:id/duplicate',
  search: 'searchmode/brief/search',
};

export const playlistRoutes = {
  playlists: 'meta/playlists',
  playlistMeta: 'meta/playlists/:id',
  playlist: 'searchmode/playlist/:id',
  tracksMeta: 'meta/playlists/:id/tracks',
  tracks: 'searchmode/playlist/:id/tracks',
  tracksReorder: 'meta/playlists/:id/tracks/reorder',
  duplicate: 'meta/playlists/:id/duplicate',
  reorder: 'meta/playlists/:id/tracks/reorder',
  search: 'searchmode/playlist/search',
};

export const tracklistRoutes = {
  search: 'searchmode/track-listing/search',
  detail: 'searchmode/:type/:id',
  tracksMeta: 'meta/:type/:id/tracks',
  tracks: 'searchmode/:type/:id/tracks',
  copyright: 'meta/copyright/:type/:id',
  tracklists: 'meta/:type',
  archive: 'meta/archive',
  variationsBulk: 'searchmode/track/variations/bulk',
};

export const catalogRoutes = {
  search: 'searchmode/catalog/search',
};

export const albumRoutes = {
  album: 'searchmode/album/:id',
  search: 'searchmode/album/search',
};

export const artistRoutes = {
  search: 'searchmode/artist/search',
};

export const trackRoutes = {
  download: 'meta/tracks/:id/download',
  copyright: 'meta/copyright/track/:id',
  createVariation: 'searchmode/track/variations',
  licenseQuote: 'searchmode/track/:id/license-request-email',
  search: 'searchmode/track/search',
  similarity: 'searchmode/track/similarity/url',
  versions: 'searchmode/track/:id/versions',
};

export const tagsRoutes = {
  search: 'searchmode/options/tags/search',
  list: 'searchmode/options/tags',
};

export const realtimeRoutes = {
  token: 'dashboard/realtime/token',
};

export const uploadRoutes = {
  createUploadSession: 'upload/session',
  createUpload: 'upload/',
  session: 'upload/session/:id',
  upload: 'upload/:id',
  validateUpload: 'upload/:id/validate',
  matchUpload: 'upload/:id/match',
  searchTrack: 'upload/match/search-spotify',
  ingestUploadSession: 'upload/session/:id/ingest',
};
