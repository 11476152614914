import classNames from 'classnames';
import Chip from '~/components/ui/control/chip/chip';
import { useTranslation } from 'react-i18next';
import { useBriefContext } from '../../brief.context';

export function TrackNumberChip() {
  const { data } = useBriefContext();
  const { t } = useTranslation('brief-page');
  const { tracks } = data;

  return (
    <Chip
      className={classNames('cursor-default', {
        'bg-success-emphasis': tracks,
        'bg-danger-emphasis': !tracks,
      })}
    >
      {t('header.tracks-number', { count: tracks })}
    </Chip>
  );
}
