import classNames from 'classnames';
import { useCallback, useEffect, useRef, useState } from 'react';
import useMusemindStore from '~/stores/musemind-store';

type ResizableDivProps = {
  minWidth?: number;
  className?: string;
  children: React.ReactNode;
};

const ORIGINAL_MOUSE_POSITION = { x: 0, y: 0 };
const ORIGINAL_CONTAINER_POSITION = { x: 0, y: 0 };
const ORIGINAL_CONTAINER_SIZE = { width: 0, height: 0 };
let CONTAINER_RATIO = 0;

const ResizableDiv = (props: ResizableDivProps) => {
  const { minWidth, className, children } = props;
  const [isResizing, setIsResizing] = useState(false);
  const [activeCorner, setActiveCorner] = useState('');
  const resizableRef = useRef<HTMLDivElement>(null);
  const musemind = useMusemindStore(['pipPosition', 'setPipPosition']);

  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      const { clientX, clientY } = e;
      let newWidth;
      let newHeight;
      let newTop;
      let newLeft;

      if (musemind.pipPosition) {
        const sizeDiff =
          (ORIGINAL_MOUSE_POSITION.x - clientX + (ORIGINAL_MOUSE_POSITION.y - clientY)) / 3;
        const sizeDiffX = (ORIGINAL_MOUSE_POSITION.x - clientX) / 2;
        const sizeDiffY = ORIGINAL_MOUSE_POSITION.y - clientY;

        switch (activeCorner) {
          case 'nw':
            newWidth = ORIGINAL_CONTAINER_SIZE.width + sizeDiff * CONTAINER_RATIO;
            newHeight = ORIGINAL_CONTAINER_SIZE.height + sizeDiff;
            newTop = ORIGINAL_CONTAINER_POSITION.y - sizeDiff;
            newLeft = ORIGINAL_CONTAINER_POSITION.x - sizeDiff * CONTAINER_RATIO;
            break;
          case 'ne':
            newWidth = ORIGINAL_CONTAINER_SIZE.width + sizeDiffY * CONTAINER_RATIO;
            newHeight = ORIGINAL_CONTAINER_SIZE.height + sizeDiffY;
            newTop = ORIGINAL_CONTAINER_POSITION.y - sizeDiffY;
            newLeft = ORIGINAL_CONTAINER_POSITION.x;
            break;
          case 'sw':
            newWidth = ORIGINAL_CONTAINER_SIZE.width + sizeDiffX * CONTAINER_RATIO;
            newHeight = ORIGINAL_CONTAINER_SIZE.height + sizeDiffX;
            newTop = ORIGINAL_CONTAINER_POSITION.y;
            newLeft = ORIGINAL_CONTAINER_POSITION.x - sizeDiffX * CONTAINER_RATIO;
            break;
          case 'se':
            newWidth = ORIGINAL_CONTAINER_SIZE.width - sizeDiff * CONTAINER_RATIO;
            newHeight = ORIGINAL_CONTAINER_SIZE.height - sizeDiff;
            newTop = ORIGINAL_CONTAINER_POSITION.y;
            newLeft = ORIGINAL_CONTAINER_POSITION.x;
            break;
          default:
            break;
        }

        if (minWidth && newWidth && newWidth < minWidth) return;

        if (newWidth && newHeight && newLeft && newTop) {
          musemind.setPipPosition({
            ...musemind.pipPosition,
            width: newWidth,
            height: newHeight,
            top: newTop,
            left: newLeft,
          });
        }
      }
    },
    [activeCorner, minWidth, musemind],
  );

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    ORIGINAL_MOUSE_POSITION.x = e.clientX;
    ORIGINAL_MOUSE_POSITION.y = e.clientY;
    ORIGINAL_CONTAINER_POSITION.x = resizableRef.current?.getBoundingClientRect().x || 0;
    ORIGINAL_CONTAINER_POSITION.y = resizableRef.current?.getBoundingClientRect().y || 0;
    ORIGINAL_CONTAINER_SIZE.width = resizableRef.current?.getBoundingClientRect().width || 0;
    ORIGINAL_CONTAINER_SIZE.height = resizableRef.current?.getBoundingClientRect().height || 0;
    CONTAINER_RATIO = ORIGINAL_CONTAINER_SIZE.width / ORIGINAL_CONTAINER_SIZE.height;
    setActiveCorner(e.currentTarget.id);
    setIsResizing(true);
  };

  useEffect(() => {
    if (isResizing) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', () => {
        setIsResizing(false);
      });
    }

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', () => {
        setIsResizing(false);
      });
    };
  }, [handleMouseMove, isResizing]);

  return (
    <div ref={resizableRef} className={classNames('h-full w-full', className)}>
      <div className="relative h-full">
        {children}
        <div
          id="nw"
          onMouseDown={handleMouseDown}
          className="resize-corner absolute top-0 left-0 h-6 w-6 cursor-nwse-resize"
        />
        <div
          id="sw"
          onMouseDown={handleMouseDown}
          className="resize-corner absolute bottom-0 left-0 h-6 w-6 cursor-nesw-resize"
        />
        <div
          id="ne"
          onMouseDown={handleMouseDown}
          className="resize-corner absolute top-0 right-0 h-6 w-6 cursor-nesw-resize"
        />
        <div
          id="se"
          onMouseDown={handleMouseDown}
          className="resize-corner absolute right-0 bottom-0 h-6 w-6 cursor-nwse-resize"
        />
      </div>
    </div>
  );
};

export default ResizableDiv;
