import { useTranslation } from 'react-i18next';
import { DialogDescription, DialogTitle } from '../common';
import { DialogExit } from '../common/exit';
import Button from '~/components/ui/control/button/button';
import { Track } from '~/types/schemas/music/track.schema';

type LicensingQuoteHeaderProps = {
  clearFields: () => void;
  track: Track;
};

export function LicensingQuoteHeader({ clearFields, track }: LicensingQuoteHeaderProps) {
  const { t } = useTranslation('request-form');

  return (
    <div className="mb-2 flex w-full items-center justify-between px-4">
      <div>
        <DialogTitle>{t('header.title')}</DialogTitle>
        <DialogDescription className="mr-4 line-clamp-1">
          {`${track.title} - ${track.display_artists.map((artist) => artist.name).join(', ')}`}
        </DialogDescription>
      </div>
      <div className="flex items-center gap-3">
        <Button
          color="default"
          onClick={clearFields}
          className="border-neutral-muted bg-transparent!"
        >
          {t('header.button.clear')}
        </Button>
        <DialogExit>{t('header.button.exit')}</DialogExit>
      </div>
    </div>
  );
}
