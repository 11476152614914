import { withScope, captureException, Scope } from '@sentry/react';
import { QueryClient, MutationCache, QueryCache, QueryClientProvider } from '@tanstack/react-query';
import { HTTPError } from 'ky';
import { globalStore } from '~/stores/global-store-v2';

function CustomQueryClientProvider({ children }: { children: React.ReactNode }) {
  const queryClient = new QueryClient({
    mutationCache: new MutationCache({
      onError: async (err, _variables, _context, mutation) => {
        await withScope(async (scope) => {
          const fingerprint: Array<string> = [];

          if (mutation.options.mutationKey) {
            scope.setFingerprint(
              // Duplicate to prevent modification
              Array.from(mutation.options.mutationKey) as string[],
            );
          }
          if (err instanceof HTTPError) {
            await setScopeResponseContext(err.response, scope);
            fingerprint.push(err.response.status.toString());
          }

          scope.setContext('mutation', {
            mutationId: mutation.mutationId,
            variables: mutation.state.variables,
          });
          scope.setFingerprint(fingerprint);
          captureException(err);
        });
        await handleUnauthorized(err);
      },
    }),
    queryCache: new QueryCache({
      onError: async (err, query) => {
        await withScope(async (scope) => {
          const fingerprint: Array<string> = [];

          fingerprint.push(query.queryHash);

          if (err instanceof HTTPError) {
            await setScopeResponseContext(err.response, scope);
            fingerprint.push(err.response.status.toString());
          }

          scope.setContext('query', { queryHash: query.queryHash });
          scope.setFingerprint(fingerprint);
          captureException(err);
        });
        await handleUnauthorized(err);
      },
    }),
    defaultOptions: {
      queries: {
        retry: false,
        refetchOnWindowFocus: false,
        staleTime: Infinity,
      },
    },
  });

  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
}
export default CustomQueryClientProvider;

const parseErrorResponse = async (response: Response) => {
  const type = response.headers.get('content-type');
  if (type?.includes('application/json')) {
    return (await response.clone().json()) as Record<string, unknown>;
  }
  if (type?.includes('text/plain')) {
    return { message: await response.clone().text() };
  }
  return { message: 'Unknown error format' };
};

const handleUnauthorized = async (err: Error) => {
  if (!(err instanceof HTTPError) || !globalStore.user.value) return;

  const status = err.response.status;

  if (status === 401) globalStore.handleUnauthorized();
  if (status === 400) {
    const body = await err.response.clone().json();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((body as any).key === 'invalid_token') {
      globalStore.handleUnauthorized();
    }
  }
};

const setScopeResponseContext = async (
  response: HTTPError<unknown>['response'],
  scope: Scope,
): Promise<Scope> => {
  const parseResponse = await parseErrorResponse(response);
  scope.setContext('HTTPError', parseResponse);

  // SET low level if unauthorized
  if (response.status === 401) scope.setLevel('warning');
  if (response.status === 400) {
    if (parseResponse.key && parseResponse.key === 'invalid_token') {
      scope.setLevel('warning');
    }
  }

  return scope;
};
