import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { MouseEvent } from 'react';
import { Brief } from '~/types/schemas/music/brief.schema';
import { Playlist } from '~/types/schemas/music/playlists.schema';
import { globalStore } from '~/stores/global-store-v2';
import { Track } from '~/types/schemas/music/track.schema';
import { useTracklistSearch } from '~/hooks/api/track-list';
import Tracklist from './tracklist';
import { useTracklist } from '../../hooks/use-add-to-tracklist';

type TracklistListProps = {
  track: Track;
  query: string;
  collections: ('briefs' | 'playlists')[];
  onOpenChange: (b: boolean) => void;
};

function TracklistList(props: TracklistListProps) {
  const { track, query, collections, onOpenChange } = props;
  const { user } = globalStore;
  const { data: tracklists, isLoading } = useTracklistSearch(collections, query, track.id);
  const { addToTracklist, removeFromTracklist } = useTracklist(track);

  function handleAdd(
    e: MouseEvent,
    hasTrack: boolean,
    tracklist: { refType: 'brief' | 'playlist'; refId: string },
  ) {
    e.stopPropagation();
    if (hasTrack) {
      removeFromTracklist(tracklist);
    } else {
      addToTracklist({ type: tracklist.refType, id: tracklist.refId });
    }
    onOpenChange(false);
  }

  if (isLoading) {
    return (
      <>
        {Array.from(Array(10).keys()).map((_item, i) => (
          <div
            key={`loading-item-${i}`}
            className="mt-2 flex h-5 w-full animate-pulse gap-1 rounded-sm"
          >
            <div className="bg-neutral-muted aspect-square w-5 rounded-sm" />
            <div className="bg-neutral-muted aspect-square w-5 rounded-sm" />
            <div className="bg-neutral-muted flex-1 rounded-sm" />
          </div>
        ))}
      </>
    );
  }

  const flatedTracklists = tracklists?.pages.flatMap((page) => page.hits);

  function chooseIconName(tracklist: Brief | Playlist, type: 'brief' | 'playlist') {
    const userIsCreator = user.value?.id === tracklist.created_by?.id;
    switch (type) {
      case 'brief':
        return 'brief-fill';
      case 'playlist':
        return userIsCreator ? 'playlist-line' : 'collab-playlist-line';
      default:
        return undefined;
    }
  }

  return (
    <>
      {flatedTracklists?.map((item, itemIndex) => {
        return (
          <DropdownMenu.Item
            key={`item-${itemIndex}`}
            className="w-full overflow-hidden outline-hidden"
          >
            <Tracklist
              label={item.value.title}
              type={item.type}
              id={item.value.id}
              ownedByUser={item.value.created_by?.id === user.value?.id}
              icon={chooseIconName(item.value, item.type)}
              className="tracklist__item"
              hasTrack={item.has_track}
              onClick={(e) =>
                handleAdd(e, item.has_track, {
                  refType: item.type,
                  refId: item.value.id!,
                })
              }
            />
          </DropdownMenu.Item>
        );
      })}
    </>
  );
}

export default TracklistList;
