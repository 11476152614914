import { Icon } from '@iconify/react';
import * as Accordion from '@radix-ui/react-accordion';
import { useState, useEffect } from 'react';
import { useSearchTagsAccordion } from '~/hooks/utils/use-tags-accordion';
import usePreSearchStore from '~/stores/presearch-store';
import { PopulatedTagCategory } from '~/types/schemas/data/tags.schema';
import { TagProps } from '../tag';
import { SubCategoryProps } from './sub-category';
import { TagList } from './tag-list';
import SubCategoryList from './sub-category-list';
import { AccordionContent } from './accordion-content';

export interface CategoryProps {
  id: string;
  name: string;
  tags?: TagProps[];
  subCategories?: SubCategoryProps[];
}

function CategoryContent(props: { subCategories?: SubCategoryProps[] }) {
  const { subCategories } = props;
  const { subCategoryDefaultOpenState } = useSearchTagsAccordion(
    subCategories?.map((subCategory) => subCategory.id),
  );
  const { query } = usePreSearchStore(['query']);
  const [values, setValues] = useState<string[]>([]);

  useEffect(() => {
    const newDefaultValue =
      subCategories
        ?.filter((category) => subCategoryDefaultOpenState?.(category as PopulatedTagCategory))
        .map((category) => category.id) || [];

    setValues(query ? newDefaultValue : []);
  }, [query]);

  return subCategories?.length ? (
    <Accordion.Root
      value={values}
      onValueChange={(v) => setValues(v)}
      type="multiple"
      className="flex w-full flex-col gap-4 overflow-hidden"
    >
      <SubCategoryList subCategories={subCategories} />
    </Accordion.Root>
  ) : null;
}

// TODO: maybe try to remove the presearch query check outside of the component
export function Category(props: CategoryProps) {
  const { id, name, tags, subCategories } = props;

  return (
    <div
      id={`category-${id}`}
      className="bg-default border-neutral-emphasis mb-3 flex w-full flex-col overflow-hidden rounded-lg border"
    >
      <Accordion.Item value={id}>
        <Accordion.Trigger className="group group flex w-full flex-1 items-center justify-between rounded-lg p-3 px-5">
          <span>{name}</span>
          <Icon
            icon="ri:arrow-down-s-line"
            className="text-muted transition-transform duration-300 ease-[cubic-bezier(0.87,_0,_0.13,_1)] group-data-[is-open=true]:rotate-180"
          />
        </Accordion.Trigger>
        <AccordionContent>
          <TagList tags={tags} />
          <CategoryContent subCategories={subCategories} />
        </AccordionContent>
      </Accordion.Item>
    </div>
  );
}
