import { components, PlaceholderProps, GroupBase } from 'react-select';
import { OptionType } from '../select';

const Placeholder = (props: PlaceholderProps<OptionType, boolean, GroupBase<OptionType>>) => {
  const { children } = props;

  return (
    <components.Placeholder
      className="text-control-placeholder! inset-0 m-0! flex h-full items-center capitalize"
      {...props}
    >
      {children}
    </components.Placeholder>
  );
};

export default Placeholder;
