import { memo } from 'react';
import { Original } from './original/original';
import { TrackSteps } from './track-steps/track-steps';
import { TracksUploadItemProvider } from './tracks-upload-item.context';
import { UploadTrack } from '~/types/features/track-upload/track-upload.types';

type TracksUploadItemProps = {
  file: UploadTrack;
  index: number;
};

export const TracksUploadItem = memo((props: TracksUploadItemProps) => {
  const { file, index } = props;

  return (
    <TracksUploadItemProvider data={file}>
      <div id={file.id} className="grid w-full grid-cols-2 gap-2">
        <Original file={file} index={index} />
        <TrackSteps file={file} />
      </div>
    </TracksUploadItemProvider>
  );
});
