import { createWithEqualityFn } from 'zustand/traditional';
import { FilterList } from '~/types/schemas/search/filter.schema';
import { Artwork } from '~/types/schemas/common/image.schema';
import { PresearchNavigationItem } from '~/components/features/presearch/components/navigation/navigation';
import { withImmer } from './middlewares/immer-combine';
import { createStorePicker } from './utilities';

export type FilterTerms = keyof FilterList;

export type ResultType = {
  id: string;
  term: FilterTerms;
  cover?: Artwork[];
  title: string;
  subtitle?: string;
};

export type SearchResultType = {
  total: number;
  results: ResultType[];
};

const defaultTabResultsNumbers: Record<PresearchNavigationItem, Record<string, number>> = {
  tags: {},
  targets: {},
  catalogs: {},
  samplers: {},
  albums: {},
  artists: {},
  playlists: {},
  briefs: {},
};

const usePreSearchStore = createStorePicker(
  createWithEqualityFn(
    withImmer({
      isOpen: false,
      query: '',
      colSize: 5,
      videoURL: undefined as { youtubeId: string; title: string; jobId?: string } | undefined,
      currentTab: 'home' as PresearchNavigationItem,
      tabResultsNumbers: defaultTabResultsNumbers,
    })((set) => ({
      setIsOpen: (isOpen: boolean, tab?: string) =>
        set((state) => {
          state.isOpen = isOpen;
          if (tab) state.currentTab = tab;
        }),
      setQuery: (query: string) =>
        set((state) => {
          state.query = query;
        }),
      setCurrentTab: (tab: PresearchNavigationItem) =>
        set((state) => {
          state.currentTab = tab;
        }),
      resetVideoURL: () =>
        set((state) => {
          state.videoURL = undefined;
        }),
      setVideoURL: (youtubeId: string, title: string) =>
        set((state) => {
          state.videoURL = {
            youtubeId,
            title,
            jobId: undefined,
          };
        }),
      setURLJobID: (jobId: string) =>
        set((state) => {
          if (state.videoURL) {
            state.videoURL.jobId = jobId;
          }
        }),
      setTabResultsNumbers: (tab: PresearchNavigationItem, query: string, total: number) =>
        set((state) => {
          state.tabResultsNumbers[tab]![query] = total;
        }),
    })),
  ),
);

export default usePreSearchStore;
