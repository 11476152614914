import useMusemindStore from '~/stores/musemind-store';
import usePlayerStore from '~/stores/player-store';
import { useTranslation } from 'react-i18next';
import { Icon } from '@iconify/react';
import { useMemo } from 'react';
import { Tooltip } from '~/components/ui/overlays/tooltip/tooltip';

export function Controls() {
  const player = usePlayerStore(['togglePlay', 'isPlaying']);
  const musemind = useMusemindStore(['isPlaying', 'setPlay']);
  const { t } = useTranslation('player');

  const icon = useMemo(() => {
    return player.isPlaying ? 'ic:baseline-pause' : 'ic:round-play-arrow';
  }, [player.isPlaying]);

  const tooltip = useMemo(() => {
    return player.isPlaying ? t('pause') : t('play');
  }, [player.isPlaying, t]);

  const handleTogglePlay = () => {
    if (musemind.isPlaying) {
      musemind.setPlay(false);
    }

    player.togglePlay();
  };

  return (
    <Tooltip label={tooltip} align="start" delayDuration={1000} asChild>
      <button
        onClick={handleTogglePlay}
        className="bg-inverse flex aspect-square w-7 cursor-pointer items-center justify-center gap-4 rounded-full"
      >
        <Icon icon={icon} className="text-on-inverse!" />
      </button>
    </Tooltip>
  );
}
