import Button from '~/components/ui/control/button/button';
import { useTracksUploadContext } from '../tracks-upload.context';
import { useTranslation } from 'react-i18next';
import { TracksUploadAlertDialog } from '~/components/ui/overlays/alert-dialogs/tracks-upload/tracks-upload-alert-dialog';

export function CloseDialog() {
  const { onExit } = useTracksUploadContext();
  const { t } = useTranslation('tracks-upload-form');

  return (
    <TracksUploadAlertDialog onExit={onExit}>
      <Button color="default" className="brief-form__button--exit">
        {t('header.exit')}
      </Button>
    </TracksUploadAlertDialog>
  );
}
