import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from '~/components/ui/control/button/button';

type ErrorPageProps = {
  title: string;
  message: string;
  errorCode: number | string;
};

export function ErrorPage(props: ErrorPageProps) {
  const { title, message, errorCode } = props;
  const navigate = useNavigate();
  const { t } = useTranslation('common');

  function handleBackToHome() {
    navigate('/');
  }

  return (
    <section className="h-full w-full p-12">
      <div className="flex h-full flex-col items-center justify-center">
        <h1 className="text-muted mb-3 text-9xl font-bold">{errorCode}</h1>
        <h2 className="text-default text-4xl">{title}</h2>
        <p className="text-default mt-1">{message}</p>
        <Button color="primary" className="mt-4 w-fit" onClick={handleBackToHome}>
          {t('back-home')}
        </Button>
      </div>
    </section>
  );
}
