import usePreSearchStore from '~/stores/presearch-store';
import Button from '~/components/ui/control/button/button';
import { useTranslation } from 'react-i18next';
function ClosePresearchButton() {
  const presearch = usePreSearchStore(['setIsOpen']);
  const { t } = useTranslation(['pre-search']);

  function handleClick() {
    presearch.setIsOpen(false);
  }

  return (
    <Button color="default" iconLeft="close-line" onClick={handleClick}>
      {t('header.close.label')}
    </Button>
  );
}

export default ClosePresearchButton;
