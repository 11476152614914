import { useRelativeTime } from '~/hooks/common/use-relative-time';
import classNames from 'classnames';
import { realtimeStore } from '~/stores/realtime-store';
import { parseMessage } from '~/components/features/brief-chats/components/chat/chat-messages/message-group/message/message-parser';
import { useComputed } from '@preact/signals-react';
import { Message } from '~/utils/chat/message';
import { ChatNotificationMessage } from '~/types/types/chat-notification';
import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { globalStore } from '~/stores/global-store-v2';
import Button from '~/components/ui/control/button/button';
import { Icon } from '@iconify/react';

export type NotificationPanelCardProps = {
  notification: Message<ChatNotificationMessage>;
  onClick: () => void;
  markRead: () => void;
};

function NotificationCard(props: NotificationPanelCardProps) {
  const { notification, onClick } = props;
  const { user } = globalStore;
  const time = useRelativeTime(+notification.timetoken / 1e4);
  const textParts = parseMessage(notification.content.data.text);
  const from = useComputed(() => realtimeStore.users.value[notification.userId]);
  const isUnread = useComputed(
    () => !notification.readActions?.find((action) => action.uuid === user.value?.id),
  );
  const { t } = useTranslation('notification-center');

  useEffect(() => {
    if (!from.value) {
      realtimeStore.addUserToFetch(notification.userId);
    }
  }, [from.value]);

  return (
    <div
      className="group border-neutral-emphasis bg-neutral-emphasis hover:bg-neutral-muted relative flex cursor-pointer flex-col rounded-lg border p-3"
      onClick={onClick}
    >
      <div className="flex flex-col pr-3">
        <h4 className="text-default text-xs font-bold">{notification.content.data.brief.title}</h4>
        <p className="text-muted mb-2 text-xs">{notification.content.data.track.title}</p>
        <div className="flex flex-col gap-0.5">
          <span className="text-xs">
            {notification.content.type === 'mention' ? (
              <span className="text-muted">
                <Trans
                  t={t}
                  i18nKey="content.mention"
                  defaults="You have been mentioned by <bold>{{name}}</bold> :"
                  values={{ name: from.value?.name }}
                  components={{ bold: <b className="text-default" /> }}
                />
                {'You have been mentioned by '}
                <b className="text-default">{`${from.value?.name} :`}</b>
              </span>
            ) : null}
            {notification.content.type === 'message' ? (
              <span className="text-muted">
                <Trans
                  t={t}
                  i18nKey="content.message"
                  defaults="New message from <bold>{{user}}</bold> :"
                  values={{ user: from.value?.name }}
                  components={{ bold: <b className="text-default" /> }}
                />
              </span>
            ) : null}
          </span>
          <p className="text-muted line-clamp-3 text-xs">
            {textParts.map((part, i) => (
              <span
                key={`${part.text}-${part.type}-${i}`}
                className={classNames({
                  'font-semibold': part.type === 'mention',
                })}
              >
                {part.text}
              </span>
            ))}
          </p>
        </div>
      </div>
      <div className="mt-1 flex items-center gap-1">
        <Icon icon="ri:time-line" className="size-3" />
        <p className="text-muted text-xs">{time}</p>
      </div>
      {isUnread.value ? (
        <>
          <div className="bg-accent-emphasis absolute top-1 right-1 h-3 w-3 rounded-full" />
          <div className="flex w-full justify-end">
            <Button
              color="default"
              iconLeft="check-double-line"
              onClick={(e) => {
                e.stopPropagation();
                props.markRead();
              }}
            >
              {t('pop-in.card.button.mark-as-read')}
            </Button>
          </div>
        </>
      ) : null}
    </div>
  );
}

export default NotificationCard;
