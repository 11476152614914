import * as RadixDropdownMenu from '@radix-ui/react-dropdown-menu';
import * as DropdownMenu from '../common/dropdown-menu';
import { useNotificationCenter } from './hooks/use-notification-center';

import { useEffect } from 'react';
import { Icon } from '@iconify/react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import NotificationGroup from './components/notification-group/notification-group';
import { EmptyPlaceholder } from './components/empty-placeholder';

type NotificationsDropdownMenuProps = {} & RadixDropdownMenu.DropdownMenuContentProps;

export const NotficationsDropdownMenu = (props: NotificationsDropdownMenuProps) => {
  const { ...rest } = props;
  const { channel, handleCardClick, notifications, showLoadMoreButton } = useNotificationCenter();
  const { t } = useTranslation('notification-center');

  useEffect(() => {
    if (channel && channel.lastMessage.value) {
      channel.setLastReadMessageTimetoken(channel.lastMessage.value.timetoken);
    }
  }, [channel, channel?.lastMessage.value]);

  return (
    <DropdownMenu.Content {...rest} style={{ zIndex: 10 }} align="end" sideOffset={5}>
      <div className="flex h-8 items-center gap-2 px-1">
        <h2 className="text-sm font-bold">{t('pop-in.header.title')}</h2>
      </div>
      <div className="flex h-full flex-col gap-2">
        {notifications.length ? (
          <NotificationGroup
            notifications={notifications}
            channel={channel}
            onCardClick={handleCardClick}
            showLoadMoreButton={!!showLoadMoreButton}
          />
        ) : (
          <EmptyPlaceholder />
        )}
      </div>
      <div className="mt-1 flex h-8 items-center px-1">
        <NavLink
          to="/notifications"
          className="text-accent outline-focus flex w-fit items-center gap-1 rounded-sm pr-2 text-sm outline-hidden focus:outline-1"
        >
          <Icon icon="ri:arrow-right-up-line" />
          {t('pop-in.footer.link')}
        </NavLink>
      </div>
    </DropdownMenu.Content>
  );
};
