import { createContext, useContext } from 'react';
import { Artwork } from '~/types/schemas/common/image.schema';
import { DisplayArtist } from '~/types/schemas/music/artist.schema';

type TrackSummaryContext = {
  id: string;
  title: string;
  displayArtists: DisplayArtist[];
  artwork?: Artwork;
};

const TrackSummaryContext = createContext<TrackSummaryContext | null>(null);

function useTrackSummaryContext() {
  const context = useContext(TrackSummaryContext);

  if (!context) {
    throw new Error('useTrackSummaryContext must be used within a TrackSummary.Root');
  }
  return context;
}

export { TrackSummaryContext, useTrackSummaryContext };
