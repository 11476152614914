import { Icon } from '@iconify/react';
import { components, MultiValueRemoveProps } from 'react-select';

const MultiValueRemove = (props: MultiValueRemoveProps) => {
  return (
    <components.MultiValueRemove {...props}>
      <Icon icon="ri:close-fill" className="text-muted" />
    </components.MultiValueRemove>
  );
};

export default MultiValueRemove;
