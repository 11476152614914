import Cover from '~/components/ui/cover/cover';
import classNames from 'classnames';
import { memo, useEffect } from 'react';
import { ChatChannelCustom, ChatMessageContent, ChatMessageMeta } from '~/types/types/chat';
import { useInView } from 'react-intersection-observer';
import { Channel } from '~/utils/chat/channel';
import { realtimeStore } from '~/stores/realtime-store';
import { briefChatsStore } from '~/stores/chats-store';
import { useComputed } from '@preact/signals-react';
import { globalStore } from '~/stores/global-store-v2';
import { useGetThreadTracks } from '~/hooks/api/thread';
import { ClickableNameList } from '~/components/ui/display/clickable-name-list';
import { parseMessage } from '../../chat/chat-messages/message-group/message/message-parser';
import ChatCardLoader from './chat-card-loader';
import { useTranslation } from 'react-i18next';

export type ChatCardProps = {
  channel: Channel<ChatMessageContent, ChatMessageMeta, ChatChannelCustom>;
  onClick?: () => void;
};

function ChatCard(props: ChatCardProps) {
  const { user } = globalStore;
  const { channel, onClick } = props;
  const { ref, inView } = useInView();
  const { t } = useTranslation('brief-chat');
  const textParts = parseMessage(
    channel.lastMessage.value?.deleted.value
      ? t('chat.hover.last-message.delete', { ns: 'music-item' })
      : channel.lastMessage.value?.content.text || '',
  );
  const { brief } = briefChatsStore;
  const { data: tracks, isLoading: trackIsLoading } = useGetThreadTracks(
    brief.value?.id,
    channel.custom?.trackId ? [channel.custom?.trackId] : [],
  );

  const messageUser = useComputed(() =>
    channel.lastMessage.value?.userId
      ? realtimeStore.users.value[channel.lastMessage.value.userId]
      : undefined,
  );
  const isUnread = useComputed(() => !channel.lastMessage.value?.isReadByUser(user.value!.id));

  useEffect(() => {
    if (inView) {
      channel.init();
    }
  }, [inView]);

  if (channel.isLoading.value || trackIsLoading) {
    return <ChatCardLoader ref={ref} />;
  }

  return (
    <div
      ref={ref}
      className="thread-list__item bg-neutral-emphasis border-neutral-muted hover:bg-neutral-muted relative flex cursor-pointer items-stretch gap-3 rounded-lg border py-3 pr-6 pl-3"
      onClick={onClick}
    >
      <Cover
        artworks={tracks?.[0]?.artwork && [tracks?.[0].artwork]}
        alt={tracks?.[0]?.title || ''}
        className="h-10! w-10! shrink-0 rounded-sm"
        iconClassName="text-base"
      />
      <div className="flex w-full flex-col gap-3 overflow-hidden">
        <div>
          <h6 className="text-default line-clamp-1 text-sm">{tracks?.[0]?.title}</h6>
          <ClickableNameList names={tracks?.[0]?.display_artists} />
        </div>
        <div className="flex w-full flex-col gap-1 text-xs">
          <span className="text-default line-clamp-1">
            {messageUser.value ? (
              `${messageUser.value?.name} (${messageUser.value?.custom?.company})`
            ) : (
              <span className="text-muted italic">{t('thread.no-message')}</span>
            )}
          </span>

          <p className="text-muted line-clamp-3">
            {textParts.length > 0 &&
              textParts.map((part, i) => (
                <span
                  key={`${part.text}-${part.type}-${i}`}
                  className={classNames({
                    'font-semibold': part.type === 'mention',
                  })}
                >
                  {part.text}
                </span>
              ))}
          </p>
          {/* <span className="text-default">{`${messageCount} Messages`}</span> */}
        </div>
      </div>
      {isUnread.value ? (
        <div className="bg-accent-emphasis absolute top-3 right-3 h-3 w-3 rounded-full" />
      ) : null}
    </div>
  );
}

export default memo(ChatCard);
