import { useSearchStore } from '~/stores/search-store';
import FilterSection from './filter-section';
import SimilarChip from './similar-chip';
import { useTranslation } from 'react-i18next';

export function SimilarityTrack() {
  const search = useSearchStore(['similarityTrack', 'similarityURL']);
  const { t } = useTranslation('filter');

  return search.similarityTrack?.id || search.similarityURL?.jobId ? (
    <FilterSection label={t('similarity.title')}>
      <SimilarChip />
    </FilterSection>
  ) : null;
}
