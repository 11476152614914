import { useTrackContext } from '~/components/entities/track/common/track.context';
import useMusemindStore from '~/stores/musemind-store';
import { useCreateTrackVariations } from '~/hooks/api/track';
import { globalStore } from '~/stores/global-store-v2';
import { useGenerateMixAction } from '~/hooks/api/action';
import { useMagicPreviewData } from './use-magic-preview-data';

export type MagicPreviewStatus =
  | 'ready'
  | 'track-not-ready'
  | 'track-ready-to-variation'
  | 'video-missing'
  | 'track-too-short'
  | 'pending'
  | 'error';

export function useMagicPreview() {
  const trackStore = useTrackContext();
  const track = trackStore.use.track();
  const {
    setAudioTracks,
    videoElement,
    setIsUploadDialogOpen,
    setIsCreateVariationsLoading,
    setPlay,
  } = useMusemindStore([
    'videoElement',
    'setAudioTracks',
    'setIsUploadDialogOpen',
    'setIsCreateVariationsLoading',
    'setPlay',
  ]);

  const { mutate: generateMixAction } = useGenerateMixAction();
  const { mutate: createTrackVariations, isPending } = useCreateTrackVariations();
  const { data: realtimeData } = useMagicPreviewData(track.id);

  function startVariationsPlayback(urls: string[]) {
    if (!globalStore.loggedAs.value) {
      generateMixAction({ trackId: track.id });
    }
    setAudioTracks(track.title, track.id, urls);
  }

  function startVariationsCreation() {
    if (videoElement) {
      setPlay(false);
      setIsCreateVariationsLoading(true);
      createTrackVariations(
        {
          id: track.id,
          duration: videoElement.duration,
          variations: 3,
        },
        {
          onSuccess: (mutationData) => {
            if (mutationData) {
              startVariationsPlayback(mutationData.result);
            }
          },
          onSettled: () => {
            setIsCreateVariationsLoading(false);
          },
        },
      );
    }
  }

  function uploadVideo() {
    setIsUploadDialogOpen(true);
  }

  function handleStatus(): MagicPreviewStatus {
    if (!videoElement) {
      return 'video-missing';
    }

    if (!track.variation_ready) {
      return 'track-not-ready';
    }

    if (track.duration && track.duration < videoElement.duration) {
      return 'track-too-short';
    }

    if (realtimeData?.status === 'PROCESSING' || isPending) {
      return 'pending';
    }

    if (realtimeData?.status === 'FAILED') {
      return 'error';
    }

    if (!realtimeData) {
      return 'track-ready-to-variation';
    }

    return 'ready';
  }

  function handleMagicPreviewClick() {
    const status = handleStatus();

    switch (status) {
      case 'ready':
        if (realtimeData?.type !== 'MUSEMIND_TRACK_VARIATION') break;
        startVariationsPlayback(realtimeData?.data.results);
        break;
      case 'track-ready-to-variation':
        startVariationsCreation();
        break;
      case 'video-missing':
        uploadVideo();
        break;
      default:
        break;
    }
  }

  return {
    status: handleStatus(),
    onMagicPreviewClick: handleMagicPreviewClick,
  };
}

// TODO: Handle the case when the track is too short
