import { PropsWithChildren, useEffect, useState } from 'react';
import { Track } from '~/types/schemas/music/track.schema';
import { useCreateVariationsBulk } from '~/hooks/api/track-list';
import useMusemindStore from '~/stores/musemind-store';

type MagicPreviewProviderProps = PropsWithChildren<{
  tracks: Track[];
  preProcessTracks?: boolean;
}>;

export function useMagicPreviewPreprocess(props: MagicPreviewProviderProps) {
  const { tracks, preProcessTracks, children } = props;

  const { mutate: createVariations } = useCreateVariationsBulk();
  const [processedTracks, setProcessedTracks] = useState<string[]>([]);

  const { videoElement } = useMusemindStore(['videoElement']);

  useEffect(() => {
    if (preProcessTracks && videoElement) {
      const filteredTracks = tracks.filter((track) => {
        // If the track is already processed, we won't process it again
        if (processedTracks.includes(track.id)) {
          return false;
        }
        // If basics data is not available, we can't process the track
        if (!track.variation_ready || !track.duration || !videoElement.duration) {
          return false;
        }

        // If the track is less longer than the video, we can't process the track
        if (track.duration < videoElement.duration) {
          return false;
        }

        return true;
      });

      if (filteredTracks.length) {
        const filteredTracksIds = filteredTracks.map((track) => track.id);
        setProcessedTracks([...processedTracks, ...filteredTracksIds]);

        createVariations({
          ids: filteredTracksIds,
          duration: videoElement.duration,
          variations: 3,
        });
      }
    }
  }, [tracks, preProcessTracks, videoElement]);

  return children;
}
