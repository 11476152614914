import classNames from 'classnames';
import { useCallback } from 'react';
import { ChatMessageContent, ChatMessageMeta, NewChatMessage } from '~/types/types/chat';
import { Channel } from '~/utils/chat/channel';
import MessageGroup, { MessageGroupType } from './message-group/message-group';
import { useChatMessages } from './use-chat-messages';
import { useTranslation } from 'react-i18next';
import Button from '~/components/ui/control/button/button';

type MessagesListProps = {
  channel: Channel<ChatMessageContent, ChatMessageMeta>;
  className?: string;
};

function ChatMessages(props: MessagesListProps) {
  const { channel, className } = props;
  const { messages, showLoadMoreButton, loadMore } = useChatMessages(channel);
  const { t } = useTranslation('brief-chat');
  const handleLoadMore = useCallback(() => loadMore(), [loadMore]);
  const groupedMessages = messages.value.reduce((acc: MessageGroupType[], currentMessage) => {
    const lastGroup: MessageGroupType | undefined = acc.at(-1);
    const lastMessage: NewChatMessage | undefined = lastGroup?.messages.at(-1);
    const lastMessageTime = lastMessage?.timetoken
      ? new Date(parseInt(lastMessage.timetoken, 10) / 10e3).getTime()
      : 0;
    const currentMessageTime = new Date(parseInt(currentMessage.timetoken, 10) / 10e3).getTime();

    if (
      lastGroup &&
      lastGroup.userId === currentMessage.userId &&
      // Compare timetokens or use another criteria
      currentMessageTime - lastMessageTime < 5 * 60 * 1000
    ) {
      // Add the message to the existing group
      lastGroup.messages.push(currentMessage);
    } else {
      // Create a new group for the message
      acc.push({
        userId: currentMessage.userId,
        messages: [currentMessage],
        timetoken: currentMessage.timetoken,
      });
    }

    return acc;
  }, []);

  return (
    <div className={classNames('flex w-full flex-col-reverse items-center gap-4 pt-4', className)}>
      {groupedMessages
        .slice()
        .reverse()
        .map((group) => {
          return <MessageGroup key={group.timetoken} group={group} />;
        })}
      {showLoadMoreButton ? (
        <Button
          color="default"
          type="button"
          isLoading={channel.isLoadingMore.value}
          onClick={handleLoadMore}
          disabled={channel.isLoadingMore.value}
        >
          {t('thread.message-list.button.load-more.label')}
        </Button>
      ) : null}
    </div>
  );
}

export default ChatMessages;
