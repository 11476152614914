import { Combobox, ComboboxValueChangeDetails } from '@ark-ui/react';
import classNames from 'classnames';
import { ChangeEvent, ReactNode, useState } from 'react';
import { SelectInputPortal } from './portal';

export type SelectInputItem<T> = {
  value: string;
  content: T;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ComboboxInputProps<T = any> = {
  value?: string;
  items: SelectInputItem<T>[];
  className?: string;
  render: (item: T) => ReactNode;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onItemSelect?: (item: T) => void;
  label?: string;
  error?: string;
  required?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
};

export const ComboboxInput = (props: ComboboxInputProps) => {
  const {
    label,
    items,
    value = '',
    onChange,
    render,
    onItemSelect,
    className,
    error,
    required,
    disabled,
    isLoading,
  } = props;
  const [isFocused, setIsFocused] = useState(false);

  const containerClass = classNames(
    'w-full h-8 px-2 flex items-center gap-2 rounded-lg border border-control-rest bg-control-rest hover:border-control-hover hover:text-control-hover',
    {
      'border-control-danger-emphasis!': !!error,
      'border-control-disabled! bg-control-disabled!': disabled,
      'outline outline-focus focus:outline-1 outline-offset-2': isFocused,
    },
  );

  const inputClass = classNames(
    'h-full relative flex-1 text-sm outline-hidden bg-transparent text-control-rest placeholder:text-control-placeholder',
  );

  const labelClass = classNames('text-control-neutral text-xs text-control-neutral', {
    'text-control-danger': error !== undefined,
  });

  function handleFocus() {
    setIsFocused(true);
  }

  function handleBlur() {
    setIsFocused(false);
  }

  function handleItemSelect(e: ComboboxValueChangeDetails<SelectInputItem<unknown>>) {
    onItemSelect?.(e.items[0]?.content);
  }

  return (
    <Combobox.Root
      items={items}
      className={classNames('flex w-full flex-col gap-2', className)}
      onChange={onChange}
      onValueChange={handleItemSelect}
      openOnClick
      lazyMount
      unmountOnExit
    >
      <span className={labelClass} onClick={handleFocus}>
        {label && <Combobox.Label>{label}</Combobox.Label>}
        {required && <span className="text-control-danger">*</span>}
      </span>
      <div className={containerClass}>
        <Combobox.Control className="flex w-full items-center gap-3">
          <Combobox.Input
            onFocus={handleFocus}
            onBlur={handleBlur}
            className={inputClass}
            value={value}
          />
        </Combobox.Control>
      </div>
      <SelectInputPortal items={items} render={render} isLoading={isLoading} />
    </Combobox.Root>
  );
};
