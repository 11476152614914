import { PropsWithChildren } from 'react';
import Button from '~/components/ui/control/button/button';
import * as DialogPrimitive from '@radix-ui/react-dialog';

type DialogExitButtonProps = PropsWithChildren<{
  className?: string;
}>;

export function DialogExit(props: DialogExitButtonProps) {
  const { children, className } = props;

  return (
    <DialogPrimitive.Close asChild>
      <Button color="default" className={className}>
        {children}
      </Button>
    </DialogPrimitive.Close>
  );
}
