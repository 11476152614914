import * as RadixDropdownMenu from '@radix-ui/react-dropdown-menu';
import { DropdownMenuDialogItem } from './dialog-item';
import { DropdownMenuSubItem } from './sub-item';
import { ContextSubMenu } from './sub-menu';
import { ContextSubMenuContainer } from './sub-menu-container';
import { DropdownMenuItem } from './item';
import { DropdownMenuGroup } from './group';
import classNames from 'classnames';
import { DropdownMenuRadioItem } from './radio-item';

type DropdownMenuContainerProps = {
  children: React.ReactNode;
} & RadixDropdownMenu.DropdownMenuContentProps;

export function DropdownMenu(props: DropdownMenuContainerProps) {
  const { children, className, ...rest } = props;

  const classes = classNames(
    'bg-control-rest border border-control-rest w-fit rounded-md p-1 z-10',
    className,
  );

  return (
    <RadixDropdownMenu.Portal>
      <RadixDropdownMenu.Content className={classes} {...rest}>
        {children}
      </RadixDropdownMenu.Content>
    </RadixDropdownMenu.Portal>
  );
}
const Root = RadixDropdownMenu.Root;
const Trigger = RadixDropdownMenu.Trigger;
const Content = DropdownMenu;
const DialogItem = DropdownMenuDialogItem;
const Group = DropdownMenuGroup;
const Item = DropdownMenuItem;
const SubItem = DropdownMenuSubItem;
const SubMenu = ContextSubMenu;
const SubMenuContainer = ContextSubMenuContainer;
const RadioItem = DropdownMenuRadioItem;
const RadioGroup = RadixDropdownMenu.RadioGroup;

export {
  Root,
  Trigger,
  Content,
  DialogItem,
  Group,
  Item,
  SubItem,
  SubMenu,
  SubMenuContainer,
  RadioItem,
  RadioGroup,
};
