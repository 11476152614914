import { useTracksUploadContext } from '~/components/entities/upload/tracks/tracks-upload.context';
import Button from '~/components/ui/control/button/button';
import { useUploadQueueStore } from '~/stores/upload/upload-queue-store';
import { useTracksUploadItemContext } from '../../../tracks-upload-item.context';

export function CancelQueueButton() {
  const { store } = useTracksUploadContext();
  const { data } = useTracksUploadItemContext();

  const removeFromList = store.use.removeFromList();
  const removeTask = useUploadQueueStore.use.removeTask();

  function handleRemoveTask() {
    removeTask(data.id);
    removeFromList(data.id);
  }

  return (
    <Button color="default" onClick={handleRemoveTask}>
      Cancel
    </Button>
  );
}
