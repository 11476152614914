import classNames from 'classnames';
import { filesize } from 'filesize';

type FileProps = {
  name?: string;
  extension?: string;
  size?: number;
  onClick?: () => void;
};

function File(props: FileProps) {
  const { name, extension, size, onClick } = props;
  const parsedSize = size ? filesize(size, { standard: 'jedec' }) : null;

  return (
    <button
      type="button"
      onClick={onClick}
      className={classNames(
        'text-muted bg-neutral-muted group/file-preview border-neutral-emphasis outline-focus relative flex h-fit w-fit max-w-[85%] items-center gap-3 rounded-sm border py-1.5 pr-4 pl-2 text-start text-xs outline-hidden focus:outline-1',
      )}
    >
      <div onClick={props.onClick} className="text-xxs bg-neutral-emphasis rounded-sm p-1 px-2">
        {`${extension?.toUpperCase()}`}
      </div>
      <div className="flex flex-1 flex-col overflow-hidden">
        <span className="ellipsis text-default flex-1 overflow-hidden text-ellipsis">{name}</span>
        {size ? <span className="text-xxs text-muted">{parsedSize}</span> : null}
      </div>
    </button>
  );
}

export default File;
