import { UploadTrack } from '~/types/features/track-upload/track-upload.types';
import { Artwork } from '~/components/ui/cover/artwork';
import { TrackStepsHeaderArtists } from '../../headers/track-step-header-summary/track-step-header-artists';
import { TrackStepHeaderTitle } from '../../headers/track-step-header-summary/track-step-header-title';
import { HeaderContainer } from '../../../common/header-container';

type FormHeaderProps = {
  track: UploadTrack['matchedMetadata'] | UploadTrack['metadata'];
  onHeaderClick?: () => void;
};

export function FormHeader(props: FormHeaderProps) {
  const { track, onHeaderClick } = props;
  const { cover, title, artists } = track || {};

  return (
    <HeaderContainer onClick={onHeaderClick}>
      <div className="size-full">
        {cover && (
          <div className="from-neutral-emphasis absolute inset-0 h-full w-full bg-linear-to-r" />
        )}
        <img src={cover} alt="" className="h-full w-full object-cover object-center blur-3xl" />
      </div>
      <div className="absolute inset-0 flex flex-1 items-center gap-2 px-3">
        <div className="bg-muted size-14 rounded-lg">
          <Artwork artworks={cover ? [cover] : undefined} className="text-xs" />
        </div>
        <div className="flex flex-1 flex-col justify-center overflow-hidden">
          <TrackStepHeaderTitle title={title || ''} />
          <TrackStepsHeaderArtists artists={artists} />
        </div>
      </div>
    </HeaderContainer>
  );
}
