import { ChangeEvent, FocusEventHandler, Ref, forwardRef } from 'react';
import classNames from 'classnames';
import { InputProps } from '../input';

interface DateInputProps extends InputProps {
  containerClass?: string;
  inputClass?: string;
  labelClass?: string;
  wrapperClass?: string;
  isFocused: boolean;
  onFocus: FocusEventHandler<HTMLInputElement>;
  onBlur: FocusEventHandler<HTMLInputElement>;
}

function Date(props: DateInputProps, inputRef: Ref<HTMLInputElement>) {
  const {
    label,
    placeholder,
    className,
    containerClass = '',
    inputClass = '',
    wrapperClass = '',
    labelClass,
    error,
    required = false,
    onChange,
    onFocus,
    onBlur,
    ...inputProps
  } = props;

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    onChange?.(e.target.value);
  }

  return (
    <div className={className}>
      <div className={wrapperClass}>
        {label && (
          <span className={labelClass}>
            {label}
            {required && <span className="text-control-danger">*</span>}
          </span>
        )}
        <div className={containerClass}>
          <input
            ref={inputRef}
            aria-label={label}
            placeholder={placeholder || label}
            type="date"
            className={classNames(inputClass, 'invalid:text-control-placeholder opacity-100')}
            onChange={handleChange}
            onFocus={onFocus}
            onBlur={onBlur}
            required
            {...inputProps}
          />
        </div>
        {error ? (
          <span className="error text-control-danger mt-1 w-full text-xs">{error}</span>
        ) : null}
      </div>
    </div>
  );
}

export const DateInput = forwardRef(Date);
