import classNames from 'classnames';

type AgentProps = {
  companyName: string;
  providerTypes: string[];
  className?: string;
};

function Agent(props: AgentProps) {
  const { companyName, providerTypes, className } = props;

  return (
    <div
      className={classNames(
        'agent bg-neutral-muted flex w-full cursor-pointer items-center justify-between rounded-sm border border-transparent px-3 py-2',
        className,
      )}
    >
      <div className="agent__left flex gap-4">
        <div className="flex flex-col">
          <h6>{`${companyName}`}</h6>
          <p className="text-muted text-xs"></p>
        </div>
      </div>
      <div className="agent__right flex flex-col items-end justify-center">
        <p className="text-muted text-xs capitalize">{providerTypes.join(', ')}</p>
      </div>
    </div>
  );
}

export default Agent;
