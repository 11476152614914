import classNames from 'classnames';
import { ReactNode } from 'react';

type InfoChipProps = {
  value: string;
  color?: string;
  iconLeft?: ReactNode;
  className?: string;
};

const InfoChip = (props: InfoChipProps) => {
  const { value, iconLeft, color, className } = props;

  return (
    <span
      className={classNames(
        'text-default bg-neutral-muted flex h-fit items-center gap-2 rounded-sm px-2 py-1 text-xs',
        className,
      )}
      style={{ backgroundColor: color }}
    >
      {iconLeft}
      {value}
    </span>
  );
};

export default InfoChip;
