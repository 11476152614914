import classNames from 'classnames';

export type NameBase = {
  id: string;
  name: string;
};

type ClickableNameListProps<T> = {
  names?: T[];
  onNameClick?: (name: T) => void;
};

export function ClickableNameList<T extends NameBase>(props: ClickableNameListProps<T>) {
  const { names, onNameClick } = props;

  return (
    <div className="text-neutral line-clamp-1 inline-block w-full overflow-hidden text-xs text-ellipsis whitespace-nowrap select-none">
      {names?.map((name, i) => (
        <span
          key={`name-${name.id}`}
          className={classNames('mr-0.5 w-fit shrink-0 whitespace-nowrap', {
            'hover:text-link cursor-pointer hover:underline': onNameClick,
          })}
          onClick={() => onNameClick?.(name)}
        >
          {`${name.name}${names.length - 1 !== i ? ', ' : ''}`}
        </span>
      ))}
    </div>
  );
}
