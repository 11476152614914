import RangeSlider from '~/components/ui/control/slider/range.slider';

type RangeProps = {
  value: [number, number];
  customFrom?: string;
  customTo?: string;
  min: number;
  max: number;
  onValueChange?: (value: [number, number]) => void;
};

function Range(props: RangeProps) {
  const { value, customFrom, customTo, onValueChange, min, max } = props;
  const [from, to] = value;

  return (
    <div className="flex flex-col gap-3 p-2">
      <div className="flex w-full justify-between">
        <span className="text-muted text-xs">{customFrom || from}</span>
        <span className="text-muted text-xs">{customTo || to}</span>
      </div>
      <RangeSlider
        value={value}
        min={min}
        max={max}
        onValueChange={onValueChange}
        className="w-full"
      />
    </div>
  );
}

export default Range;
