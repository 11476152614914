import Button from '~/components/ui/control/button/button';
import { useCardClick } from '~/components/features/presearch/hook/use-card-click';
import { useTranslation } from 'react-i18next';
import usePreSearchStore from '~/stores/presearch-store';
import { useArtistSearch } from '~/hooks/api/artist';
import CardShelf from '../../../card-shelf/card-shelf';
import Tab from '../tab';

function ArtistsTab() {
  const { query } = usePreSearchStore(['query']);
  const { data: artists, fetchNextPage } = useArtistSearch(query);
  const total = artists?.pages[0]?.total.value || 0;
  const flatedArtists = artists?.pages.flatMap((page) => page.hits);
  const { handleFocusClick, handleClick } = useCardClick('master_artists');
  const { t } = useTranslation(['pre-search', 'common']);

  return (
    <Tab value="artists" isEmpty={!flatedArtists?.length || !flatedArtists}>
      {flatedArtists ? (
        <>
          <CardShelf
            label={t('tab.artists.title')}
            containerClassName="flex-1 col-span-full"
            cardsProps={flatedArtists?.map((artist) => ({
              type: 'artist',
              artworks: artist.artwork ? [artist.artwork] : undefined,
              title: artist.full_name,
              chips: artist.music_type
                .map((type) => {
                  if (type.includes('core')) return t('music-type.core');
                  if (type.includes('production')) return t('music-type.prod');
                  return undefined;
                })
                .filter((type) => type)
                .sort() as string[],
              onCardClick: () => handleFocusClick(artist.full_name, artist.master_artist),
              onIncludeClick: () => handleClick(artist.full_name, artist.master_artist, 'is'),
              onExcludeClick: () => handleClick(artist.full_name, artist.master_artist, 'is_not'),
            }))}
          />
          {flatedArtists?.length < total ? (
            <Button
              color="primary"
              onClick={() => fetchNextPage()}
              className="col-span-full mx-auto"
            >
              {t('button.load-more.label', 'common')}
            </Button>
          ) : null}
        </>
      ) : null}
    </Tab>
  );
}

export default ArtistsTab;
