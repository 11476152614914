export function parseDeadline(date?: string, time?: string) {
  if (!date) {
    return null;
  }
  const [year, month, day] = date.split('-');
  const [hour, minute] = time ? time.split(':') : '00:00'.split(':');
  return new Date(
    parseInt(year!, 10),
    parseInt(month!, 10) - 1,
    parseInt(day!, 10),
    parseInt(hour!, 10),
    parseInt(minute!, 10),
  ).toISOString();
}

export function reverseParseDeadline(date?: string) {
  if (!date) {
    return null;
  }
  const deadline = new Date(date);
  const day = deadline.getDate() < 10 ? `0${deadline.getDate()}` : deadline.getDate();
  const month =
    deadline.getMonth() + 1 < 10 ? `0${deadline.getMonth() + 1}` : deadline.getMonth() + 1;
  const year = deadline.getFullYear();
  return {
    date: `${year}-${month}-${day}`,
    time: `${deadline.getHours() < 10 ? `0${deadline.getHours()}` : deadline.getHours()}:${deadline.getMinutes() < 10 ? `0${deadline.getMinutes()}` : deadline.getMinutes()}`,
  };
}

export function recapDeadline(date?: string) {
  if (!date) {
    return null;
  }
  const deadline = reverseParseDeadline(date);

  return deadline ? `${deadline.date} - ${deadline.time}` : '-';
}
