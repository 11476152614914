import { useCreatePlaylistFromTrack } from '~/hooks/api/playlist';
import { useAddTrackToTracklist, useRemoveTrackFromTracklist } from '~/hooks/api/track-list';
import { useTrackContext } from '~/components/entities/track/common/track.context';
import { Tracklist } from '~/types/schemas/music/tracklist.schema';
import { useAddToTracklistContext } from '~/components/ui/control/menus/dropdown-menu/track-actions/add-to-tracklist/add-to-tracklist.context';

export function useAddToTracklist() {
  const store = useTrackContext();
  const track = store.use.track();

  const { selectedTracks } = useAddToTracklistContext();

  const { mutate: removeTrackFromTrackListMutation } = useRemoveTrackFromTracklist();
  const { mutate: addTrackToTrackListMutation } = useAddTrackToTracklist();
  const { mutate: handleCreatePlaylistFromTrack } = useCreatePlaylistFromTrack(track);

  function addTrackToTracklist(tracklist: Tracklist) {
    let tracks = [track];

    if (selectedTracks?.length && selectedTracks?.length > 1) {
      tracks = selectedTracks;
    }

    addTrackToTrackListMutation({
      type: tracklist.type,
      id: tracklist.value.id!,
      tracks,
    });
  }

  function removeTrackFromTracklist(tracklist: Tracklist) {
    removeTrackFromTrackListMutation({
      type: tracklist.type,
      id: tracklist.value.id!,
      tracks: [track],
    });
  }

  function createNewPlaylist() {
    handleCreatePlaylistFromTrack({ title: track.title });
  }

  return {
    addTrackToTracklist,
    removeTrackFromTracklist,
    createNewPlaylist,
  };
}
