import { MouseEvent } from 'react';
import { useSearchStore } from '~/stores/search-store';
import { FilterBlock, ParsedFilter } from '../utils/parse-filters';
import SearchChip from './search-chip';
import { useTagColor } from '../utils/use-tag-color';

type FilterChipProps = {
  block: FilterBlock;
  filter: ParsedFilter;
};

const FilterChip = (props: FilterChipProps) => {
  const { block, filter } = props;
  const search = useSearchStore([
    'removeValue',
    'toggleBlockCondition',
    'toggleBlockValueCondition',
  ]);
  const termAndBlock: string[] = ['tags'];
  const color = useTagColor(filter.value.value);

  const handleToggle = () => {
    if (termAndBlock.includes(block.term.value)) {
      search.toggleBlockCondition(filter.blockId);
    } else {
      search.toggleBlockValueCondition(filter.blockId, filter);
    }
  };

  const isActive = filter.condition.value === 'is' || filter.condition.value === 'eq';

  const handleRemoveValue = (e: MouseEvent) => {
    e.stopPropagation();
    search.removeValue(filter.id);
  };

  return (
    <SearchChip
      value={filter.value.label as string}
      color={color}
      isExcluded={!isActive}
      onChipClick={handleToggle}
      onRemoveClick={handleRemoveValue}
    />
  );
};

export default FilterChip;
