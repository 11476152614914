type SettingProps = {
  title: string;
  description: string;
  children: React.ReactNode;
};

export function Setting(props: SettingProps) {
  const { title, description, children } = props;
  return (
    <div className="grid gap-2">
      <h2 className="font-bold">{title}</h2>
      <div className="grid grid-cols-[2fr_1fr]">
        <div className="flex items-center">
          <p className="text-muted text-sm">{description}</p>
        </div>
        {children}
      </div>
    </div>
  );
}
