import { useTranslation } from 'react-i18next';
import EmptyIcon from 'virtual:icons/solar/expressionless-square-linear';

function EmptyBlock() {
  const { t } = useTranslation('pre-search');

  return (
    <div className="flex h-full w-full flex-col items-center justify-center gap-4">
      <EmptyIcon className="text-muted text-5xl" />
      <h5 className="text-muted text-sm">{t('tab.all.no-result')}</h5>
    </div>
  );
}

export default EmptyBlock;
