import Logo from '~/assets/mewo/logo.svg';

const MinWidthWindow = () => {
  return (
    <div className="bg-neutral-emphasis fixed top-0 left-0 z-9999 flex h-screen w-screen flex-col items-center justify-center gap-2 min-[980px]:hidden">
      <img src={Logo} alt="Logo Mewo" className="mb-10 w-[25%]" />
      <p className="text-default font-medium">
        This app is not responsive yet. Please use a bigger screen
      </p>
    </div>
  );
};

export default MinWidthWindow;
