import * as DialogPrimitive from '@radix-ui/react-dialog';
import classNames from 'classnames';
import { MouseEvent, PropsWithChildren } from 'react';

type DialogContainerProps = PropsWithChildren<{
  className?: string;
}>;

export function DialogContainer(props: DialogContainerProps) {
  const { children, className } = props;

  function handleClick(e: MouseEvent) {
    e.stopPropagation();
  }

  return (
    <DialogPrimitive.Portal>
      <DialogPrimitive.Overlay className="bg-overlay-backdrop fixed inset-0 z-30 backdrop-blur-sm" />
      <DialogPrimitive.Content
        onClick={handleClick}
        className={classNames(
          'fixed top-[50%] left-[50%] z-40 translate-x-[-50%] translate-y-[-50%]',
          'max-h-[85vh] w-[90vw] max-w-2xl',
          'bg-overlay-default flex flex-col items-stretch gap-4 overflow-hidden rounded-lg p-4 shadow-2xl',
          className,
        )}
      >
        {children}
      </DialogPrimitive.Content>
    </DialogPrimitive.Portal>
  );
}
