import classNames from 'classnames';
import { Icon } from '@iconify/react/dist/iconify.js';
import { ReactNode } from 'react';

type TrackCoverProps = {
  img?: string;
  icon?: string;
  title?: string;
  version?: string;
  artists?: string;
  album?: string;
  titleClassName?: string;
  className?: string;
  width?: string;
  height?: string;
  customMedia?: ReactNode;
};

export default function Cover(props: TrackCoverProps) {
  const {
    img,
    icon = 'ri:music-line',
    title,
    version,
    titleClassName,
    artists,
    album,
    className,
    width = 'auto',
    height = '3.5rem',
    customMedia,
  } = props;

  return (
    <div className={classNames('flex h-full items-center gap-3', className)}>
      {!customMedia ? (
        <div
          style={{ width, height }}
          className={classNames(
            'bg-neutral-emphasis flex aspect-square shrink-0 items-center justify-center overflow-hidden rounded-sm',
          )}
        >
          {img ? (
            <img src={img} alt="track cover" className={classNames('h-full w-full object-cover')} />
          ) : (
            <Icon icon={icon} className="text-base" />
          )}
        </div>
      ) : (
        customMedia
      )}
      <div className="flex w-full flex-col overflow-hidden">
        <div className="line-clamp-1 flex items-center gap-1">
          <h3 className={classNames('text-default line-clamp-1 text-sm font-bold', titleClassName)}>
            {title}
          </h3>
        </div>
        <p className="text-muted line-clamp-1 text-xs">{artists}</p>
        {album && (
          <div className="flex items-center gap-1">
            <Icon icon="ri:album-line" className="text-default text-xs" />
            <p className="text-muted line-clamp-1 flex-1 text-xs italic">{album}</p>
          </div>
        )}
        {!!version && (
          <div className="flex items-center gap-1">
            <Icon icon="ri:price-tag-3-line" className="text-default text-xs" />
            <p className="text-muted line-clamp-1 flex-1 text-xs">{version}</p>
          </div>
        )}
      </div>
    </div>
  );
}
