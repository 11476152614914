import classNames from 'classnames';
import { Outlet } from 'react-router-dom';
import { useBlur } from '~/hooks/utils/use-has-focus';

export function SensitiveContent() {
  const blur = useBlur();

  return (
    <div
      className={classNames('h-full w-full overflow-hidden', {
        'pointer-events-none blur-md': blur,
      })}
    >
      <Outlet />
    </div>
  );
}
