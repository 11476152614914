import { useTranslation } from 'react-i18next';
import { SortType } from '~/hooks/api/track/use-track-search';
import { toastr } from '~/components/ui/notifications/toast/utils/toast-call';
import { trackRoutes } from '~/routes/api';
import { LicensingQuote, LicensingQuoteForm } from '~/types/schemas/form/licensing-quote.schema';
import { SimilarityTrackType } from '~/stores/search-store';
import { TrackVariationCreateOutputType } from '~/types/schemas/musemind/variation.schema';
import {
  TrackSchema,
  TrackSearchResult,
  TrackSearchResultSchema,
} from '~/types/schemas/music/track.schema';
import { Operators } from '~/types/schemas/search/filter.schema';
import { api } from '~/utils/api';
import { pathToUrl } from '~/utils/path-to-url';
import { useFetch } from '~/hooks/utils/react-query/use-fetch';
import { useGenericMutation } from '~/hooks/utils/react-query/use-generic-mutation';
import { useLoadMore } from '~/hooks/utils/react-query/use-load-more';
import { usePost } from '~/hooks/utils/react-query/use-post';

type TrackDownloadArgs = {
  trackId: string;
  format: 'hd_mp3' | 'original';
};

export const useDownloadTrack = () =>
  useGenericMutation<TrackDownloadArgs, string>({
    url: `${trackRoutes.download}`,
    func: (data) => {
      const { trackId, format } = data as TrackDownloadArgs;
      return api.get(`${pathToUrl(trackRoutes.download, { id: trackId })}?format=${format}`).json();
    },
    mutateOptions: {
      onSuccess: async (data) => {
        const element = document.createElement('a');
        element.href = data;
        element.click();
      },
    },
  });

export const useDownloadTrackCopyright = () =>
  useGenericMutation<
    {
      id: string;
    },
    string
  >({
    url: trackRoutes.copyright,
    func: (data) => api.get(pathToUrl(trackRoutes.copyright, { id: data?.id })).text(),
    mutateOptions: {
      onSuccess: async (data, params) => {
        const element = document.createElement('a');
        const file = new Blob([data], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = `copyrights-track-${params.id}.txt`;
        element.click();
      },
    },
  });

export const useQuoteTrack = (trackId: string, content: LicensingQuoteForm) => {
  const { t } = useTranslation('toast');

  usePost({
    url: pathToUrl(trackRoutes.licenseQuote, { id: trackId }),
    queryParams: {
      json: {
        ...content,
      },
    },
    mutateOptions: {
      onSuccess: () => {
        toastr(
          'success',
          t('interest-form.success-send.title'),
          t('interest-form.success-send.content'),
        );
      },
      onError: () => {
        toastr('error', t('interest-form.error-send.title'), t('interest-form.error-send.content'));
      },
    },
  });
};

export const useSearchTrack = (
  query: string,
  filters: Operators,
  similarity: SimilarityTrackType,
  sort: SortType,
) => {
  return useLoadMore<TrackSearchResult>({
    url: trackRoutes.search,
    schema: TrackSearchResultSchema.passthrough(),
    queryParams: {
      query,
      ...filters,
      ...similarity,
      ...sort,
    },
  });
};

export const useURLSimilarityTrack = () => {
  return usePost<{ url: string }, unknown>({
    url: trackRoutes.similarity,
  });
};

export const useGetTrackVersions = (trackId: string) => {
  return useFetch({
    url: pathToUrl(trackRoutes.versions, { id: trackId }),
    schema: TrackSchema.array(),
  });
};

export const usePostTrackLicenseQuote = (trackId: string) => {
  const { t } = useTranslation('toast');

  return usePost<LicensingQuote, unknown>({
    url: pathToUrl(trackRoutes.licenseQuote, { id: trackId }),
    mutateOptions: {
      onSuccess: (_data, params) => {
        localStorage.setItem('last_interest_form', JSON.stringify(params));
        toastr('success', t('license-quote.success.title'), t('license-quote.success.content'));
      },
      onError: () => {
        toastr('error', t('license-quote.error.title'), t('license-quote.error.content'));
      },
    },
  });
};

export const useCreateTrackVariations = () => {
  return usePost<
    {
      id: string;
      duration: number;
      variations: number;
    },
    TrackVariationCreateOutputType
  >(
    {
      url: trackRoutes.createVariation,
    },
    {
      timeout: 200000,
    },
  );
};
