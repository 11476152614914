import { z } from 'zod';

export const YoutubeVideoSchema = z
  .object({
    id: z.string(),
    snippet: z.object({
      title: z.string(),
    }),
  })
  .passthrough();

export const YoutubeVideoResponseSchema = z
  .object({
    items: z.array(YoutubeVideoSchema),
  })
  .passthrough();

export type YoutubeVideo = z.infer<typeof YoutubeVideoSchema>;
