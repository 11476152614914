import { CloseDialog } from './close-dialog';

type BriefFormHeaderProps = {
  title: string;
  briefTitle?: string;
  onSaveAndExitClick?: () => void;
  onDiscardAndExitClick?: () => void;
};

function BriefFormHeader(props: BriefFormHeaderProps) {
  const { title, briefTitle, onSaveAndExitClick, onDiscardAndExitClick } = props;

  return (
    <div className="flex w-full items-center justify-between">
      <div className="flex flex-col">
        <h1 className="text-2xl font-bold">{title}</h1>
        {briefTitle ? <span className="text-muted text-sm">{briefTitle}</span> : null}
      </div>
      <CloseDialog onExit={onSaveAndExitClick} onDiscard={onDiscardAndExitClick} />
    </div>
  );
}

export default BriefFormHeader;
