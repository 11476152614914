import { useComputed } from '@preact/signals-react';
import { MouseEvent, useEffect, useState } from 'react';
import { useRealtime } from '~/hooks/realtime/use-realtime';
import { activeChannelStore, briefChatsStore } from '~/stores/chats-store';
import { globalStore } from '~/stores/global-store-v2';
import { realtimeStore } from '~/stores/realtime-store';
import { ChatChannelCustom, ChatMessageContent, ChatMessageMeta } from '~/types/types/chat';
import { Channel } from '~/utils/chat/channel';

export function useChatTrigger(briefId: string, trackId: string) {
  const { user } = globalStore;
  const { pubnub } = useRealtime();
  const [isHovered, setIsHovered] = useState(false);
  const channel = useComputed(() =>
    briefChatsStore.channels.value.find((chan) => chan.custom?.trackId === trackId),
  );
  const from = useComputed(() =>
    channel.value?.lastMessage.value
      ? realtimeStore.users.value[channel.value?.lastMessage.value?.userId]
      : undefined,
  );

  function handleClick(e: MouseEvent<HTMLDivElement>) {
    e.stopPropagation();

    let nextChannel = channel.value;

    if (!nextChannel) {
      nextChannel = new Channel<ChatMessageContent, ChatMessageMeta, ChatChannelCustom>({
        pubnub,
        userId: user.value!.id,
        channel: `sync_brief_${briefId}.${trackId}`,
        custom: { trackId },
      });

      briefChatsStore.channels.value = [...briefChatsStore.channels.value, nextChannel];
    }

    briefChatsStore.isPanelOpen.value = true;
    activeChannelStore.channel.value = nextChannel;
    activeChannelStore.trackId.value = trackId;

    // TODO: Mark as read in notifications
  }

  function handleMouseEnter() {
    setIsHovered(true);
  }

  function handleMouseLeave() {
    setIsHovered(false);
  }

  useEffect(() => {
    if (channel.value) {
      channel.value?.init();
    }
  }, [channel.value]);

  return {
    isLoading: briefChatsStore.isLoading.value || channel.value?.isLoading.value,
    from,
    channel: channel.value,
    messageCount: 0,
    isHovered,
    handleClick,
    handleMouseEnter,
    handleMouseLeave,
  };
}
