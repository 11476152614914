import classNames from 'classnames';
import { ReactNode } from 'react';
import { FormStepProps } from '~/components/ui/forms/form-steps/components/form-step';
import { FormSteps } from '~/components/ui/forms/form-steps/form-steps';

export type FormLayoutProps = {
  steps?: {
    title?: string;
    description?: string;
    tab: ReactNode;
    disabled?: boolean;
  }[];
  activeStepIndex?: number;
  footer?: ReactNode;
  onStepClick?: (index?: number) => void;
};

function FormLayout(props: FormLayoutProps) {
  const { steps, activeStepIndex, footer, onStepClick } = props;
  const stepTriggers = steps
    ?.map(
      (step) =>
        ({
          title: step.title,
          description: step.description,
          disabled: step.disabled,
        }) as FormStepProps,
    )
    .filter((step) => step.title !== undefined);
  const activeStep = steps?.[activeStepIndex || 0];

  return (
    <>
      <div
        className={classNames(
          'animation-delay-300 animate-slide-right flex h-full justify-end opacity-0',
          {
            'w-[25%]': stepTriggers?.length,
            'w-[10%] xl:w-[25%]': !stepTriggers?.length,
          },
        )}
      >
        {stepTriggers ? (
          <FormSteps
            onStepClick={onStepClick}
            steps={stepTriggers.map((step, i) => ({
              ...step,
              state: activeStepIndex === i ? 'active' : 'todo',
            }))}
            className="mr-12"
          />
        ) : null}
      </div>
      <form action="" className="flex h-full flex-1 flex-col items-stretch gap-6">
        {activeStep?.tab}
        {footer ? <div className="h-fit">{footer}</div> : null}
      </form>
      <div className="h-full w-[10%] xl:w-[25%]" />
    </>
  );
}

export default FormLayout;
