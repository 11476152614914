import { CloseDialog } from './close-dialog';
import { TracksUploadHeaderSubTitle } from './subtitle';
import { TracksUploadHeaderTitle } from './title';
import { SubmitButton } from './submit-button';
import { Alert } from '~/components/ui/notifications/alert/alert';
import { useTracksUploadContext } from '../tracks-upload.context';
import { useTranslation } from 'react-i18next';

export function TracksUploadHeader() {
  const { errorTracks } = useTracksUploadContext();
  const { t } = useTranslation('tracks-upload-form');
  return (
    <>
      <div className="mb-4 flex h-fit w-full items-center justify-between gap-3 pr-3">
        <div className="flex flex-col">
          <TracksUploadHeaderTitle />
          <TracksUploadHeaderSubTitle />
        </div>
        <div className="flex gap-3">
          <SubmitButton />
          <CloseDialog />
        </div>
      </div>
      {errorTracks.length > 0 && (
        <Alert closable type="info" title={t('header.alert-info')}>
          <ul>
            {errorTracks.map((track, index) => (
              <li key={index}>- {track}</li>
            ))}
          </ul>
        </Alert>
      )}
    </>
  );
}
