import { t } from 'i18next';
import { ChangeEvent, ForwardedRef, forwardRef } from 'react';
import { toastr } from '~/components/ui/notifications/toast/utils/toast-call';
import { EventToFileArray } from '~/utils/form/input-file';

type UploadTrackProps = {
  onFilesUploaded: (files?: File[]) => void;
};

export const FileInputs = forwardRef(
  (props: UploadTrackProps, ref: ForwardedRef<HTMLInputElement>) => {
    const { onFilesUploaded } = props;

    async function handleChanges(e: ChangeEvent<HTMLInputElement>) {
      const files = EventToFileArray(e);
      const filteredFiles = files.filter((file) => {
        // filter out files that are larger than 200MB
        if (file.size > 200000000) {
          toastr(
            'error',
            t('upload-track.error.file-too-large.title', { ns: 'toast' }),
            t('upload-track.error.file-too-large.content', { filename: file.name, ns: 'toast' }),
          );
        }

        return file.size < 200000000;
      });

      onFilesUploaded(filteredFiles);
      // empty the input
      e.target.value = '';
    }

    return (
      <input
        ref={ref}
        type="file"
        accept=".mp3,.wav,.ogg,.flac,.aac"
        id="filesInput"
        multiple
        onChange={handleChanges}
        className="hidden"
      />
    );
  },
);
