import { createContext, PropsWithChildren, useContext } from 'react';
import { Track } from '~/types/schemas/music/track.schema';

type AddToTracklistContextType = {
  selectedTracks?: Track[];
};

type AddToTracklistProviderProps = PropsWithChildren<AddToTracklistContextType>;

const AddToTracklistContext = createContext<AddToTracklistContextType | null>(null);

export function useAddToTracklistContext() {
  const context = useContext(AddToTracklistContext);
  if (!context) {
    throw new Error('useAddToTracklistContext must be used within a AddToTracklistProvider');
  }
  return context;
}

export function AddToTracklistProvider(props: AddToTracklistProviderProps) {
  const { children, ...rest } = props;

  return <AddToTracklistContext.Provider value={rest}>{children}</AddToTracklistContext.Provider>;
}
