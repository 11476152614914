import { LicensingQuoteForm as LicensingQuoteFormType } from '~/types/schemas/form/licensing-quote.schema';
import { LicensingQuoteFormContent } from './form-content';
import { Control } from 'react-hook-form';

type LicensingQuoteFormProps = {
  onSubmit: () => void;
  control: Control<LicensingQuoteFormType, unknown>;
};

export function LicensingQuoteForm(props: LicensingQuoteFormProps) {
  const { onSubmit, control } = props;

  return (
    <form
      id="licensing"
      onSubmit={onSubmit}
      className="custom-scrollbar mr-1 h-full overflow-y-auto pr-3 pl-4"
    >
      <LicensingQuoteFormContent control={control} />
    </form>
  );
}
