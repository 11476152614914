import { ReactNode } from 'react';

type LibraryProps = {
  nbHits?: number;
  header: ReactNode;
  emptyPlaceholder?: ReactNode;
  children?: ReactNode;
};

function LibraryLayout(props: LibraryProps) {
  const { nbHits, header, emptyPlaceholder, children } = props;

  return (
    <section className="relative flex size-full h-full flex-col items-stretch gap-6 overflow-y-auto p-4">
      {header}
      {nbHits ? (
        <div
          className="@container grid gap-4 pb-4"
          style={{
            gridTemplateColumns: 'repeat(auto-fill, minmax(30rem, 1fr))',
          }}
        >
          {children}
        </div>
      ) : (
        <>{emptyPlaceholder}</>
      )}
    </section>
  );
}

export default LibraryLayout;
