import classNames from 'classnames';

import ReleaseDate from './components/release-date';
import Popularity from './components/popularity';
import MusicType from './components/music-type';
import AddFilterButton from './components/add-filter-button';
import Bpm from './components/bpm';

import { FilterList } from './components/filter-list';
import { SimilarityTrack } from './components/similarity-track';
import { Query } from './components/query';
import { FilterCount } from './components/filter-count';

type SearchPreviewProps = {
  className?: string;
};

function SearchFilters(props: SearchPreviewProps) {
  const { className } = props;

  return (
    <div className={classNames('flex flex-col gap-3', className)}>
      <FilterCount />
      <div className="flex w-full flex-wrap items-center gap-3">
        <MusicType />
        <ReleaseDate />
        <Popularity />
        <Bpm />
        <FilterList />
        <SimilarityTrack />
        <Query />
        <AddFilterButton />
      </div>
    </div>
  );
}

export default SearchFilters;
