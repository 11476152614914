import { useEffect } from 'react';
import { NotificationListEmpty } from './components/notification-list.empty';
import NotificationCard from '~/components/ui/control/menus/dropdown-menu/notifications/components/notification-group/notification-card/notification-card';
import Button from '~/components/ui/control/button/button';
import { useNotificationCenter } from '~/components/ui/control/menus/dropdown-menu/notifications/hooks/use-notification-center';

function Notificiations() {
  const { channel, handleCardClick, notifications, showLoadMoreButton } = useNotificationCenter();

  useEffect(() => {
    if (channel && channel.lastMessage.value) {
      channel.setLastReadMessageTimetoken(channel.lastMessage.value.timetoken);
    }
  }, [channel, channel?.lastMessage.value]);

  if (!channel || channel.isLoading.value) {
    return <>loading;</>;
  }

  if (notifications.length === 0) {
    return (
      <div className="relative flex h-fit min-h-full w-full flex-col px-4">
        <NotificationListEmpty />
      </div>
    );
  }

  return (
    <div className="flex w-full flex-col items-center">
      <div className="flex w-[70%] flex-col gap-2 xl:w-1/2">
        {notifications.map((notification) => (
          <NotificationCard
            key={notification.timetoken}
            notification={notification}
            onClick={() => handleCardClick(notification)}
            markRead={() => notification.markAsRead()}
          />
        ))}
        {showLoadMoreButton ? (
          <div className="flex w-full justify-center">
            <Button color="default" onClick={() => channel.getOlderMessages()}>
              Load more
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default Notificiations;
