import classNames from 'classnames';
import Icon from '~/components/ui/icon/icon';
import { Tooltip } from '~/components/ui/overlays/tooltip/tooltip';
import { parseDateNumeric } from '~/utils/common/parse-date';
import { useTrackContext } from '../track.context';

type TrackAddedByProps = {
  className: string;
};

function TrackAddedBy(props: TrackAddedByProps) {
  const { className } = props;
  const store = useTrackContext();
  const track = store.use.track();
  const addedBy = track.metadata?.added_by;
  const avatar = addedBy?.user.organization_settings?.avatar || undefined;

  if (!addedBy) return null;

  return (
    <Tooltip
      label={`Added by ${addedBy.user.first_name} ${addedBy.user.last_name} / ${addedBy.user.company_name} - ${parseDateNumeric(
        addedBy.date,
        {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
        },
      )}`}
      asChild
    >
      <div
        className={classNames(
          'bg-neutral-emphasis flex h-6 w-fit items-center justify-center gap-1.5 rounded-sm rounded-l-full pr-2 text-xs font-semibold',
          className,
        )}
      >
        <div className="bg-muted flex aspect-square h-6 w-6 items-center justify-center overflow-hidden rounded-full">
          {avatar ? (
            <img
              src={avatar}
              alt={addedBy.user.company_name || ''}
              className="h-full w-full object-cover"
            />
          ) : (
            <Icon name="user-line" className="h-4 w-4" />
          )}
        </div>
        {`${addedBy?.user.first_name?.[0]!.toUpperCase()}${addedBy?.user.last_name?.[0]!.toUpperCase()}`}
      </div>
    </Tooltip>
  );
}

export default TrackAddedBy;
