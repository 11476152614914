/* eslint-disable react-hooks/exhaustive-deps */
import useBriefFormStore from '~/stores/forms/brief-form-store';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import usePlaylistFormStore from '~/stores/forms/playlist-form-store';
import useInterestFormStore from '~/stores/forms/interest-form-store';
import useGlobalFormStore from '~/stores/forms/global-form-store';
import BriefForm from './brief/brief-form';
import PlaylistForm from './playlist/playlist-form';
import './form.styles.scss';
import InterestForm from './interest/interest-form';

function FormHandler() {
  const globalForm = useGlobalFormStore(['isOpen', 'setIsOpen']);
  const briefForm = useBriefFormStore(['isOpen', 'brief', 'close']);
  const playlistForm = usePlaylistFormStore(['isOpen', 'playlist', 'close']);
  const interestForm = useInterestFormStore(['isOpen', 'interest', 'close']);
  const location = useLocation();
  const isFormOpen = briefForm.isOpen || playlistForm.isOpen || interestForm.isOpen;

  useEffect(() => {
    if (
      location.pathname !== `/briefs/${briefForm.brief?.id}` ||
      !location.pathname.includes('/briefs')
    ) {
      briefForm.close();
    }
    if (playlistForm.playlist && location.pathname !== `/playlists/${playlistForm.playlist?.id}`) {
      playlistForm.close();
    }
    interestForm.close();
  }, [location.pathname, briefForm.brief?.id]);

  useEffect(() => {
    globalForm.setIsOpen(briefForm.isOpen || playlistForm.isOpen || interestForm.isOpen);
  }, [briefForm.isOpen, playlistForm.isOpen, interestForm.isOpen]);

  return (
    isFormOpen && (
      <div className="absolute top-0 z-20 flex h-full w-full flex-col">
        <div className="animate-slide-in-bottom bg-overlay-backdrop absolute inset-0 z-10 flex h-full items-center justify-center overflow-hidden overflow-y-auto px-6 pt-6 backdrop-blur-md">
          {briefForm.isOpen ? <BriefForm brief={briefForm.brief} /> : null}
          {playlistForm.isOpen ? <PlaylistForm playlist={playlistForm.playlist} /> : null}
          {interestForm.isOpen ? <InterestForm /> : null}
        </div>
      </div>
    )
  );
}

export default FormHandler;
