import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { PropsWithChildren } from 'react';
import { useTrackItem } from '~/hooks/entities/track/track-item/use-track-item';
import { Content } from './content/content';

export function TrackItemWrapper(props: PropsWithChildren) {
  const { children } = props;
  const { isOpen, isTrackActive, isSelected, handleClick, onAnimationComplete } = useTrackItem();

  return (
    <div
      className={classNames(
        'group/track-item',
        'overflow-hidden rounded-lg border border-transparent outline-hidden select-none',
        'hover:bg-neutral-emphasis! hover:border-neutral-emphasis',
        {
          'bg-neutral-emphasis border-neutral-emphasis': isTrackActive,
          'bg-neutral-emphasis': isSelected || isOpen,
        },
      )}
      onClick={handleClick}
    >
      <div className="p-2">{children}</div>

      <AnimatePresence>
        {isOpen ? (
          <motion.div
            layout="position"
            initial={{ height: 0, opacity: 0, marginTop: 0, marginBottom: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0, marginTop: 0, marginBottom: 0 }}
            transition={{ duration: 0.4 }}
            onAnimationComplete={() => {
              onAnimationComplete(true);
            }}
          >
            <div className="p-2">
              <Content />
            </div>
          </motion.div>
        ) : null}
      </AnimatePresence>
    </div>
  );
}
