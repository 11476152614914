import * as DialogPrimitive from '@radix-ui/react-dialog';
import { TracksUpload } from '~/components/entities/upload/tracks/tracks-upload';
import classNames from 'classnames';
import { UploadTrack } from '~/types/features/track-upload/track-upload.types';
import { MouseEvent, useEffect, useRef, useState } from 'react';
import { FileInputs } from '~/components/entities/upload/tracks/files-input';
import { UploadSessionProvider } from '~/providers/upload/session/upload-session';
import { TracksUploadProvider } from '~/components/entities/upload/tracks/tracks-upload.context';
import { TracksUploadAlertDialog } from '../../alert-dialogs/tracks-upload/tracks-upload-alert-dialog';
import { fileArrayToUploadTrackArray } from '~/utils/upload/file-parser';
import { compact } from 'lodash';
import { toastr } from '~/components/ui/notifications/toast/utils/toast-call';
import { useTranslation } from 'react-i18next';

type RightHoldersDialogProps = {
  context: string;
  contextId: string;
  children: React.ReactNode;
};

export function TracksUploadDialog(props: RightHoldersDialogProps) {
  const { context, contextId, children } = props;
  const [uploadTracks, setUploadTracks] = useState<UploadTrack[]>([]);
  const [errorTracks, setErrorTracks] = useState<string[]>([]);
  const [open, setOpen] = useState(false);
  const { t } = useTranslation('toast');

  const inputRef = useRef<HTMLInputElement>(null);
  const alertRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (uploadTracks.length > 0) {
      setOpen(true);
    }
  }, [uploadTracks]);

  function handleTriggerClick(e: MouseEvent) {
    e.preventDefault();
    inputRef.current?.click();
  }

  async function handleFilesUploaded(files?: File[]) {
    if (files && files.length > 0) {
      const tracksProcess = await fileArrayToUploadTrackArray(files);
      const uploadTracks = tracksProcess.filter((track) => typeof track !== 'string');
      const tracksOnError = tracksProcess.filter((track) => typeof track === 'string');

      const fullOfError = uploadTracks.length === 0 && tracksOnError.length > 0;

      if (fullOfError) {
        toastr(
          'error',
          t('brief.invalid-file'),
          <ul>
            {tracksOnError.map((track, index) => (
              <li key={index}>- {track}</li>
            ))}
          </ul>,
        );
      }

      setErrorTracks(tracksOnError);
      setUploadTracks(compact(uploadTracks));
    }
  }

  function handleExit() {
    setOpen(false);
  }

  function handleOpenChange(open: boolean) {
    if (!open) {
      alertRef.current?.click();
    }
  }

  return (
    <>
      <DialogPrimitive.Root open={open} onOpenChange={handleOpenChange}>
        <DialogPrimitive.Trigger asChild onClick={handleTriggerClick}>
          {children}
        </DialogPrimitive.Trigger>
        <DialogPrimitive.Portal>
          <DialogPrimitive.Overlay className="bg-overlay-backdrop fixed inset-0 z-30 backdrop-blur-sm" />
          <DialogPrimitive.Content
            className={classNames(
              'fixed top-[50%] left-[50%] z-40 h-full w-full max-w-[70%] translate-x-[-50%] translate-y-[-50%]',
            )}
          >
            <UploadSessionProvider context={context} contextId={contextId}>
              <TracksUploadProvider
                onExit={handleExit}
                files={uploadTracks}
                errorTracks={errorTracks}
              >
                <TracksUpload />
              </TracksUploadProvider>
            </UploadSessionProvider>
          </DialogPrimitive.Content>
        </DialogPrimitive.Portal>
      </DialogPrimitive.Root>
      <TracksUploadAlertDialog onExit={handleExit}>
        <button ref={alertRef} className="hidden"></button>
      </TracksUploadAlertDialog>
      <FileInputs ref={inputRef} onFilesUploaded={handleFilesUploaded} />
    </>
  );
}
