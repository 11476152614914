import classNames from 'classnames';
import Icon from '~/components/ui/icon/icon';

export type ToastType = 'default' | 'success' | 'error' | 'warning';

type ToastProps = {
  type: ToastType;
  title?: string;
  content?: React.ReactNode;
  icon?: string;
};

const Toast = (props: ToastProps) => {
  const { type, title, content, icon } = props;

  function chooseIcon() {
    switch (type) {
      case 'success':
        return 'check-line';
      case 'error':
        return 'close-circle-line';
      case 'warning':
        return 'error-warning-line';
      default:
        return '';
    }
  }

  const backgroundStateColor: Record<ToastType, string> = {
    default: 'bg-neutral-emphasis',
    success: 'bg-success-emphasis',
    warning: 'bg-warning-emphasis',
    error: 'bg-danger-emphasis',
  };

  const textStateColor: Record<ToastType, string> = {
    default: 'text-default',
    success: 'text-success',
    warning: 'text-warning',
    error: 'text-danger',
  };

  return (
    <div
      className={classNames(
        'bg-overlay-default relative w-96 overflow-hidden rounded-sm p-5 backdrop-blur-md',
        `toast-${type}`,
      )}
    >
      <div className={classNames('absolute top-0 left-0 h-full w-1', backgroundStateColor[type])} />
      <div className="h-ful flex w-full">
        <div className="icon mr-5 flex h-full justify-start">
          <Icon
            name={icon || chooseIcon()}
            className={classNames('text-[1rem] leading-4', textStateColor[type])}
          />
        </div>
        <div>
          <h2 className="mb-2 text-sm leading-none font-bold">{title}</h2>
          {content ? <div className="text-muted text-xs">{content}</div> : null}
        </div>
      </div>
    </div>
  );
};

export default Toast;
