import { ChangeEvent, useEffect, useState } from 'react';
import { Text } from '~/components/ui/forms/combobox/custom-render/text';
import { ComboboxInput } from '~/components/ui/forms/combobox/combobox';
import useDebounce from '~/hooks/common/use-debounce';
import { useTenantCatalogSearch } from '~/hooks/api/catalog';
import { globalStore } from '~/stores/global-store-v2';

type TitleInputProps = {
  label?: string;
  value?: string;
  error?: string;
  required?: boolean;
  className?: string;
  onChange?: (v: string) => void;
};

export function CatalogInput(props: TitleInputProps) {
  const { value, onChange, ...rest } = props;
  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounce(query, 500);
  const { user } = globalStore;
  const { data, isLoading } = useTenantCatalogSearch(debouncedQuery, user.value?.tenant_id || '');

  const currentValue = value === undefined ? data?.pages[0]?.hits[0]?.name : value;

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    const v = e.target.value;

    setQuery(v);
    onChange?.(v);
  }

  const flatedData = data?.pages.flatMap((page) => page.hits) || [];
  const options = flatedData.map((item) => ({
    value: item.name,
    content: {
      label: item.name,
    },
  }));

  useEffect(() => {
    const v = options[0]?.value;

    if (value === undefined && v) {
      setQuery(v);
      onChange?.(v);
    }
  }, [options]);

  return (
    <ComboboxInput
      value={currentValue}
      onChange={handleChange}
      onItemSelect={(item) => onChange?.(item.label)}
      items={options}
      render={Text}
      isLoading={isLoading}
      {...rest}
    />
  );
}
