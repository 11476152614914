import { DragEndEvent, useDndMonitor } from '@dnd-kit/core';
import { useAddTrackToTracklist } from '~/hooks/api/track-list';

export function useDragToTracklist() {
  const { mutate: addToTracklist } = useAddTrackToTracklist();

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;
    if (over?.data.current?.type === 'playlist' || over?.data.current?.type === 'brief') {
      if (active.data.current?.type === 'track') {
        addToTracklist({
          type: over?.data.current?.type,
          tracks: [active.data.current.track],
          id: over?.id as string,
        });
      }
      if (active.data.current?.type === 'track-group') {
        addToTracklist({
          type: over?.data.current?.type,
          tracks: active.data.current.tracks,
          id: over?.id as string,
        });
      }
    }
  }

  useDndMonitor({
    onDragEnd: handleDragEnd,
  });
}
