import { useTrackContext } from '~/components/entities/track/common/track.context';

export function LyricsContent() {
  const store = useTrackContext();
  const track = store.use.track();

  return (
    <div className="custom-scrollbar h-full overflow-y-auto pr-3">
      <p className="text-muted whitespace-pre-line">{track.lyrics}</p>
    </div>
  );
}
