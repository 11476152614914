import { Controller } from 'react-hook-form';
import Textarea from '~/components/ui/forms/textarea/textarea';
import { useTranslation } from 'react-i18next';
import { BriefStepProps } from '../brief-form';
import Tab from './tab';

const LOCALE = 'en';

function Content(props: BriefStepProps) {
  const { t } = useTranslation('brief-form');
  const { title = '', brief, control, ...actions } = props;

  return (
    <Tab title={title} briefTitle={brief?.title} {...actions}>
      <div className="form-content__row h-full">
        <Controller
          name="descriptions"
          defaultValue={
            brief?.descriptions.find((d) => d.locale === LOCALE) ||
            brief?.descriptions.find((d) => d.value) || { locale: LOCALE, value: '' }
          }
          control={control}
          render={({ field: { value: description, onChange } }) => {
            return (
              <Textarea
                value={description.value}
                onChange={(e) => {
                  onChange({ locale: LOCALE, value: e.target.value });
                }}
                label={t('step-two.input.brief.label')}
                boxClassName="h-full"
                className="h-full"
                placeholder={t('step-two.input.brief.placeholder')}
              />
            );
          }}
        />
      </div>
      <div className="form-content__row h-fit">
        <Controller
          name="video_link"
          defaultValue={brief?.video_link}
          control={control}
          render={({ field: { value, onChange }, fieldState }) => (
            <Textarea
              value={value}
              onChange={onChange}
              label={t('step-two.input.video-link.label')}
              className="h-32"
              placeholder={t('step-two.input.video-link.placeholder')}
              rows={4}
              error={fieldState.error?.message}
            />
          )}
        />
      </div>
    </Tab>
  );
}

export default Content;
