import classNames from 'classnames';
import { ChangeEvent, MouseEvent } from 'react';
import { Icon } from '@iconify/react';

export type CheckedState = boolean | 'indeterminate';

type CheckboxProps = {
  checked: CheckedState;
  checkboxClassName?: string;
  onChange?: (checked: CheckedState) => void;
  onClick?: (e: MouseEvent) => void;
};

const Checkbox = (props: CheckboxProps) => {
  const { checked, checkboxClassName, onChange, onClick } = props;

  function chooseIcon() {
    switch (checked) {
      case true:
        return <Icon icon="ri:check-line" className="size-full" />;
      case false:
        return null;
      default:
        return <Icon icon="material-symbols:check-indeterminate-small" className="size-full" />;
    }
  }

  function handleCheckChange(e: ChangeEvent<HTMLInputElement>) {
    return onChange?.(e.target.checked);
  }

  return (
    <label className="flex cursor-pointer items-center gap-3 text-white select-none">
      <div
        className={classNames(
          'border-neutral-emphasis bg-neutral-emphasis h-3 w-3 rounded-xs border text-[0.5rem] transition-all duration-200',
          {
            'bg-accent-emphasis! border-accent-emphasis': checked,
          },
          checkboxClassName,
        )}
      >
        {chooseIcon()}
      </div>
      <input
        type="checkbox"
        className="hidden"
        checked={checked === 'indeterminate' ? undefined : checked}
        onClick={onClick}
        onChange={handleCheckChange}
      />
    </label>
  );
};

export default Checkbox;
