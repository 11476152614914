import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from '~/components/ui/control/button/button';
import { IngestLoading } from '~/pages/brief/components/ingest-loading';

type BriefTrackItemListEmptyProps = {
  briefId: string;
};

export function BriefTrackItemListEmpty(props: BriefTrackItemListEmptyProps) {
  const navigate = useNavigate();
  const { t } = useTranslation('music-item-list');

  return (
    <>
      <IngestLoading id={props.briefId} />
      <div className="flex flex-1 flex-col items-center justify-center">
        <span className="text-xl font-bold">{t('brief.empty.title')}</span>
        <p className="text-muted">{t('brief.empty.content')}</p>
        <Button color="primary" className="mt-4" onClick={() => navigate('/search')}>
          {t('brief.empty.button')}
        </Button>
      </div>
    </>
  );
}
