import { useParsedRightsHolder } from '~/hooks/data/use-parsed-rights-holder';
import { itemConditionsOptions, termOptions, useSearchStore } from '~/stores/search-store';
import { OwnerShips } from '~/types/schemas/music/track.schema';

export function useRightHoldersDialog(ownerships: OwnerShips) {
  const search = useSearchStore(['addValueToMatchingBlock']);
  const rightsHolders = useParsedRightsHolder(ownerships);

  function rightHolderClick<T extends { id: string; name: string }>(
    owner: T,
    type: keyof typeof termOptions,
  ) {
    search.addValueToMatchingBlock(termOptions[type]!, itemConditionsOptions.is!, {
      value: owner.id,
      label: owner.name,
    });
  }

  return { rightsHolders, rightHolderClick };
}
