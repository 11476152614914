import { useTracksUploadContext } from '../../../tracks-upload.context';
import TrackInfoGroup from '../common/track-infos/track-info-group';
import { Header } from './header';
import { UploadTrack } from '~/types/features/track-upload/track-upload.types';
import { memo } from 'react';
import { useTracksUploadItemContext } from '../tracks-upload-item.context';

type OriginalProps = {
  index: number;
  file: UploadTrack;
};

export const Original = memo((props: OriginalProps) => {
  const { index, file } = props;

  const { store } = useTracksUploadContext();
  const setSelected = store.use.setSelected();

  const { isSelected } = useTracksUploadItemContext();

  return (
    <div className="bg-neutral-emphasis flex w-full flex-col items-stretch overflow-hidden rounded-lg">
      <Header index={index + 1} fileName={file.name} onHeaderClick={() => setSelected(file.id)} />
      {isSelected ? <TrackInfoGroup track={file.metadata} /> : null}
    </div>
  );
});
