import { useState } from 'react';
import Dropdown, { dropdownGroups } from '~/components/ui/control/dropdown/dropdown';
import IconDownloadLine from 'virtual:icons/ri/download-line';
import { useTranslation } from 'react-i18next';
import { useDownloadTracklistTracks } from '~/hooks/api/track-list';
import Button from '~/components/ui/control/button/button';

export type DownloadTracklistTracksParams = {
  context: 'playlist' | 'brief';
  with_versions: boolean;
  quality: 'original' | 'hd_mp3';
  id: string;
};

type DownloadButtonProps = {
  context: 'playlist' | 'brief';
  id: string;
  disabled?: boolean;
};

const DownloadButton = (props: DownloadButtonProps) => {
  const { id, context, disabled } = props;
  const { mutate: downloadTracks } = useDownloadTracklistTracks();
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const { t } = useTranslation(['tracklist-page', 'common']);

  function handleDownload(params: DownloadTracklistTracksParams) {
    setIsDropDownOpen(false);
    downloadTracks(params);
  }

  const groups: dropdownGroups = [
    {
      title: t('interactions.button.download.label'),
      elements: [
        {
          label: t('interactions.button.download.dropdown.option.mp3-single.label'),
          onclick: () => handleDownload({ id, context, quality: 'hd_mp3', with_versions: false }),
        },
        {
          label: t('interactions.button.download.dropdown.option.mp3-all.label'),
          onclick: () => handleDownload({ id, context, quality: 'hd_mp3', with_versions: true }),
        },
        {
          label: t('interactions.button.download.dropdown.option.original-single.label'),
          onclick: () => handleDownload({ id, context, quality: 'original', with_versions: false }),
        },
        {
          label: t('interactions.button.download.dropdown.option.original-all.label'),
          onclick: () => handleDownload({ id, context, quality: 'original', with_versions: true }),
        },
      ],
    },
  ];

  return (
    <Dropdown
      groups={groups}
      open={isDropDownOpen}
      onOpenChange={setIsDropDownOpen}
      disabled={disabled}
      itemClassName="outline-hidden"
      triggerClassname="w-fit"
      align="start"
    >
      <Button color="primary">
        {t('interactions.button.download.label')}
        <IconDownloadLine />
      </Button>
    </Dropdown>
  );
};

export default DownloadButton;
