import { UseInfiniteQueryResult, InfiniteData } from '@tanstack/react-query';
import { Range } from '@tanstack/react-virtual';
import { useState, useMemo, useEffect } from 'react';
import { ZodSchema } from 'zod';
import { useTrackSearch } from '~/hooks/api/track/use-track-search';
import { useSearchStore } from '~/stores/search-store';
import { InfinitePage } from '~/types/schemas/api/infinite-page.schema';
import { Track } from '~/types/schemas/music/track.schema';

export function useSearchTrackItemList() {
  const search = useSearchStore(['setFacets', 'setNbResults']);
  const {
    data: tracks,
    fetchNextPage,
    isLoading,
    hasNextPage,
    isFetchingNextPage,
    isSuccess,
  } = useTrackSearch(100) as UseInfiniteQueryResult<InfiniteData<InfinitePage<ZodSchema<Track>>>>;

  const flatTracks = tracks?.pages.map((page) => page.hits).flat();
  const [currentLimit, setCurrentLimit] = useState(50);
  const currentTracks = useMemo(() => flatTracks?.slice(0, currentLimit), [tracks, currentLimit]);

  useEffect(() => {
    if (tracks) {
      search.setFacets(tracks.pages[0]!.facets);

      search.setNbResults(tracks.pages[0]!.total.value);
    }
  }, [isSuccess, tracks, search]);

  function handleNextPageFetch({ endIndex }: Range) {
    if (endIndex >= (currentTracks?.length || 0) - 30 && !isFetchingNextPage) {
      if ((flatTracks?.length || 0) > currentLimit) {
        setCurrentLimit(currentLimit + 50);
      } else if (hasNextPage) fetchNextPage();
    }
  }

  return {
    tracks: currentTracks || [],
    hasNextPage,
    isLoading,
    isFetching: isFetchingNextPage,
    handleNextPageFetch,
  };
}
