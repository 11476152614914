import classNames from 'classnames';
import { dateDifferenceDetail, timeDifferenceWithToday } from '~/utils/common/date-difference';
import { parseDateNumeric } from '~/utils/common/parse-date';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '../../../ui/overlays/tooltip/tooltip';
import { Icon } from '@iconify/react';

type BriefStateProps = {
  deadline: string;
  isSended?: boolean;
};

function BriefState(props: BriefStateProps) {
  const { isSended, deadline } = props;
  const timeBeforeDeadline = timeDifferenceWithToday(deadline);
  const { days, hours, minutes } = dateDifferenceDetail(deadline);
  const { t } = useTranslation('libraries');

  return (
    <div className="flex h-6">
      <Tooltip
        label={
          isSended
            ? timeBeforeDeadline < 0
              ? t('brief-card.state.tooltip.expired')
              : t('brief-card.state.tooltip.live')
            : t('brief-card.state.tooltip.draft')
        }
      >
        <div
          className={classNames('flex h-full items-center rounded-l px-2', {
            'bg-warning-emphasis': !isSended,
            'bg-danger-emphasis': isSended && timeBeforeDeadline >= 0,
            'bg-neutral-muted': isSended && timeBeforeDeadline < 0,
          })}
        >
          {!isSended && <Icon icon="ri:edit-line" className="text-default font-bold" />}
          {isSended &&
            (timeBeforeDeadline < 0 ? (
              <Icon icon="ri:calendar-close-fill" className="text-default font-bold" />
            ) : (
              <Icon icon="ri:timer-line" className="text-default font-bold" />
            ))}
        </div>
      </Tooltip>

      <div
        className={classNames(
          'text-default flex h-full w-fit items-center rounded-sm rounded-l-none border border-transparent px-2',
          {
            'border-warning-emphasis! border-l-0': !isSended,
            'border-danger-emphasis! border-l-0': isSended && timeBeforeDeadline >= 0,
            'bg-neutral-muted': isSended && timeBeforeDeadline < 0,
          },
        )}
      >
        <div>
          {!isSended ? t('brief-card.state.draft') : null}
          {isSended &&
            (timeBeforeDeadline < 0
              ? parseDateNumeric(deadline, {
                  month: 'long',
                  day: '2-digit',
                  year: 'numeric',
                  hour12: true,
                  hour: '2-digit',
                  minute: '2-digit',
                })
              : t('brief-card.state.open', {
                  day: days,
                  hours,
                  mins: minutes,
                }))}
        </div>
      </div>
    </div>
  );
}

export default BriefState;
