import { StepWaiting } from './steps/step-waiting';
import { StepUploadProcess } from './steps/step-upload-process';
import { useUploadSessionContext } from '~/providers/upload/session/upload-session.context';
import { UploadTrack } from '~/types/features/track-upload/track-upload.types';
import { StepMatch } from './steps/step-match/step-match';
import { useTracksUploadItemContext } from '../tracks-upload-item.context';

type TrackStepProps = {
  file: UploadTrack;
};

export function TrackSteps({ file }: TrackStepProps) {
  const { session } = useUploadSessionContext();
  const { uploadComplete, presignedData } = useTracksUploadItemContext();

  if (!session) {
    return <StepWaiting />;
  }

  if (!uploadComplete || !presignedData) {
    return <StepUploadProcess file={file} />;
  }

  return <StepMatch file={file} />;
}
