import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { OptionType } from '~/components/ui/forms/select/select';

export function useAgentTypes() {
  const { t } = useTranslation('brief-form');

  const agentsTypesOptions: OptionType[] = useMemo(
    () => [
      {
        label: t('step-three.providers-group.edit.filter.provider-type.independent'),
        value: 'independent',
      },
      {
        label: t('step-three.providers-group.edit.filter.provider-type.major'),
        value: 'major',
      },
      {
        label: t('step-three.providers-group.edit.filter.provider-type.production'),
        value: 'production',
      },
      {
        label: t('step-three.providers-group.edit.filter.provider-type.unknown'),
        value: 'unknown',
      },
    ],
    [t],
  );

  const defaultAgentTypes = useMemo(() => {
    const independent = agentsTypesOptions[0]!;
    const major = agentsTypesOptions[1]!;

    return [independent, major];
  }, [agentsTypesOptions]);

  console.log(agentsTypesOptions, defaultAgentTypes);

  return { agentsTypesOptions, defaultAgentTypes };
}
