import classNames from 'classnames';
import { ReactNode } from 'react';

type AddFilterElementProps = {
  label: string;
  children: ReactNode;
  className?: string;
};

function AddFilterElement(props: AddFilterElementProps) {
  const { label, children, className } = props;
  return (
    <div className={classNames('flex h-full flex-col items-stretch px-2 pt-2', className)}>
      <h4 className="text-muted mb-1 h-4 px-2 text-xs">{label}</h4>
      <div className="flex h-full flex-col gap-0.5 overflow-hidden">{children}</div>
    </div>
  );
}

export default AddFilterElement;
