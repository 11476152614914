import classNames from 'classnames';
import { memo } from 'react';
import MewoLogo from '~/assets/mewo/logo-without-text.svg';

type TracksStepsHeaderLoaderProps = {
  className?: string;
  title: string;
  state: string;
};

function TrackStepHeaderLoaderBase(props: TracksStepsHeaderLoaderProps) {
  const { title, state, className } = props;

  return (
    <div
      className={classNames(
        'relative row-span-full flex h-20 items-center gap-3 px-3 pr-5',
        className,
      )}
    >
      <div className="bg-neutral-muted flex aspect-square h-14 animate-pulse items-center justify-center rounded-sm">
        <img src={MewoLogo} alt="Mewo Logo" className="aspect-square w-1/2" />
      </div>
      <div className="flex w-[75%] flex-col">
        <h5 className="text-default text-sm font-bold">{title}</h5>
        <p className="text-muted text-xs">{state}</p>
      </div>
    </div>
  );
}

export const TrackStepHeaderLoader = memo(TrackStepHeaderLoaderBase);
