import classNames from 'classnames';
import { MouseEvent } from 'react';
import Cover from '~/components/ui/cover/cover';
import Chip from '~/components/ui/control/chip/chip';
import GeneralIcons from '~/components/ui/icon/general-icons';
import Icon from '~/components/ui/icon/icon';
import { Tooltip } from '~/components/ui/overlays/tooltip/tooltip';
import { Artwork } from '~/types/schemas/common/image.schema';
import { parseThousands } from '~/utils/common/parse-thousands';
import { useTranslation } from 'react-i18next';

type ArtistResultProps = {
  name: string;
  type?: string[];
  instagramFollowers?: number;
  spotifyListeners?: number;
  tiktokFollowers?: number;
  artworks?: Artwork[];
  onCardClick?: () => void;
  onIncludeClick?: () => void;
  onExcludeClick?: () => void;
};

function ArtistResult(props: ArtistResultProps) {
  const {
    name,
    type,
    instagramFollowers,
    spotifyListeners,
    tiktokFollowers,
    artworks,
    onCardClick,
    onIncludeClick,
    onExcludeClick,
  } = props;
  const { t } = useTranslation('pre-search');

  function handleIncludeClick(e: MouseEvent) {
    e.stopPropagation();
    onIncludeClick?.();
  }

  function handleExcludeClick(e: MouseEvent) {
    e.stopPropagation();
    onExcludeClick?.();
  }

  function handleCardClick(e: MouseEvent) {
    e.stopPropagation();
    onCardClick?.();
  }

  return (
    <div
      onClick={handleCardClick}
      className={classNames(
        'best-artist-card group/best-artist group relative flex h-full w-full cursor-pointer flex-col gap-3 rounded-lg p-4',
        'bg-neutral-emphasis hover:bg-neutral-muted cursor-pointer',
      )}
    >
      <div className="flex h-full items-center gap-6">
        <Cover type="artist" artworks={artworks} alt={name} className="h-[7.5rem]! w-[7.5rem]!" />
        <div className="flex flex-col gap-3">
          {instagramFollowers ? (
            <div className="flex items-center gap-2 text-xs">
              <GeneralIcons icon="instagram" className="text-base" />
              <span className="text-muted">
                {t('tab.all.artist.instagram', {
                  followers: parseThousands(instagramFollowers.toString(), ','),
                })}
              </span>
            </div>
          ) : null}
          {tiktokFollowers ? (
            <div className="flex items-center gap-2 text-xs">
              <GeneralIcons icon="tiktok" className="text-base" />
              <span className="text-muted">
                {t('tab.all.artist.tiktok', {
                  followers: parseThousands(tiktokFollowers.toString(), ','),
                })}
              </span>
            </div>
          ) : null}
          {spotifyListeners ? (
            <div className="flex items-center gap-2 text-xs">
              <GeneralIcons icon="spotify" className="text-base" />
              <span className="text-muted">
                {t('tab.all.artist.spotify', {
                  listener: parseThousands(spotifyListeners.toString(), ','),
                })}
              </span>
            </div>
          ) : null}
        </div>
      </div>
      <div className="best-artist-card__information flex flex-col gap-1.5">
        <Tooltip label={name} triggerClassName="text-left">
          <h5 className="line-clamp-1 text-2xl font-bold">{name}</h5>
        </Tooltip>
        <div className="flex gap-1.5">
          {type?.map((t) => (
            <Chip key={t} className="bg-neutral-muted group-hover:bg-neutral-emphasis">
              {t}
            </Chip>
          ))}
        </div>
      </div>
      <div
        className={classNames(
          'best-artist-card__buttons absolute right-4 bottom-4 flex gap-3 opacity-0 transition-all',
          'group-hover/best-artist:opacity-100',
        )}
      >
        <button
          type="button"
          className="best-artist-card__buttons__subtract bg-neutral-emphasis hover:bg-muted text-inverse flex h-6 w-6 shrink-0 items-center justify-center rounded-lg"
          onClick={handleExcludeClick}
        >
          <Icon name="subtract-line" />
        </button>
        <button
          type="button"
          className="best-artist-card__buttons__add bg-neutral-emphasis hover:bg-muted text-inverse flex h-6 w-6 shrink-0 items-center justify-center rounded-lg"
          onClick={handleIncludeClick}
        >
          <Icon name="add-line" />
        </button>
      </div>
    </div>
  );
}

export default ArtistResult;
