import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { useState } from 'react';
import { contentClassName } from '~/components/ui/control/dropdown/dropdown';
import { Tooltip } from '~/components/ui/overlays/tooltip/tooltip';
import GeneralIcons from '~/components/ui/icon/general-icons';
import { Track } from '~/types/schemas/music/track.schema';
import Filter from '~/components/entities/navbar/components - legacy/tracklist-search/filter';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import useDebounce from '~/hooks/common/use-debounce';
import Tracklist from './tracklist';
import TracklistList from './tracklist-list';
import { useCreatePlaylistFromTrack } from '../../hooks/use-create-tracklist';

type AddToTracklistProps = {
  track: Track;
  onOpenChange?: (isOpen: boolean) => void;
};

const AddToTracklist = (props: AddToTracklistProps) => {
  const { track, onOpenChange } = props;
  const [query, setQuery] = useState('');
  const debounceQuery = useDebounce(query, 500);
  const [isOpen, setIsOpen] = useState(false);
  const [collections, setCollections] = useState<('briefs' | 'playlists')[]>([
    'briefs',
    'playlists',
  ]);
  const { handleCreatePlaylist } = useCreatePlaylistFromTrack();
  const { t } = useTranslation('track-interactions');

  function handleOpenChange(open: boolean) {
    setIsOpen(open);
    onOpenChange?.(open);
  }

  const triggerClassName =
    'w-7 h-7 flex items-center justify-center cursor-pointer transition-colors outline-hidden duration-100  hover:text-primary-400 text-xl';
  const dropdownWidth = '384px';

  return (
    <div data-no-dnd="true">
      <DropdownMenu.Root open={isOpen} onOpenChange={(b) => handleOpenChange(b)}>
        <Tooltip label={t('add-to-playlist.tooltip')} asChild>
          <DropdownMenu.Trigger asChild>
            <button
              type="button"
              className={classNames('track-interactions__add-to-playlist', triggerClassName)}
            >
              <GeneralIcons icon="add-to-playlist" />
            </button>
          </DropdownMenu.Trigger>
        </Tooltip>

        <DropdownMenu.Portal>
          <DropdownMenu.Content
            className={contentClassName('bottom', 'add-to-tracklist__dropdown')}
            style={{ width: dropdownWidth }}
            align="end"
            side="bottom"
            sideOffset={5}
            data-track-id={track.id}
            data-track-title={track.title}
          >
            <Filter
              className="p-1"
              onChange={setQuery}
              setSelectedFilter={(c) => setCollections(c as ('briefs' | 'playlists')[])}
            />
            <DropdownMenu.Group className="tracklist__list custom-scrollbar scrollbar-on-hover my-2 h-60 overflow-y-auto">
              <TracklistList
                track={track}
                query={debounceQuery}
                collections={collections}
                onOpenChange={(b) => handleOpenChange(b)}
              />
            </DropdownMenu.Group>
            <DropdownMenu.Separator className="bg-neutral-muted h-px w-full" />
            <Tracklist
              label="Create Playlist"
              icon="add-line"
              className="add-to-tracklist__dropdown__create-playlist pt-2"
              onClick={() => handleCreatePlaylist([track])}
            />
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
    </div>
  );
};

export default AddToTracklist;
