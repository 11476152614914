import { userSettingsStore } from '~/stores/user-settings-store';
import { LANGUAGES } from '~/constants/languages';
import { SupportedLanguages } from '~/types/schemas/data/translate.schema';
import { useTranslation } from 'react-i18next';
import Select from '~/components/ui/forms/select/select';
import { Setting } from './components/setting';
import { useQueryClient } from '@tanstack/react-query';
import { optionRoutes, tagsRoutes } from '~/routes/api';

export function Settings() {
  const { language, setLanguage } = userSettingsStore;
  const { t } = useTranslation('user-settings');
  const queryClient = useQueryClient();

  const onLanguageSelect = async (value: SupportedLanguages) => {
    setLanguage(value);
    await queryClient.invalidateQueries({
      queryKey: [optionRoutes.other],
    });
    await queryClient.invalidateQueries({
      queryKey: [tagsRoutes.list],
    });
  };

  return (
    <div className="size-full p-4">
      <div className="mx-auto flex size-full max-w-4xl flex-col gap-6 px-4">
        <h1 className="mb-4 text-3xl font-bold">{t('title')}</h1>
        <Setting title={t('language.title')} description={t('language.description')}>
          <Select
            containerClassName="mt-3"
            label={t('language.input.label')}
            options={LANGUAGES}
            value={LANGUAGES.find((l) => l.value === language.value) || LANGUAGES[0]}
            onSingleValueChange={async (v) => await onLanguageSelect(v.value as SupportedLanguages)}
          />
        </Setting>
      </div>
    </div>
  );
}
