import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react';
import { UploadTrack } from '~/types/features/track-upload/track-upload.types';
import { useTracksUploadContext } from '../../tracks-upload.context';
import { PresignedUploadData } from '~/types/schemas/file-upload-session/upload.schema';

type TracksUploadItemContextType = {
  isSelected?: boolean;
  isEditing?: boolean;
  presignedData: PresignedUploadData | null;
  setPresignedData: Dispatch<SetStateAction<PresignedUploadData | null>>;
  isCoverUploaded: boolean;
  setIsCoverUploaded: Dispatch<SetStateAction<boolean>>;
  uploadComplete: boolean;
  setUploadComplete: Dispatch<SetStateAction<boolean>>;
  data: UploadTrack;
};

type TracksUploadItemProviderProps = PropsWithChildren<{
  data: UploadTrack;
}>;

const TracksUploadItemContext = createContext<TracksUploadItemContextType | null>(null);

export function useTracksUploadItemContext() {
  const context = useContext(TracksUploadItemContext);

  if (!context) {
    throw new Error(
      'useTracksUploadItemContext must be used within a TracksUploadItemContext.Provider',
    );
  }

  return context;
}

export function TracksUploadItemProvider(props: TracksUploadItemProviderProps) {
  const { children, data } = props;
  const [presignedData, setPresignedData] = useState<PresignedUploadData | null>(null);
  const [uploadComplete, setUploadComplete] = useState(false);
  const [isCoverUploaded, setIsCoverUploaded] = useState(true);
  const { store } = useTracksUploadContext();
  const selected = store.use.selected();
  const editing = store.use.editing();

  const contextValue = useMemo(
    () => ({
      isSelected: data.id === selected,
      isEditing: data.id === editing,
      presignedData,
      setPresignedData,
      uploadComplete,
      setUploadComplete,
      isCoverUploaded,
      setIsCoverUploaded,
      data,
    }),
    [
      data,
      selected,
      editing,
      presignedData,
      uploadComplete,
      setPresignedData,
      setUploadComplete,
      setIsCoverUploaded,
      isCoverUploaded,
    ],
  );

  return (
    <TracksUploadItemContext.Provider value={contextValue}>
      {children}
    </TracksUploadItemContext.Provider>
  );
}
