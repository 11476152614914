import { ChangeEvent, FocusEventHandler, Ref, forwardRef, useState } from 'react';
import classNames from 'classnames';
import { parseThousands } from '~/utils/common/parse-thousands';
import { InputProps } from '../input';

interface NumberInputProps extends InputProps {
  containerClass?: string;
  inputClass?: string;
  labelClass?: string;
  wrapperClass?: string;
  isFocused: boolean;
  onFocus: FocusEventHandler<HTMLInputElement>;
  onBlur: FocusEventHandler<HTMLInputElement>;
}

function Number(props: NumberInputProps, inputRef: Ref<HTMLInputElement>) {
  const {
    label,
    value,
    placeholder,
    className,
    separator = ' ',
    containerClass = '',
    inputClass = '',
    wrapperClass = '',
    labelClass,
    error,
    required = false,
    onNumberChange,
    onFocus,
    onBlur,
    ...inputProps
  } = props;
  const [currentValue, setCurrentValue] = useState<string | undefined>(
    parseThousands(value, separator),
  );

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    const newValue = e.target.value;
    const regex = new RegExp(`${separator}`, 'g');
    const parsedNumber = parseInt(newValue.replace(regex, ''), 10);
    onNumberChange?.(parsedNumber);
    setCurrentValue(parseThousands(newValue, separator));
  }

  return (
    <div className={className}>
      <div className={wrapperClass}>
        {label && (
          <span className={labelClass}>
            {label}
            {required && <span className="text-control-danger">*</span>}
          </span>
        )}
        <div className={containerClass}>
          <input
            {...inputProps}
            type="text"
            ref={inputRef}
            aria-label={label}
            value={currentValue}
            placeholder={placeholder || label}
            className={classNames(inputClass, 'opacity-100')}
            onChange={handleChange}
            onFocus={onFocus}
            onBlur={onBlur}
          />
          {error ? (
            <span className="error text-control-danger mt-1 w-full text-xs">{error}</span>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export const NumberInput = forwardRef(Number);
