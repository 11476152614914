import * as Tabs from '@radix-ui/react-tabs';
import TabTrigger from './tab-trigger';
import AddFilterElement from '../filter-panel-element';
import { useTranslation } from 'react-i18next';

export type PanelFilter =
  | 'tag'
  | 'release_date'
  | 'bpm'
  | 'popularity'
  | 'catalog'
  | 'album'
  | 'artist'
  | 'playlist'
  | 'sampler'
  | 'brief';

function Navigation() {
  const { t } = useTranslation('filter');

  const navigationItems: Record<PanelFilter, string> = {
    tag: t('panel.tags-audiences.title'),
    release_date: t('panel.release-date.title'),
    bpm: t('panel.bpm.title'),
    popularity: t('panel.popularity.title'),
    catalog: t('panel.catalogs.title'),
    album: t('panel.albums.title'),
    artist: t('panel.artists.title'),
    playlist: t('panel.playlists.title'),
    sampler: t('panel.samplers.title'),
    brief: t('panel.briefs.title'),
  };

  return (
    <AddFilterElement label="Filters" className="border-neutral-muted border-r">
      <Tabs.List className="flex flex-col gap-0.5">
        {Object.entries(navigationItems).map(([key, value]) => (
          <TabTrigger key={`tab-${key}`} label={value} value={key} />
        ))}
      </Tabs.List>
    </AddFilterElement>
  );
}

export default Navigation;
