import LibraryLayout from '~/components/layout/library-layout';
import Button from '~/components/ui/control/button/button';
import { useTranslation } from 'react-i18next';
import BriefLibraryHeader from './components/header';
import { useBriefs } from './hooks/use-briefs';
import BriefCard from './components/brief-card';
import CardLoader from './components/card-loader';

function Briefs() {
  const { t } = useTranslation('common');
  const {
    briefs,
    nbHits,
    isEachDataDisplayed,
    isFetching,
    isLoading,
    fetchNextPage,
    handleCreateClick,
    handleCardClick,
  } = useBriefs();

  return (
    <LibraryLayout
      nbHits={nbHits}
      header={<BriefLibraryHeader total={nbHits} onCreateClick={handleCreateClick} />}
    >
      {!isLoading ? (
        <>
          {briefs.map((brief) => (
            <BriefCard
              key={`brief-card-${brief.id}`}
              onClick={() => handleCardClick(brief.id!)}
              brief={brief}
            />
          ))}
          {!isEachDataDisplayed && (
            <Button
              color="primary"
              type="button"
              isLoading={isFetching}
              onClick={() => fetchNextPage()}
              className="col-span-full mx-auto mb-3 w-fit"
            >
              {t('button.load-more.label')}
            </Button>
          )}
        </>
      ) : null}
      {isLoading && !briefs.length
        ? Array.from({ length: 10 }).map((_, index) => <CardLoader key={`card-loader-${index}`} />)
        : null}
    </LibraryLayout>
  );
}

export default Briefs;
