import { Language } from '~/types/types/languages';
import { userSettingsStore } from '~/stores/user-settings-store';
import { getShortLanguage } from '~/utils/common/language';
import { useMemo } from 'react';
import { TranslateToAnotherLanguageButton } from './translate-to-another-language-button';
import { TranslateButton } from './translate-button';
import { TranslateSelectContext, useTranslateSelectContext } from './translate-select.context';

type TranslateSelectProps = {
  targetLanguage?: Language;
  onLanguageChange: (value?: Language) => void;
};

function TranslateSelectBase() {
  const { targetLanguage } = useTranslateSelectContext();

  if (targetLanguage) {
    return <TranslateToAnotherLanguageButton />;
  }

  return <TranslateButton />;
}

export function TranslateSelect(props: TranslateSelectProps) {
  const { targetLanguage, onLanguageChange } = props;
  const { language } = userSettingsStore;
  const shortLanguage = getShortLanguage(language.value);

  const contextValue = useMemo(
    () => ({
      userLanguage: shortLanguage,
      targetLanguage,
      onLanguageChange,
    }),
    [targetLanguage, onLanguageChange],
  );

  return (
    <TranslateSelectContext.Provider value={contextValue}>
      <TranslateSelectBase />
    </TranslateSelectContext.Provider>
  );
}
