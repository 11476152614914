import { signal } from '@preact/signals-react';
import { RealtimeResponse } from '~/types/schemas/api/realtime.schema';
import { User } from '~/types/schemas/auth/user';
import * as Sentry from '@sentry/browser';
import { getCookie, removeCookie, setCookie } from '~/utils/common/cookie';
import { toastr } from '~/components/ui/notifications/toast/utils/toast-call';
import { t } from 'i18next';

export const globalStore = {
  apiUrl: signal(localStorage.getItem('apiUrl') || import.meta.env.VITE_API_URL),
  user: signal<User | undefined>(undefined),
  userToken: signal(getCookie('x-auth')),
  realtimeData: signal<RealtimeResponse | undefined>(undefined),
  loggedAs: signal(localStorage.getItem('loggedAs') === 'true'),
  setApiUrl: (url: string) => {
    globalStore.apiUrl.value = url;
    localStorage.setItem('apiUrl', url);
  },
  setAdminToken: (token: string, reload?: boolean) => {
    setCookie('x-auth', token, { path: '/', secure: true });
    window.localStorage.setItem('loggedAs', 'true');

    if (reload) {
      window.location.reload();
    }
  },
  setUser(user: User | null, token?: string) {
    if (user) {
      globalStore.user.value = user;
      Sentry.setUser(user);
      if (token) {
        globalStore.userToken.value = token;

        setCookie('x-auth', token, {
          path: '/',
          expires: new Date(Date.now() + 1000 * 60 * 60 * 72),
          secure: true,
        });
        localStorage.removeItem('loggedAs');
      }
    }

    if (!user) {
      Sentry.setUser(null);
      localStorage.removeItem('userToken');
      globalStore.userToken.value = undefined;
      globalStore.user.value = undefined;
      localStorage.removeItem('loggedAs');
    }
  },
  setRealtimeData: (data: RealtimeResponse) => {
    globalStore.realtimeData.value = data;
  },
  handleUnauthorized: () => {
    globalStore.user.value = undefined;
    globalStore.userToken.value = undefined;
    removeCookie('x-auth');
    localStorage.removeItem('userToken');
    localStorage.removeItem('headers');
    localStorage.removeItem('loggedAs');
    toastr(
      'error',
      t('auth.login.invalid-token.title', { ns: 'toast' }),
      t('auth.login.invalid-token.content', { ns: 'toast' }),
      'invalid_token',
    );
  },
  handleLogout: () => {
    globalStore.user.value = undefined;
    globalStore.userToken.value = undefined;
    removeCookie('x-auth');
    localStorage.removeItem('userToken');
    localStorage.removeItem('headers');
    localStorage.removeItem('loggedAs');
  },
};
