import { Icon } from '@iconify/react';
import * as RadixDropdownMenu from '@radix-ui/react-dropdown-menu';
import { useTranslation } from 'react-i18next';
import * as DropdownMenu from '~/components/ui/control/menus/dropdown-menu/common/dropdown-menu';
import { Tooltip } from '~/components/ui/overlays/tooltip/tooltip';
import { useState } from 'react';
import { useTrackContext } from '~/components/entities/track/common/track.context';
import { TrackItemsDropdownItems } from './dropdown-items';

export function TrackItemDropdownMenu() {
  const { t } = useTranslation('track-interactions');
  const store = useTrackContext();
  const setIsTrackActive = store.use.setIsTrackActive();

  const [isOpen, setIsOpen] = useState(false);

  function handleDropDownMenuOpenChange(b: boolean) {
    setIsOpen(b);
    setIsTrackActive(b);
  }

  return (
    <RadixDropdownMenu.Root open={isOpen} onOpenChange={handleDropDownMenuOpenChange}>
      <Tooltip label={t('more-options.tooltip')} asChild>
        <RadixDropdownMenu.Trigger
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={false}
          className="hover:bg-neutral-muted text-muted hover:text-default outline-focus relative flex size-7 cursor-pointer items-center justify-center rounded-md p-1 outline-hidden focus:outline-1"
        >
          <Icon icon="ri:more-line" />
        </RadixDropdownMenu.Trigger>
      </Tooltip>
      <DropdownMenu.Content align="end" sideOffset={5}>
        <TrackItemsDropdownItems />
      </DropdownMenu.Content>
    </RadixDropdownMenu.Root>
  );
}
