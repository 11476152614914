import { PropsWithChildren } from 'react';

type BriefDescriptionBlockProps = PropsWithChildren<{
  label: string;
  isLoading: boolean;
}>;

export function BriefDescriptionBlock(props: BriefDescriptionBlockProps) {
  const { label, isLoading, children } = props;

  return (
    <div className="bg-neutral-muted flex size-full flex-col rounded-lg p-2 text-xs">
      <span className="text-muted mb-0.5 w-full">{label}</span>
      {isLoading ? (
        <div className="flex w-full items-center">
          <div className="bg-neutral-muted h-3 w-[50%] animate-pulse rounded-sm" />
        </div>
      ) : (
        children
      )}
    </div>
  );
}
