import classNames from 'classnames';
import { forwardRef } from 'react';

function ChatCardLoader(_props: unknown, ref: React.Ref<HTMLDivElement>) {
  const pulseClass = 'rounded-sm bg-neutral-muted animate-pulse';

  return (
    <div
      ref={ref}
      className="bg-neutral-emphasis hover:border-neutral-emphasis relative flex cursor-pointer items-stretch gap-3 rounded-lg border border-transparent py-3 pr-6 pl-3"
    >
      <div className={classNames('aspect-square h-10! w-10!', pulseClass)} />
      <div className="flex w-full flex-col gap-3 overflow-hidden">
        <div className="flex w-[80%] flex-col gap-1">
          <div className={classNames('h-5 w-[50%]', pulseClass)} />
          <div className={classNames('h-4 w-[60%]', pulseClass)} />
        </div>
        <div className="flex w-full flex-col gap-1">
          <div className={classNames('h-4', pulseClass)} />
          <div className={classNames('h-4', pulseClass)} />
          <div className={classNames('h-4 w-[20%]', pulseClass)} />
        </div>
      </div>
    </div>
  );
}

export default forwardRef(ChatCardLoader);
