import { Icon } from '@iconify/react';
import {
  FocusEventHandler,
  KeyboardEvent,
  MutableRefObject,
  Ref,
  forwardRef,
  useEffect,
  useState,
} from 'react';
import classNames from 'classnames';
import { InputProps } from '../input';

interface TagInputProps extends InputProps {
  containerClass?: string;
  inputClass?: string;
  labelClass?: string;
  wrapperClass?: string;
  isFocused: boolean;
  onFocus: FocusEventHandler<HTMLInputElement>;
  onBlur: FocusEventHandler<HTMLInputElement>;
}

function Tag(props: TagInputProps, inputRef: Ref<HTMLInputElement>) {
  const {
    label,
    labelInfo,
    placeholder,
    className,
    containerClass = '',
    wrapperClass = '',
    inputClass = '',
    labelClass,
    error,
    defaultValue,
    value,
    iconLeft,
    iconRight,
    required = false,
    onFocus,
    onBlur,
    onChange,
    ...inputProps
  } = props;

  const initValue = value || defaultValue || '';

  const [input, setInput] = useState('');
  const [tags, setTags] = useState<string[]>(
    initValue ? initValue?.split(',').map((item) => item.trim() || '') : [],
  );
  const [isKeyReleased, setIsKeyReleased] = useState(false);
  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    if (value === '' || value == null) {
      setTags([]);
    }
    setTags(value ? value?.split(',').map((item) => item.trim() || '') : []);
  }, [value]);

  function onInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    setInput(e.target.value);
  }

  function onKeyDown(e: KeyboardEvent<HTMLInputElement>) {
    const { key } = e;
    const trimmedInput = input.trim();

    if ((key === 'Enter' || key === ',') && trimmedInput.length && !tags.includes(trimmedInput)) {
      e.preventDefault();
      setTags((prev) => [...prev, trimmedInput]);
      setInput('');
    }

    if (key === 'Backspace' && !input.length && tags.length && isKeyReleased) {
      e.preventDefault();
      const tagsCopy = [...tags];
      const poppedTag = tagsCopy.pop();

      setTags(tagsCopy);
      setInput(poppedTag || '');
    }

    setIsKeyReleased(false);
  }

  function onKeyUp() {
    setIsKeyReleased(true);
  }

  function deleteTag(index: number) {
    setTags((prev) => prev.filter((_, i) => i !== index));
  }

  function handleFocus() {
    (inputRef as MutableRefObject<HTMLInputElement>).current?.focus();
  }

  useEffect(() => {
    if (!isFirstRender) {
      onChange?.(tags.join(','));
    }
    setIsFirstRender(false);
  }, [tags]);

  return (
    <div className={className}>
      <div className={classNames(wrapperClass, 'h-fit min-h-12')} onClick={handleFocus}>
        <div className="flex gap-1">
          {label && (
            <span className={labelClass}>
              {label}
              {required && <span className="text-control-danger">*</span>}
            </span>
          )}
          {labelInfo && <span className={labelClass}>{`- ${labelInfo}`}</span>}
        </div>
        <div className={classNames(containerClass, 'h-fit min-h-8')}>
          {iconLeft ? <Icon icon={iconLeft} className="text-muted" /> : null}
          <div className="hide-scrollbar flex flex-1 flex-wrap items-center gap-3 overflow-x-auto">
            <div className="flex w-fit shrink-0 flex-wrap items-center gap-1 py-1.5">
              {tags.map((item, index) => (
                <label
                  key={`${item}-${index}`}
                  htmlFor={`input-text-multiple-${item}`}
                  className="text-neutral bg-neutral-muted flex h-5 w-fit shrink-0 items-center gap-1 rounded-sm pr-1 pl-2 text-xs"
                >
                  <span>{item}</span>
                  <button
                    id={`input-text-multiple-${item}`}
                    onClick={() => deleteTag(index)}
                    aria-label="delete item"
                    type="button"
                  >
                    <Icon icon="ri:close-fill" className="text-muted" />
                  </button>
                </label>
              ))}
              <input
                ref={inputRef}
                aria-label={label}
                placeholder={placeholder || label}
                className={classNames(inputClass, 'flex-1')}
                value={input}
                onFocus={onFocus}
                onBlur={onBlur}
                onKeyDown={onKeyDown}
                onKeyUp={onKeyUp}
                onChange={onInputChange}
                {...inputProps}
              />
            </div>
          </div>
          {iconRight ? <Icon icon={iconRight} className="text-muted" /> : null}
        </div>
      </div>
      {error ? (
        <span className="error text-control-danger mt-1 w-full text-xs">{error}</span>
      ) : null}
    </div>
  );
}

export const TagInput = forwardRef(Tag);
