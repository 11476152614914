import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useCreateBrief, useGetBriefsList } from '~/hooks/api/brief';

const MAX_ITEMS_PER_PAGE = 50;

export function useBriefs() {
  const { data, isLoading, isFetching, isError, fetchNextPage } = useGetBriefsList();
  const pages = data?.pages || [];
  const navigate = useNavigate();
  const { mutate: createBrief } = useCreateBrief();
  const { t } = useTranslation('brief-form');

  const total = pages?.[0]?.total?.value || 0;
  const isAllDataDisplayed = total <= (pages?.length || 0) * MAX_ITEMS_PER_PAGE;

  function handleCardClick(id: string) {
    navigate(`/briefs/${id}`);
  }

  function handleCreateClick() {
    createBrief(
      { title: t('input.title.default') },
      {
        onSuccess: (newBrief) => {
          navigate(`/briefs/${newBrief.id}`);
        },
      },
    );
  }

  return {
    briefs: pages.flatMap((page) => page.hits) ?? [],
    nbHits: total,
    isLoading,
    isFetching,
    isError,
    isEachDataDisplayed: isAllDataDisplayed,
    fetchNextPage,
    handleCardClick,
    handleCreateClick,
  };
}
