import * as Portal from '@radix-ui/react-portal';
import { motion } from 'framer-motion';
import { useRelativeTime } from '~/hooks/common/use-relative-time';
import Icon from '~/components/ui/icon/icon';
import classNames from 'classnames';
import { RealtimeUser } from '~/types/types/realtime';
import { parseMessage } from '../../chat/chat-messages/message-group/message/message-parser';

type ChatPreviewProps = {
  user?: RealtimeUser;
  message?: string;
  totalMessages?: number;
  timetoken: string;
  portalStyle: React.CSSProperties;
};

function ChatPreview(props: ChatPreviewProps) {
  const { user, message, timetoken, portalStyle, totalMessages } = props;
  const textParts = parseMessage(message || '');
  const sentAt = useRelativeTime(+timetoken / 1e4);

  return (
    <Portal.Root className="absolute z-10" style={portalStyle}>
      <motion.div
        initial={{ width: '0', opacity: 0 }}
        animate={{ width: '15rem', opacity: 1 }}
        exit={{ width: '0', padding: 0, opacity: 0 }}
        transition={{ ease: 'easeIn', duration: 0.3 }}
        className="absolute bottom-0 left-0 w-fit cursor-pointer overflow-hidden rounded-lg"
      >
        <motion.div
          initial={{ y: '100%', opacity: 0 }}
          animate={{ y: '0%', opacity: 1 }}
          exit={{ y: '100%', opacity: 0 }}
          transition={{ ease: 'easeInOut', duration: 0.3 }}
          className="bg-accent-emphasis flex gap-2 p-2 pr-9"
        >
          <div className="bg-muted flex aspect-square h-6 w-6 shrink-0 items-center justify-center overflow-hidden rounded-full">
            {user?.custom?.avatar ? (
              <img
                src={user?.custom?.avatar}
                alt={user.name || ''}
                className="h-full w-full object-cover"
              />
            ) : (
              <Icon name="user-line" className="text-xs" />
            )}
          </div>
          <div className="flex w-fit flex-col">
            <div className="flex h-4 gap-1.5 overflow-hidden text-xs">
              <h6 className="text-default line-clamp-1 font-bold whitespace-nowrap">{`${user?.name}`}</h6>
              {message && <span className="text-muted w-fit whitespace-nowrap">{sentAt}</span>}
            </div>
            <p className="text-default line-clamp-3 shrink-0 text-xs">
              {textParts.map((part, i) => (
                <span
                  key={`${part.text}-${part.type}-${i}`}
                  className={classNames({ 'font-bold': part.type === 'mention' })}
                >
                  {part.text}
                </span>
              ))}
            </p>
            <span className="text-muted text-xs">{`${totalMessages} Messages`}</span>
          </div>
        </motion.div>
      </motion.div>
    </Portal.Root>
  );
}

export default ChatPreview;
