import { z } from 'zod';

const PresignedUploadDataSchema = z.object({
  id: z.string(),
  url: z.string(),
  fields: z.record(z.string()),
});

type PresignedUploadData = z.infer<typeof PresignedUploadDataSchema>;

export { PresignedUploadDataSchema as PresignedUploadDataSchema };
export type { PresignedUploadData as PresignedUploadData };
