import * as Track from '~/components/entities/track/common/track';
import { PlaylistTrackItemLayout } from '~/components/layout/track-items/playlist-track-item.layout';
import { TrackItemDropdownMenu } from '../menus/dropdown-menu/dropdown-menu';
import { RemoveFromTracklist } from './actions/remove-from-tracklist';
import { TrackItemHeaderSummary } from './summary/summary';
import { MagicPreview } from './actions/magic-preview';
import { useTrackItem } from '~/hooks/entities/track/track-item/use-track-item';

export function PlaylistTrackItemHeader() {
  const { isOpen, isSelected } = useTrackItem();

  return (
    <PlaylistTrackItemLayout>
      <TrackItemHeaderSummary className="col-start-[summary]" />
      <Track.MusicType className="col-start-[music-type] hidden justify-self-center @[41rem]:block" />
      <Track.AddedByChip className="col-start-[added-by] justify-self-center" />
      <Track.Versions className="col-start-[versions] justify-self-center" />
      <Track.Popularity className="h- col-start-[popularity] justify-self-center" />
      <Track.Waveform
        isSelected={isOpen || isSelected}
        className="col-start-[waveform] hidden justify-self-end @[50rem]:block"
      />
      <Track.Duration className="col-start-[duration] justify-self-end" />
      <Track.ActionList className="col-start-[actions">
        <MagicPreview />
        <RemoveFromTracklist />
        <TrackItemDropdownMenu />
      </Track.ActionList>
    </PlaylistTrackItemLayout>
  );
}
