import { UseQueryResult, keepPreviousData, useQuery } from '@tanstack/react-query';
import { KyOptions } from 'ky/distribution/types/options';
import { ZodSchema } from 'zod';
import { QueryKeyT, postFetcher } from '../../../utils/react-query/fetcher';

export type useAlternativeFetchOptions<T> = {
  url: string;
  schema: ZodSchema<T>;
  queryParams?: KyOptions;
  staleTime?: number;
  onSuccess?: (data: T) => void;
};

export const useAlternativeFetch = <T>(
  params: useAlternativeFetchOptions<T>,
): UseQueryResult<T> => {
  const { url, schema, queryParams, staleTime } = params;
  return useQuery<T, Error, T, QueryKeyT>({
    queryKey: [url!, queryParams],
    queryFn: ({ queryKey, ...rest }) => postFetcher({ ...rest, queryKey }, schema),
    enabled: !!url,
    staleTime,
    placeholderData: keepPreviousData,
  });
};
