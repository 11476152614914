import { useEffect, useState } from 'react';
import SearchInput from '~/components/ui/search-input/search-input';
import useDebounce from '~/hooks/common/use-debounce';
import { InfiniteScroll } from '~/components/layout/infinite-scroll/infinite-scroll';
import { Range } from '@tanstack/react-virtual';
import FilterItem, { ItemProps } from '../items/filter-item';

type ItemSearchProps = {
  itemType: string;
  inputPlaceholder: string;
  items?: ItemProps[];
  totalCount: number;
  hasNextPage: boolean;
  isFetchingNextPage: boolean;
  isEndReached?: boolean;
  value?: string;
  onValueChange?: (value: string) => void;
  fetchNextPage?: () => void;
};

const FETCH_OVERSCAN = 10;

function ItemSearch(props: ItemSearchProps) {
  const {
    itemType,
    inputPlaceholder,
    value: defaultValue,
    items,
    totalCount,
    hasNextPage,
    isFetchingNextPage,
    isEndReached,
    onValueChange,
    fetchNextPage,
  } = props;
  const [value, setValue] = useState(defaultValue || '');
  const debouncedValue = useDebounce(value, 500);

  function handleNextPageFetch({ endIndex }: Range) {
    if (endIndex >= (items?.length || 0) - FETCH_OVERSCAN && hasNextPage && !isFetchingNextPage) {
      fetchNextPage?.();
    }
  }

  useEffect(() => {
    if (debouncedValue !== undefined) {
      onValueChange?.(debouncedValue);
    }
  }, [debouncedValue, onValueChange]);

  return (
    <div className="mt-2 flex h-full flex-col items-stretch gap-2">
      <div className="px-2">
        <SearchInput value={value} onChange={setValue} placeholder={inputPlaceholder} />
      </div>
      <div
        id={`filter-search-${itemType}`}
        className="custom-scrollbar flex h-full flex-col gap-0.5 overflow-y-auto p-2 pb-0"
      >
        <div className="h-fit w-full">
          <InfiniteScroll
            data={items}
            count={totalCount}
            estimateSize={42}
            overscan={10}
            onRangeChange={handleNextPageFetch}
            renderItem={(item) => {
              return <FilterItem {...(item as ItemProps)} />;
            }}
            isEndReached={isEndReached}
            scrollParent={document.getElementById(`filter-search-${itemType}`)}
          />
        </div>
      </div>
    </div>
  );
}

export default ItemSearch;
