type TextProps = {
  label: string;
};

export function Text(props: TextProps) {
  const { label } = props;

  return (
    <div className="hover:bg-neutral-muted flex items-center rounded-sm px-3 py-2 text-sm">
      {label}
    </div>
  );
}
