import { createContext, useContext } from 'react';
import { Brief } from '~/types/schemas/music/brief.schema';
import { Language } from '~/types/types/languages';

type BriefContextType = {
  data: Brief;
  isLoading: boolean;
  targetLanguage?: Language;
  handleTargetLanguageChange: (language?: Language) => void;
};

export const BriefContext = createContext<BriefContextType | null>(null);

export function useBriefContext() {
  const context = useContext(BriefContext);

  if (!context) {
    throw new Error('useBriefContext must be used within a BriefContext.Provider');
  }

  return context;
}
