import { IconifyIconProps, Icon } from '@iconify/react';
import { ReactNode } from 'react';

type ActionIconProps = {
  icon?: IconifyIconProps['icon'];
  customIcon?: ReactNode;
  className?: string;
};

export function ActionIcon(props: ActionIconProps) {
  const { icon, customIcon, className } = props;

  return (
    <div className={className}>
      {icon ? <Icon icon={icon} className="text-default size-4" /> : null}
      {customIcon}
    </div>
  );
}
