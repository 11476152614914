import { useMemo } from 'react';
import { useTrackContext } from '~/components/entities/track/common/track.context';
import useMusemindStore from '~/stores/musemind-store';
import usePlayerStore from '~/stores/player-store';

export function useTrackPlaybackState() {
  const store = useTrackContext();
  const track = store.use.track();

  const player = usePlayerStore(['isPlaying', 'currentTrack']);
  const magicPreview = useMusemindStore(['trackId', 'isPlaying', 'isCreateVariationsLoading']);

  const playerTrackId = useMemo(() => player.currentTrack?.id, [player.currentTrack]);
  const magicPreviewTrackId = useMemo(() => magicPreview.trackId, [magicPreview.trackId]);

  const isCurrentPlayerTrack = useMemo(() => playerTrackId === track.id, [playerTrackId, track.id]);
  const isCurrentMagicPreviewTrack = useMemo(
    () => magicPreviewTrackId === track.id,
    [magicPreviewTrackId, track.id],
  );
  const isCurrentTrack = useMemo(
    () =>
      (player.isPlaying && isCurrentPlayerTrack) ||
      (magicPreview.isPlaying && isCurrentMagicPreviewTrack),
    [player.isPlaying, isCurrentPlayerTrack, magicPreview.isPlaying, isCurrentMagicPreviewTrack],
  );

  const isReady = useMemo(() => track.waveform && track.duration, [track.duration, track.waveform]);

  const isPlaying = useMemo(
    () => isCurrentTrack && (player.isPlaying || magicPreview.isPlaying),
    [isCurrentTrack, player.isPlaying, magicPreview.isPlaying],
  );
  const isPending = useMemo(
    () => magicPreview.isCreateVariationsLoading,
    [magicPreview.isCreateVariationsLoading],
  );

  return {
    isCurrentTrack,
    isReady,
    isPlaying,
    isPending,
  };
}
