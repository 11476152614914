import { useSearchStore } from '~/stores/search-store';
import { useTranslation } from 'react-i18next';
import Select, { OptionType } from '~/components/ui/forms/select/select';
import FilterSection from './filter-section';
import { useMemo } from 'react';

const MusicType = () => {
  const search = useSearchStore(['musicType', 'setMusicType']);
  const { t } = useTranslation(['filter']);

  const isActive = useMemo(() => search.musicType.length < 2, [search.musicType.length]);

  const options = useMemo(
    () => [
      {
        value: 'all',
        label: t('music-type.options.all.label'),
      },
      {
        value: 'core',
        label: t('music-type.options.core.label'),
        description: t('music-type.options.core.description'),
      },
      {
        value: 'production',
        label: t('music-type.options.prod.label'),
        description: t('music-type.options.prod.description'),
      },
    ],
    [t],
  );
  const defaultValue = useMemo(() => {
    if (search.musicType.length === 2) return options[0];
    else if (search.musicType[0] === 'core') return options[1];
    else if (search.musicType[0] === 'production') return options[2];
  }, [search.musicType, options]);

  function handleSingleValueChange(option: OptionType) {
    switch (option.value) {
      case 'core':
        search.setMusicType(['core']);
        break;
      case 'production':
        search.setMusicType(['production']);
        break;
      default:
        search.setMusicType(['core', 'production']);
        break;
    }
  }

  return (
    <FilterSection label={t('music-type.title')} isActive={isActive} className="w-fit min-w-64">
      <Select
        value={defaultValue}
        onSingleValueChange={handleSingleValueChange}
        containerClassName="w-full"
        options={options}
      />
    </FilterSection>
  );
};

export default MusicType;
