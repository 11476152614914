import usePreSearchStore from '~/stores/presearch-store';
import { useSearchStore } from '~/stores/search-store';
import { MouseEvent, useEffect, useState } from 'react';
import { useAllTabData } from '~/components/features/presearch/data/all-tab.data';
import { Track } from '~/types/schemas/music/track.schema';
import AddToTracklist from '~/components/features/track-interactions/components/add-to-tracklist/add-to-tracklist';
import { useCardClick } from '~/components/features/presearch/hook/use-card-click';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import PresearchBlock from '../../../presearch-block/presearch-block';
import TrackResult from '../../../track-result/track-result';
import EmptyBlock from './empty-block';

type TrackResultBlockProps = {
  className?: string;
};

function TrackResultBlock(props: TrackResultBlockProps) {
  const { className } = props;
  const { tracks, isLoading } = useAllTabData();
  const [currentTracks, setCurrentTracks] = useState<Track[] | undefined>(undefined);
  const search = useSearchStore(['addValueToMatchingBlock', 'setQuery', 'setSort', 'resetFilters']);
  const presearch = usePreSearchStore(['query', 'setIsOpen']);
  const { handleFocusClick: filterByTrack, handleArtistClick } = useCardClick('id');
  const { t } = useTranslation('pre-search');

  useEffect(() => {
    if (!isLoading) {
      setCurrentTracks(tracks);
    }
  }, [isLoading, tracks]);

  const handleClick = (e: MouseEvent, id: string, name: string) => {
    e.preventDefault();
    filterByTrack(name, id);
    presearch.setIsOpen(false);
  };

  const handleAllTracksClick = () => {
    presearch.setIsOpen(false);
    search.resetFilters();
    search.setSort('relevance');
    search.setQuery(presearch.query);
  };

  return currentTracks ? (
    <PresearchBlock
      label={t('tab.all.tracks-search.label')}
      containerClassName={classNames('relative w-full', className)}
      childrenClassName="w-full h-full self-stretch flex flex-col gap-1 "
    >
      {currentTracks?.length ? (
        currentTracks
          ?.slice(0, 4)
          .map((track: Track) => (
            <TrackResult
              key={track.id}
              title={track.title}
              artists={track.display_artists}
              artwork={track.artwork}
              type={track.music_type}
              duration={track.duration}
              onArtistNameClick={handleArtistClick}
              onClick={(e) =>
                handleClick(
                  e,
                  track.id,
                  `${track.title} - ${track.display_artists.map((artist) => artist.name).join(', ')}`,
                )
              }
              interactiveButton={<AddToTracklist track={track} />}
            />
          ))
      ) : (
        <EmptyBlock />
      )}
      <button
        type="button"
        onClick={handleAllTracksClick}
        className="text-muted hover:text-default absolute top-1 right-2 text-xs"
      >
        {t('tab.all.tracks-search.see-all')}
      </button>
    </PresearchBlock>
  ) : null;
}

export default TrackResultBlock;
