import Button from '~/components/ui/control/button/button';
import { useTranslation } from 'react-i18next';
import { BriefFormAlertDialog } from '~/components/ui/overlays/alert-dialogs/brief-form/brief-form-alert-dialog';

type CloseDialogProps = {
  onExit?: () => void;
  onDiscard?: () => void;
};

export function CloseDialog(props: CloseDialogProps) {
  const { onExit, onDiscard } = props;
  const { t } = useTranslation('brief-form');

  return (
    <BriefFormAlertDialog onExit={onExit} onDiscard={onDiscard}>
      <Button color="default" className="brief-form__button--exit">
        {t('header.exit')}
      </Button>
    </BriefFormAlertDialog>
  );
}
