type BlinkOptions = {
  blinkCount: number;
  blinkIntervalDelay: number;
};

function blinkElement(el: HTMLElement, opt?: Partial<BlinkOptions>) {
  const { blinkCount = 2, blinkIntervalDelay = 200 } = opt || {};
  // create animate flash on unvalidated file
  el.style.transition = 'opacity 0.5s';
  let blinkCounter = 0;
  const blinkInterval = setInterval(() => {
    el.style.opacity = el.style.opacity === '1' ? '0.3' : '1';
    blinkCounter++;
    if (blinkCounter === blinkCount * 2) {
      clearInterval(blinkInterval);
      el.style.opacity = '1';
    }
  }, blinkIntervalDelay);
}

export default blinkElement;
