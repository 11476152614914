import { Icon, IconifyIconProps } from '@iconify/react';

type NavButtonProps = {
  icon: IconifyIconProps['icon'];
  onClick: () => void;
};

const NavButton = (props: NavButtonProps) => {
  const { icon, onClick } = props;

  return (
    <button
      type="button"
      className="bg-button-default-rest hover:bg-button-default-hover text-button-danger-rest hover:text-button-default-hover outline-focus flex aspect-square w-8 items-center justify-center rounded-full outline-hidden transition-all focus:outline-1"
      onClick={onClick}
    >
      <Icon icon={icon} className="size-5" />
    </button>
  );
};

export default NavButton;
