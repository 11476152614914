import { CSSProperties } from 'react';

export function TracklistSentinel(style?: CSSProperties) {
  return (
    <div
      className="bg-sidebar-tracklist-sentinel-sm 2xl:bg-sidebar-tracklist-sentinel-base h-full w-full bg-repeat-y"
      style={{
        ...style,
      }}
    />
  );
}
