import { createContext, useContext } from 'react';
import { Language } from '~/types/types/languages';

type TranslateSelectContextType = {
  userLanguage: Language;
  targetLanguage?: Language;
  onLanguageChange: (l?: Language) => void;
};

export const TranslateSelectContext = createContext<TranslateSelectContextType | null>(null);

export function useTranslateSelectContext() {
  const context = useContext(TranslateSelectContext);

  if (!context) {
    throw new Error('useTranslateSelect context must be in a TranslateSelectProvider');
  }

  return context;
}
