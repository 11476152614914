import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Button from '~/components/ui/control/button/button';

type FooterExtendButtonProps = {
  extend: boolean;
  onExtendClick: () => void;
  className?: string;
};

export function FooterExtendButton(props: FooterExtendButtonProps) {
  const { extend, onExtendClick, className } = props;
  const { t } = useTranslation('brief-page');

  return (
    <Button
      color="default"
      type="button"
      className={classNames(
        'h-fit w-fit rounded-lg border-2 border-transparent shadow-xl',
        className,
      )}
      onClick={onExtendClick}
    >
      {extend
        ? t('header.brief-content.show-less.label')
        : t('header.brief-content.show-more.label')}
    </Button>
  );
}
