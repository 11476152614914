import { components, GroupBase, MultiValueProps } from 'react-select';
import { OptionType } from '../../select';

const MultiValue = (props: MultiValueProps<OptionType, boolean, GroupBase<OptionType>>) => {
  const { children } = props;

  return (
    <components.MultiValue className="text-neutral! m-0! bg-transparent! p-0! text-xs" {...props}>
      {children}
    </components.MultiValue>
  );
};

export default MultiValue;
