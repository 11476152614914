import { Active } from '@dnd-kit/core';
import { createContext, useContext, useState, useMemo } from 'react';

export type DraggedPropsContextType = {
  draggedProps: Active | null;
  setDraggedProps: (props: Active | null) => void;
};

export const DraggedPropsContext = createContext<DraggedPropsContextType | undefined>(undefined);

export function useDraggedProps() {
  const context = useContext(DraggedPropsContext);
  if (!context) {
    throw new Error('useDraggedProps must be used within a DraggedPropsProvider');
  }
  return context;
}

export function DraggedPropsProvider({ children }: { children: React.ReactNode }) {
  const [draggedProps, setDraggedProps] = useState<Active | null>(null);
  const defaultValue = useMemo(
    () => ({ draggedProps, setDraggedProps }),
    [draggedProps, setDraggedProps],
  );

  return (
    <DraggedPropsContext.Provider value={defaultValue}>{children}</DraggedPropsContext.Provider>
  );
}
