import Input from '../forms/input/input';

type SearchInputProps = {
  value: string;
  placeholder?: string;
  onChange?: (value: string) => void;
};

function SearchInput(props: SearchInputProps) {
  const { value, placeholder = 'Search', onChange } = props;

  return <Input type="text" placeholder={placeholder} value={value} onChange={onChange} />;
}

export default SearchInput;
