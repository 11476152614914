import { Icon } from '@iconify/react';
import { useTranslation } from 'react-i18next';

export function NotificationListEmpty() {
  const { t } = useTranslation('notification-center');

  return (
    <div className="flex flex-1 flex-col items-center justify-center">
      <Icon icon="ri:notification-off-line" className="text-muted mb-2 size-8" />
      <span className="text-xl font-bold">{t('empty-notification-list.title')}</span>
      <p className="text-muted">{t('empty-notification-list.description')}</p>
    </div>
  );
}
