import { z } from 'zod';

export const LicensingQuoteFormSchema = z.object({
  productionType: z.string().min(1),
  productionName: z.string().optional(),
  requestedRights: z.string().min(1),
  licensingClientName: z.string().optional(),
  licensingCountry: z.string().optional(),
  creativeDescription: z.string().optional(),
  maxLength: z.string().optional(),
  videoLinks: z.string().optional(),
  medias: z.string().optional(),
  duration: z.string().optional(),
  launchDate: z.string().optional(),
  territories: z.string().optional(),
  budget: z.number().optional(),
  currency: z.string().optional(),
});

export const LicensingQuoteSchema = z.object({
  production_type: z.string().min(1),
  production_name: z.string().optional(),
  requested_rights: z.string().min(1),
  licensing_client_name: z.string().optional(),
  licensing_country: z.string().optional(),
  creative_description: z.string().optional(),
  max_length: z.string().optional(),
  video_links: z.string().optional(),
  medias: z.string().optional(),
  duration: z.string().optional(),
  launch_date: z.string().optional(),
  territories: z.string().optional(),
  budget: z.number().optional(),
  currency: z.string().optional(),
});

export type LicensingQuote = z.infer<typeof LicensingQuoteSchema>;
export type LicensingQuoteForm = z.infer<typeof LicensingQuoteFormSchema>;
