import i18n from '~/i18n/config';
import { TranslatedFilterSchema } from '~/types/schemas/data/translated-filter.schema';
import { SupportedLanguages } from '~/types/schemas/data/translate.schema';
import { FilterKeys } from '~/types/schemas/search/filter.schema';
import translatedFiltersRaw from '~/data/filters.json';
import FilterChip from './filter-chip';
import { Tags } from './tags';
import { useParsedFilterBlock } from '../utils/parse-filters';
import FilterSection from './filter-section';
import { useTranslation } from 'react-i18next';

export function FilterList() {
  const parsedFilterBlocks = useParsedFilterBlock();
  const translatedFilters = TranslatedFilterSchema.parse(translatedFiltersRaw);
  const { t } = useTranslation('filter');

  return Object.values(parsedFilterBlocks)?.map((block) => {
    if (block.term.value === 'tags' || block.term.value === 'tag_categories') {
      return <Tags block={block} />;
    }

    return (
      <FilterSection
        key={block.id}
        label={
          translatedFilters[i18n.language as SupportedLanguages]?.[
            block?.term.value as FilterKeys
          ] || ''
        }
        isActive
      >
        {Object.values(block.filters).map((filter, i) => (
          <div key={`block-${filter.id}-${i}`}>
            <FilterChip block={block} filter={filter} />
            {Object.values(block.filters).length - 1 > i ? (
              <span className="text-muted ml-2 text-xs font-bold">
                {block.term.value === 'tags' || block.term.value === 'tag_categories'
                  ? t('filter.operator.and')
                  : t('filter.operator.or')}
              </span>
            ) : null}
          </div>
        ))}
      </FilterSection>
    );
  });
}
