import * as Accordion from '@radix-ui/react-accordion';
import { AnimatePresence, motion, Variants } from 'framer-motion';

interface AccordionContentProps {
  children: React.ReactNode;
}

export function AccordionContent(props: AccordionContentProps) {
  const { children } = props;

  const contentVariants: Variants = {
    hidden: {
      opacity: 0,
      height: 0,
    },
    visible: {
      opacity: 1,
      height: 'auto',
      transition: { ease: 'easeInOut', duration: 0.1 },
    },
  };

  return (
    <AnimatePresence mode="wait">
      <Accordion.Content asChild>
        <motion.ul initial="hidden" animate="visible" exit="hidden" variants={contentVariants}>
          <div className="flex flex-col gap-3 p-4 pt-0">{children}</div>
        </motion.ul>
      </Accordion.Content>
    </AnimatePresence>
  );
}
