import { ReactNode } from 'react';
import Button from '~/components/ui/control/button/button';
import useMusemindStore from '~/stores/musemind-store';
import { useTranslation } from 'react-i18next';

export default function VariationControlBase() {
  const musemind = useMusemindStore(['audioTracks', 'selectedAudioTrack', 'setSelectedAudioTrack']);
  const { t } = useTranslation('magic-preview');

  return (
    <div className="flex gap-2">
      {musemind.audioTracks ? (
        <>
          <ControlButton
            label={t('pip.footer.ost')}
            isActive={musemind.selectedAudioTrack === -1}
            onClick={() => musemind.setSelectedAudioTrack(-1)}
          />
          <ControlButton
            label={t('pip.footer.v1')}
            isActive={musemind.selectedAudioTrack === 0}
            onClick={() => musemind.setSelectedAudioTrack(0)}
          />
          <ControlButton
            label={t('pip.footer.v2')}
            isActive={musemind.selectedAudioTrack === 1}
            onClick={() => musemind.setSelectedAudioTrack(1)}
          />
          <ControlButton
            label={t('pip.footer.v3')}
            isActive={musemind.selectedAudioTrack === 2}
            onClick={() => musemind.setSelectedAudioTrack(2)}
          />
        </>
      ) : null}
    </div>
  );
}

export const ControlButton = (props: {
  label?: string;
  children?: ReactNode;
  isActive: boolean;
  isLoading?: boolean;
  onClick: () => void;
}) => {
  return (
    <Button
      color={props.isActive ? 'primary' : 'default'}
      disabled={props.isLoading}
      isLoading={props.isLoading}
      onClick={props.onClick}
      className={
        'text-default rounded-md border-2 px-2 py-1 text-center text-xs transition-colors duration-100'
      }
      dataNoDnd
    >
      {props.label}
      {props.children}
    </Button>
  );
};
