import Button from '~/components/ui/control/button/button';
import { useTranslation } from 'react-i18next';

type InterestFormFooterProps = {
  onSaveAndExitClick?: () => void;
};

function InterestFormFooter(props: InterestFormFooterProps) {
  const { onSaveAndExitClick } = props;
  const { t } = useTranslation('request-form');

  return (
    <div className="animation-delay-800 animate-slide-top flex h-8 w-full items-center justify-end opacity-0">
      <Button color="primary" className="playlist-form__submit-button" onClick={onSaveAndExitClick}>
        {t('footer.button.send')}
      </Button>
    </div>
  );
}

export default InterestFormFooter;
