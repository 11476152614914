import classNames from 'classnames';
import { CSSProperties, forwardRef, MouseEvent, Ref } from 'react';
import { Icon } from '@iconify/react';

type ChipProps = {
  children: React.ReactNode;
  className?: string;
  color?: string;
  iconLeft?: string;
  iconRight?: string;
  suffix?: string;
  outline?: boolean;
  style?: CSSProperties;
  onClick?: (e: MouseEvent) => void;
};

const Chip = forwardRef((props: ChipProps, ref: Ref<HTMLButtonElement>) => {
  const {
    children,
    className = '',
    color,
    style = {},
    suffix,
    outline,
    iconLeft,
    iconRight,
    onClick,
  } = props;

  const handleClick = (e: MouseEvent) => {
    e.stopPropagation();
    onClick?.(e);
  };

  return (
    <button
      ref={ref}
      type="button"
      className={classNames(
        'line-clamp-1 inline-flex h-6 shrink-0 items-center gap-[4px] rounded-lg px-2 text-xs leading-none font-semibold outline-hidden',
        {
          'pr-1!': suffix || iconRight,
          'pl-1': iconLeft,
          'border-muted border bg-transparent!': outline,
          'cursor-pointer': onClick,
        },
        className,
      )}
      style={{ ...(color && { backgroundColor: color }), ...style }}
      onClick={handleClick}
      data-no-dnd="true"
    >
      {iconLeft && <Icon icon={iconLeft} />}
      {children}
      {suffix && (
        <span
          className="bg-neutral-muted inline-flex h-4 items-center rounded-full px-1 text-xs leading-none"
          style={{ color }}
        >
          {suffix}
        </span>
      )}
      {iconRight && <Icon icon={iconRight} />}
    </button>
  );
});

export default Chip;
