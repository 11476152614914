import { components, SingleValueProps, GroupBase } from 'react-select';
import { OptionType } from '../select';
import classNames from 'classnames';

const SingleValue = (props: SingleValueProps<OptionType, boolean, GroupBase<OptionType>>) => {
  const { children, isDisabled } = props;
  const classes = classNames('m-0! capitalize text-control-rest!', {
    'text-muted!': isDisabled,
  });
  return (
    <components.SingleValue className={classes} {...props}>
      {children}
    </components.SingleValue>
  );
};

export default SingleValue;
