import classNames from 'classnames';
import * as Tabs from '@radix-ui/react-tabs';

type TabTriggerProps = {
  label: string;
  value: string;
};

function TabTrigger(props: TabTriggerProps) {
  const { label, value } = props;
  return (
    <Tabs.Trigger
      value={value}
      className={classNames(
        `filter-panel__tab-trigger--${value}`,
        'text-default w-full rounded-sm px-2 text-left transition-colors duration-200 ease-in-out',
        'hover:bg-neutral-muted data-[state=active]:bg-neutral-muted bg-transparent',
      )}
    >
      <span className="text-xs">{label}</span>
    </Tabs.Trigger>
  );
}

export default TabTrigger;
