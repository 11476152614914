import classNames from 'classnames';
import * as Track from '~/components/entities/track/common/track';
import { SummaryArtist } from './artists';
import { SummaryControl } from './control';

type TrackItemHeaderSummaryProps = {
  className?: string;
};

export function TrackItemHeaderSummary(props: TrackItemHeaderSummaryProps) {
  const { className } = props;

  return (
    <Track.Summary.Root
      className={classNames('flex h-10 w-full items-center gap-3 overflow-hidden', className)}
    >
      <div className="group/track-item-cover relative aspect-square size-10">
        <Track.Summary.Cover />
        <SummaryControl />
      </div>
      <div className="flex flex-1 flex-col justify-center overflow-hidden">
        <div className="flex items-center gap-2">
          <Track.Summary.StatusChip />
          <Track.Summary.Title className="w-fit max-w-full" />
        </div>
        <SummaryArtist />
      </div>
    </Track.Summary.Root>
  );
}
