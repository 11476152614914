import Button from '~/components/ui/control/button/button';
import { useTranslation } from 'react-i18next';

type BriefFormFooterProps = {
  onSaveAndExitClick?: () => void;
};

function PlaylistFormFooter(props: BriefFormFooterProps) {
  const { t } = useTranslation('playlist-form');
  const { onSaveAndExitClick } = props;
  return (
    <div className="animation-delay-800 animate-slide-top flex h-8 w-full items-center justify-end opacity-0">
      <Button color="primary" className="playlist-form__submit-button" onClick={onSaveAndExitClick}>
        {t('footer.exit.button.label')}
      </Button>
    </div>
  );
}

export default PlaylistFormFooter;
