import * as SliderPrimitive from '@radix-ui/react-slider';
import classNames from 'classnames';

type SliderProps = {
  className?: string;
  min: number;
  max: number;
  value: number[];
  onValueChange?: (v: number[]) => void;
  onValueCommit?: (v: number[]) => void;
};

const Slider = (props: SliderProps) => {
  const { value, min, max, className, onValueChange, onValueCommit } = props;

  return (
    <SliderPrimitive.Root
      className={classNames(
        'SliderRoot group/slider relative flex w-full touch-none items-center select-none',
        className,
      )}
      value={value}
      max={max}
      min={min}
      step={1}
      aria-label="Volume"
      onValueCommit={onValueCommit}
      onValueChange={onValueChange}
    >
      <SliderPrimitive.Track className="relative h-1 grow rounded-full bg-[#3c3c3c]">
        <SliderPrimitive.Range className="SliderRange group-hover/slider:bg-accent-emphasis bg-inverse absolute h-full rounded-full" />
      </SliderPrimitive.Track>
      <SliderPrimitive.Thumb className="SliderThumb bg-inverse hidden h-4 w-4 cursor-pointer rounded-md shadow-sm group-hover/slider:block" />
    </SliderPrimitive.Root>
  );
};

export default Slider;
