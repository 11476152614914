import { useTranslation } from 'react-i18next';

export function TracksUploadItemListHeader() {
  const { t } = useTranslation('tracks-upload-form');

  return (
    <div className="grid w-full grid-cols-2 gap-4">
      <div className="mb-4">
        <span className="text-sm font-bold">{t('item-list.header.left.title')}</span>
        <p className="text-muted text-xs">{t('item-list.header.left.subtitle')}</p>
      </div>
      <div className="mb-4">
        <span className="text-sm font-bold">{t('item-list.header.right.title')}</span>
        <p className="text-muted text-xs">{t('item-list.header.right.subtitle')}</p>
      </div>
    </div>
  );
}
