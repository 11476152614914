import Button from '~/components/ui/control/button/button';
import useInterestFormStore from '~/stores/forms/interest-form-store';
import { useTranslation } from 'react-i18next';

type InterestFormHeaderProps = {
  onDiscardAndExitClick?: () => void;
  onClearFieldsClick?: () => void;
};

function InterestFormHeader(props: InterestFormHeaderProps) {
  const { t } = useTranslation('request-form');
  const { onDiscardAndExitClick, onClearFieldsClick } = props;
  const interestForm = useInterestFormStore(['trackTitle', 'interest']);

  return (
    <div className="flex w-full items-center justify-between">
      <div className="flex flex-col gap-1">
        <h1 className="text-2xl font-bold">{t('header.title')}</h1>
        <p className="text-muted text-xs">{interestForm.trackTitle}</p>
      </div>
      <div className="flex items-center gap-3">
        <Button color="default" onClick={onClearFieldsClick}>
          {t('header.button.clear')}
        </Button>
        <Button color="default" onClick={onDiscardAndExitClick}>
          {t('header.button.exit')}
        </Button>
      </div>
    </div>
  );
}

export default InterestFormHeader;
