import classNames from 'classnames';
import { PropsWithChildren } from 'react';

type TrackActionListProps = PropsWithChildren<{
  className?: string;
}>;

export function TrackActionList(props: TrackActionListProps) {
  const { children, className } = props;

  return <div className={classNames('flex justify-end gap-2', className)}>{children}</div>;
}
