import { notificationStore } from '~/stores/notifications-store';
import { useComputed } from '@preact/signals-react';
import Button from '~/components/ui/control/button/button';

import * as DropdownMenu from '~/components/ui/control/menus/dropdown-menu/common/dropdown-menu';
import { NotficationsDropdownMenu } from '~/components/ui/control/menus/dropdown-menu/notifications/notifications';
import { useTranslation } from 'react-i18next';

function NotificationPanel() {
  const unreadNotficiationCount = useComputed(
    () => notificationStore.channel.value?.unreadMessageCount.value || 0,
  ).value;
  const { t } = useTranslation('notification-center');

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <Button color="default" iconLeft="inbox-2-line" className="relative">
          {unreadNotficiationCount
            ? t('pop-in.header.count', {
                'count-unread': unreadNotficiationCount,
              })
            : t('pop-in.header.title')}
          {unreadNotficiationCount > 0 ? (
            <div className="bg-accent-emphasis absolute top-1.5 left-2 size-2 rounded-full" />
          ) : null}
        </Button>
      </DropdownMenu.Trigger>
      <NotficationsDropdownMenu />
    </DropdownMenu.Root>
  );
}

export default NotificationPanel;
