/* eslint-disable quote-props */
import { useTranslation } from 'react-i18next';
import { MagicPreviewStatus } from './use-magic-preview';

export function useMagicPreviewTooltip(state: MagicPreviewStatus) {
  const { t } = useTranslation('track-interactions');

  const tooltips: Record<MagicPreviewStatus, string> = {
    ready: t('magic-preview.tooltip.ready'),
    'track-ready-to-variation': t('magic-preview.tooltip.ready'),
    'track-not-ready': t('magic-preview.tooltip.not-ready'),
    'video-missing': t('magic-preview.tooltip.ready.empty'),
    'track-too-short': t('magic-preview.tooltip.track-shorter'),
    pending: t('magic-preview.tooltip.pending'),
    error: t('magic-preview.tooltip.error'),
  };

  return {
    tooltip: tooltips[state],
  };
}
