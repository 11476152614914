import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { toastr } from '~/components/ui/notifications/toast/utils/toast-call';
import { briefUpdater } from '~/components/features/forms/brief/utils/brief-update-parser';
import { briefRoutes, tracklistRoutes } from '~/routes/api';
import {
  Brief,
  BriefAPIUpdate,
  BriefSchema,
  BriefSearchResultSchema,
  BriefTracksSchema,
  TranslatedBrief,
  TranslatedBriefSchema,
} from '~/types/schemas/music/brief.schema';
import { Track, TrackSimplifiedSchema } from '~/types/schemas/music/track.schema';
import { Tracklist } from '~/types/schemas/music/tracklist.schema';
import { api } from '~/utils/api';
import { pathToUrl } from '~/utils/path-to-url';
import { useDelete } from '~/hooks/utils/react-query/use-delete';
import { useDeleteMultiple } from '~/hooks/utils/react-query/use-delete-multiple';
import { useFetch } from '~/hooks/utils/react-query/use-fetch';
import { useGenericMutation } from '~/hooks/utils/react-query/use-generic-mutation';
import { useLoadMore } from '~/hooks/utils/react-query/use-load-more';
import { usePost } from '~/hooks/utils/react-query/use-post';
import { useUpdate } from '~/hooks/utils/react-query/use-update';
import { Language } from '~/types/types/languages';
import { useQueryClient } from '@tanstack/react-query';
import { useUpdateHelper } from '../utils/react-query/use-update-helper';

export const useGetBriefDetails = (briefId?: string) => {
  const fetchedData = useFetch<Brief>({
    url: pathToUrl(briefRoutes.brief, { id: briefId }),
    schema: BriefSchema,
    staleTime: 0,
  });

  return {
    ...fetchedData,
    data: fetchedData.data || ({} as Brief),
  };
};

export const useGetTranslateBriefDetails = (briefId?: string, language?: Language) => {
  const fetchedData = useFetch<TranslatedBrief>({
    url: language ? pathToUrl(briefRoutes.translateBrief, { id: briefId, language }) : undefined,
    schema: TranslatedBriefSchema,
    staleTime: 0,
    enabled: !!briefId && !!language,
  });

  return {
    ...fetchedData,
    data: fetchedData.data || ({} as TranslatedBrief),
  };
};

export const useGetBriefsList = () => {
  return useLoadMore<Brief>({
    url: briefRoutes.search,
    schema: BriefSearchResultSchema,
    queryParams: {
      query: '',
      size: 50,
      sort: {
        key: 'created_at',
        direction: -1,
      },
    },
  });
};

export const useGetBriefTracks = (id: string, maxItems: number = 20) => {
  return useLoadMore<Track>({
    url: pathToUrl(briefRoutes.tracks, { id }),
    schema: BriefTracksSchema,
    queryParams: {
      size: maxItems,
    },
  });
};

export const useCreateBrief = () => {
  const { t } = useTranslation('toast');
  const { addInfiniteDataUpdater } = useUpdateHelper();
  return usePost<{ title: string }, BriefAPIUpdate>({
    url: briefRoutes.briefs,
    mutateOptions: {
      onSuccess: async (newData) => {
        addInfiniteDataUpdater<Brief>(
          [briefRoutes.search],
          briefUpdater({} as Brief, newData) || ({} as Brief),
        );
        addInfiniteDataUpdater<Tracklist>([tracklistRoutes.search], {
          type: 'brief',
          value: briefUpdater({} as Brief, newData) || ({} as Brief),
          has_track: false,
        });
      },
      onError: () => {
        toastr('error', t('brief.creation-error.title'), t('brief.creation-error.content'));
      },
    },
  });
};

export const useUpdateBrief = (briefId?: string) => {
  const { t } = useTranslation('toast');
  const { updateData, updateInfiniteDataUpdater } = useUpdateHelper();
  return useUpdate<BriefAPIUpdate, BriefAPIUpdate>({
    url: pathToUrl(briefRoutes.briefMeta, { id: briefId || 'no-id' }),
    updater: (updatedBrief) => {
      if (!briefId) return;

      updateData<Brief>([pathToUrl(briefRoutes.brief, { id: briefId })], (oldBrief) =>
        briefUpdater(oldBrief!, updatedBrief),
      );
      updateInfiniteDataUpdater<Tracklist>(
        [tracklistRoutes.search],
        (oldTracklist) => ({
          type: 'brief',
          value: briefUpdater(oldTracklist.value as Brief, updatedBrief),
          has_track: false,
        }),
        (item) => item.value.id === briefId,
      );
    },
    mutateOptions: {
      onError: () => {
        toastr('error', t('brief.update-error.title'), t('brief.update-error.content'));
      },
    },
  });
};

export const useUpdateNewBriefRound = () => {
  const { updateData, updateInfiniteDataUpdater } = useUpdateHelper();
  return useGenericMutation<Brief, Brief>({
    url: briefRoutes.briefs,
    func: async (data) => {
      const { id, ...brief } = data || ({} as Brief);
      const briefUpdateData: BriefAPIUpdate = {
        agents: brief.agents,
        budget: brief.budget,
        descriptions: brief.descriptions,
        confidentiality: brief.confidentiality,
        currency: brief.currency,
        deadline: brief.deadline,
        music_type: brief.music_type,
        scope: brief.scope,
        title: brief.title,
        video_link: brief.video_link,
        public: false,
      };
      const updatedBriefData = await api
        .put(pathToUrl(briefRoutes.briefMeta, { id }), { json: briefUpdateData })
        .json<BriefAPIUpdate>();
      return BriefSchema.parse(updatedBriefData);
    },
    updater: (newData) => {
      updateData<Brief>([pathToUrl(briefRoutes.brief, { id: newData.id })], () => newData);
      updateInfiniteDataUpdater<Tracklist>(
        [tracklistRoutes.search],
        () => ({
          type: 'brief',
          value: newData,
          has_track: false,
        }),
        (item) => item.value.id === newData.id,
      );
    },
  });
};

export const useDeleteBriefs = () => {
  const { t } = useTranslation('toast');
  const { multipleDeleteUpdater } = useUpdateHelper();
  const queryClient = useQueryClient();

  return useDeleteMultiple({
    url: briefRoutes.briefs,
    updater: (data) => {
      multipleDeleteUpdater<Brief>([briefRoutes.search], (item) =>
        item.id ? !data.ids.includes(item.id) : true,
      );
      multipleDeleteUpdater<Tracklist>([tracklistRoutes.search], (item) =>
        item.value.id ? !data.ids.includes(item.value.id) : true,
      );
    },
    mutateOptions: {
      onSuccess: async () => {
        toastr('success', t('brief.success-delete.title'), t('brief.success-delete.content'));
      },
      onError: () => {
        queryClient.invalidateQueries({ queryKey: [tracklistRoutes.search] });
        queryClient.invalidateQueries({ queryKey: [briefRoutes.search] });
        toastr('error', t('brief.error-delete.title'), t('brief.error-delete.content'));
      },
    },
  });
};

export const useBriefSearch = (query: string) =>
  useLoadMore<Brief>({
    url: briefRoutes.search,
    schema: BriefSearchResultSchema,
    queryParams: {
      query,
      size: 50,
    },
  });

export const useAddTrackToBrief = (briefId: string, trackIds: string[]) => {
  const { t } = useTranslation('toast');
  const { updateInfiniteDataUpdater } = useUpdateHelper();
  return usePost<Track, unknown>({
    url: pathToUrl(briefRoutes.tracks, { id: briefId }),
    queryParams: {
      json: {
        ids: trackIds,
      },
    },
    schema: z.array(TrackSimplifiedSchema),
    updater: (newData) => {
      updateInfiniteDataUpdater<Track>(
        [briefRoutes.tracks, { id: briefId }],
        () => newData,
        (item) => !trackIds.includes(item.id),
      );
    },
    mutateOptions: {
      onSuccess: () => {
        toastr(
          'success',
          t('tracklist.success-addition.title'),
          t('tracklist.success-addition.content', { 'tracklist-type': 'brief' }),
        );
      },
      onError: (error: Error) => {
        if (error.message.includes('403')) {
          toastr('error', t('tracklist.error-sharing.title'), t('tracklist.error-sharing.content'));
        } else {
          toastr(
            'error',
            t('tracklist.error-addition.title'),
            t('tracklist.error-addition.content', { 'tracklist-type': 'brief' }),
          );
        }
      },
    },
  });
};

export const useRemoveTracksFromBrief = (briefId: string) => {
  const { t } = useTranslation('toast');
  const { multipleDeleteUpdater } = useUpdateHelper();
  return useDelete<{ ids: string[] }, unknown>({
    url: pathToUrl(briefRoutes.tracksMeta, { id: briefId }),
    updater: (data) => {
      multipleDeleteUpdater<Brief>(
        [pathToUrl(briefRoutes.tracks, { id: briefId })],
        (item) => !data.ids.includes(item.id!),
      );
    },
    mutateOptions: {
      onSuccess: () => {
        toastr(
          'success',
          t('brief.success-track-remove.title'),
          t('brief.success-track-remove.content'),
        );
      },
      onError: () => {
        toastr('error', t('brief.error-track-remove.title'), t('brief.error-track-remove.content'));
      },
    },
  });
};
