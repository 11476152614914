import { useTranslation } from 'react-i18next';

type SubTitleProps = {
  type: 'playlist' | 'brief';
  company?: string;
};

export function SubTitle(props: SubTitleProps) {
  const { type, company } = props;
  const { t } = useTranslation('common');

  return (
    <span className="text-muted line-clamp-1 text-xs leading-none">{`${t(type)} - ${company}`}</span>
  );
}
