import usePlayerStore from '~/stores/player-store';
import classNames from 'classnames';
import usePreSearchStore from '~/stores/presearch-store';
import * as Track from '~/components/entities/track/common/track';
import { PlayerControls } from './components/controls/player-controls';
import { PlayerSummary } from './components/summary/summary';
import { Actions } from './components/actions/actions';
import { MagicPreviewRealtimeListener } from '~/providers/magic-preview-provider/magic-preview.provider';

export type PlayerProps = {
  className?: string;
};

export const Player = (props: PlayerProps) => {
  const { className } = props;
  const presearch = usePreSearchStore(['isOpen']);
  const player = usePlayerStore(['currentTrack']);
  const track = player.currentTrack;

  return track ? (
    <MagicPreviewRealtimeListener>
      <Track.Root track={track} context={{ type: 'player' }}>
        <section
          className={classNames(
            'player bg-default @container flex h-20 w-full items-center gap-4 px-7 text-xl shadow-lg',
            presearch.isOpen && 'z-70',
            className,
          )}
        >
          <PlayerSummary className="w-[25%]!" />
          <PlayerControls className="w-[50%]!" />
          <Actions className="w-[25%]!" />
        </section>
      </Track.Root>
    </MagicPreviewRealtimeListener>
  ) : null;
};
