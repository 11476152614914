import equal from 'fast-deep-equal';
import { create } from 'zustand/react';
import pick from 'lodash/pick';

import { useStore, StoreApi } from 'zustand';
import { shallow } from 'zustand/shallow';

// From zustand/react.d.ts
type ExtractState<S> = S extends {
  getState: () => infer T;
}
  ? T
  : never;

interface KeyPicker<State> {
  (): State;
  <K extends keyof State>(keys: K[]): Pick<State, K>;
  <T>(selector: (state: State) => T): T;
}

type selector<State> = (keyof State)[] | (<T>(state: State) => T);

export const createStorePicker =
  <Store extends ReturnType<typeof create>, State extends ExtractState<Store>>(
    store: Store,
  ): KeyPicker<State> =>
  (data?: selector<State>) => {
    if (!data) {
      return store();
    }

    if (typeof data === 'function') {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return store(data as any, equal) as any;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return store((state: any) => pick(state, data), equal);
  };

// Selector for store create with createStore
export type WithSelectors<S> = S extends { getState: () => infer T }
  ? S & { use: { [K in keyof T]: () => T[K] } }
  : never;

export const createSelectors = <S extends StoreApi<object>>(_store: S) => {
  const store = _store as WithSelectors<typeof _store>;
  store.use = {};

  for (const k of Object.keys(store.getState())) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (store.use as any)[k] = (equalityFn = shallow) =>
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useStore(_store, (s) => s[k as keyof typeof s], equalityFn);
  }

  return store;
};
