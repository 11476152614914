import { useTracklistSearch } from '~/hooks/api/track-list';
import { useTrackContext } from '~/components/entities/track/common/track.context';
import { TracklistGroup } from './tracklist-group';

type SearchResultProps = {
  query: string;
  collection: 'playlists' | 'briefs';
};

export function SearchResult(props: SearchResultProps) {
  const { query, collection } = props;
  const trackStore = useTrackContext();
  const track = trackStore.use.track();
  const { data: tracklistData } = useTracklistSearch([collection], query, track.id);

  const tracklists = tracklistData?.pages.flatMap((page) => page.hits);

  return (
    <div className="custom-scrollbar h-full max-h-64 w-full overflow-y-auto">
      <TracklistGroup tracklists={tracklists} />
    </div>
  );
}
