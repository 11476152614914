import { Icon } from '@iconify/react';

type SearchProps = {
  query: string;
  setQuery: (query: string) => void;
  placeholder?: string;
};

export function SearchInput(props: SearchProps) {
  const { query, setQuery, placeholder } = props;

  return (
    <div className="bg-control-neutral mb-1 flex h-8 w-full items-center gap-3 rounded-sm px-2">
      <Icon icon="ri:search-line" className="text-muted h-4 w-4" />
      <input
        type="text"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        placeholder={placeholder}
        className="placeholder:text-muted bg-transparent text-sm outline-hidden"
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
          if (
            e.key !== 'arrowDown' &&
            e.key !== 'arrowUp' &&
            e.key !== 'Enter' &&
            e.key !== 'Escape'
          ) {
            e.stopPropagation();
          }
        }}
      />
    </div>
  );
}
