import {
  KeyboardEvent,
  MouseEvent,
  PropsWithChildren,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Track } from '~/types/schemas/music/track.schema';
import { SelectionContext } from './selection.context';

type SelectionProps = PropsWithChildren<{
  data: Track[];
  onDefaultClick?: (e: MouseEvent | KeyboardEvent, value: Track) => void;
}>;

export function SelectionProvider(props: SelectionProps) {
  const { data, onDefaultClick, children } = props;
  const ref = useRef(null);
  const [multipleSelection, setMultipleSelection] = useState<Track[]>([]);
  const [singleSelections, setSingleSelections] = useState<Track[]>([]);
  const values = useMemo<Track[]>(
    () => [...new Set([...multipleSelection, ...singleSelections])],
    [multipleSelection, singleSelections],
  );

  function handleSingleSelection(value: Track) {
    if (values.some((v) => v.id === value.id)) {
      const newValues = values.filter((v) => v.id !== value.id);
      const newMultipleSelection = multipleSelection.filter((v) => v.id !== value.id);
      setMultipleSelection(newMultipleSelection);
      setSingleSelections(newValues);
    } else {
      const newValues = [...values, value];
      setSingleSelections(newValues);
    }
  }

  function handleMultipleSelection(value: Track) {
    const valueIndex = data.findIndex((v) => v.id === value.id);

    if (valueIndex < 0) {
      throw new Error(`Value ${value} not found in data ${data}`);
    }

    const lastSelected = singleSelections.at(-1);
    const lastSelectedValueIndex = lastSelected
      ? data.findIndex((v) => v.id === lastSelected.id)
      : -1;

    const start = Math.min(valueIndex, lastSelectedValueIndex);
    const end = Math.max(valueIndex, lastSelectedValueIndex);

    if (start > -1 && end > -1) {
      let newMultipleSelection: Track[] = [];
      newMultipleSelection = data.slice(start, end + 1);

      setSingleSelections(
        singleSelections.filter((v) => {
          const vIndex = data.indexOf(v);
          return vIndex <= start || vIndex >= end;
        }),
      );
      setMultipleSelection(newMultipleSelection);
    } else {
      handleSingleSelection(value);
    }
  }

  const handleItemClick = useCallback(
    (e: MouseEvent | KeyboardEvent, value: Track) => {
      if (e.ctrlKey || e.metaKey) {
        handleSingleSelection(value);
      } else if (e.shiftKey) {
        handleMultipleSelection(value);
      } else {
        setSingleSelections([]);
        setMultipleSelection([]);
        onDefaultClick?.(e, value);
      }
    },
    [values],
  );

  const contextValue = useMemo(() => ({ values, handleItemClick }), [values]);

  return (
    <div ref={ref}>
      <SelectionContext.Provider value={contextValue}>{children}</SelectionContext.Provider>
    </div>
  );
}

export default SelectionProvider;
