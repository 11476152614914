import { Range } from '@tanstack/react-virtual';
import { useMemo } from 'react';
import { InfiniteScroll } from '~/components/layout/infinite-scroll/infinite-scroll';
import { useTracklistSearch } from '~/hooks/api/track-list';
import { TracklistSentinel } from './tracklist/tracklist-sentinel';
import { Tracklist } from './tracklist/tracklist';
import { Tracklist as TracklistType } from '~/types/schemas/music/tracklist.schema';
import { useDragToTracklist } from '~/hooks/interactions/use-drag-to-tracklist';

type ResultsProps = {
  query: string;
  collections: ('playlists' | 'briefs')[];
};

export function Results(props: ResultsProps) {
  const { query, collections } = props;
  const {
    data: tracklists,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useTracklistSearch(collections, query);

  const flatTracklists = useMemo(
    () => tracklists?.pages.flatMap((page) => page.hits),
    [tracklists],
  );

  function handleNextPageFetch({ endIndex }: Range) {
    if (endIndex >= (flatTracklists?.length || 0) - 1 && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }

  useDragToTracklist();

  return (
    <nav className="flex h-full flex-col gap-2 pl-2">
      <InfiniteScroll<TracklistType>
        data={flatTracklists}
        count={flatTracklists?.length}
        estimateSize={21}
        overscan={15}
        onRangeChange={handleNextPageFetch}
        renderSentinel={TracklistSentinel}
        renderItem={(tracklist) => {
          return tracklist ? (
            <div className="w-full px-1">
              <Tracklist id={tracklist?.value.id} data={tracklist} />
            </div>
          ) : null;
        }}
        scrollParent={document.getElementById('tracklist-search')}
      />
    </nav>
  );
}
