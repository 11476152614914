import classNames from 'classnames';
import { MouseEvent } from 'react';
import CloseIcon from 'virtual:icons/ri/close-line';

type FilterSectionProps = {
  label: string;
  className?: string;
  isActive?: boolean;
  children: React.ReactNode;
  onDelete?: (e: MouseEvent) => void;
};

function FilterSection(props: FilterSectionProps) {
  const { label, className, isActive = false, children, onDelete } = props;

  return (
    <div
      className={classNames(
        'bg-neutral-emphasis relative flex flex-col gap-1.5 rounded-lg p-2 pt-1',
        isActive ? 'border-neutral-emphasis border' : 'border-default border',
        className,
      )}
    >
      <div className="flex items-center justify-between gap-3">
        <h5 className="text-muted text-xs">{label}</h5>
        {onDelete ? (
          <button
            type="button"
            onClick={onDelete}
            className="text-muted hover:text-default! rounded-sm p-0.25 text-[0.8rem]"
          >
            <CloseIcon />
          </button>
        ) : null}
      </div>
      <div className="flex items-center gap-1.5">{children}</div>
    </div>
  );
}

export default FilterSection;
