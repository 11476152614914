import { tagsRoutes } from '~/routes/api';
import useDataStore from '~/stores/mewo-data-store';
import { useSearchStore } from '~/stores/search-store';
import { TagsResponse } from '~/types/schemas/data/mewo-data.schema';
import {
  Tag,
  TAG_TYPE,
  TagCategory,
  TagSearchSchema,
  TagSubCategory,
} from '~/types/schemas/data/tags.schema';
import { useAlternativeFetch } from '~/hooks/utils/react-query/use-alternative-fetch';
import { useFetch } from '~/hooks/utils/react-query/use-fetch';
import { filterAndMarkRelevantPopulatedTagByTagIds } from '~/utils/tags/filter-populated-tag-by-tag-ids';

export type TagDataType = {
  tags?: Tag[];
  categories?: TagCategory[];
  subCategories?: TagSubCategory[];
};

export function useGetTags() {
  const { setData } = useDataStore(['setData']);

  return useFetch({
    url: tagsRoutes.list,
    schema: TagsResponse,
    onSuccess(data) {
      setData(data);
    },
  });
}

export function useTagSearch(query: string) {
  const {
    tags: tagsData,
    categories: categoriesData,
    subCategories: subCategoriesData,
  } = useDataStore(['tags', 'categories', 'subCategories']);

  const { data, ...rest } = useAlternativeFetch({
    url: tagsRoutes.search,
    schema: TagSearchSchema,
    queryParams: {
      json: { query },
    },
    staleTime: 0,
  });

  if (query) {
    const categories: TagCategory[] = [];
    const subCategories: TagSubCategory[] = [];
    const tags: Tag[] = [];

    data?.tags.forEach((tag) => tags.push(tagsData.object[tag]!));
    data?.categories.forEach((category) => {
      const potentialSubCategory = subCategoriesData.object[category];
      const potentialCategory = categoriesData.object[category];

      if (potentialSubCategory) {
        subCategories.push(potentialSubCategory);
      }

      if (potentialCategory) {
        categories.push(potentialCategory);
      }
    });

    return {
      ...rest,
      data: {
        tags,
        categories,
        subCategories,
      },
    };
  }

  return {
    ...rest,
    data: {
      tags: tagsData.array,
      categories: categoriesData.array,
      subCategories: subCategoriesData.array,
    },
  };
}

export function usePopulatedTagsSearch(query: string, type?: keyof typeof TAG_TYPE) {
  const { populatedCategories } = useDataStore(['populatedCategories']);
  const { facets } = useSearchStore(['facets']);
  const selectedType = TAG_TYPE[type ?? 'TAG'];

  const { data, ...rest } = useAlternativeFetch({
    url: tagsRoutes.search,
    schema: TagSearchSchema,
    queryParams: {
      json: { query },
    },
    staleTime: 0,
  });

  const tagCategories = type
    ? populatedCategories.array?.filter((category) => category.type === selectedType)
    : populatedCategories.array;
  const result = filterAndMarkRelevantPopulatedTagByTagIds({
    tagIds: data?.tags,
    categoriesIds: data?.categories,
    relevantTags: Object.keys(facets?.tags ?? {}),
    populatedTagCategories: tagCategories,
  });

  return {
    ...rest,
    data: {
      rawData: data,
      categories: result,
    },
  };
}
