import { HeaderContainer } from '../common/header-container';

type HeaderProps = {
  index: number;
  fileName: string;
  onHeaderClick?: () => void;
};

export function Header(props: HeaderProps) {
  const { index, fileName, onHeaderClick } = props;

  return (
    <HeaderContainer onClick={onHeaderClick}>
      <div className="flex size-full items-center gap-2 px-3">
        <div className="flex aspect-square size-14 items-center justify-center">
          <div className="bg-neutral-muted flex size-10 items-center justify-center rounded-md">
            {index}
          </div>
        </div>
        <span className="lineclamp-2 text-sm">{fileName}</span>
      </div>
    </HeaderContainer>
  );
}
