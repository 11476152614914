import { Icon } from '@iconify/react';
import classNames from 'classnames';
import { MouseEvent } from 'react';
import { Link } from 'react-router-dom';
import * as ContextMenu from '~/components/ui/control/menus/context-menu/common/context-menu';
import { useAddToTracklist } from '~/hooks/entities/track/actions/use-add-track-to-tracklist';
import { globalStore } from '~/stores/global-store-v2';
import { Tracklist as TracklistType } from '~/types/schemas/music/tracklist.schema';
import { useAddToTracklistContext } from '../../../dropdown-menu/track-actions/add-to-tracklist/add-to-tracklist.context';

type TracklistProps = {
  tracklist: TracklistType;
  link: string;
};

export function Tracklist(props: TracklistProps) {
  const { tracklist, link } = props;
  const { selectedTracks } = useAddToTracklistContext();
  const { addTrackToTracklist, removeTrackFromTracklist } = useAddToTracklist();
  const isMultiSelection = selectedTracks ? selectedTracks.length > 1 : false;
  const user = globalStore.user.value;

  const isTracklistOwnedByUser = tracklist.value.tenant.id === user?.id;

  const icon = () => {
    switch (tracklist.type) {
      case 'brief':
        return 'ri:megaphone-line';
      case 'playlist':
        return isTracklistOwnedByUser ? 'ri:headphone-line' : 'ri:group-line';
      default:
        return undefined;
    }
  };

  function handleAddTrackToTracklist(e: MouseEvent) {
    e.stopPropagation();
    if (tracklist.has_track && !isMultiSelection) {
      removeTrackFromTracklist(tracklist);
    } else {
      addTrackToTracklist(tracklist);
    }
  }

  return (
    <div className="flex">
      <ContextMenu.Item
        icon={icon()}
        onClick={handleAddTrackToTracklist}
        className={classNames('flex-1', {
          'bg-control-neutral': tracklist.has_track && !isMultiSelection,
        })}
      >
        {tracklist.value.title}
      </ContextMenu.Item>
      <Link
        to={link}
        className="hover:bg-neutral-muted mx-1 flex h-8 w-8 items-center justify-center rounded-sm"
      >
        <Icon icon="ri:link" className="h-4 w-4" />
      </Link>
    </div>
  );
}
