import { z } from 'zod';

const TrackDataSchema = z.object({
  title: z.string(),
  artists: z.string().array(),
  album: z.string(),
  release_date: z.string(),
  cover: z.string().optional(),
  musicType: z.string().optional(),
});

const MatchingTypes = {
  SPOTIFY: 'spotify',
  IDENTIFIED: 'identified',
  MANUAL: 'manual',
} as const;

const MatchingTypeSchema = z.nativeEnum(MatchingTypes);

const MatchingStatus = {
  PENDING: 'pending',
  QUEUED: 'queued',
  DONE: 'done',
  ERROR: 'error',
} as const;

const ResultMatchMetadataSchema = z
  .discriminatedUnion('type', [
    z
      .object({
        type: z.literal(MatchingTypes.SPOTIFY),
        cover: z.string(),
      })
      .merge(TrackDataSchema),
    z
      .object({
        type: z.literal(MatchingTypes.IDENTIFIED),
        cover: z.string().optional(),
      })
      .merge(TrackDataSchema),
    z
      .object({
        type: z.literal(MatchingTypes.MANUAL),
      })
      .merge(TrackDataSchema),
  ])
  .nullable();

const MatchMetadataSchema = z.object({
  match: z
    .object({
      status: z.nativeEnum(MatchingStatus),
      result: ResultMatchMetadataSchema,
    })
    .optional(),
});

const MatchResultSchema = z.object({
  id: z.string(),
  session: z.string(),
  filename: z.string(),
  size: z.number().optional(),
  createdAt: z.string().optional(),
  metadata: MatchMetadataSchema.optional(),
});

const MatchResultArraySchema = MatchResultSchema;
type MatchResult = z.infer<typeof MatchResultSchema>;
type MatchMetadata = z.infer<typeof MatchMetadataSchema>;
type ResultMatchMetadata = z.infer<typeof ResultMatchMetadataSchema>;
type TrackData = z.infer<typeof TrackDataSchema>;
type MatchingType = z.infer<typeof MatchingTypeSchema>;

export type { MatchResult, MatchMetadata, TrackData, MatchingType, ResultMatchMetadata };
export { MatchingTypes, MatchingStatus };
export {
  MatchResultSchema,
  MatchResultArraySchema,
  MatchMetadataSchema,
  TrackDataSchema,
  MatchingTypeSchema,
  ResultMatchMetadataSchema,
};
