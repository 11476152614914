import { ForwardedRef, forwardRef } from 'react';

export type PresearchBlockProps = {
  label: string;
  children: React.ReactNode;
  containerClassName?: string;
  childrenClassName?: string;
  childrenStyle?: React.CSSProperties;
};

const PresearchBlock = forwardRef(
  (props: PresearchBlockProps, ref: ForwardedRef<HTMLDivElement>) => {
    const { label, containerClassName, children, childrenClassName, childrenStyle } = props;

    return (
      <section ref={ref} className={`flex flex-col gap-3 ${containerClassName}`}>
        <h3 className="text font-bold">{label}</h3>
        <div className={childrenClassName} style={childrenStyle}>
          {children}
        </div>
      </section>
    );
  },
);

export default PresearchBlock;
