/* eslint-disable react-hooks/rules-of-hooks */
import { mewoDataStore } from '~/stores/mewo-data-store-v2';

export function findVersionByLabel(label: string) {
  const { versions } = mewoDataStore;

  return versions.array.value.find(
    (version) => version.name?.toLowerCase() === label.toLowerCase(),
  );
}
