import { ChangeEvent, FocusEventHandler, Ref, forwardRef, useState } from 'react';
import classNames from 'classnames';
import { Icon } from '@iconify/react';
import { InputProps } from '../input';

interface DateInputProps extends InputProps {
  containerClass?: string;
  wrapperClass?: string;
  inputClass?: string;
  labelClass?: string;
  isFocused: boolean;
  onFocus: FocusEventHandler<HTMLInputElement>;
  onBlur: FocusEventHandler<HTMLInputElement>;
}

function Password(props: DateInputProps, inputRef: Ref<HTMLInputElement>) {
  const {
    label,
    placeholder,
    className,
    containerClass = '',
    wrapperClass = '',
    inputClass = '',
    labelClass,
    error,
    required = false,
    onChange,
    onFocus,
    onBlur,
    type,
    ...inputProps
  } = props;
  const [showPassword, setShowPassword] = useState(!(type === 'password'));

  function handleClick() {
    setShowPassword((prev) => !prev);
  }

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    onChange?.(e.target.value);
  }

  return (
    <div className={className}>
      <div className={wrapperClass}>
        {label && (
          <span className={labelClass}>
            {label}
            {required && <span className="text-control-danger">*</span>}
          </span>
        )}
        <div className={containerClass}>
          <input
            ref={inputRef}
            aria-label={label}
            placeholder={placeholder || label}
            type={showPassword ? 'text' : 'password'}
            className={classNames(inputClass)}
            onChange={handleChange}
            onFocus={onFocus}
            onBlur={onBlur}
            {...inputProps}
          />
          <button
            type="button"
            className="flex size-6 shrink-0 items-center justify-center transition-all"
            onClick={handleClick}
          >
            <Icon
              icon={showPassword ? 'ri:eye-line' : 'ri:eye-off-line'}
              className="text-control-rest hover:text-control-hover text-xs"
            />
          </button>
        </div>
        {error ? (
          <span className="error text-control-danger mt-1 w-full text-xs">{error}</span>
        ) : null}
      </div>
    </div>
  );
}

export const PasswordInput = forwardRef(Password);
