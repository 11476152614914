import ReactSelect, {
  MultiValue as MultiValueType,
  SingleValue as SingleValueType,
} from 'react-select';
import { useState } from 'react';
import Control from './components/control';
import ValueContainer from './components/value-container';
import IndicatorsContainer from './components/indicators/indicators-container';
import ClearIndicator from './components/indicators/clear-indicator';
import DropdownIndicator from './components/indicators/dropdown-indicator';
import Input from './components/input';
import SingleValue from './components/single-value';
import MultiValue from './components/multi-value/multi-value';
import MultiValueRemove from './components/multi-value/multi-value-remove';
import Menu from './components/menu';
import OptionComponent from './components/option';
import Placeholder from './components/placeholder';

import classNames from 'classnames';
import MultiValueContainer from './components/multi-value/multi-value-container';

export type OptionType = {
  value?: string;
  label?: string | null;
  description?: string;
};
export interface SelectProps {
  id?: string;
  label?: string;
  placeholder?: string;
  options: OptionType[];
  value?: OptionType | OptionType[];
  isMulti?: boolean;
  defaultValue?: OptionType | OptionType[];
  dataTestId?: string;
  containerClassName?: string;
  isLoading?: boolean;
  required?: boolean;
  error?: string;
  disabled?: boolean;
  searchable?: boolean;
  onSingleValueChange?: (value: OptionType) => void;
  onMultiValueChange?: (value: OptionType[]) => void;
}

const Select = (props: SelectProps) => {
  const {
    onMultiValueChange,
    onSingleValueChange,
    containerClassName,
    value,
    isMulti,
    label,
    placeholder = label,
    required,
    isLoading,
    error,
    disabled,
    searchable,
  } = props;
  const [currentValue, setCurrentValue] = useState<OptionType | OptionType[]>(
    value as OptionType | OptionType[],
  );

  const handleChange = (newValue: MultiValueType<OptionType> | SingleValueType<OptionType>) => {
    if (Array.isArray(newValue)) {
      onMultiValueChange?.(newValue as OptionType[]);
    } else {
      onSingleValueChange?.(newValue as OptionType);
    }
    setCurrentValue(newValue as OptionType | OptionType[]);
  };

  const labelClass = classNames('text-control-neutral text-xs text-control-neutral', {
    'text-control-danger': error !== undefined,
  });

  const wrapperClass = classNames('flex flex-col gap-2 ', containerClassName);

  return (
    <div className={wrapperClass}>
      {label ? <label className={labelClass}>{label}</label> : null}
      <ReactSelect
        {...props}
        placeholder={placeholder}
        styles={{
          control(base) {
            return {
              ...base,
              outline: 'none',
            };
          },
          dropdownIndicator(base, state) {
            return {
              ...base,
              transition: 'transform 0.3s ease',
              transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)',
            };
          },
        }}
        components={{
          Control,
          ValueContainer,
          DropdownIndicator,
          ClearIndicator,
          IndicatorsContainer,
          SingleValue,
          MultiValueContainer,
          MultiValueRemove,
          MultiValue,
          Input,
          IndicatorSeparator: () => null,
          Menu,
          Option: OptionComponent,
          Placeholder,
        }}
        classNamePrefix="select"
        onChange={handleChange}
        value={currentValue}
        isMulti={isMulti}
        isSearchable={searchable}
        isDisabled={disabled}
        isLoading={isLoading}
        required={required}
      />
    </div>
  );
};

export default Select;
