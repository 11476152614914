import { Icon } from '@iconify/react';

function ChatLoader() {
  return (
    <div className="bg-default flex h-full flex-col gap-6 rounded-lg pr-2">
      <div className="flex flex-col gap-3 px-4 pt-6">
        <button type="button" className="group/back flex items-center gap-2 text-xs">
          <Icon icon="ri:arrow-left-s-line" className="text-default" />
          <span className="transition-transform group-hover/back:-translate-x-1">
            Back to threads
          </span>
        </button>
        <div className="flex w-full items-center gap-3 overflow-hidden">
          <div className="bg-neutral-emphasis h-10! w-10! shrink-0 animate-pulse rounded-sm" />
          <div className="flex w-full flex-col justify-between gap-2">
            <div className="bg-neutral-emphasis h-4 w-[25%] animate-pulse rounded-sm" />
            <div className="bg-neutral-emphasis h-3 w-[50%] animate-pulse rounded-sm" />
          </div>
        </div>
        <div className="bg-neutral-emphasis h-3 w-full animate-pulse rounded-sm" />
      </div>
      <div className="scrollbar-on-hover flex h-full items-center justify-center self-stretch overflow-y-auto px-4">
        <Icon icon="svg-spinners:180-ring-with-bg" className="text-muted size-6" />
      </div>
      <div className="px-4 pb-4">
        <div className="bg-neutral-emphasis h-8 w-[80%] animate-pulse rounded-sm" />
      </div>
    </div>
  );
}

export default ChatLoader;
