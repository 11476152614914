import { useCardClick } from '~/components/features/presearch/hook/use-card-click';
import useAddFilterStore from '~/stores/add-filter-store';
import { useTranslation } from 'react-i18next';
import { useCatalogSearch } from '~/hooks/api/catalog';
import { useGetProviders } from '~/hooks/api/dashboard';
import ItemSearch from '../../../search/item-search';
import Tab from '../tab';

function Catalog() {
  const addFilter = useAddFilterStore(['setIsOpen', 'query', 'setQuery']);
  const {
    data: catalogs,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useCatalogSearch(addFilter.query.catalog || '');
  const { data: providers } = useGetProviders();
  const { handleClick: addToFilter } = useCardClick('catalog');
  const { t } = useTranslation(['filter', 'common']);

  const flattenCatalogs = catalogs?.pages.flatMap((page) => page.hits) || [];

  function handleClick(name: string, id: string, operator: 'is' | 'is_not') {
    addToFilter(name, id, operator);
    addFilter.setIsOpen(false);
  }

  return (
    <Tab label={t('panel.catalogs.tab.title')} value="catalog">
      <ItemSearch
        itemType="catalog"
        inputPlaceholder={t('panel.catalogs.tab.input.placeholder')}
        value={addFilter.query.catalog}
        onValueChange={(q) => addFilter.setQuery('catalog', q)}
        items={flattenCatalogs?.map((catalog) => {
          const providerType = providers
            ?.find((provider) => provider.id === catalog.tenant.id)
            ?.organization_settings.meta.provider_types?.map((type) => {
              if (type === 'core') return t('music-type.core', { ns: 'common' });
              if (type === 'production') return t('music-type.prod', { ns: 'common' });
              return undefined;
            });

          return {
            title: catalog.name,
            subtitle: providerType
              ?.filter((item, index) => item && providerType.indexOf(item) === index)
              .sort()
              .join(', '),
            artworks: catalog.artwork ? [catalog.artwork] : [],
            onIncludeClick: () => handleClick(catalog.name, catalog.id, 'is'),
            onExcludeClick: () => handleClick(catalog.name, catalog.id, 'is_not'),
          };
        })}
        hasNextPage={hasNextPage}
        isFetchingNextPage={isFetchingNextPage}
        totalCount={flattenCatalogs.length}
        fetchNextPage={fetchNextPage}
        isEndReached={!hasNextPage}
      />
    </Tab>
  );
}

export default Catalog;
