import classNames from 'classnames';
import { MouseEvent } from 'react';
import Chip from '~/components/ui/control/chip/chip';
import Icon from '~/components/ui/icon/icon';

type SearchChipProps = {
  value: string;
  color?: string;
  isExcluded?: boolean;
  className?: string;
  onChipClick?: (e: MouseEvent) => void;
  onRemoveClick?: (e: MouseEvent) => void;
};

const SearchChip = (props: SearchChipProps) => {
  const { value, color, isExcluded = false, className, onChipClick, onRemoveClick } = props;

  return (
    <Chip
      className={classNames(
        'filter-chip group/filter-chip border-control-rest bg-control-rest hover:bg-control-hover relative flex h-8 overflow-hidden border border-1 pr-1!',
        {
          'border-dashed!': isExcluded,
        },
        className,
      )}
      onClick={onChipClick}
    >
      {color ? (
        <div className="mr-[1px] h-3 w-3 rounded-sm" style={{ backgroundColor: color }} />
      ) : null}
      <span
        className={classNames('text-default font-normal', {
          'text-neutral line-through': isExcluded,
        })}
      >
        {value}
      </span>
      <div className="aspect-square h-4 w-4">
        <div
          className="group/close ml-auto flex aspect-square h-full items-center justify-center rounded-sm"
          onClick={onRemoveClick}
        >
          <Icon
            name="close-line"
            className="text-muted group-hover/close:text-default! text-[0.8rem]"
          />
        </div>
      </div>
    </Chip>
  );
};

export default SearchChip;
