import * as AlertDialogPrimitive from '@radix-ui/react-alert-dialog';
import classNames from 'classnames';

type AlertDialogTitleProps = {
  children: React.ReactNode;
  className?: string;
};

export function AlertDialogTitle(props: AlertDialogTitleProps) {
  const { children, className } = props;

  return (
    <AlertDialogPrimitive.Title className={classNames('mb-0.5 text-xl font-bold', className)}>
      {children}
    </AlertDialogPrimitive.Title>
  );
}
