import { playlistRoutes } from '~/routes/api';
import { globalStore } from '~/stores/global-store-v2';
import { Playlist, PlaylistSearchResultSchema } from '~/types/schemas/music/playlists.schema';
import { useLoadMore } from '~/hooks/utils/react-query/use-load-more';

export const useSamplerSearch = (query: string) => {
  const { user } = globalStore;

  return useLoadMore<Playlist>({
    url: playlistRoutes.search,
    schema: PlaylistSearchResultSchema,
    queryParams: {
      query,
      size: 50,
      filters: {
        nand: [
          {
            tenant: user.value?.tenant_id,
          },
        ],
      },
    },
  });
};
