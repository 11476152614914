import { createContext, PropsWithChildren, useContext, useMemo } from 'react';

type UploadSessionContextType = {
  context: string;
  contextId: string;
  session?: string;
};

type UploadSessionProviderProps = PropsWithChildren<UploadSessionContextType>;

const UploadSessionContext = createContext<UploadSessionContextType | null>(null);

export function useUploadSessionContext() {
  const context = useContext(UploadSessionContext);

  if (!context) {
    throw new Error('useUploadSessionContext must be used within a UploadSessionContext.Provider');
  }

  return context;
}

export function UploadSessionContextProvider(props: UploadSessionProviderProps) {
  const { children, context, contextId, session } = props;
  const contextValue = useMemo(
    () => ({ context, contextId, session }),
    [context, contextId, session],
  );

  return (
    <UploadSessionContext.Provider value={contextValue}>{children}</UploadSessionContext.Provider>
  );
}
